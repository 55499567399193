<template>
  <div
    class="contact-card tw-cursor-pointer tw-border tw-border-mp-blue tw-rounded tw-shadow"
    @click="goToAdditionalContact"
  >
    <div
      class="contact-card-title tw-h-12 tw-bg-mp-blue tw-text-white tw-flex tw-justify-center tw-items-center"
    >
      <h2>{{ contact.first_name + " " + contact.last_name }}</h2>
    </div>
    <div class="contact-card-body tw-px-4 tw-py-4 tw-bg-white tw-rounded-b">
      <div class="tw-grid tw-gap-2 md:tw-table">
        <div class="tw-block md:tw-table-row md:tw-my-6">
          <div
            class="tw-text-mp-blue tw-text-left md:tw-table-cell md:tw-align-top"
          >
            Email:
          </div>
          <div class="tw-text-left md:tw-table-cell md:tw-pl-4">
            <a
              :href="'mailto:' + contact.email"
              class="tw-text-blue-500 tw-hover:tw-underline"
              target="_blank"
              rel="noreferrer"
            >
              {{ contact.email }}
            </a>
          </div>
        </div>
        <div class="tw-block md:tw-table-row tw-mt-2 md:tw-my-6">
          <div
            class="tw-text-mp-blue tw-text-left md:tw-table-cell md:tw-align-top"
          >
            Mobile:
          </div>
          <div class="tw-text-left md:tw-table-cell md:tw-pl-4">
            <a
              href="tel:+1234567890"
              class="tw-text-blue-500 tw-hover:tw-underline"
              target="_blank"
              rel="noreferrer"
            >
              {{ contact.mobile }}
            </a>
          </div>
        </div>
        <div class="tw-block md:tw-table-row tw-mt-2 md:tw-my-6">
          <div
            class="tw-text-mp-blue tw-text-left md:tw-table-cell md:tw-align-top"
          >
            Address:
          </div>
          <div
            class="tw-text-left md:tw-table-cell md:tw-pl-4 tw-w-full md:tw-w-80"
          >
            {{ contact.address_readable }}
          </div>
        </div>
      </div>

      <div class="pets-container tw-overflow-x-auto tw-mt-4 tw-h-20 tw-w-full">
        <div class="pets tw-flex tw-justify-center">
          <div v-for="pet in contact.pets" :key="pet.id">
            <button
              class="pet tw-bg-mp-blue tw-text-white tw-p-1 tw-px-2 tw-ml-2 tw-rounded-2xl"
              @click="goToPet(pet.id)"
            >
              {{ pet.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contact: null,
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    goToAdditionalContact() {
      this.$router.push({
        name: "AdditionalContact",
        params: { uuid: this.contact.uuid },
      });
    },
    goToPet(id) {
      this.$router.push({ name: "PetProfile", params: { id } });
    },
  },
};
</script>

<style scoped>
/* Ensures that long addresses wrap */
.tw-w-full {
  word-wrap: break-word;
  white-space: normal;
}
</style>