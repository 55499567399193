<template>
  <v-container class="fill-height" fluid>
    <v-row justify="center">
      <v-col lg="7">
        <v-card allow height="100%">
          <!--          <v-data-table-->
          <!--            disablePagination-->
          <!--            :headers="headers"-->
          <!--            hideDefaultFooter-->
          <!--            :items="products"-->
          <!--          >-->
          <!--            <template v-slot:top>-->
          <!--              <v-toolbar flat>-->
          <!--                <v-toolbar-title class="grey&#45;&#45;text"-->
          <!--                  ><v-icon class="grey&#45;&#45;text" size="20">mdi-cart</v-icon-->
          <!--                  >&nbsp;Basket</v-toolbar-title-->
          <!--                >-->
          <!--              </v-toolbar>-->
          <!--            </template>-->
          <!--          </v-data-table>-->

          <v-toolbar flat>
            <v-toolbar-title class="black--text">
              <v-icon class="black--text" size="20">mdi-cart </v-icon
              >&nbsp;Basket
            </v-toolbar-title>
          </v-toolbar>
          <responsive-table
            class="tw-mt-8"
            :headers="headers"
            :values="products"
          >
          </responsive-table>
          <v-row justify="center">
            <v-col cols="12" sm="6" md="8">
              <v-btn
                v-if="!couponUserHasCode"
                @click="couponUserHasCode = !couponUserHasCode"
                class="transp-bkg no-shadow cap blue-text mob-text mt-3"
                >I have a coupon code
              </v-btn>
              <span v-if="couponUserHasCode" class="d-inline-flex">
                <v-text-field
                  class="mr-4"
                  label="Coupon code"
                  v-model="coupon"
                  @input="checkValidCoupon()"
                >
                  <template v-slot:append>
                    <v-icon color="#00a81f" v-if="couponChecked && couponValid"
                      >mdi-check-circle</v-icon
                    >
                    <v-icon color="#b54100" v-if="!couponValid && couponChecked"
                      >mdi-close-circle</v-icon
                    >
                  </template>
                </v-text-field>
                <v-btn
                  class="mt-3 white--text"
                  color="#0074b5"
                  @click="checkValidCoupon()"
                >
                  Check
                </v-btn>
              </span>
            </v-col>
            <v-col cols="6" md="4">
              <v-card-text
                class="font-weight-black black--text"
                id="order-total-text"
              >
                Order total: {{ this.orderTotal }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row justify="center mb-2 checkout-button-row">
            <v-btn
              class="cap white--text checkout-button"
              @click="checkout"
              color="#00b53f"
            >
              Checkout
            </v-btn>
          </v-row>
          <v-row justify="center">
            <!--            <input-->
            <!--            alt="Check out with Stripe"-->
            <!--            class="mb-4 checkout-button"-->
            <!--            @click="checkout"-->
            <!--            src="https://s3.eu-west-2.amazonaws.com/media.mypethq.io/payment-strip.svg"-->
            <!--            type="image"-->
            <!--            v-if="this.$store.getters.cartCount > 0"-->
            <!--            />-->
            <payment-strip
              alt="Check out with Stripe"
              class="mb-4 checkout-button tw-cursor-pointer"
              @click="checkout"
              type="image"
            ></payment-strip>
            <!--            <img v-if="this.$store.getters.cartCount > 0" alt="Check out with Stripe" @click="checkout" class="mb-4 checkout-button tw-cursor-pointer" src="@/assets/img/payment-strip.svg" />-->
          </v-row>
          <v-row justify="center">
            <input
              v-show="false"
              alt="Check out with PayPal"
              class="mb-4"
              id="paypal-button"
              src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/checkout-logo-large.png"
              type="image"
            />
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import StripeModel from "../models/Stripe";
import ResponsiveTable from "./shared/ResponsiveTable";
import PaymentStrip from "./shared/icons/PaymentStrip";

const runCheckout = async function (result) {
  const stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);

  stripe.redirectToCheckout({ sessionId: result.id });
};

export default {
  components: {
    ResponsiveTable,
    PaymentStrip,
  },
  data() {
    return {
      coupon: "",
      couponObject: Object,
      couponChecked: false,
      couponValid: false,
      couponUserHasCode: false,
      products: [],
      user: Object,
      headers: [
        {
          align: "start",
          class: "grey--text font-weight-black",
          text: "Item",
          sortable: false,
          value: "name",
        },
        {
          class: "grey--text font-weight-black",
          sortable: false,
          text: "Pet name",
          value: "pet_name",
        },
        // {
        //   class: "grey--text font-weight-black",
        //   sortable: false,
        //   text: "Quantity",
        //   value: "quantity",
        // },
        {
          class: "grey--text font-weight-black",
          sortable: false,
          text: "Price",
          value: "price",
        },
        // {
        //   class: "grey--text font-weight-black",
        //   sortable: false,
        //   text: "Subtotal",
        //   value: "subtotal",
        // },
      ],
    };
  },
  methods: {
    async checkout() {
      const data = {
        coupon: this.coupon,
      };

      const result = await StripeModel.checkout(data);

      if (result.data.free) {
        await this.$router.push({
          name: "CheckoutSuccessFreeVersion",
          params: { pets: result.data.registered_pets },
        });
      } else {
        await runCheckout(result.data);
      }
    },
    async checkValidCoupon() {
      if (this.coupon.length === 0) return false;

      const data = {
        coupon: this.coupon,
      };

      this.couponChecked = true;

      const result = await StripeModel.checkValidCoupon(data);

      this.couponObject = result.coupon;
      this.couponValid = result.valid;
    },
  },
  async beforeMount() {
    this.user = await this.retrieveUser();

    if (this.user.microchip_queue && this.user.microchip_queue.pet_count > 0) {
      var i;
      for (i = 0; i < this.user.microchip_queue.pets.length; i++) {
        let pet = this.user.microchip_queue.pets[i];
        this.products.push({
          id: pet.id,
          name: "Lifetime microchip registration",
          quantity: 1,
          pet_name: pet.name,
          price: 2.99,
        });
      }
    }
  },
  computed: {
    orderTotal() {
      let value = this.user.microchip_queue?.pet_count * 2.99;

      if (this.couponChecked && this.couponValid && this.couponObject) {
        value = value * (1 - this.couponObject.value / 100);
      }

      return parseFloat(value.toFixed(2));
    },
  },
};
</script>

<style>
.checkout-button-row {
}

.checkout-button {
  width: 250px;
}

#order-total-text {
  font-size: 1em;
}

#paypal-button {
  width: 250px;
}

.v-input__icon--append .v-icon {
  color: purple;
}
</style>