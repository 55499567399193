<template>
  <v-container class="fill-height" fluid>
    <back-button></back-button>
    <v-row align="center" justify="center">
      <v-col cols="12" md="9" sm="8">
        <v-card v-if="!loading" class="mx-auto mt-5" width="600">
          <v-toolbar color="blue-bkg" dark flat>
            <v-toolbar-title>Your pet's details</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" lazy-validation method="post">
              <v-alert v-if="form.errors.get('microchip')" outlined type="error"
              >This microchip is registered to another account. If you are the
                new keeper of this pet, please click
                <a
                    class="blue--text font-weight-bold"
                    href="https://mypethq.io/new-owner-transfer"
                >HERE</a
                >. If you have any questions please contact support@mypethq.io
              </v-alert>
              <v-text-field
                  v-model="microchipValue"
                  :error="form.errors.get('microchip')"
                  :rules="microchipRules"
                  autofocus
                  label="Microchip number"
                  required
                  @blur="onMicrochipInputBlur"
              ></v-text-field>
              <v-text-field
                  v-model="form.name"
                  :error="form.errors.get('name')"
                  :rules="textFieldRules"
                  label="Pet name"
                  required
                  @blur="checkFreeInsuranceEligible"
              ></v-text-field>
              <v-select
                  v-model="form.type"
                  :error="form.errors.get('type')"
                  :items="petTypes"
                  label="Type of pet"
                  @blur="checkFreeInsuranceEligible"
                  @change="populateBreeds()"
              ></v-select>
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="computedDateFormatted"
                      label="Date of birth (or best estimate)"
                      prepend-icon="mdi-calendar-range"
                      readonly
                      :error="form.errors.get('dob')"
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    ref="picker"
                    v-model="date"
                    :max="new Date().toISOString().substr(0, 10)"
                    color="#0074b5"
                    min="1950-01-01"
                    @change="save"
                ></v-date-picker>
              </v-menu>
              <p>Sex</p>
              <v-btn-toggle
                  v-model="form.sex"
                  color="primary"
                  dense
                  @change="checkFreeInsuranceEligible"
              >
                <v-btn value="male"> Male</v-btn>

                <v-btn value="female"> Female</v-btn>

                <v-btn value="unknown"> Unknown</v-btn>
              </v-btn-toggle>
              <v-combobox
                  v-if="
                  form.type === 'Dog' ||
                  form.type === 'Cat' ||
                  form.type === 'Lizard' ||
                  form.type === 'Bird' ||
                  form.type === 'Tortoise'
                "
                  :items="this.petBreeds"
                  clearable
                  label="Breed"
                  @blur="checkFreeInsuranceEligible"
                  @update:search-input="updateBreed"
              ></v-combobox>
              <v-text-field
                  v-model="form.colour"
                  :error="form.errors.get('colour')"
                  label="Colour"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="form.distinguishing_features"
                  label="Distinguishing features"
                  required
              ></v-text-field>
              <p>Spayed / Neutered</p>
              <v-btn-toggle
                  v-model="neutered"
                  class="mb-4"
                  color="primary"
                  dense
              >
                <v-btn value="1"> Yes</v-btn>

                <v-btn value="0"> No</v-btn>

                <v-btn value="null"> Unknown</v-btn>
              </v-btn-toggle>
              <p class="mt-4">
                You must fully complete all your personal details to comply with
                microchip regulations.
              </p>
              <v-alert
                  v-if="userFieldsMissing.length === 0"
                  dense
                  outlined
                  type="success"
              >
                All your personal details have been completed. Please keep them
                up to date!
              </v-alert>
            </v-form>

            <insurance-option
                v-if="showFreeInsuranceOffer"
                :checked="freeInsurance"
                :crossbreedText="crossbreedText"
                :name="form.name"
                flow="organic"
                :pet="insurancePet"
                @input="freeInsurance = !freeInsurance"
                @toggleHelp="toggleInsuranceHelp"
            ></insurance-option>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="grey-bkg" @click="$router.go(-1)">Back</v-btn>
            <v-btn
                :disabled="!valid"
                color="blue-bkg"
                type="submit"
                @click="submit()"
            >Next
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-progress-circular
            v-if="loading"
            :size="100"
            :width="8"
            class="mt-12"
            color="#0074b5"
            indeterminate
        ></v-progress-circular>
      </v-col>
      <microchip-already-assigned-modal
          v-if="showMicrochipAlreadyAssignedModal"
          :microchip="microchipValue"
          :breed="existingBreed"
          :sex="existingSex"
          :type="existingType"
          :existsInUserAccount="existsInUserAccount"
          @confirm="microchipAlreadyAssignedModalConfirm"
          @decline="microchipAlreadyAssignedModalDecline"
      ></microchip-already-assigned-modal>
      <duplicate-registration-modal
          v-if="showDuplicateRegistrationModal"
          :database-name="externalDatabaseName"
          :database-url="'https://' + externalDatabaseUrl"
          @close="toggleDuplicateRegistration"
      ></duplicate-registration-modal>
      <pet-transfer-requested-modal v-if="showPetTransferModal">
      </pet-transfer-requested-modal>
    </v-row>
    <v-row align="center" justify="center">
      <insurance-help-modal
          :modalHeadline="
          'Congratulations! ' + form.name + ' is eligible for FREE insurance.'
        "
          :visible="showInsuranceHelp"
          @close="toggleInsuranceHelp"
      >
      </insurance-help-modal>
    </v-row>
  </v-container>
</template>

<script>
import Pet from "../models/Pet";
import moment from "moment";
import PetTransferRequestedModal from "@/components/modals/PetTransferRequestedModal";
import MicrochipAlreadyAssignedModal from "@/components/modals/MicrochipAlreadyAssignedModal";
import DuplicateRegistrationModal from "@/components/modals/DuplicateRegistrationModal";
import Microchip from "@/models/Microchip";
import Agria from "@/models/Agria";
import InsuranceOption from "@/components/InsuranceOption";
import InsuranceHelpModal from "@/components/modals/InsuranceHelpModal";
import BackButton from "@/components/shared/BackButton.vue";

export default {
  components: {
    BackButton,
    InsuranceOption,
    InsuranceHelpModal,
    MicrochipAlreadyAssignedModal,
    PetTransferRequestedModal,
    DuplicateRegistrationModal,
  },
  props: {
    user: Object,
    userFieldsMissing: Array,
    microchip: String,
  },
  data() {
    const data = {
      name: "",
      dob: "",
      breed: "",
      colour: "",
      distinguishing_features: "",
      microchip: "",
      sex: "unknown",
      type: null,
    };

    return {
      microchipRules: [
        (v) => !!v || "Microchip is required",
        (v) =>
            (v && (v.length === 10 || v.length === 15) && /^\d+$/.test(v)) ||
            "Microchip must be 10 or 15 numbers",
      ],
      menu: false,
      breeds: [],
      petTypes: [
        "Dog",
        "Cat",
        "Rabbit",
        "Hamster",
        "Horse",
        "Lizard",
        "Bird",
        "Tortoise",
        "Ferret",
        "Guinea pig",
        "Other",
        "Unknown",
      ],
      showPetTransferModal: false,
      showMicrochipAlreadyAssignedModal: false,
      showDuplicateRegistrationModal: false,
      externalDatabaseName: "",
      externalDatabaseUrl: "",
      petBreeds: [],
      neutered: "unknown",
      date: "",
      existingBreed: "",
      existingSex: "",
      existingType: "",
      existsInUserAccount: false,
      freeInsurance: false,
      loading: false,
      insurancePet: Object,
      microchipValue: "",
      crossbreedText: "",
      modal: false,
      showFreeInsuranceOffer: false,
      hasLivePolicy: true,
      showInsuranceHelp: false,
      textFieldRules: [(v) => !!v || "Required"],
      valid: true,
      form: new window.Form(data, async (form) => {
        this.form.dob = moment(this.date).format("ddd DD MMM YYYY");
        this.form.microchip = this.microchipValue;

        if (this.neutered === "1") {
          this.form.neutered = true;
        } else if (this.neutered === "0") {
          this.form.neutered = false;
        } else {
          this.form.neutered = null;
        }

        const pet = await Pet.create(form);

        this.scrollToTop();
        this.loading = true;

        await this.$store.commit("update_cart", this.user);

        await this.$router.push({
          name: "PetRegistrationStaging",
          query: {pet: pet.id, flow: "organic"},
        });
      }),
    };
  },
  beforeMount() {
    this.initialize();
  },
  computed: {
    computedDateFormatted() {
      return this.date ? moment(this.date).format("dddd, MMMM Do YYYY") : "";
    },
    toScroll: function () {
      return this.form.errors.get("microchip") || this.form.errors.get("dob");
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    toScroll: function (newVal, oldVal) {
      if (newVal) {
        this.scrollToTop();
      }
    },
  },
  async created() {
    this.showFreeInsuranceOffer = await this.freeInsuranceEligible();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    toggleInsuranceHelp() {
      this.showInsuranceHelp = !this.showInsuranceHelp;
    },
    toggleDuplicateRegistration() {
      this.showDuplicateRegistrationModal =
          !this.showDuplicateRegistrationModal;
    },
    async checkFreeInsuranceEligible() {
      this.showFreeInsuranceOffer = await this.freeInsuranceEligible();
    },
    async freeInsuranceEligible() {
      if (this.user.role === "rescue" || this.user.role === "breeder") {
        return;
      }

      const pet = {
        ...this.form,
        ...{
          dob: this.date,
          microchip: this.microchipValue,
          route: "organic",
        },
      };

      this.insurancePet = pet;

      const petHasPrerequisites = Agria.petHasPrerequisites(pet);

      if (!petHasPrerequisites) {
        return false;
      }

      if (this.showFreeInsuranceOffer) {
        return true;
      }

      if (petHasPrerequisites && !this.hasLivePolicy) {
        const response = await Agria.freeInsuranceCheck(pet);

        this.crossbreedText = response.CrossbreedText || "";

        return response.CanOfferInsurance;
      }

      return false;
    },
    onMicrochipInputBlur() {
      this.checkMicrochipRegistered();
      this.hasLiveAgriaPolicy();
    },
    async checkMicrochipRegistered() {
      if (
          this.microchipValue.length === 10 ||
          this.microchipValue.length === 15
      ) {
        const internalResponse =
            await Microchip.isMicrochipRegisteredInternally(this.microchipValue);

        this.existingBreed = internalResponse.breed;
        this.existingSex = internalResponse.sex;
        this.existingType = internalResponse.type;
        this.existsInUserAccount = internalResponse.belongs_to_requester;

        if (internalResponse.registered) {
          this.showMicrochipAlreadyAssignedModal = true;
        } else {
          const externalResponse =
              await Microchip.isMicrochipRegisteredExternally(
                  this.microchipValue
              );
          this.externalDatabaseName = externalResponse.database_name;
          this.externalDatabaseUrl = externalResponse.database_url;
          this.showDuplicateRegistrationModal = externalResponse.registered;
          if (this.showDuplicateRegistrationModal) {
            this.$mixpanel.track(
                "Duplicate microchip registration attempt - external.",
                {
                  microchip: this.microchipValue,
                }
            );
            this.microchipValue = "";
          }
        }
      }
    },
    async hasLiveAgriaPolicy() {
      if (
          this.microchipValue &&
          (this.microchipValue.length === 10 || this.microchipValue.length === 15)
      ) {
        this.hasLivePolicy = await Agria.livePolicyCheck(this.microchipValue);
      }
      await this.checkFreeInsuranceEligible();
    },
    initialize() {
      if (this.microchip) {
        this.microchipValue = this.microchip;
      }
    },
    save(date) {
      this.form.errors.clear();
      this.$refs.menu.save(date);
      this.checkFreeInsuranceEligible();
    },
    submit() {
      this.form.submit();
    },
    updateBreed(currentValue) {
      this.form.breed = currentValue;
    },
    async microchipAlreadyAssignedModalConfirm() {
      const result = await Microchip.duplicateRequest(this.microchipValue);

      this.showPetTransferModal = true;
    },
    microchipAlreadyAssignedModalDecline() {
      this.showMicrochipAlreadyAssignedModal = false;
      this.microchipValue = "";
    },
    populateBreeds() {
      if (this.form.type === "Dog") {
        this.petBreeds = [
          "Unknown",
          "Crossbreed",
          "Affenpinscher",
          "Afghan Hound",
          "Airedale Terrier",
          "Akita Inu",
          "Alaskan Malamute",
          "Am Staff ",
          "American Coonhound",
          "American Eskimo Dog",
          "American Foxhound",
          "American Pit Bull Terrier",
          "American Water Spaniel",
          "Anatolian Shepherd Dog",
          "Australian Cattle",
          "Australian Shepherd",
          "Australian Terrier",
          "Azawakh",
          "Basenji",
          "Basset Hound ",
          "Beagle",
          "Bearded Collie",
          "Beauceron",
          "Bedlington Terrier",
          "Belgian Laekenois",
          "Belgian Malinois",
          "Belgian Sheepdog",
          "Belgian Tervuren",
          "Bergamasco",
          "Bernese Mountain Dog",
          "Bichon Frisé",
          "Biewer Terrier",
          "Black & Tan Coonhound",
          "Black Russian Terrier",
          "Bloodhound",
          "Bluetick Coonhound",
          "Boerboel",
          "Border Collie",
          "Border Terrier",
          "Borzoi",
          "Boston Terrier",
          "Bouvier des Flandres",
          "Boxer",
          "Briard",
          "Brittany",
          "Brussels Griffon",
          "Bull Terrier",
          "Bulldog - American",
          "Bullmastiff",
          "Cairn Terrier ",
          "Canaan Dog",
          "Cane Corso",
          "Cardigan Welsh Corgi",
          "Carolina Dog",
          "Cavalier King Charles",
          "Cesky Terrier ",
          "Chesapeake Bay Retriever",
          "Chihuahua",
          "Chinese Crested",
          "Chinese Shar-Pei",
          "Chinook",
          "Chow Chow",
          "Cirneco dell'Etna ",
          "Clumber Spaniel",
          "Cocker Spaniel - American",
          "Cocker Spaniel - English",
          "Collie",
          "Curly-Coated Retriever",
          "Dachshund",
          "Dachshund - Long-Haired",
          "Dachshund - Miniature Long Haired",
          "Dachshund - Smooth Haired",
          "Dachshund - Miniature Smooth haired",
          "Dachshund - Wire Haired",
          "Dachshund - Miniature Wire Haired",
          "Dalmatian",
          "Dandie Dinmont Terrier",
          "Doberman Pinscher",
          "Dogo Argentino",
          "English Bulldog",
          "English Foxhound",
          "English Pointer",
          "English Setter",
          "English Toy Spaniel",
          "Entlebucher Mountain Dog",
          "Field Spaniel",
          "Fila Brasileiro",
          "Finnish Lapphund",
          "Finnish Spitz",
          "Flat Coated Retriever",
          "French Bulldog",
          "German Pinscher",
          "German Shepherd",
          "German Shorthaired Pointer",
          "German Wirehaired Pointer",
          "Giant Schnauzer",
          "Glen of Imaal Terrier",
          "Golden Retriever",
          "Goldendoodle",
          "Gordon Setter",
          "Great Dane",
          "Great Pyrenees",
          "Greater Swiss Mountain Dog",
          "Greyhound",
          "Harrier",
          "Havana Silk Dog",
          "Havanese",
          "Ibizan Hound",
          "Icelandic Sheepdog",
          "Irish Setter",
          "Irish Terrier",
          "Irish Water Spaniel",
          "Irish Wolfhound",
          "Italian Greyhound",
          "Jack Chi",
          "Jack Russell Terrier",
          "Japanese Chin",
          "Keeshond",
          "Kerry Blue Terrier",
          "Komondor",
          "Kuvasz",
          "Labradoodle",
          "Labrador Retriever",
          "Lakeland Terrier",
          "Lhasa Apso",
          "Lowchen",
          "Maltese",
          "Manchester Terrier",
          "Mastiff",
          "Miniature Bull Terrier",
          "Miniature Pinscher",
          "Miniature Schnauzer",
          "NAID Breed",
          "Neapolitan Mastiff",
          "Newfoundland",
          "Norfolk Terrier",
          "Norwegian Buhund",
          "Norwegian Elkhound",
          "Norwegian Lundehund",
          "Norwich Terrier",
          "Nova Scotia Duck Tolling",
          "Old English Sheepdog",
          "Other",
          "Otterhound",
          "Papillon",
          "Parson Russell Terrier",
          "Pekingese",
          "Pembroke Welsh Corgi",
          "Peruvian Inca Orchid",
          "Petit Basset Griffon Vendéen",
          "Pharaoh Hound",
          "Plott Hound",
          "Polish Lowland",
          "Pomeranian",
          "Poodle - Standard",
          "Poodle - Toy",
          "Portuguese Podengo Pequeno ",
          "Portuguese Water Dog",
          "Pug",
          "Puli",
          "Pumi",
          "Pyrenean Shepherd",
          "Rat Terrier",
          "Redbone Coonhound",
          "Rhodesian Ridgeback",
          "Rottweiler",
          "Saint Bernard",
          "Saluki",
          "Samoyed",
          "Schipperke",
          "Scottish Deerhound",
          "Scottish Terrier",
          "Sealyham Terrier",
          "Shetland Sheepdog",
          "Shiba Inu",
          "Shih Tzu",
          "Siberian Husky",
          "Silky Terrier",
          "Skye Terrier",
          "Sloughi",
          "Smooth Fox Terrier",
          "Soft Coated Wheaten Terrier",
          "Spinone Italiano",
          "Springer Spaniel - English",
          "Staffordshire Bull Terrier",
          "Standard Schnauzer",
          "Sussex Spaniel",
          "Swedish Vallhund",
          "Tibetan Mastiff",
          "Tibetan Spaniel",
          "Tibetan Terrier",
          "Toy Fox Terrier",
          "Treeing Walker Coonhound",
          "Unkown",
          "Vizsla",
          "Weimaraner",
          "Welsh Springer Spaniel",
          "Welsh Terrier",
          "West Highland White Terrier",
          "Whippet",
          "Wire Fox Terrier",
          "Wirehaired Pointing Griffon",
          "Wirehaired Vizsla",
          "Xoloitzcuintli",
          "Yorkshire Terrier",
        ];
      } else if (this.form.type === "Tortoise") {
        this.petBreeds = ["Hermann",
          "Ibera",
          "Whitei",
          "Marginated",
          "Horsefield",
          "Leopard",
          "Sulcata",
          "Other MST",
          "Other Tropical",
          "Unknown"];
      } else if (this.form.type == "Cat") {
        this.petBreeds = [
          "Unknown",
          "Crossbreed",
          "Abyssinian",
          "Aegean",
          "American Bobtail",
          "American Curl",
          "American Shorthair",
          "American Wirehair",
          "Aphrodite Giant",
          "Arabian Mau",
          "Asian",
          "Asian Semi-longhair",
          "Australian Mist",
          "Balinese",
          "Bambino",
          "Bengal",
          "Birman",
          "Bombay",
          "Brazilian Shorthair",
          "British Longhair",
          "British Semi-longhair",
          "British Shorthair",
          "Burmese",
          "Burmilla",
          "California Spangled",
          "Chantilly-Tiffany",
          "Chartreux",
          "Chausie",
          "Cheetoh",
          "Colorpoint Longhair",
          "Colorpoint Persian",
          "Colorpoint Shorthair",
          "Cornish Rex",
          "Cymric, or",
          "Cyprus",
          "Devon Rex",
          "Don Sphynx",
          "Donskoy",
          "Dragon Li",
          "Dwelf",
          "Egyptian Mau",
          "European Shorthair",
          "Exotic Shorthair",
          "Foldex",
          "Foreign Longhair",
          "German Rex",
          "Havana Brown",
          "Highlander",
          "Himalayan, or",
          "Japanese Bobtail",
          "Javanese, or",
          "Karelian Bobtail",
          "Khao Manee",
          "Korat",
          "Korean Bobtail",
          "Korn Ja",
          "Kuril Islands Bobtail",
          "Kurilian Bobtail",
          "LaPerm",
          "Longhaired Manx",
          "Lykoi",
          "Maine Coon",
          "Mandarin",
          "Manx",
          "Manx Longhair",
          "Mekong Bobtail",
          "Minskin",
          "Munchkin",
          "Napoleon",
          "Nebelung",
          "Norwegian Forest cat",
          "Ocicat",
          "Ojos Azules",
          "Oregon Rex",
          "Oriental Bicolor",
          "Oriental Longhair",
          "Oriental Shorthair",
          "Other",
          "Persian (modern)",
          "Persian (traditional)",
          "Peterbald",
          "Pixie-bob",
          "Raas",
          "Ragamuffin, or",
          "Ragdoll",
          "Russian Black",
          "Russian Blue",
          "Russian Tabby",
          "Russian White",
          "Sam Sawet",
          "Savannah",
          "Scottish Fold",
          "Selkirk Rex",
          "Serengeti",
          "Serrade petit",
          "Siamese",
          "Siamese (modern)",
          "Siberian",
          "Siberian Forest Cat",
          "Singapura",
          "Snowshoe",
          "Sokoke",
          "Somali",
          "Sphynx",
          "Suphalak",
          "Thai Lilac",
          "Thai, or",
          "Tonkinese",
          "Toyger",
          "Turkish Angora",
          "Turkish Van",
          "Ukrainian Levkoy",
          "Unknown",
          "Wichien Maat",
          "Wila Krungthep",
          "York Chocolate",
        ];
      } else if (this.form.type == "Lizard") {
        this.petBreeds = [
          "Unknown",
          "Chinese water dragon",
          "Leopard gecko",
          "Tokay gecko",
          "Bearded Dragon",
          "Crested gecko",
          "Monitor lizards",
          "Blue tongued skink",
          "Frilled dragon",
          "Uromastyx",
          "Veiled chameleon",
        ];
      } else if (this.form.type == "Bird") {
        this.petBreeds = [
          "Unknown",
          "Budgerigars",
          "Caiques",
          "Canaries",
          "Cockatiels",
          "Cockatoos",
          "Finches",
          "Grey Parrots",
          "Macaws",
        ];
      }
    },
  },
};
</script>