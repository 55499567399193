<template>
  <div v-if="!loading" class="tw-flex tw-flex-col tw-items-center">
    <label
        class="tw-block tw-w-32 tw-h-32 tw-py-4 tw-rounded-full tw-overflow-hidden tw-border tw-border-gray-300 tw-cursor-pointer tw-bg-white"
        @click="onFileLabelClicked"
    >
      <input
          @change="onFileChange"
          :type="
          clickable && (subscriptionType === 'premium' || isAuthorisedUser)
            ? 'file'
            : 'number'
        "
          class="tw-hidden"
      />
      <div class="tw-flex tw-justify-center tw-items-center tw-h-full">
        <camera-upload-icon
            v-if="!imageUrl && !uploading"
            alt="camera upload icon"
            class="mb-4 checkout-button tw-cursor-pointer"
            type="image"
        ></camera-upload-icon>
        <div v-if="uploading" class="tw-flex tw-justify-center tw-items-center">
          <div class="spinner"></div>
        </div>
        <div
            v-if="
            (subscriptionType === 'premium' || this.isAuthorisedUser) &&
            !!imageUrl &&
            !uploading
          "
        >
          <img :src="imageUrl" alt="Pet Profile Image"/>
        </div>
      </div>
    </label>
    <premium-upgrade-modal
        v-if="showPremiumUpgradeModal"
        @upgrade="createSubscription"
        @close="closePremiumUpgradeModal"
    ></premium-upgrade-modal>
    <div v-if="errorMessage" class="tw-text-red-500 tw-mt-2">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import Compressor from "compressorjs";
import CameraUploadIcon from "@/components/shared/icons/CameraUploadIcon.vue";
import PremiumUpgradeModal from "@/components/modals/PremiumUpgradeModal.vue";
import File from "@/models/File";

export default {
  components: {
    CameraUploadIcon,
    PremiumUpgradeModal,
    File,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    entityUuid: {
      type: String,
      default: "",
    },
    hasFileUploads: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageUrl: null,
      subscriptionType: "basic",
      isAuthorisedUser: false,
      showPremiumUpgradeModal: false,
      expanded: false,
      loading: true,
      uploading: false,
      errorMessage: null,
    };
  },
  methods: {
    closePremiumUpgradeModal() {
      this.showPremiumUpgradeModal = false;
    },
    async createSubscription() {
      if (this.subscriptionType === "basic") {
        await this.$router.push({name: "SubscriptionPlansPage"});
      }
      this.showPremiumUpgradeModal = false;
    },
    onFileLabelClicked(e) {
      if (this.clickable && this.subscriptionType === "basic") {
        this.showPremiumUpgradeModal = true;
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.errorMessage = null;

      if (this.clickable && this.subscriptionType === "premium") {
        if (file) {
          new Compressor(file, {
            quality: 0.6, // Adjust quality as needed (0.6 means 60% quality)
            success: (compressedFile) => {
              if (compressedFile.size > 4 * 1024 * 1024) {
                this.errorMessage = "File size should be less than 4MB.";
                return;
              }
              this.getPresignedUrlAndUpload(
                  compressedFile,
                  file.name.split(".").pop()
              );
            },
            error: (err) => {
              this.errorMessage = err.message;
            },
          });
        }
      } else if (this.clickable) {
        this.showPremiumUpgradeModal = true;
      }
    },
    async getPresignedUrlAndUpload(file, extension) {
      this.uploading = true;

      try {
        const response = await File.getPresignedUrl(
            file,
            "pet",
            this.entityUuid,
            extension
        );
        await this.uploadFileToS3(file, response.url, response.uuid);
      } catch (error) {
        this.errorMessage = "Error obtaining pre-signed URL.";
        this.uploading = false;
      }
    },
    async uploadFileToS3(file, presignedUrl, fileUuid) {
      try {
        const result = await fetch(presignedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": file.type, // Use the MIME type here
          },
          body: file,
        });

        if (!result.ok) {
          throw new Error("Upload failed");
        }

        this.imageUrl = presignedUrl.split("?")[0];
        await File.store(fileUuid, this.entityUuid);
        this.hasFileUploads = true;
        await this.fetchImage();
        this.uploading = false;
      } catch (error) {
        this.errorMessage = "Error uploading file to S3.";
        this.uploading = false;
      }
    },
    async fetchImage() {
      if (
          this.hasFileUploads &&
          (this.subscriptionType === "premium" || this.isAuthorisedUser)
      ) {
        try {
          const response = await window.Http.get(
              "/api/v2/pets/" + this.entityUuid + "/images"
          );
          this.imageUrl = "data:image/png;base64," + response.image;
        } catch (error) {
          console.error("Error fetching image:", error);
        }
      }
    },
  },
  async mounted() {
    this.subscriptionType = await this.getSubscriptionType();
    this.isAuthorisedUser =
        this.$store.state.user?.role === "rescue" ||
        this.$store.state.user?.role === "microchip_authorised_user";
    await this.fetchImage();
    this.loading = false;
  },
};
</script>

<style>
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>