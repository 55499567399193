import { render, staticRenderFns } from "./ResentCertificateModal.vue?vue&type=template&id=01f3bbae&scoped=true"
import script from "./ResentCertificateModal.vue?vue&type=script&lang=js"
export * from "./ResentCertificateModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01f3bbae",
  null
  
)

export default component.exports