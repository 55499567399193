<template>
  <div>
    <h2 class="tw-text-4xl tw-mt-4">Your plan</h2>
    <div class="md:tw-flex tw-w-full tw-mt-8">
      <div class="md:tw-w-1/2 sm:tw-w-full">
        <my-pet-basic-subscription-panel
          :show-relative="true"
          @acceptBasic="acceptBasic"
        ></my-pet-basic-subscription-panel>
      </div>
      <div class="md:tw-w-1/2 sm:tw-w-full">
        <my-pet-premium-subscription-panel
          :show-relative="true"
          @acceptPremium="acceptPremium"
        ></my-pet-premium-subscription-panel>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon } from "@vue-hero-icons/solid";
import MyPetBasicSubscriptionPanel from "@/components/shared/MyPetBasicSubscriptionPanel.vue";
import MyPetPremiumSubscriptionPanel from "@/components/shared/MyPetPremiumSubscriptionPanel.vue";
import StripeModel from "@/models/Stripe";

const runCheckout = async function (result) {
  const stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
  stripe.redirectToCheckout({ sessionId: result.id });
};
export default {
  components: {
    MyPetPremiumSubscriptionPanel,
    MyPetBasicSubscriptionPanel,
    CheckCircleIcon,
  },
  data() {
    return {
      subscriptionType: "basic",
    };
  },
  async mounted() {
    this.subscriptionType = await this.getSubscriptionType();
  },
  methods: {
    async acceptBasic() {
      await this.$router.push({ name: "Checkout" });
    },
    async acceptPremium() {
      const result = await StripeModel.subscribe();
      await runCheckout(result.data);
    },
  },
};
</script>
<style scoped></style>