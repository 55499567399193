<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="9" sm="8">
        <v-alert class="mx-auto" color="#00b53f" outlined type="success">
          <div class="tw-flex tw-flex-col">
            <div
                v-if="$route.query.action === 'referred-pet-transfer-to'"
                class="mb-2"
            >
              Thanks, your pet has been successfully transferred.
            </div>
            <div class="mb-2">
              Please create a password for your new account below.
            </div>
          </div>
        </v-alert>
        <v-alert class="mx-auto" color="#B54100" outlined type="info">
          <div>This page will expire after 10 minutes.</div>
        </v-alert>
        <v-form ref="form" v-model="valid" lazy-validation method="post">
          <v-card class="mx-auto mt-5">
            <v-toolbar class="blue-bkg no-shadow">
              <v-toolbar-title>Confirm your password</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-text-field
                  v-model="password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show ? 'text' : 'password'"
                  counter
                  label="New Password"
                  prepend-icon="mdi-lock"
                  @click:append="show = !show"
              ></v-text-field>
              <v-text-field
                  v-model="passwordConfirmation"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min, passwordConfirmationRule]"
                  :type="show1 ? 'text' : 'password'"
                  counter
                  label="Confirm Password"
                  prepend-icon="mdi-lock"
                  @click:append="show1 = !show1"
              ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions
                class="justify-center"
                style="flex-direction: column"
            >
              <v-btn
                  :disabled="!valid"
                  class="cap"
                  color="blue-bkg"
                  @click="form.submit()"
              >
                Create password
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import User from "../models/User";

export default {
  components: {},
  props: {
    pet: String,
    token: String,
  },
  data() {
    const initialData = {};

    return {
      form: new window.Form(initialData, async (form) => {
        this.form.createNewPassword = this.password;
        this.form.createNewPasswordConfirm = this.passwordConfirmation;

        if (this.$route.query.action) {
          const action = this.$route.query.action;

          if (action === "referred-microchip-registration") {
            this.form.microchip_registration = true;
            this.form.microchip = this.$route.query.microchip;
          }
        }

        await this.user.update(form);

        if (this.$route.query.action === "referred-microchip-registration") {
          window.location = "/confirm-details?flow=myvet";
        }

        if (this.$route.query.action === "referred-pet-transfer-to") {
          window.location = "/confirm-details?flow=rehome";
        }
      }),
      password: "",
      passwordConfirmation: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      show: false,
      show1: false,
      user: User,
      valid: false,
    };
  },
  computed: {
    passwordConfirmationRule() {
      return (
          this.password === this.passwordConfirmation || "Password must match"
      );
    },
  },
  methods: {
    async authenticate() {
      await window.Http.setAuthorizationToken(this.token);
      const user = this.retrieveUser();
      const t = this.token;

      await this.$store.commit("auth_success", {t, user});

      return user;
    },
  },
  async mounted() {
    this.user = await this.authenticate();
  },
};
</script>

<style scoped></style>