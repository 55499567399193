<template>
  <div
    class="vet-card tw-cursor-pointer tw-border tw-border-mp-blue tw-rounded tw-shadow"
    @click="goToVet"
  >
    <div
      class="vet-card-title tw-h-12 tw-bg-mp-blue tw-text-white tw-flex tw-justify-center tw-items-center"
    >
      <h2>{{ vet.name }}</h2>
    </div>
    <div class="vet-card-body tw-px-4 tw-py-4 tw-h-62 tw-bg-white tw-rounded-b">
      <div class="phone tw-mt-2">
        <span class="md:tw-flex tw-items-center">
          <h3 class="tw-mp-blue tw-font-semibold">Phone:</h3>
          <div class="tw-text-blue-500 tw-hover:tw-underline md:tw-ml-8">
            {{ vet.phone }}
          </div>
        </span>
      </div>

      <div class="address tw-mt-2">
        <span class="md:tw-flex tw-items-center">
          <h3 class="tw-mp-blue tw-font-semibold">Address:</h3>
          <div class="tw-hover:tw-underline md:tw-ml-8">
            {{ vet.address }}
          </div>
        </span>
      </div>

      <div class="pets-container tw-overflow-x-auto tw-mt-8 tw-h-20 tw-w-full">
        <div class="pets tw-flex tw-justify-center tw-mt-2">
          <div v-for="pet in vet.pets" :key="pet.id">
            <button
              class="pet tw-bg-mp-blue tw-text-white tw-p-1 tw-px-2 tw-ml-2 tw-rounded-2xl"
              @click="goToPet(pet.id)"
            >
              {{ pet.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    vet: null,
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    goToVet() {
      this.$router.push({
        name: "Vet",
        params: { uuid: this.vet.uuid },
      });
    },
    goToPet(id) {
      this.$router.push({ name: "PetProfile", params: { id } });
    },
  },
};
</script>

<style scoped>
.text.single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>