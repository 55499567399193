<template>
  <div>
    <back-button></back-button>
    <v-col class="d-flex justify-center">
      <v-img
        :src="require('../assets/mypet-db-logo-tagline.svg')"
        class="my-3"
        contain
        height="200"
      />
    </v-col>
    <v-col class="d-flex justify-center">
      <v-text-field
        v-model="chipnumber"
        dense
        filled
        label="Search microchip here"
        rounded
        style="max-width: 450px"
        @keydown.enter="search"
      ></v-text-field>
      <!-- <v-btn
              v-if="displayOtherDatabases"
              @click="search"
              color="#0074b5"
              large
              class="ml-2 mt-1"
              ><v-icon color="white">mdi-magnify</v-icon></v-btn
            > -->
    </v-col>
    <v-btn
      class="cap white--text mb-2"
      color="#0074b5"
      depressed
      @click="search"
    >
      Search
      <v-icon class="ml-1">mdi-magnify</v-icon>
    </v-btn>

    <p class="tw-text-xl tw-text-black mt-4">
      Search all Defra compliant databases in the UK
    </p>

    <p v-if="mp_loading" class="font-weight-medium tw-text-xl tw-text-black">
      Searching the MyPet database...
    </p>
    <v-progress-circular
      v-if="mp_loading"
      :size="40"
      :width="4"
      color="#0074b5"
      indeterminate
    ></v-progress-circular>

    <v-card-text v-if="mp_error_message" class="tw-text-black">
      <v-icon class="white-text">mdi-alert-circle</v-icon>
      {{ this.mp_error_message }}
    </v-card-text>

    <div
      v-if="
        mc_status === 'FALSE' && mp_loading === false && !this.mp_error_message
      "
      class="tw-flex tw-items-center tw-justify-center"
    >
      <v-alert
        class="tw-text-mp-blue tw-text-lg tw-w-8/12"
        outlined
        type="error"
      >
        We don't have a record of this pet on our database.
      </v-alert>
    </div>

    <div
      v-else-if="mc_status === 'TRUE'"
      class="tw-flex tw-items-center tw-justify-center"
    >
      <v-alert
        v-if="belongs_to_requester"
        class="tw-w-10/12 md:tw-w-6/12"
        color="#00b53f"
        outlined
        type="success"
      >
        This pet exists in your account. Click

        <v-btn
          class="blue--text font-weight-bold"
          text
          @click="$router.push({ name: 'UserPets' })"
          >Here
        </v-btn>

        to go to your pets
      </v-alert>
      <div v-if="!belongs_to_requester" class="tw-w-10/12 md:tw-w-6/12">
        <v-alert
          v-if="!unregistered_on_myvet"
          color="#00b53f"
          outlined
          type="success"
        >
          This pet exists on our database. If you are the owner you can login
          <v-btn
            class="blue--text font-weight-bold"
            text
            @click="$router.push({ name: 'UserLogin' })"
            >Here
          </v-btn>
          . If you are the new keeper and need to transfer the pet to your name
          click
          <a
            class="blue--text font-weight-bold"
            href="https://www.mypethq.io/help-centre/im-the-new-owner-of-a-pet-how-do-i-transfer-them-to-my-details-account"
            >HERE</a
          >. If you are a dog warden or another authorised user, please
          <v-btn
            class="blue--text font-weight-bold"
            text
            @click="$router.push({ name: 'UserLogin' })"
            >Login
          </v-btn>
          or
          <v-btn
            class="blue--text font-weight-bold"
            text
            @click="
              $router.push({ name: 'MicrochipAuthorisedUserRegistration' })
            "
            >Register.
          </v-btn>
        </v-alert>
        <v-alert
          v-if="unregistered_on_myvet"
          color="#00b53f"
          outlined
          type="success"
        >
          This is a MyPet microchip. If you are the keeper of this pet click
          <v-btn
            class="blue--text font-weight-bold"
            text
            @click="$router.push({ name: 'UserRegister' })"
            >HERE
          </v-btn>
          to register. If you are seeking information on this pet please contact
          us by clicking
          <a
            class="blue--text font-weight-bold"
            href="https://www.mypethq.io/open-a-support-ticket/"
            target="_blank"
            >HERE</a
          >.
        </v-alert>
      </div>
    </div>

    <div
      v-else-if="mc_status === 'INFO'"
      class="tw-flex tw-items-center tw-justify-center"
    >
      <v-alert
        v-if="belongs_to_requester"
        class="tw-w-10/12 md:tw-w-6/12"
        color="#0074b5"
        outlined
        type="info"
        >Contact us for more information on this microchip. You can email us at
        <a href="mailto:support@mypethq.io">support@mypethq.io</a> or call us on
        0800 368 4150
      </v-alert>
      <v-alert
        v-if="!belongs_to_requester"
        class="tw-w-10/12 md:tw-w-6/12"
        color="#0074b5"
        outlined
        type="info"
        >This is a MyPet microchip. If you are the keeper of this pet click
        <v-btn
          class="blue--text font-weight-bold"
          text
          @click="$router.push({ name: 'UserRegister' })"
          >HERE
        </v-btn>
        to register. If you are seeking information on this pet please contact
        us by clicking
        <a
          class="blue--text font-weight-bold"
          href="https://www.mypethq.io/open-a-support-ticket/"
          target="_blank"
          >HERE</a
        >.
      </v-alert>
    </div>

    <p v-if="dbs_search_results.length > 0" class="tw-text-xl tw-text-black">
      We found information in the following databases:
    </p>
    <div v-if="dbs_loading">
      <p v-if="mp_loading" class="font-weight-medium tw-text-xl">
        Searching other databases...
      </p>
      <v-progress-circular
        :size="20"
        :width="2"
        class="mb-10"
        color="#0074b5"
        indeterminate
      ></v-progress-circular>
    </div>

    <div v-if="dbs_search_results.length > 0" class="tw-flex tw-justify-center">
      <v-alert
        class="tw-w-10/12 md:tw-w-full"
        color="#00b53f"
        outlined
        type="success"
      >
        <div v-for="db in dbs_search_results" :key="db.id">
          <p class="tw-text-black mr-6">{{ db.name }}</p>
          <p class="tw-flex tw-justify-center tw-items-center">
            <a
              :href="'https://' + db.website"
              class="v-markdown--link blue--text"
              rel="noopener"
              style="text-decoration: none; display: flex; align-items: center"
              target="_blank"
            >
              <span>{{ db.website_display || db.website }}</span>
              <v-icon class="blue--text darken-2" size="18"
                >mdi-open-in-new
              </v-icon>
            </a>
          </p>
        </div>
      </v-alert>
    </div>

    <div
      v-else-if="dbs_not_found"
      class="tw-flex tw-items-center tw-justify-center"
    >
      <v-alert
        class="tw-text-mp-blue tw-text-lg tw-w-8/12"
        outlined
        type="error"
      >
        We didn't find this pet in other microchip databases.
      </v-alert>
    </div>

    <v-btn
      v-if="
        !isLoggedIn &&
        dbs_not_found &&
        mc_status === 'FALSE' &&
        mp_loading === false &&
        !this.mp_error_message
      "
      class="cap white--text"
      color="#00b53f"
      @click="$router.push({ name: 'UserRegister' })"
      >Register now!
    </v-btn>
    <v-btn
      v-if="
        isLoggedIn &&
        dbs_not_found &&
        mc_status === 'FALSE' &&
        mp_loading === false &&
        !this.mp_error_message
      "
      class="cap white--text"
      color="#00b53f"
      @click="
        $router.push({ name: 'PetCreate', query: { microchip: chipnumber } })
      "
      >Register your pet now!
    </v-btn>
    <div class="tw-text-mp-blue tw-font-medium tw-text-2xl mb-8 mt-4">
      Why register with MyPet?
    </div>
    <offers></offers>
  </div>
</template>

<script>
import axios from "axios";
import Offers from "../components/shared/Offers";
import BackButton from "@/components/shared/BackButton.vue";

export default {
  props: {
    microchip: String,
  },
  components: { BackButton, Offers },
  data() {
    return {
      chipnumber: "",
      dbs_loading: false,
      dbs_not_found: false,
      dbs_search_results: [],
      mp_error_message: "",
      mp_loading: false,
      mp_search_results: [],
      mc_status: "NULL",
      belongs_to_requester: "NULL",
      unregistered_on_myvet: false,
      headers: [
        {
          text: "COMPANY",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "WEBSITE",
          align: "start",
          sortable: true,
          value: "website",
        },
      ],
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    async search() {
      await this.clearTable();

      if (!this.chipnumber) return;

      this.searchDbs();
      this.searchMypet();
    },
    async searchDbs() {
      this.dbs_loading = true;

      const dbs_response = await axios.get(
        process.env.VUE_APP_API_URL +
          "/mc_search?mc_number=" +
          this.chipnumber.replace(/ /g, "")
      );
      this.dbs_search_results = dbs_response.data.mc_dbs;
      if (this.dbs_search_results.length === 0) {
        this.dbs_not_found = true;
      }

      this.dbs_loading = false;
    },
    async searchMypet() {
      this.mp_loading = true;

      let ctx = this;

      let url =
        process.env.VUE_APP_API_URL +
        "/v2/lookup?chipnumber=" +
        this.chipnumber.replace(/ /g, "") +
        "&username=mypet&password=SQcR4FuhR*7";

      if (this.isLoggedIn) {
        url =
          process.env.VUE_APP_API_URL +
          "/api/v2/microchips?microchip=" +
          this.chipnumber.replace(/ /g, "");
      }

      const mp_response = await axios.get(url).catch(function (error) {
        if (error.response) {
          ctx.mp_error_message = error.response.data.error_message;
          ctx.mp_loading = false;
        }
      });

      this.mp_search_results = mp_response.data;
      this.belongs_to_requester = mp_response.data.belongs_to_requester;
      this.unregistered_on_myvet = mp_response.data.unregistered_on_myvet;

      if (this.mp_search_results.status === "TRUE") {
        this.mc_status = "TRUE";
      } else if (this.mp_search_results.status === "INFO") {
        this.mc_status = "INFO";
      } else if (this.mp_search_results.status === "FALSE") {
        this.mc_status = "FALSE";
      }

      this.mp_loading = false;
    },
    async clearTable() {
      this.dbs_not_found = false;
      this.dbs_search_results = [];
      this.mp_search_results = [];
      this.mp_error_message = "";
    },
  },
  mounted() {
    if (this.microchip) {
      this.chipnumber = this.microchip;
      this.search();
    }
  },
  async beforeMount() {
    const user = await this.retrieveUser();

    const roles = ["microchip_authorised_user", "rescue"];

    if (roles.includes(user.role)) {
      this.$router.push({ name: "MicrochipAuthorisedSearch" });
    }
  },
};
</script>

<style scoped>
.theme--light.v-data-table thead tr th {
  color: white !important;
}

.theme--light.v-data-table {
  color: white !important;
}

.theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: white !important;
}

.v-data-table__wrapper {
  border-style: solid;
  border-width: 2px;
  border-color: white;
  margin: 10px;
  border-radius: 10px;
}
</style>