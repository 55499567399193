<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="9" sm="8">
        <v-card class="mx-auto mt-5" width="600">
          <v-toolbar color="blue-bkg" dark flat>
            <v-toolbar-title>New Keeper details</v-toolbar-title>
          </v-toolbar>
          <v-form class="pa-4" method="post">
            <p>
              We will email the new owner to confirm transfer. Only when they
              accept the transfer will we move {{ pet.name }} to their profile.
              If the new keeper does not have a MyPet account they will need to
              create one.
            </p>
            <v-text-field
                v-model="form.email"
                :rules="emailRules"
                label="Email"
                required
                autofocus
            >
              <template #label>
                <span class="red--text"><strong>* </strong></span>Email
              </template>
            </v-text-field>
            <div class="tw-flex tw-items-center">
              <flag-drop-down-deprecated
                  @input="setIsoCountryCode"
                  class="tw-w-12 tw-h-8 tw-mr-4"
              ></flag-drop-down-deprecated>
              <v-text-field
                  class="tw-w-8"
                  v-model="form.mobile"
                  :rules="textFieldRules"
                  @input="handleMobileNumberInput"
                  label="Mobile"
              />
              <v-progress-circular
                  v-if="pinpointApiLoading"
                  :size="20"
                  :width="4"
                  color="#0074b5"
                  indeterminate
              ></v-progress-circular>
              <CheckCircleIcon
                  v-if="mobilePhoneNumberIsValid"
                  class="ml-1 w-6 h-6 tw-text-mp-green"
              >
              </CheckCircleIcon>
              <XCircleIcon
                  v-if="mobilePhoneNumberIsInvalid"
                  class="ml-1 w-6 h-6 tw-text-mp-red"
              >
              </XCircleIcon>
            </div>

            <v-btn
                class="transp-bkg no-shadow cap blue-text mob-text"
                @click="showMoreFields = !showMoreFields"
            >{{ showMoreFields ? "Show less..." : "Show more..." }}
            </v-btn>

            <v-text-field
                v-show="showMoreFields"
                v-model="form.first_name"
                label="First name"
                required
            ></v-text-field>

            <v-text-field
                v-show="showMoreFields"
                v-model="form.last_name"
                label="Last name"
                required
            ></v-text-field>

            <v-text-field
                v-show="showMoreFields"
                v-model="form.phone"
                class="mb-4"
                label="Telephone"
            ></v-text-field>

            <v-row>
              <v-label
              ><h3 v-show="showMoreFields" class="ml-3 tw-text-mp-blue">
                Address details
              </h3></v-label
              >
            </v-row>

            <v-row>
              <v-text-field
                  v-show="showMoreFields"
                  v-model="form.postcode"
                  class="ml-3 mt-3"
                  label="Enter postcode"
                  required
              ></v-text-field>
              <v-btn
                  v-show="showMoreFields"
                  class="cap ml-5 mt-5 mr-2 white--text"
                  color="#00b53f"
                  prepend-icon="mdi-map-marker"
                  @click="listAddresses()"
              >
                Find address
              </v-btn>
            </v-row>
            <v-select
                v-if="addresses.length > 0"
                v-show="showMoreFields"
                v-model="address"
                :items="addresses"
                item-text="line_1"
                label="Address"
                return-object
            ></v-select>
            <v-text-field
                v-show="showMoreFields"
                v-model="form.address_line_1"
                label="Address line 1"
                required
            ></v-text-field>
            <v-text-field
                v-show="showMoreFields"
                v-model="form.address_line_2"
                label="Address line 2"
                required
            ></v-text-field>
            <v-text-field
                v-if="this.form.address_line_3"
                v-show="showMoreFields"
                v-model="form.address_line_3"
                label="Address line 3"
                required
            ></v-text-field>
            <v-text-field
                v-if="this.form.address_line_4"
                v-show="showMoreFields"
                v-model="form.address_line_4"
                label="Address line 4"
                required
            ></v-text-field>
            <v-text-field
                v-show="showMoreFields"
                v-model="form.town"
                label="Town/City"
                required
            ></v-text-field>
            <v-checkbox
                v-show="$store.state.user?.role === 'rescue'"
                v-model="form.rescue_backup"
                label="Rescue Back-up"
            ></v-checkbox>

            <h3 class="mb-4 mt-2 tw-text-mp-blue">
              Do you want to transfer {{ pet.name }}?
            </h3>
            <v-btn
                :disabled="!ableToSubmitForm"
                class="cap white--text mb-2"
                color="#0074b5"
                depressed
                @click="rehomePet"
            >Rehome pet
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar color="green darken-1" top>
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar_text = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import Pet from "../models/Pet";
import FormService from "@/services/FormService";
import {debounce} from "lodash";
import PhoneNumberService from "@/services/PhoneNumberService";
import {CheckCircleIcon, XCircleIcon} from "@vue-hero-icons/solid";
import FlagDropdown from "@/components/shared/FlagDropDown";
import FlagDropDownDeprecated from "@/components/shared/FlagDropDownDeprecated.vue";

export default {
  components: {
    FlagDropDownDeprecated,
    FlagDropdown,
    CheckCircleIcon,
    XCircleIcon,
  },
  props: {
    pet: Object,
    rescues: Array,
  },
  async beforeMount() {
    this.form.rescue_backup = false;
    this.setIsoCountryCode("GB");
  },
  computed: {
    ableToSubmitForm() {
      if (!this.form.email.length > 6) {
        return false;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      return emailRegex.test(this.form.email);
    },
  },
  data() {
    const initialData = {
      first_name: "",
      last_name: "",
      telephone: "",
      postcode: "",
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      address_line_4: "",
      mobile: "+44",
      town: "",
      email: "",
    };

    return {
      address: "",
      addresses: [],
      showMoreFields: false,
      snackbar: false,
      snackbar_text: "",
      timeout: 2000,
      mobilePhoneNumberIsValid: false,
      mobilePhoneNumberIsInvalid: false,
      pinpointApiLoading: false,
      isoCountryCode: "GB",
      beforeChangeIsoCountryCode: "GB",
      textFieldRules: [(v) => !!v || "Required"],
      isoCountryCodeMap: [
        {
          isoCountryCode: "GB",
          prefix: "+44",
        },
        {
          isoCountryCode: "IE",
          prefix: "+353",
        },
      ],
      emailRules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
      form: new window.Form(initialData, async (form) => {
        await Pet.transfer(this.pet.id, form);

        this.$router.push({
          name: "GenericMessageWrapper",
          params: {
            link: "Ok",
            title: "Pet Re-homing process complete!",
            message:
                "Thanks! Once the new pet owner confirms, we'll transfer " +
                this.pet.name +
                " to their new owner!",
          },
        });
      }),
      valid: true,
    };
  },
  methods: {
    setIsoCountryCode(code) {
      const oldPrefix = this.isoCountryCodeMap.find(
          (i) => i.isoCountryCode === this.beforeChangeIsoCountryCode
      ).prefix;
      const suffix = this.form.mobile.slice(oldPrefix.length);

      this.isoCountryCode = code;
      this.beforeChangeIsoCountryCode = code;

      const newPrefix = this.isoCountryCodeMap.find(
          (i) => i.isoCountryCode === this.isoCountryCode
      ).prefix;
      this.form.mobile = newPrefix + suffix;

      this.setCountryMobilePhoneCode();
    },
    async setCountryMobilePhoneCode() {
      const prefix = this.isoCountryCodeMap.find(
          (i) => i.isoCountryCode === this.isoCountryCode
      ).prefix;

      if (
          !this.form.mobile ||
          ["+44", "+353"].includes(this.form.mobile) ||
          this.form.mobile.length < prefix.length
      ) {
        this.form.mobile = prefix;
        this.mobilePhoneNumberIsInvalid = false;
      }

      if (this.form.mobile.charAt(prefix.length) === "0") {
        this.form.mobile =
            this.form.mobile.slice(0, prefix.length) +
            this.form.mobile.slice(prefix.length + 1, this.form.mobile.length);
      }

      let result = [...this.form.mobile];

      for (let i = 0; i < prefix.length; i++) {
        const c = prefix.charAt(i);
        const t = result[i];
        if (c !== t) {
          result.splice(i, 0, c);
        }
      }

      this.form.mobile = result.join("");
    },
    async resetMobileSettings() {
      this.pinpointApiLoading = false;
      this.mobilePhoneNumberIsValid = false;
      this.mobilePhoneNumberIsInvalid = false;
    },
    async focusMobileNumberInput(event) {
      FormService.setCursorPosition(event.target, this.form.mobile.length);
    },
    async handleMobileNumberInput() {
      await this.resetMobileSettings();
      await this.setCountryMobilePhoneCode();

      if (this.form.mobile && this.form.mobile.length < 8) {
        return;
      }

      this.pinpointApiLoading = true;
      this.mobileNumberInput();
    },
    mobileNumberInput: debounce(function () {
      this.debouncedMobileNumberValidation();
    }, 1600),
    async debouncedMobileNumberValidation() {
      const phoneNumberValidatedEntity =
          await PhoneNumberService.validatePhoneNumber(
              this.form.mobile,
              "mobile",
              true
          );

      if (phoneNumberValidatedEntity.is_valid) {
        this.mobilePhoneNumberIsValid = true;
      } else {
        this.mobilePhoneNumberIsInvalid = true;
      }

      this.pinpointApiLoading = false;
    },
    async listAddresses() {
      if (!this.form.postcode) return;
      const response = await axios.get(
          "https://api.getaddress.io/find/" +
          this.form.postcode +
          "?api-key=vb3__sSB4kaFuyPu5vWS7g18118&sort=true&expand=true"
      );

      this.addresses = response.data.addresses;
    },
    rehomePet() {
      this.form.submit();
    },
    toggleDisabled() {
      this.disabled = !this.disabled;
    },
    redirect() {
      setTimeout(
          function () {
            this.$router.push({name: "PetTable"});
          }.bind(this),
          2500
      );
    },
  },
  watch: {
    address: function () {
      if (this.address) {
        this.form.address_line_1 = this.address.line_1;
        this.form.address_line_2 = this.address.line_2;
        this.form.address_line_3 = this.address.line_3;
        this.form.address_line_4 = this.address.line_4;
        this.form.town = this.address.town_or_city;
      }
    },
  },
};
</script>

<style scoped>
select {
  -webkit-appearance: listbox !important;
}
</style>