<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="9">
        <v-card class="mx-auto mt-5">
          <v-toolbar color="blue-bkg" dark flat>
            <v-toolbar-title>Lost Pet</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-col>
                <v-row class="justify-center">
                  <div>
                    <v-list-item
                      ><h1 class="black--text tw-text-xl mr-2">
                        Current status:
                      </h1>
                      <v-icon
                        :color="pet.is_lost ? 'orange darken-2' : 'green'"
                        class="mr-2"
                        >{{
                          pet.is_lost ? "mdi-alert" : "mdi-check-circle"
                        }}</v-icon
                      >
                      {{ pet.is_lost ? "LOST" : "SAFE" }}</v-list-item
                    >
                  </div>
                </v-row>
              </v-col>
              <v-col>
                <v-row class="justify-center">
                  <v-btn
                    :disabled="!this.pet.is_lost"
                    @click="markAsSafe()"
                    color="blue-bkg"
                    >Mark pet as safe</v-btn
                  >
                </v-row>
              </v-col>
              <v-col>
                <v-row class="justify-center">
                  <v-btn
                    :disabled="this.pet.is_lost"
                    @click="markAsLost()"
                    color="blue-bkg"
                    >Mark pet as lost</v-btn
                  >
                </v-row>
              </v-col>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center" style="flex-direction: column">
            <v-btn
              @click="$router.go(-1)"
              class="mb-2 transp-bkg no-shadow cap mob-text grey-text"
              >Go Back</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      color="green darken-1"
      v-model="snackbar"
      :timeout="timeout"
      top
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Pet from "../models/Pet";

export default {
  props: {
    pet: Object,
  },
  data() {
    return {
      snackbar: false,
      text: "",
      timeout: 2000,
    };
  },
  methods: {
    async markAsLost() {
      this.text = "Pet marked as lost";
      await this.pet.toggleLost(true);
      this.snackbar = true;
    },
    async markAsSafe() {
      this.text = "Pet marked as safe";
      await this.pet.toggleLost(false);
      this.snackbar = true;
    },
  },
};
</script>
