export default class FormService {
  static setCursorPosition(element, position) {
    element.focus();
    element.setSelectionRange(position, position);
  }

  static handleKeyboardEvent(event, keys, callback) {
    if (keys.includes(event.key)) {
      callback();
    }
  }
}
