<template>
  <transition name="modal-fade">
    <div class="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto tw-m-auto">
      <div
        class="tw-flex tw-items-center tw-justify-center tw-min-h-screen tw-pt-4 tw-px-8 tw-pb-20 tw-text-center tw-sm:block tw-sm:p-0"
      >
        <div
          aria-hidden="true"
          class="tw-fixed tw-inset-0 tw-transition-opacity"
        >
          <div
            class="tw-absolute tw-inset-0 tw-bg-gray-500 tw-opacity-75"
          ></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          aria-hidden="true"
          class="tw-hidden tw-sm:inline-block tw-sm:align-middle tw-sm:h-screen"
          >&#8203;</span
        >
        <div
          aria-labelledby="modal-headline"
          aria-modal="true"
          class="tw-inline-block tw-align-bottom tw-bg-white tw-rounded-lg tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all tw-sm:my-8 tw-sm:align-middle tw-sm:max-w-lg tw-sm:w-full"
          role="dialog"
        >
          <div class="tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 tw-sm:p-6 tw-sm:pb-4">
            <svg
              fill="currentColor"
              style="
                width: 30px;
                height: 30px;
                cursor: pointer;
                position: absolute;
                top: 0;
                right: 0;
              "
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              @click="close"
            >
              <path
                clip-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                fill-rule="evenodd"
              />
            </svg>
            <div class="tw-sm:flex tw-sm:items-start">
              <div
                class="tw-mx-auto tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-16 tw-w-16 tw-rounded-full tw-bg-blue-100 tw-sm:mx-0 tw-sm:h-14 tw-sm:w-14"
              >
                <ExclamationIcon
                  class="tw-h-12 tw-w-12 tw-text-mp-red"
                  aria-hidden="true"
                />
              </div>
              <div class="tw-mt-4" v-if="existsInUserAccount">
                This pet already exists in your account. If you need help
                contact us at
                <a href="mailto:support@mypethq.io">support@mypethq.io</a>
              </div>
              <div
                v-else
                class="tw-mt-3 tw-text-center tw-sm:mt-0 tw-sm:ml-4 tw-sm:text-left"
              >
                <div class="tw-mt-4">
                  We’re sorry this microchip number already exists. Please
                  contact us at
                  <a href="mailto:support@mypethq.io">support@mypethq.io</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { ExclamationIcon } from "@vue-hero-icons/solid";

export default {
  name: "modal",
  components: {
    ExclamationIcon,
  },
  props: {
    microchip: {
      type: String,
      required: true,
    },
    breed: {
      type: String,
      required: true,
    },
    sex: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    existsInUserAccount: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      checked: false,
      windowWidth: 800,
    };
  },
  methods: {
    close() {
      this.$emit("decline");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
  created() {
    this.windowWidth = window.innerWidth;
  },
};
</script>
<style scoped>
table {
  width: 100%; /* changing to auto stops the truncation */
  border-spacing: 5px;
}

td {
  max-width: 0;
  overflow: hidden;
  padding: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>