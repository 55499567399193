<template>
  <v-container class="fill-height" fluid>
    <back-button></back-button>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="pets"
            :items-per-page="itemsPerPage"
            :server-items-length="totalItems"
            :search="search"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @click:row="handleTableClick"
            @update:page="updatePage"
            @update:items-per-page="updateItemsPerPage"
            @update:options="handleSort"
          >
          </v-data-table>
          <v-card-actions width="600" style="justify-content: center">
            <v-btn
              depressed
              color="#0074b5"
              class="cap white--text mb-2"
              @click="$router.push({ name: 'PetCreate' })"
            >
              <v-icon>mdi-plus</v-icon>
              Add pet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Pet from "../models/Pet";
import Pagination from "@/services/Pagination";
import { debounce } from "lodash";
import BackButton from "@/components/shared/BackButton.vue";

export default {
  components: { BackButton },
  data() {
    return {
      search: "",
      itemsPerPage: 15,
      totalItems: 0,
      debouncedFetchData: null,
      sortBy: "",
      sortDesc: false,
      order: "desc",
      headers: [
        {
          text: "Type",
          align: "start",
          filterable: false,
          sortable: true,
          value: "type",
          class: "white--text tw-bg-mp-blue tw-border-2 title",
        },
        {
          text: "Name",
          value: "name",
          class: "white--text tw-bg-mp-blue tw-border-2 title",
        },
        {
          text: "Microchip",
          value: "microchip",
          class: "white--text tw-bg-mp-blue tw-border-2 title",
        },
        {
          text: "Breed",
          value: "breed",
          class: "white--text tw-bg-mp-blue tw-border-2 title",
        },
        {
          text: "Colour",
          value: "colour",
          class: "white--text tw-bg-mp-blue tw-border-2 title",
        },
        {
          text: "Distinguishing features",
          value: "distinguishing_features",
          class: "white--text tw-bg-mp-blue tw-border-2 title",
        },
        {
          text: "Spayed / Neutered",
          value: "neutered",
          class: "white--text tw-bg-mp-blue tw-border-2 title",
        },
        {
          text: "Date of birth",
          value: "dob_ddmmyyyy",
          class: "white--text tw-bg-mp-blue tw-border-2 title",
        },
        {
          text: "Status",
          value: "transfer_status",
          sortable: false,
          class: "white--text tw-bg-mp-blue tw-border-2 title",
        },
        {
          text: "In rescue since",
          value: "rescued_at",
          sortable: false,
          class: "white--text tw-bg-mp-blue tw-border-2 title",
        },
        {
          text: "Rehomed date",
          value: "rehomed_at",
          sortable: false,
          class: "white--text tw-bg-mp-blue tw-border-2 title",
        },
        {
          text: "Rescue backup?",
          value: "rescue_backup",
          sortable: false,
          class: "white--text tw-bg-mp-blue tw-border-2 title",
        },
      ],
      pets: [],
    };
  },
  created() {
    this.debouncedFetchData = debounce(this.fetchData, 300);
  },
  watch: {
    search: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.debouncedFetchData();
        }
      },
      immediate: false,
    },
  },
  methods: {
    // async getPets() {
    //   try {
    //     this.pets = await Pet.v2list("rescue_details", new Pagination(15, 1));
    //
    //     this.pets.map(function (i) {
    //       i.transfer_status = i.rescue_details.status;
    //       i.rescued_at = i.rescue_details.in_rescue_since;
    //       i.rehomed_at = i.rescue_details.rehomed_date;
    //
    //       i.rescue_backup = i.rescue_backups.length > 0 ? "Yes" : "No";
    //     });
    //     const user = await this.retrieveUser();
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    async handleSort(options) {
      const sortBy = (await this.getField(options?.sortBy[0])) ?? null;
      const sortDesc = options?.sortDesc[0] ?? null;

      if (this.sortBy === sortBy) {
        if (sortDesc === undefined) {
          this.order = null;
        } else if (!sortDesc) {
          this.order = "asc";
        } else {
          this.order = "desc";
        }
        await this.fetchData();
      } else {
        // Set the new sorting column and use ascending order
        this.sortBy = sortBy;
        this.order = "asc";
        await this.fetchData();
      }
    },
    async getField(field) {
      if (field === "dob_ddmmyyyy") {
        return "dob";
      }

      return field;
    },
    handleTableClick(pet) {
      this.$router.push({ name: "PetProfile", params: { id: pet.id } });
    },
    updatePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.fetchData();
    },
    async fetchData() {
      this.loading = true;

      try {
        const response = await Pet.v2list(
          "rescue_details",
          new Pagination(this.itemsPerPage, this.currentPage),
          this.search,
          this.sortBy,
          this.order
        );

        this.pets = response.data;

        this.pets.map(function (i) {
          i.transfer_status = i.rescue_details.status;
          i.rescued_at = i.rescue_details.in_rescue_since;
          i.rehomed_at = i.rescue_details.rehomed_date;

          i.rescue_backup = i.rescue_backups.length > 0 ? "Yes" : "No";
        });

        this.totalItems = response.meta.total;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    // this.fetchData();
  },
};
</script>

<style scoped>
.v-data-table-header {
  background-color: lawngreen;
}
</style>