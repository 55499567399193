<template>
  <div
      class="tw-bg-white tw-m-8 tw-rounded-lg tw-p-2 tw-border tw-border-mp-blue tw-shadow-xl"
  >
    <div class="tw-mt-2">
      <h3 class="tw-text-lg">MyPet Basic</h3>
      <div class="tw-text-left">
        <div class="tw-ml-8 tw-mt-4 tw-flex">
          <CheckCircleIcon class="tw-text-mp-green"></CheckCircleIcon>
          <div class="tw-ml-2">Update your details for life</div>
        </div>
        <div class="tw-ml-8 tw-mt-2 tw-flex">
          <CheckCircleIcon class="tw-text-mp-green"></CheckCircleIcon>
          <div class="tw-ml-2">6 monthly reminder emails</div>
        </div>
        <div class="tw-ml-8 tw-mt-2 tw-flex tw-mb-4">
          <CheckCircleIcon class="tw-text-mp-green"></CheckCircleIcon>
          <div class="tw-ml-2">Basic registration certificate</div>
        </div>
        <div
            v-if="isLoggedIn && subscriptionType === 'basic' && showRelative"
            class="tw-flex tw-justify-center tw-items-center tw-mt-8 tw-mb-8 tw-bg-white tw-rounded tw-ml-8 tw-mr-8 tw-py-1 tw-text-mp-blue"
        >
          <InformationCircleIcon class="tw-mr-1 tw-h-8"></InformationCircleIcon>
          <div>You are currently on this plan</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {CheckCircleIcon, InformationCircleIcon, XCircleIcon,} from "@vue-hero-icons/solid";

export default {
  name: "MyPetBasicSubscription",
  components: {CheckCircleIcon, XCircleIcon, InformationCircleIcon},
  props: {
    showRelative: true,
  },
  data() {
    return {
      user: null,
      subscriptionType: "basic",
    };
  },
  async mounted() {
    this.user = this.$store.getters.user;
    this.subscriptionType = await this.getSubscriptionType();
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>
<style scoped></style>