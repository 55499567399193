<template>
  <div class="tw-flex tw-flex-col">
    <h2 class="tw-float-left tw-mt-2">Address</h2>
    <div class="tw-relative">
      <base-input-v2
          v-if="!addressManualEntry"
          v-model="addressSearchField"
          label="Find address"
          :placeholder="hasPickedAddress ? '' : 'eg. 46 George St / DL22 7IW'"
          type="text"
          @focus="focusAddressInput"
          @down="incrementAddressDisplayObjectSelectedPosition"
          @enter="selectAddressDisplayObjectByPosition"
          @input="lookupAddress"
          @up="decrementAddressDisplayObjectSelectedPosition"
      ></base-input-v2>
      <div
          class="tw-w-1/2 tw-inline-flex tw-justify-center tw-mt-4 tw-py-2 tw-border tw-border-transparent tw-shadow-lg tw-py-2 tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-text-opacity-100 tw-bg-mp-purple tw-opacity-70 hover:tw-opacity-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
          @click="addressManualEntry = !addressManualEntry"
      >
        <div class="tw-ml-2 tw-mr-2">
          {{ addressManualEntry ? "search address" : "or enter manually" }}
        </div>
      </div>
      <div
          v-if="addressDisplayObjects.length > 0 && displayAddressSection"
          class="tw-absolute tw-left-0 tw-right-0 tw-max-h-60 tw-overflow-y-auto tw-border tw-border-slate-500 tw-rounded-md tw-z-50"
      >
        <div
            v-for="addressDisplayObject in addressDisplayObjects"
            :key="addressDisplayObject.position"
            :class="[
            addressDisplayObject.position ===
            addressDisplayObjectSelectedPosition
              ? 'tw-bg-violet-500 tw-text-white'
              : 'tw-bg-gray-50 tw-text-gray-700',
          ]"
            class="tw-text-sm tw-font-medium tw-flex tw-justify-items-start"
            @click="selectAddress(addressDisplayObject)"
            @mouseover="
            setAddressDisplayObjectSelectedPosition(
              addressDisplayObject.position
            )
          "
        >
          <span id="address-item" class="tw-ml-2">{{
              addressDisplayObject.address
            }}</span>
        </div>
      </div>
      <base-input-v2
          ref="addressLine1AnchorRef"
          v-if="address_line_1 || addressManualEntry || addressSelected"
          required="true"
          :highlight-class="highlightAddressLine1 ? 'animate-highlight-red' : ''"
          v-model="address_line_1"
          class="py-2"
          label="Address line 1"
          :invalid-class="
          address_line_1 === ''
            ? '  tw-border-solid tw-border-red-500 tw-bg-red-100'
            : ''
        "
          type="text"
      ></base-input-v2>
      <base-input-v2
          v-if="address_line_2 || address_line_1 || addressManualEntry"
          v-model="address_line_2"
          class="py-2"
          label="Address line 2"
          type="text"
      ></base-input-v2>
      <base-input-v2
          v-if="address_line_2 && address_line_2.length > 0"
          v-model="address_line_3"
          class="py-2"
          label="Address line 3"
          type="text"
      ></base-input-v2>
      <base-input-v2
          v-if="address_line_3 && address_line_3.length > 0"
          v-model="address_line_4"
          class="py-2"
          label="Address line 4"
          type="text"
      ></base-input-v2>
      <base-input-v2
          ref="postcodeAnchorRef"
          v-if="postcode || addressManualEntry || addressSelected"
          required="true"
          v-model="postcode"
          :highlight-class="highlightPostcode ? 'animate-highlight-red' : ''"
          class="py-2"
          :uppercase="true"
          :invalid-class="
          postcode === ''
            ? '  tw-border-solid tw-border-red-500 tw-bg-red-100'
            : ''
        "
          label="Postcode"
          type="text"
      ></base-input-v2>
      <base-input-v2
          ref="cityAnchorRef"
          v-if="city || addressManualEntry || addressSelected"
          required="true"
          v-model="city"
          class="py-2"
          :highlight-class="highlightCity ? 'animate-highlight-red' : ''"
          label="Town/city"
          :invalid-class="
          city === '' ? '  tw-border-solid tw-border-red-500 tw-bg-red-100' : ''
        "
          type="text"
      ></base-input-v2>
    </div>
  </div>
</template>

<script>
import BaseInputV2 from "@/components/shared/BaseInputV2";
import axios from "axios";

export default {
  components: {BaseInputV2},
  props: {
    address: {},
  },
  data() {
    return {
      addressSearchField: "",
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      address_line_4: "",
      postcode: "",
      city: "",
      addressDisplayObjects: [],
      addressDisplayObjectSelectedPosition: 1,
      addressManualEntry: false,
      addressSelected: false,
      highlightAddressLine1: false,
      highlightPostcode: false,
      highlightCity: false,
      displayAddressSection: false, // Add a new data property to track the display state
    };
  },
  mounted() {
    this.address_line_1 = this.address.address_line_1;
    this.address_line_2 = this.address.address_line_2;
    this.address_line_3 = this.address.address_line_3;
    this.address_line_4 = this.address.address_line_4;
    this.postcode = this.address.postcode;
    this.city = this.address.city;
  },
  computed: {
    hasPickedAddress() {
      return this.address_line_1 && this.postcode && this.city;
    },
    watchValidationVariables() {
      return [this.address_line_1, this.postcode, this.city];
    },
  },
  watch: {
    watchValidationVariables: function (newVals, oldVals) {
      this.emitSelectedAddress();
    },
    address: {
      handler(newVal) {
        this.address_line_1 = newVal.address_line_1 || "";
        this.address_line_2 = newVal.address_line_2 || "";
        this.address_line_3 = newVal.address_line_3 || "";
        this.address_line_4 = newVal.address_line_4 || "";
        this.postcode = newVal.postcode || "";
        this.city = newVal.city || "";
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async jumpToAnchor(item) {
      const el = this.$refs[item.anchor];

      if (el) {
        window.scrollTo(0, el.offsetTop);
      }

      switch (item.type) {
        case "address_line_1":
          this.highlightAddressLine1 = true;
          break;
        case "postcode":
          this.highlightPostcode = true;
          break;
        case "city":
          this.highlightCity = true;
          break;
      }
    },
    async focusAddressInput(value) {
      this.displayAddressSection = true;
      await this.lookupAddress(value);
    },
    async lookupAddress(value) {
      const response = await axios.post(
          "https://api.getaddress.io/autocomplete/" +
          value +
          "?api-key=vb3__sSB4kaFuyPu5vWS7g18118",
          {
            all: true,
            template: "{formatted_address}{postcode,, }{postcode}",
            top: 6,
          }
      );

      this.addressDisplayObjects = response.data.suggestions.map((e, i) => ({
        ...e,
        position: i + 1,
      }));

      this.displayAddressSection = true; // Show the address display section
    },
    async selectAddress(address) {
      const response = await axios.get(
          "https://api.getaddress.io/get/" +
          address.id +
          "?api-key=vb3__sSB4kaFuyPu5vWS7g18118"
      );

      const selectedAddress = response.data;

      this.address_line_1 = selectedAddress.line_1;
      this.address_line_2 = selectedAddress.line_2;
      this.address_line_3 = selectedAddress.line_3;
      this.address_line_4 = selectedAddress.line_4;
      this.postcode = selectedAddress.postcode?.toUpperCase();
      this.city = selectedAddress.town_or_city;

      this.addressDisplayObjects = [];
      this.addressSearchField = "";
      this.addressSelected = true;
      this.displayAddressSection = false;
    },
    async emitSelectedAddress() {
      this.$emit("setAddressLines", {
        address_line_1: this.address_line_1,
        address_line_2: this.address_line_2,
        address_line_3: this.address_line_3,
        address_line_4: this.address_line_4,
        postcode: this.postcode?.toUpperCase(),
        city: this.city,
      });
    },
    async setAddressDisplayObjectSelectedPosition(position) {
      this.addressDisplayObjectSelectedPosition = position;
    },
    selectAddressDisplayObjectByPosition() {
      this.selectAddress(
          this.addressDisplayObjects[
          this.addressDisplayObjectSelectedPosition - 1
              ]
      );
    },
    incrementAddressDisplayObjectSelectedPosition() {
      if (
          !(
              this.addressDisplayObjectSelectedPosition >=
              this.addressDisplayObjects.length
          )
      ) {
        this.setAddressDisplayObjectSelectedPosition(
            this.addressDisplayObjectSelectedPosition + 1
        );
      }
    },
    decrementAddressDisplayObjectSelectedPosition() {
      if (!(this.addressDisplayObjectSelectedPosition <= 1)) {
        this.setAddressDisplayObjectSelectedPosition(
            this.addressDisplayObjectSelectedPosition - 1
        );
      }
    },
    handleClickOutside(event) {
      if (!event?.target?.id || event?.target?.id !== "address-item") {
        this.displayAddressSection = false;
      }
    },
  },
  created() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped></style>