<template>
  <microchip-authorised-user-registration-form></microchip-authorised-user-registration-form>
</template>

<script>
import MicrochipAuthorisedUserRegistrationForm from "../components/MicrochipAuthorisedUserRegistrationForm";

export default {
  components: { MicrochipAuthorisedUserRegistrationForm },
  data() {
    return {};
  },
};
</script>
