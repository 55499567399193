import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import User from "./models/User";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    user: {},
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, { token, user }) {
      state.status = "success";
      state.user = user;
      state.token = token;
    },
    auth_error(state) {
      state.status = "error";
    },
    update_cart(state, user) {
      state.user = user;
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
  },
  actions: {
    async login({ commit }, u) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: process.env.VUE_APP_API_URL + "/api/auth/login",
          data: u,
          method: "POST",
        })
          .then(async (resp) => {
            window.Http.removeAuthenticationToken();

            const token = resp.data.access_token;
            // const user = resp.data.user

            await window.Http.setAuthorizationToken(token);
            const user = await User.retrieve();
            commit("auth_success", { token, user });

            // commit("auth_user", u);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            window.Http.removeAuthenticationToken();
            reject(err);
          });
      });
    },
    async googleLogin({ commit }, token, user) {
      await window.Http.setAuthorizationToken(token);
      const u = await User.retrieve();
      commit("auth_success", { token, u });
    },
    async register({ commit }, form) {
      commit("auth_request");
      const response = await window.Http.post("/api/auth/register", form);
      const token = response.access_token;
      await window.Http.setAuthorizationToken(token);

      const user = User.retrieve();
      commit("auth_success", token, user);
      return response;
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout");
        window.Http.removeAuthenticationToken();
        resolve();
      });
    },
  },
  getters: {
    cartCount: (state) =>
      state.user ? state.user.microchip_queue?.pet_count : 0,
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    user: (state) => state.user,
  },
});