import Http from "../services/Http";

export default class PhoneNumberService {
  static async validatePhoneNumber(
    phoneNumber,
    phoneType,
    checkDespiteUniqueExceptSelf = false
  ) {
    const response = await Http.post("api/v2/validate-phone-number", {
      phone_number: phoneNumber,
      phone_type: phoneType,
      check_despite_unique_except_self: checkDespiteUniqueExceptSelf,
    });

    const phoneNumberValidatedEntity = response;

    if (
      phoneNumberValidatedEntity.phone_type &&
      phoneNumberValidatedEntity.phone_type.toUpperCase() !==
        phoneType.toUpperCase()
    ) {
      phoneNumberValidatedEntity.is_valid = false;
    }

    return phoneNumberValidatedEntity;
  }
}