<template>
  <div>
    <back-button></back-button>
    <v-col v-if="!displayOtherDatabases" class="d-flex justify-center">
      <v-img
        :src="require('../assets/logo.svg')"
        class="my-3"
        contain
        height="200"
      />
    </v-col>
    <v-col class="d-flex justify-center">
      <v-text-field
        style="max-width: 450px"
        @keydown.enter="search"
        v-model="chipnumber"
        label="Search microchip here"
        filled
        rounded
        dense
      ></v-text-field>
      <v-btn
        v-if="displayOtherDatabases"
        @click="search"
        color="#B90050"
        large
        class="ml-2 mt-1"
      >
        <v-icon color="white">mdi-magnify</v-icon>
      </v-btn>
    </v-col>

    <v-btn
      v-if="!displayOtherDatabases"
      @click="search"
      depressed
      color="#0074b5"
      class="cap white--text mb-2"
    >
      Search
      <v-icon class="ml-1">mdi-magnify</v-icon>
    </v-btn>

    <v-card-text v-if="mp_loading" class="font-weight-medium tw-text-xl"
      >Searching the MyPet database...
    </v-card-text>
    <v-progress-circular
      v-if="mp_loading"
      :size="40"
      :width="4"
      color="#0074b5"
      indeterminate
    ></v-progress-circular>

    <div
      v-if="mypetInfoChip"
      class="tw-flex tw-justify-center tw-items-center tw-mt-16 tw-mb-8"
    >
      <div
        class="tw-bg-white tw-w-1/2 tw-shadow-xl tw-p-2 tw-border tw-border-red-400 tw-rounded"
      >
        This is a microchip which we have information on. Please get in touch
        with us
        <a
          class="mr-4 tw-text-mp-blue"
          href="https://mypethq.io/open-a-support-ticket/"
          target="_blank"
          rel="noopener noreferrer"
          >HERE.</a
        >
        <br /><br />If your request is urgent call us on 0800 368 4150
      </div>
    </div>

    <div v-if="!mypetInfoChip" v-for="pet in mp_search_results" :key="pet.id">
      <div
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-8"
      >
        <div class="tw-flex tw-justify-center tw-items-center">
          <image-upload
            :entity-uuid="pet.uuid"
            :clickable="false"
            type="pet"
            :has-file-uploads="pet.has_file_uploads"
          ></image-upload>
          <div class="tw-ml-4">{{ pet.name }}</div>
        </div>
        <div
          class="pet-data tw-mt-4 tw-bg-mp-blue-opacity tw-w-10/12 tw-rounded tw-border tw-border-mp-blue tw-relative"
        >
          <div class="tw-absolute tw-top-0 tw-right-0 tw-m-2">
            <div
              v-if="!pet.is_lost"
              class="tw-w-14 tw-h-14 tw-bg-mp-green tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white tw-py-4"
            >
              Safe
            </div>
            <div
              v-if="pet.is_lost"
              class="tw-w-14 tw-h-14 tw-bg-mp-red tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white tw-py-4"
            >
              Lost
            </div>
          </div>
          <div id="pet-details" class="tw-font-medium tw-leading-loose">
            <table>
              <tr>
                <td class="tw-text-mp-light-blue">Type:</td>
                <td class="">{{ pet.type }}</td>
              </tr>
              <tr>
                <td class="tw-text-mp-light-blue">DOB:</td>
                <td>{{ pet.dob_ddmmyyyy }}</td>
              </tr>
              <tr>
                <td class="tw-text-mp-light-blue">Age:</td>
                <td>{{ pet.age }}</td>
              </tr>
              <tr>
                <td class="tw-text-mp-light-blue">Gender:</td>
                <td>{{ pet.sex }}</td>
              </tr>
              <tr>
                <td class="tw-text-mp-light-blue">Breed:</td>
                <td>{{ pet.breed }}</td>
              </tr>
              <tr>
                <td class="tw-text-mp-light-blue">Colour:</td>
                <td>{{ pet.colour }}</td>
              </tr>
              <tr>
                <td class="tw-text-mp-light-blue">Distinguishing features:</td>
                <td>{{ pet.distinguishing_features }}</td>
              </tr>
              <tr>
                <td class="tw-text-mp-light-blue">Spayed / Neutered:</td>
                <td>
                  {{
                    pet.neutered === true
                      ? "yes"
                      : pet.neutered === false
                      ? "no"
                      : "unknown"
                  }}
                </td>
              </tr>
              <tr>
                <td class="tw-text-mp-light-blue">Last updated:</td>
                <td>{{ pet.updated_at_readable }}</td>
              </tr>
            </table>
            <p class="tw-text-mp-blue-grey tw-mt-6">
              If you need more information call us on 0800 368 4150
            </p>
            <v-btn
              v-if="$store.state.user.role === 'rescue'"
              @click="showPetTransferModal(pet)"
              color="#0074b5"
              class="cap white--text mb-4 mt-4"
            >
              Transfer to pet rescue
            </v-btn>
          </div>
        </div>
        <div
          class="pet-data tw-mt-8 tw-bg-mp-green-opacity tw-w-10/12 tw-rounded tw-border tw-border-mp-green tw-relative"
        >
          <div class="tw-text-lg tw-mt-2">Pet Parent</div>
          <table class="tw-mt-4">
            <tr>
              <td class="tw-text-mp-light-blue">First name:</td>
              <td class="">{{ pet.owner ? pet.owner.first_name : "" }}</td>
            </tr>
            <tr>
              <td class="tw-text-mp-light-blue">Surname:</td>
              <td class="">{{ pet.owner ? pet.owner.last_name : "" }}</td>
            </tr>
            <tr>
              <td class="tw-text-mp-light-blue">Email:</td>
              <td class="">{{ pet.owner ? pet.owner.email : "" }}</td>
            </tr>
            <tr>
              <td class="tw-text-mp-light-blue">Mobile:</td>
              <td>{{ pet.owner ? pet.owner.mobile : "" }}</td>
            </tr>
            <tr>
              <td class="tw-text-mp-light-blue">Phone number:</td>
              <td>{{ pet.owner ? pet.owner.phone : "" }}</td>
            </tr>
            <tr>
              <td class="tw-text-mp-light-blue">Address:</td>
              <td>{{ pet.owner ? pet.owner.address_readable : "" }}</td>
            </tr>
          </table>
        </div>
        <div
          v-for="contact in pet.additional_contacts"
          :key="contact.id"
          class="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center"
        >
          <div
            class="additional-contact-data tw-mt-8 tw-bg-mp-blue-opacity tw-w-10/12 tw-rounded tw-border tw-border-mp-blue tw-relative"
          >
            <div class="tw-text-lg tw-mt-2">Additional Contact</div>
            <table class="tw-mt-4">
              <tr>
                <td class="tw-text-mp-light-blue">First name:</td>
                <td class="">{{ contact ? contact.first_name : "" }}</td>
              </tr>
              <tr>
                <td class="tw-text-mp-light-blue">Surname:</td>
                <td class="">{{ contact ? contact.last_name : "" }}</td>
              </tr>
              <tr>
                <td class="tw-text-mp-light-blue">Email:</td>
                <td class="">{{ contact ? contact.email : "" }}</td>
              </tr>
              <tr>
                <td class="tw-text-mp-light-blue">Mobile:</td>
                <td>{{ contact ? contact.mobile : "" }}</td>
              </tr>
              <tr>
                <td class="tw-text-mp-light-blue">Phone number:</td>
                <td>{{ contact ? contact.phone : "" }}</td>
              </tr>
              <tr>
                <td class="tw-text-mp-light-blue">Address:</td>
                <td>{{ contact ? contact.address_readable : "" }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div
          v-for="vet in pet.vets"
          :key="vet.id"
          class="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center"
        >
          <div
            class="vet-data tw-mt-8 tw-bg-mp-green-opacity tw-w-10/12 tw-rounded tw-border tw-border-mp-green tw-relative"
          >
            <div class="tw-text-lg tw-mt-2">Vet</div>
            <table class="tw-mt-4">
              <tr>
                <td class="tw-text-mp-light-blue">Name:</td>
                <td class="">{{ vet ? vet.name : "" }}</td>
              </tr>
              <tr>
                <td class="tw-text-mp-light-blue">Phone number:</td>
                <td>{{ vet ? vet.phone : "" }}</td>
              </tr>
              <tr>
                <td class="tw-text-mp-light-blue">Address:</td>
                <td>{{ vet ? vet.address : "" }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div
          v-for="rb in pet.rescue_backups"
          :key="rb.id"
          class="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center"
        >
          <div
            class="additional-contact-data tw-mt-4 tw-bg-mp-blue-opacity tw-w-10/12 tw-rounded tw-border tw-border-mp-blue tw-relative"
          >
            <div class="tw-text-lg tw-mt-2">Rescue Backup</div>
            <table>
              <tr>
                <td class="tw-text-mp-blue tw-font-semibold tw-text-lg">
                  Rescue backup details
                </td>
              </tr>
              <tr v-if="rb.organisations && rb.organisations.length > 0">
                <td>Organisation:</td>
                <td>{{ rb.organisations[0].name }}</td>
              </tr>
              <tr>
                <td>First name:</td>
                <td>{{ rb.first_name }}</td>
              </tr>
              <tr>
                <td>Last name:</td>
                <td>{{ rb.last_name }}</td>
              </tr>
              <tr>
                <td>Telephone:</td>
                <td>{{ rb.phone }}</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>{{ rb.email }}</td>
              </tr>
              <tr>
                <td>Address line 1:</td>
                <td>{{ rb.address_line_1 }}</td>
              </tr>
              <tr>
                <td>Address line 2:</td>
                <td>{{ rb.address_line_2 }}</td>
              </tr>
              <tr v-if="rb.address_line_2">
                <td>Address line 3:</td>
                <td>{{ rb.address_line_3 }}</td>
              </tr>
              <tr v-if="rb.address_line_3">
                <td>Address line 4:</td>
                <td>{{ rb.address_line_4 }}</td>
              </tr>
              <tr>
                <td>Town/city:</td>
                <td>{{ rb.city }}</td>
              </tr>
              <tr>
                <td>Postcode:</td>
                <td>{{ rb.postcode }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <v-col
      v-if="mp_loading === false && not_on_mypet_db && !mypetInfoChip"
      class="d-flex justify-center"
    >
      <v-alert dense outlined type="error" style="max-width: 450px">
        MyPet does not hold any information on this microchip.
      </v-alert>
    </v-col>

    <v-card-text v-if="dbs_loading" class="font-weight-medium tw-text-xl"
      >Searching all Defra compliant databases in the UK...
    </v-card-text>
    <v-progress-circular
      v-if="dbs_loading"
      :size="40"
      :width="4"
      color="#0074b5"
      indeterminate
    ></v-progress-circular>

    <v-row v-if="displayOtherDatabases">
      <v-col cols="12">
        <div class="tw-text-mp-blue tw-mt-8 tw-font-semibold tw-text-lg">
          Other databases:
        </div>
      </v-col>
    </v-row>

    <v-col v-if="dbs_search_results.length > 0" class="d-flex justify-center">
      <v-card style="min-width: 450px; margin: auto">
        <v-col>
          <v-card-title>This microchip was found on:</v-card-title>
        </v-col>

        <v-row v-for="db in dbs_search_results" :key="db.id">
          <v-col>
            <div class="mt-2">
              <span class="mr-4">{{ db.name }}:</span>
              <a
                :href="'https://' + db.website"
                target="_blank"
                rel="noopener"
                class="v-markdown--link blue-text"
              >
                <span>{{ db.website_display || db.website }}</span>
                <v-icon
                  class="blue-text darken-2"
                  size="18"
                  style="margin-left: 5px"
                  >mdi-open-in-new
                </v-icon>
              </a>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col v-else-if="dbs_not_found" class="d-flex justify-center">
      <v-alert dense outlined type="error" style="max-width: 450px">
        We didn't find this pet in any other Defra compliant microchip database.
      </v-alert>
    </v-col>
    <v-snackbar
      color="green darken-1"
      v-model="snackbar"
      :timeout="timeout"
      top
    >
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar_text = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <confirmation-modal
      v-show="isPetTransferModalVisible"
      modalHeadline="Confirm transfer"
      :message="
        'Do you want to transfer ' + selectedPet.name + ' to your rescue?'
      "
      subScript="We'll email the current owner to confirm the transfer to you. If they don't confirm we'll do our best to reach out to them.  We will email you when we confirm this with the pet owner. If for any reason you don't want us to email the current owner, please call us on 0800 368 4150. DO NOT TRANSFER PET. Call us first."
      @transferPetEvent="transferPetEvent"
      @close="closePetTransferModal"
    >
    </confirmation-modal>
  </div>
</template>

<script>
import axios from "axios";
import OrganisationRescue from "../models/OrganisationRescue";
import ConfirmationModal from "../components/modals/ConfirmationModal.vue";
import BackButton from "@/components/shared/BackButton.vue";
import ImageUpload from "@/components/shared/ImageUpload.vue";

export default {
  components: { ImageUpload, BackButton, ConfirmationModal },
  props: {
    microchip: String,
  },
  data() {
    return {
      chipnumber: "",
      dbs_loading: false,
      dbs_not_found: false,
      dbs_search_results: [],
      mp_error_message: "",
      mp_loading: false,
      mypetInfoChip: false,
      mp_search_results: [],
      mc_status: "NULL",
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      selectedPet: {},
      isPetTransferModalVisible: false,
      displayOtherDatabases: false,
      not_on_mypet_db: false,
      snackbar: false,
      snackbar_text: "",
      timeout: 2000,
    };
  },
  methods: {
    showPetTransferModal(pet) {
      this.selectedPet = pet;
      this.isPetTransferModalVisible = true;
    },
    closePetTransferModal() {
      this.isPetTransferModalVisible = false;
    },
    async search() {
      await this.clearTable();

      if (!this.chipnumber) return;

      this.searchDbs();
      this.searchMypet();
    },
    async searchDbs() {
      this.dbs_loading = true;

      const dbs_response = await axios.get(
        process.env.VUE_APP_API_URL +
          "/mc_search?mc_number=" +
          this.chipnumber.replace(/ /g, "")
      );

      this.dbs_search_results = dbs_response.data.mc_dbs;

      if (this.dbs_search_results.length === 0) {
        this.dbs_not_found = true;
      }

      this.dbs_loading = false;
      this.displayOtherDatabases = true;
    },
    async searchMypet() {
      this.mp_loading = true;
      this.mypetInfoChip = false;

      let ctx = this;
      const mp_response = await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v2/authorised-microchip-lookup?chipnumber=" +
            this.chipnumber.replace(/ /g, "")
        )
        .catch(function (error) {
          if (error.response) {
            ctx.mp_error_message = error.response.data.error_message;
            ctx.mp_loading = false;
          }
        });

      if (mp_response.data?.data?.status === "INFO") {
        this.mypetInfoChip = true;
      } else {
        this.mp_search_results = mp_response.data;
      }

      if (this.mp_search_results.length === 0) {
        this.not_on_mypet_db = true;
      }

      this.mp_loading = false;
    },
    async transferPetEvent(checked) {
      this.snackbar_text =
        "We have created a transfer request to the owner to transfer this pet to your rescue account.";

      OrganisationRescue.transferPetToRescue(this.$store.state.user.id, {
        pet_id: this.selectedPet.id,
        rescue_backup: checked,
      });
      this.snackbar = true;

      this.isPetTransferModalVisible = false;
    },
    async clearTable() {
      this.displayOtherDatabases = false;
      this.dbs_not_found = false;
      this.not_on_mypet_db = false;
      this.dbs_search_results = [];
      this.mp_search_results = [];
      this.mp_error_message = "";
    },
  },
  beforeMount() {
    this.search();
  },
};
</script>

<style scoped>
/*.theme--light.v-data-table thead tr th {*/
/*  color: white !important;*/
/*}*/
/*.theme--light.v-data-table {*/
/*  color: white !important;*/
/*}*/
/*.theme--light.v-data-table*/
/*  tbody*/
/*  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {*/
/*  background-color: transparent !important;*/
/*}*/
/*.theme--light.v-data-table*/
/*  .v-data-table-header*/
/*  th.sortable*/
/*  .v-data-table-header__icon {*/
/*  color: white !important;*/
/*}*/
/*.v-data-table__wrapper {*/
/*  border-style: solid;*/
/*  border-width: 2px;*/
/*  border-color: white;*/
/*  margin: 10px;*/
/*  border-radius: 10px;*/
/*}*/

td {
  text-align: left;
  padding: 8px;
  /*width: 220px;*/
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}
</style>