<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="75pt"
    height="74.999997pt"
    viewBox="0 0 75 74.999997"
    version="1.2"
  >
    <defs>
      <clipPath id="clip1">
        <path d="M 29 62 L 46 62 L 46 70.5 L 29 70.5 Z M 29 62 " />
      </clipPath>
    </defs>
    <g id="surface1">
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(0%, 70.979309%, 24.708557%);
          fill-opacity: 1;
        "
        d="M 54.105469 66.878906 C 49.667969 66.878906 44.199219 65.398438 37.5 61.46875 C 21.847656 70.652344 12.914062 66.464844 8.152344 61.257812 C 2.292969 54.84375 0.28125 45.632812 2.78125 36.621094 C 5.171875 28.007812 10.320312 20.367188 18.097656 13.90625 C 29.339844 4.570312 45.660156 4.566406 56.902344 13.90625 C 64.675781 20.367188 69.828125 28.007812 72.21875 36.621094 C 74.71875 45.628906 72.710938 54.835938 66.851562 61.253906 C 64.125 64.234375 60.035156 66.878906 54.105469 66.878906 Z M 37.5 57.34375 L 38.417969 57.90625 C 49.796875 64.847656 58.484375 65.171875 64.242188 58.871094 C 69.277344 53.363281 70.988281 45.398438 68.816406 37.5625 C 66.625 29.660156 61.855469 22.613281 54.644531 16.625 C 44.710938 8.371094 30.285156 8.371094 20.355469 16.625 C 13.144531 22.613281 8.378906 29.660156 6.1875 37.5625 C 4.011719 45.398438 5.726562 53.363281 10.761719 58.875 C 16.519531 65.171875 25.207031 64.847656 36.582031 57.902344 Z M 37.5 57.34375 "
      />
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(0%, 70.979309%, 24.708557%);
          fill-opacity: 1;
        "
        d="M 7.277344 21.695312 C 6.488281 21.695312 5.769531 21.160156 5.566406 20.363281 C 4.023438 14.257812 6.210938 8.433594 10.765625 6.515625 C 15.402344 4.566406 20.285156 6.410156 23.519531 11.328125 C 24.054688 12.144531 23.828125 13.238281 23.015625 13.773438 C 22.199219 14.3125 21.105469 14.082031 20.566406 13.269531 C 18.296875 9.8125 15.144531 8.5 12.136719 9.769531 C 9.199219 11.003906 7.878906 15.097656 8.992188 19.496094 C 9.230469 20.441406 8.65625 21.402344 7.710938 21.640625 C 7.566406 21.675781 7.421875 21.695312 7.277344 21.695312 Z M 7.277344 21.695312 "
      />
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(0%, 70.979309%, 24.708557%);
          fill-opacity: 1;
        "
        d="M 67.71875 21.695312 C 67.578125 21.695312 67.429688 21.675781 67.285156 21.640625 C 66.339844 21.402344 65.769531 20.441406 66.007812 19.496094 C 67.121094 15.097656 65.796875 11.003906 62.863281 9.769531 C 59.855469 8.503906 56.703125 9.808594 54.429688 13.269531 C 53.894531 14.082031 52.796875 14.3125 51.984375 13.773438 C 51.167969 13.238281 50.941406 12.144531 51.476562 11.328125 C 54.714844 6.410156 59.597656 4.5625 64.234375 6.515625 C 68.789062 8.429688 70.976562 14.253906 69.433594 20.359375 C 69.226562 21.160156 68.507812 21.695312 67.71875 21.695312 Z M 67.71875 21.695312 "
      />
      <g clip-path="url(#clip1)" clip-rule="nonzero">
        <path
          style="
            stroke: none;
            fill-rule: nonzero;
            fill: rgb(0%, 70.979309%, 24.708557%);
            fill-opacity: 1;
          "
          d="M 45.152344 70.140625 L 29.847656 70.140625 L 29.847656 62.394531 L 33.378906 62.394531 L 33.378906 66.613281 L 41.621094 66.613281 L 41.621094 62.394531 L 45.152344 62.394531 Z M 45.152344 70.140625 "
        />
      </g>
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(0%, 70.979309%, 24.708557%);
          fill-opacity: 1;
        "
        d="M 37.5 60.917969 C 35.988281 60.917969 34.566406 60.25 33.601562 59.082031 L 30.257812 55.039062 C 29 53.515625 28.742188 51.457031 29.582031 49.667969 C 30.425781 47.882812 32.179688 46.769531 34.15625 46.769531 L 40.84375 46.769531 C 42.824219 46.769531 44.574219 47.882812 45.417969 49.667969 C 46.261719 51.457031 46 53.515625 44.742188 55.039062 L 41.398438 59.082031 C 40.433594 60.25 39.011719 60.917969 37.5 60.917969 Z M 34.15625 50.296875 C 33.261719 50.296875 32.875 50.964844 32.78125 51.171875 C 32.683594 51.375 32.414062 52.097656 32.984375 52.789062 L 36.328125 56.832031 C 36.726562 57.3125 37.234375 57.386719 37.5 57.386719 C 37.769531 57.386719 38.277344 57.3125 38.675781 56.832031 L 42.019531 52.789062 C 42.589844 52.097656 42.320312 51.375 42.222656 51.171875 C 42.128906 50.964844 41.742188 50.296875 40.847656 50.296875 Z M 34.15625 50.296875 "
      />
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(0%, 70.979309%, 24.708557%);
          fill-opacity: 1;
        "
        d="M 35.734375 64.363281 L 39.265625 64.363281 L 39.265625 68.152344 L 35.734375 68.152344 Z M 35.734375 64.363281 "
      />
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(0%, 70.979309%, 24.708557%);
          fill-opacity: 1;
        "
        d="M 28.78125 34.078125 C 28.78125 34.371094 28.726562 34.652344 28.613281 34.921875 C 28.503906 35.195312 28.34375 35.433594 28.136719 35.640625 C 27.929688 35.847656 27.691406 36.003906 27.417969 36.117188 C 27.148438 36.230469 26.867188 36.285156 26.574219 36.285156 C 26.28125 36.285156 26 36.230469 25.730469 36.117188 C 25.460938 36.003906 25.222656 35.847656 25.015625 35.640625 C 24.808594 35.433594 24.648438 35.195312 24.535156 34.921875 C 24.425781 34.652344 24.367188 34.371094 24.367188 34.078125 C 24.367188 33.785156 24.425781 33.503906 24.535156 33.234375 C 24.648438 32.964844 24.808594 32.726562 25.015625 32.519531 C 25.222656 32.3125 25.460938 32.152344 25.730469 32.039062 C 26 31.925781 26.28125 31.871094 26.574219 31.871094 C 26.867188 31.871094 27.148438 31.925781 27.417969 32.039062 C 27.691406 32.152344 27.929688 32.3125 28.136719 32.519531 C 28.34375 32.726562 28.503906 32.964844 28.613281 33.234375 C 28.726562 33.503906 28.78125 33.785156 28.78125 34.078125 Z M 28.78125 34.078125 "
      />
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(0%, 70.979309%, 24.708557%);
          fill-opacity: 1;
        "
        d="M 50.632812 34.078125 C 50.632812 34.371094 50.574219 34.652344 50.464844 34.921875 C 50.351562 35.195312 50.191406 35.433594 49.984375 35.640625 C 49.777344 35.847656 49.539062 36.003906 49.269531 36.117188 C 49 36.230469 48.71875 36.285156 48.425781 36.285156 C 48.132812 36.285156 47.851562 36.230469 47.582031 36.117188 C 47.308594 36.003906 47.070312 35.847656 46.863281 35.640625 C 46.65625 35.433594 46.496094 35.195312 46.386719 34.921875 C 46.273438 34.652344 46.21875 34.371094 46.21875 34.078125 C 46.21875 33.785156 46.273438 33.503906 46.386719 33.234375 C 46.496094 32.964844 46.65625 32.726562 46.863281 32.519531 C 47.070312 32.3125 47.308594 32.152344 47.582031 32.039062 C 47.851562 31.925781 48.132812 31.871094 48.425781 31.871094 C 48.71875 31.871094 49 31.925781 49.269531 32.039062 C 49.539062 32.152344 49.777344 32.3125 49.984375 32.519531 C 50.191406 32.726562 50.351562 32.964844 50.464844 33.234375 C 50.574219 33.503906 50.632812 33.785156 50.632812 34.078125 Z M 50.632812 34.078125 "
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "dog-icon",
};
</script>