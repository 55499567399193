import Model from "./Model";

export default class File extends Model {
  static async getPresignedUrl(file, type, entityUuid, extension) {
    const response = await window.Http.post(
      process.env.VUE_APP_API_URL + "/api/v2/files/get-presigned-url",
      {
        entity_uuid: entityUuid,
        type,
        file_name: file.name,
        file_type: file.type, // Send the MIME type of the file
        file_extension: extension,
      }
    );

    return this.make(response);
  }

  static async store(fileUuid, entityUuid) {
    const response = await window.Http.put(
      process.env.VUE_APP_API_URL + "/api/v2/files/" + fileUuid,
      {
        entity_uuid: entityUuid,
        file_uuid: fileUuid,
      }
    );

    return this.make(response);
  }
}