import Model from "./Model";
import axios from "axios";

export default class User extends Model {
    static async checkout(data) {
        const config = {
            method: "post",
            url:
                process.env.VUE_APP_API_URL + "/api/v1/stripe/create-checkout-session",
            data: data,
        };

        return await axios(config);

        // const result = await window.Http.post("api/v1/stripe/checkout", data);
    }

    static async completePurchase(data) {
        const result = await window.Http.post("api/v1/shop/orders", data);
        return result;
    }

    static async checkValidCoupon(data) {
        const result = await window.Http.post("api/v1/shop/validate-coupon", data);
        return result;
    }

    static async completeSubscription(data) {
        const result = await window.Http.post(
            "api/v1/stripe/complete-subscription",
            data
        );
        return result;
    }

    static async createSubscription(coupon) {
        const config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/v1/stripe/create-subscription",
            data: {coupon},
        };

        return await axios(config);
    }

    static async updatePaymentMethod(data) {
        const result = await window.Http.post(
            "api/v1/stripe/update-payment-method",
            data
        );
        return result;
    }

    static async cancelSubscription(data) {
        const result = await window.Http.post(
            "api/v1/stripe/cancel-subscription",
            data
        );
        return result;
    }
}