<template>
  <div>
    <back-button></back-button>
    <div class="vet-add tw-w-full tw-mt-8 tw-bg-white tw-z-20">
      <div class="vet-add-form tw-border tw-border-mp-blue tw-rounded">
        <form class="tw-flex tw-flex-col">
          <div
            class="vet-add-form-title tw-h-16 tw-bg-mp-blue-opacity tw-flex tw-justify-center tw-items-center tw-rounded-t"
          >
            <h2 class="tw-text-black">Vet</h2>
          </div>
          <div class="vet-add-form-body md:tw-px-24 tw-px-4 tw-py-2">
            <vet-lookup
              class="tw-z-50"
              v-if="!loading"
              :vet="vet"
              @setVet="setVet"
            ></vet-lookup>
          </div>
          <div class="tw-flex tw-justify-items-start tw-px-4">
            <label
              class="tw-inline-flex tw-items-top tw-mt-3 tw-justify-center tw-items-center"
            >
              <input
                v-model="applyToAllPets"
                type="checkbox"
                class="form-checkbox tw-h-4 tw-w-4 tw-text-gray-600"
                checked
              /><span class="ml-2 mr-2 text-gray-700"
                >Make this my vet for all my pets</span
              >
            </label>
          </div>
          <div
            v-if="!applyToAllPets"
            class="pets-container tw-overflow-x-auto tw-mt-2 tw-h-32 tw-w-full tw-px-4"
          >
            <div class="tw-flex tw-justify-items-start">
              <div>Only apply to these pets:</div>
            </div>
            <div class="pets tw-flex tw-justify-center tw-mt-2">
              <div v-for="pet in pets" :key="pet.id">
                <button
                  :class="[
                    'tw-py-1 tw-px-2 tw-ml-2 tw-rounded-2xl tw-text-white',
                    selectedPets.includes(pet.id)
                      ? 'tw-bg-mp-blue'
                      : 'tw-bg-gray-400',
                  ]"
                  @click.prevent="toggleSelectPet(pet.id)"
                >
                  {{ pet.name }}
                </button>
              </div>
            </div>
          </div>
          <div class="tw-flex tw-justify-center tw-w-full">
            <button
              :disabled="!vet.name"
              :class="[vet.name ? 'tw-bg-mp-light-blue' : 'tw-bg-gray-300']"
              type="submit"
              class="tooltip-target tw-border-transparent tw-w-2/3 tw-h-full tw-inline-flex tw-justify-center tw-mt-4 tw-mb-2 tw-py-2 tw-border tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-opacity-70 hover:tw-opacity-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
              @click.prevent="save"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInputV2 from "@/components/shared/BaseInputV2.vue";
import AddressLookup from "@/components/shared/AddressLookup.vue";
import { CheckCircleIcon, XCircleIcon } from "@vue-hero-icons/solid";
import FlagDropdown from "@/components/shared/FlagDropDown.vue";
import VetLookup from "@/components/shared/VetLookup.vue";
import Vet from "@/models/Vet";
import BackButton from "@/components/shared/BackButton.vue";
import Pet from "@/models/Pet";

export default {
  components: {
    BackButton,
    CheckCircleIcon,
    FlagDropdown,
    XCircleIcon,
    AddressLookup,
    BaseInputV2,
    VetLookup,
  },
  data() {
    return {
      applyToAllPets: true,
      loading: false,
      vet: {
        name: null,
        address: null,
        place_id: null,
      },
      pets: [],
      selectedPets: [],
    };
  },
  methods: {
    async toggleSelectPet(petId) {
      if (this.selectedPets.includes(petId)) {
        this.selectedPets = this.selectedPets.filter((item) => item !== petId);
      } else {
        this.selectedPets.push(petId);
      }
    },
    async setVet(googleVetData) {
      this.vet.name = googleVetData.name;
      this.vet.address = googleVetData.address;
      this.vet.place_id = googleVetData.place_id;
    },
    async save() {
      const data = {
        ...this.vet,
        pet_uuids: this.applyToAllPets
          ? (this.selectedPets = this.pets.map((p) => p.id))
          : this.selectedPets,
      };
      await Vet.create(data);
      await this.$router.push({
        name: "VetsHome",
      });
    },
  },
  async beforeMount() {
    this.pets = await Pet.list();
    this.selectedPets = this.pets.map((p) => p.id);
  },
};
</script>

<style scoped></style>