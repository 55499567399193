import { render, staticRenderFns } from "./MicrochipAlreadyAssignedModal.vue?vue&type=template&id=48f006db&scoped=true"
import script from "./MicrochipAlreadyAssignedModal.vue?vue&type=script&lang=js"
export * from "./MicrochipAlreadyAssignedModal.vue?vue&type=script&lang=js"
import style0 from "./MicrochipAlreadyAssignedModal.vue?vue&type=style&index=0&id=48f006db&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f006db",
  null
  
)

export default component.exports