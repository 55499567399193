<template>
  <v-progress-circular
      v-if="loading"
      :size="70"
      :width="7"
      color="#0074b5"
      indeterminate
  ></v-progress-circular>
  <pet-create-form
      v-else
      :user="user"
      :user-fields-missing="checkUserDetails"
      :microchip="microchip"
  ></pet-create-form>
</template>

<script>
import PetCreateForm from "../components/PetCreateForm.vue";

export default {
  components: {PetCreateForm},
  props: {
    microchip: String,
  },
  data() {
    return {
      loading: true,
      user: "",
      userFieldsMissing: [],
    };
  },
  async mounted() {
    await this.getUser();
  },
  computed: {
    checkUserDetails() {
      let userFieldsMissing = [];

      if (!this.user.first_name) {
        userFieldsMissing.push({name: "First name"});
      }
      if (!this.user.last_name) {
        userFieldsMissing.push({name: "Last name"});
      }
      if (!this.user.phone) {
        userFieldsMissing.push({name: "Phone number"});
      }
      if (!this.user.address_line_1) {
        userFieldsMissing.push({name: "Address line 1"});
      }
      if (!this.user.city) {
        userFieldsMissing.push({name: "City"});
      }
      if (!this.user.postcode) {
        userFieldsMissing.push({name: "Postcode"});
      }

      return userFieldsMissing;
    },
  },
  methods: {
    async getUser() {
      try {
        this.user = await this.retrieveUser();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
</style>
