<template>
  <div>
    <back-button></back-button>
    <v-progress-circular
      v-if="loading"
      :size="70"
      :width="7"
      color="#0074b5"
      indeterminate
    ></v-progress-circular>
    <pet-profile-form
      v-else
      :pet="pet"
      :user="user"
      :user-fields-missing="checkUserDetails"
      :action="action"
    ></pet-profile-form>
  </div>
</template>

<script>
import Pet from "../models/Pet";
import PetProfileForm from "../components/PetProfileForm";
import BackButton from "@/components/shared/BackButton.vue";

export default {
  components: { BackButton, PetProfileForm },
  props: {
    id: "",
    action: "",
    microchip: "",
  },
  data() {
    return {
      loading: true,
      pet: "",
      user: "",
    };
  },
  async beforeMount() {
    await this.getPet();
    await this.getUser();
  },
  computed: {
    checkUserDetails() {
      let userFieldsMissing = [];

      if (!this.user.first_name) {
        userFieldsMissing.push("First name");
      }
      if (!this.user.last_name) {
        userFieldsMissing.push("Last name");
      }
      if (!this.user.email) {
        userFieldsMissing.push("Email");
      }
      if (!this.user.phone) {
        userFieldsMissing.push("Phone number");
      }
      if (!this.user.address_line_1) {
        userFieldsMissing.push("Address line 1");
      }
      if (!this.user.city) {
        userFieldsMissing.push("City");
      }
      if (!this.user.postcode) {
        userFieldsMissing.push("Postcode");
      }

      return userFieldsMissing;
    },
  },
  methods: {
    async getPet() {
      try {
        this.pet = await Pet.retrieve(this.id);

        if (!this.pet.microchip_number && this.microchip) {
          this.pet.microchip_number = this.microchip;
        }
      } finally {
      }
    },
    async getUser() {
      try {
        this.user = await this.retrieveUser();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>