<template>
  <transition name="modal-fade">
    <div
        class="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto tw-w-full tw-m-auto"
    >
      <div
          class="
          tw-flex
          tw-items-center
          tw-justify-center
          tw-min-h-screen
          tw-pt-4
          tw-px-8
          tw-text-center
          tw-sm:block
          tw-sm:p-0
        "
      >
        <div
            class="tw-fixed tw-inset-0 tw-transition-opacity"
            aria-hidden="true"
        >
          <div
              class="tw-absolute tw-inset-0 tw-bg-gray-500 tw-opacity-75"
          ></div>
        </div>

        <div
            class="tw-z-40 tw-py-4 tw-bg-white tw-items-center tw-justify-center tw-mx-auto tw-rounded-lg tw-border-2 tw-border-mp-blue tw-p-4"
        >
          <img src="@/assets/logo.svg" class=" tw-w-80 tw-mb-4 tw-mx-auto"/>

          <hr class="tw-mt-3 tw-mb-4">

          <div
              style="display: block; text-align: center; word-break: break-word"
              class="tw-text-xl tw-font-medium tw-ml-2 tw-mr-2 tw-mb-4"
          >
            We’ve started the new keeper process. The following will now happen:
          </div>

          <div
              class="tw-mx-auto tw-text-md"
          >
            <div class="tw-text-left">
              <p>&#x2022; The previous keeper will receive an email or SMS with the ability to Confirm the transfer or
                reject
                the
                transfer</p>
              <p>&#x2022; If they do not respond within 3 days they will receive a second email.</p>
              <p>&#x2022; On the 7th day we will send another email.</p>
              <p>&#x2022; If they don’t respond by the end of the 7th day we’ll automatically transfer the pet.</p>
              <p>&#x2022; You’ll get an update as soon as the pet is transferred to you or the previous keeper rejects
                the
                transfer.</p>
            </div>
            <button class="tw-bg-white tw-text-mp-blue tw-rounded-md tw-w-16 tw-h-8"
                    @click="$router.push({name: 'UserPets' })">Home
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      checked: false,
    };
  },
};
</script>