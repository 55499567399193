export default class UrlBuilder {
  constructor(base) {
    this.base = base;
    this.queryParams = {};
  }

  addQueryParam(key, value) {
    this.queryParams[key] = value;
    return this;
  }

  removeQueryParam(key) {
    delete this.queryParams[key];
    return this;
  }

  build() {
    let url = this.base;

    const queryStrings = [];
    for (let key in this.queryParams) {
      const value = encodeURIComponent(this.queryParams[key]);
      const encodedKey = encodeURIComponent(key);
      queryStrings.push(`${encodedKey}=${value}`);
    }

    if (queryStrings.length) {
      url += "?" + queryStrings.join("&");
    }

    return url;
  }
}
