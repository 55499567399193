<template>
  <v-dialog v-model="visible" width="800">
    <div class="insurance-help" style="margin: auto; width: 100%">
      <div class="insurance-help-main">
        <div
          class="insurance-help-main-header blue-bkg"
          style="position: relative"
        >
          <div>{{ modalHeadline }}</div>
          <svg
            fill="currentColor"
            style="
              width: 50px;
              height: 50px;
              cursor: pointer;
              position: absolute;
              top: 0;
              right: 0;
            "
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            @click="close"
          >
            <path
              clip-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              fill-rule="evenodd"
            />
          </svg>
        </div>
        <div class="insurance-help-main-body mt-4 ml-4 mr-4">
          <div class="insurance-help-main-body-text">
            As you're joining the MyPet community we're excited to offer you 4
            weeks free pet insurance in partnership with the award-winning Agria
            Pet Insurance. Your 4 weeks of pet insurance is completely free.
            There's no obligation and the insurance will automatically expire at
            the end of the cover.
          </div>
          <br />

          <div class="whats-included">
            <div class="insurance-text-title">What's included:</div>

            <br />

            <div class="insurance-items-included">
              <div class="insurance-list-item">
                <svg
                  class="insurance-check-mark"
                  fill="currentColor"
                  style="left: 0"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    fill-rule="evenodd"
                  />
                </svg>
                Vet fees cover up to £,1500
              </div>
              <div class="insurance-list-item">
                <svg
                  class="insurance-check-mark"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    fill-rule="evenodd"
                  />
                </svg>
                Immediate cover for injury
              </div>
              <div class="insurance-list-item">
                <svg
                  class="insurance-check-mark"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    fill-rule="evenodd"
                  />
                </svg>
                Illness cover starts in 14 days
              </div>
              <div class="insurance-list-item">
                <svg
                  class="insurance-check-mark"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    fill-rule="evenodd"
                  />
                </svg>
                Advertising & reward costs up to £1,000
              </div>
            </div>
          </div>

          <br />

          <div class="insurance-legal mt-8">
            <div class="insurance-text-title">The Legal Stuff:</div>
            <br />
            <div class="insurance-text">
              By activating this cover, you consent to your personal details
              being passed to Agria for the purposes of your insurance. Please
              note, the insurance does not provide cover for any illness, injury
              or sign that your animal was unwell, that happened before the
              insurance starts. Please refer to the
              <a
                href="https://assets.agriapet.co.uk/terms/AO4wf_policy_terms_and_conditions.pdf"
                style="text-decoration: underline"
                target="_blank"
                >T&Cs</a
              >
              and
              <a
                href="https://www.agriapet.co.uk/privacy-policy/"
                style="text-decoration: underline"
                target="_blank"
                >Agria privacy policy</a
              >
              for full details.
              <br />
              You can see the full Insurance Product Information document
              <a
                href="https://mypethq.io/wp-content/uploads/2023/10/Agria-4-week-free-IPID.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                HERE </a
              >.
            </div>
          </div>
          <br />
          <div class="insurance-sub-title mt-4">DOGS NOT COVERED</div>
          <br />
          <br />
          <div class="insurance-text" style="float: left">
            Unfortunately we are unable to offer insurance if your dog is, or is
            crossed with, any of the following breeds: African Boerboel, Alapaha
            Blue Blood Bulldog, American Bull Terrier, American Bulldog,
            American Bully, American Pitbull Terrier, Bully Kutta, Cane Corso,
            Caucasian Shepherd, Czechoslovakian Wolfdog, Dogo Argentino, Fila
            Brasiliero, Japanese Tosa, Perro De Presa Canario (Canary Dog), Thai
            Ridgeback, Saarloos Wolfdog and wolf hybrids.
          </div>
          <br />
          <div class="insurance-sub-title mt-4">CATS NOT COVERED</div>
          <br />
          <div class="insurance-text mb-4" style="float: left">
            Unfortunately, we cannot offer insurance if your cat is a Munchkin,
            any cat that you require a wild animal license to keep, any breed of
            wild cat and any cross with the preceding breeds.
          </div>
          <br />
          <div class="insurance-sub-text mb-4" style="float: left">
            Agria Pet Insurance Ltd is authorised and regulated by the Financial
            Conduct Authority, Financial Services Register Number 496160. Agria
            Pet Insurance Ltd is registered and incorporated in England and
            Wales with registered number 04258783. Registered office: First
            Floor, Blue Leanie, Walton Street, Aylesbury, Buckinghamshire, HP21
            7QW. Agria insurance policies are underwritten by Agria Försäkring.
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      checked: false,
    };
  },
  props: {
    modalHeadline: {
      type: String,
      required: true,
    },
    visible: true,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.insurance-help {
  overflow-y: auto;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
}

.insurance-help-main {
  background-color: white;
}

.insurance-help-main-header {
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.insurance-help-main-body {
  background-color: white;
  text-align: left;
}

.insurance-help-main-body-text {
  color: #444242;
}

.insurance-text-title {
  color: #0074b5;
  font-size: 30px;
}

.insurance-sub-title {
  color: #0074b5;
  font-size: 20px;
  float: left;
}

.insurance-items-included {
  color: #444242;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
  justify-content: flex-start;
  align-self: flex-start;
}

.insurance-text {
  color: #444242;
  font-size: 15px;
}

.insurance-sub-text {
  color: #444242;
  font-size: 12px;
}

.insurance-check-mark {
  width: 30px;
  height: 30px;
  color: #34aa43;
  margin-right: 4px;
}

.insurance-list-item {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.insurance-legal {
  display: flex;
  flex-direction: column;
  float: left;
}
</style>