<template>
  <v-progress-circular
      :size="70"
      :width="7"
      class="mt-8"
      color="#0074b5"
      indeterminate
  ></v-progress-circular>
</template>

<script>
import User from "../models/User";

export default {
  props: {
    token: String,
  },
  data() {
    return {
      user: "",
    };
  },
  methods: {
    async authenticate() {
      await window.Http.setAuthorizationToken(this.token);
      const user = await User.retrieve();
      const t = this.token;

      await this.$store.commit("auth_success", {t, user});

      return user;
    },
  },
  async mounted() {
    const user = await this.authenticate();

    if (user.postcode && !this.$route.query.action) {
      window.location = "/";
    } else if (this.$route.query.action) {
      const action = this.$route.query.action;

      if (action === "referred-microchip-registration") {
        window.location = "/confirm-details?flow=myvet";
      }

      if (action === "transfer-complete") {
        window.location = "/redirect-destination?destination=new-keeper-transfer-accepted";
      }

      if (action === "login-from-forgot-password") {
        window.location = "/";
      }
    } else {
      window.location = "/register-details";
    }
  },
};
</script>

<style scoped>
</style>