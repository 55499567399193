<template>
  <div class="insurance tw-bg-white tw-rounded tw-border tw-border-slate-500">
    <!--    <v-checkbox-->
    <!--        v-model="checked"-->
    <!--    ></v-checkbox>-->
    <div class="insurance-info">
      <div class="insurance-description">
        <div class="title">
          <div class="title-text">
            Congratulations! {{ name }} is eligible for FREE insurance.
          </div>
          <svg
            class="insurance-help-icon"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            @click="$emit('toggleHelp')"
          >
            <path
              clip-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
              fill-rule="evenodd"
            />
          </svg>
        </div>
        <br />
        <div class="main-text">
          As you're joining the MyPet community we're excited to offer you 4
          weeks FREE pet insurance with the award-winning Agria Pet Insurance.
          There's no obligation and the insurance will automatically expire at
          the end of the cover.
        </div>
        <br />
        <div></div>
        <br />
        <v-alert v-if="crossbreedText.length > 0" outlined type="info">
          {{ crossbreedText }}
        </v-alert>

        <div class="sub-text">
          By activating this cover, you consent to your personal details being
          passed to Agria for the purposes of your insurance. Please note, the
          insurance does not provide cover for any illness, injury or sign that
          your animal was unwell, that happened before the insurance starts.
          Please refer to the
          <a
            href="https://assets.agriapet.co.uk/terms/AO4wf_policy_terms_and_conditions.pdf"
            target="_blank"
            rel="noopener noreferrer"
            >T&Cs</a
          >
          and
          <a
            href="https://www.agriapet.co.uk/privacy-policy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Agria privacy policy
          </a>
          for full details. You can see the full Insurance Product Information
          document
          <a
            href="https://mypethq.io/wp-content/uploads/2023/10/Agria-4-week-free-IPID.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            HERE </a
          >.
        </div>
        <br />
        <div style="display: flex">
          <div class="tw-mr-2">For more information click&nbsp;</div>
          <div
            style="color: #0074b5; text-decoration: underline; cursor: pointer"
            @click="$emit('toggleHelp')"
          >
            HERE
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
          "
        >
          <v-col
            class="mx-auto"
            cols="9"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
            "
          >
            <v-btn
              :class="[checked ? 'black--text' : 'white--text']"
              :height="buttonHeight"
              :outlined="checked"
              :disabled="checked"
              class="insurance-option-button cap mb-2"
              color="#00b53f"
              @click="acceptInsurance"
            >
              {{
                checked
                  ? "Awesome! We will send you details!"
                  : "Yes please - I want free insurance!"
              }}
              <v-icon v-if="checked" class="ml-2" color="#00b53f"
                >mdi-checkbox-marked-circle
              </v-icon>
            </v-btn>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Agria from "@/models/Agria";

export default {
  props: {
    name: "",
    crossbreedText: "",
    pet: Object,
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    acceptInsurance() {
      this.checked = true;
      this.$emit("input", this.checked);

      Agria.createPolicy({ pet_id: this.pet.id, ...this.pet });
    },
  },
  computed: {
    buttonHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 100;
        case "sm":
          return 100;
        case "md":
          return 50;
        case "lg":
          return 50;
        case "xl":
          return 50;
      }
    },
  },
};
</script>

<style scoped>
.insurance {
  display: flex;
  align-items: center;
  text-align: left;
}

.insurance-info {
  border-radius: 0.375rem;
  border: 1px solid rgb(226 232 240);
  padding: 1rem;
}

.insurance-help-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #11ab5b;
  cursor: pointer;
}

@media (max-width: 768px) {
  .insurance-help-icon {
    width: 3rem;
    height: 3rem;
    color: #11ab5b;
    cursor: pointer;
  }
}

.title {
  display: flex;
  align-items: baseline;
}

.title-text {
  font-size: 1.125rem;
}

.main-text {
  font-size: 1rem;
}

.sub-text {
  font-size: 0.75rem;
}
</style>