<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="9" sm="8">
        <v-card class="mx-auto mt-5" width="400">
          <v-toolbar class="blue-bkg no-shadow">
            <v-toolbar-title>Login</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-alert v-if="errorMessage" dense outlined type="error">
                {{ errorMessage }}
              </v-alert>
              <div class="tw-flex tw-flex-col tw-text-left">
                <v-text-field
                    v-if="!loginViaMobile"
                    v-model="email"
                    :rules="emailRules"
                    label="Email"
                    prepend-icon="mdi-account-circle"
                />
                <div class="tw-flex tw-items-center" v-if="loginViaMobile">
                  <flag-drop-down-deprecated
                      @input="setIsoCountryCode"
                      class="tw-w-12 tw-h-8 tw-mr-4"
                  ></flag-drop-down-deprecated>
                  <v-text-field
                      class="tw-w-8"
                      v-model="mobile"
                      :rules="textFieldRules"
                      label="Mobile"
                  />
                </div>
              </div>
              <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  label="Password"
                  prepend-icon="mdi-lock"
                  @click:append="showPassword = !showPassword"
              />
              <div
                  class="tw-text-mp-blue tw-cursor-pointer tw-font-thin"
                  @click="loginViaMobile = !loginViaMobile"
              >
                login via {{ loginViaMobile ? "email" : "mobile" }}
              </div>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center" style="flex-direction: column">
            <span style="display: flex; flex-direction: column">
              <v-btn class="cap white--text blue-bkg mb-2" @click="submit"
              >Login</v-btn
              >
              <GoogleLoginRed
                  class="cap mb-2"
                  text="Login with google"
                  @click="googleLogin"
              ></GoogleLoginRed>
              <v-btn
                  class="cap transp-bkg no-shadow cap mob-text grey-text"
                  @click="$router.push({ name: 'UserRegister' })"
              >Register new account</v-btn
              >
            </span>
          </v-card-actions>
          <!--                    <v-row class="justify-start"> <v-btn @click="googleLogin" color="white&#45;&#45;text blue-bkg mb-2 ml-5">Google Login</v-btn></v-row>-->
        </v-card>
        <div
            class="transp-bkg no-shadow cap blue-text mob-text mt-5"
            @click="$router.push({ name: 'ForgotPassword' })"
        >
          Forgot password?
        </div>
      </v-col>
    </v-row>
    <div class="mx-auto tw-text-mp-blue tw-font-medium tw-text-2xl mb-8 mt-4">
      Why register with MyPet?
    </div>
    <offers></offers>
  </v-container>
</template>

<script lang="js">
import GoogleLoginRed from "../components/shared/GoogleLoginRed";
import Offers from "../components/shared/Offers";
import FlagDropdown from "../components/shared/FlagDropDown";
import FlagDropDownDeprecated from "@/components/shared/FlagDropDownDeprecated.vue";

export default {
  components: {FlagDropDownDeprecated, GoogleLoginRed, Offers, FlagDropdown},
  data() {
    return {
      loginViaMobile: false,
      email: "",
      mobile: "",
      emailRules: [
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      textFieldRules: [(v) => !!v || "Required"],
      errorMessage: "",
      password: "",
      showPassword: false,
      beforeChangeIsoCountryCode: "GB",
      isoCountryCode: "GB",
      isoCountryCodeMap: [
        {
          "isoCountryCode": "GB",
          "prefix": "+44"
        },
        {
          "isoCountryCode": "IE",
          "prefix": "+353"
        }
      ],
    };
  },
  mounted() {
    if (this.isLoggedIn && this.$route.query.redirect) {
      let location = "";

      if (this.$route.query.redirect === 'subscriptions') {
        location = "/subscription-checkout";
      }

      if (this.$route.query.coupon) {
        location += '?coupon=' + this.$route.query.coupon;
      }

      window.location = location;
    }
  },
  beforeMount() {
    this.setCountryMobilePhoneCode();
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    setIsoCountryCode(code) {
      const oldPrefix = this.isoCountryCodeMap.find(i => i.isoCountryCode === this.beforeChangeIsoCountryCode).prefix;
      const suffix = this.mobile.slice(oldPrefix.length);

      this.isoCountryCode = code;
      this.beforeChangeIsoCountryCode = code;

      const newPrefix = this.isoCountryCodeMap.find(i => i.isoCountryCode === this.isoCountryCode).prefix;
      this.mobile = newPrefix + suffix;

      this.setCountryMobilePhoneCode();
    },
    async setCountryMobilePhoneCode() {
      const prefix = this.isoCountryCodeMap.find(i => i.isoCountryCode === this.isoCountryCode).prefix;

      if (!this.mobile || ["+44", "+353"].includes(this.mobile) || this.mobile.length < prefix.length) {
        this.mobile = prefix;
        this.mobilePhoneNumberIsInvalid = false;
      }

      if (this.mobile.charAt(prefix.length) === '0') {
        this.mobile = this.mobile.slice(0, prefix.length) + this.mobile.slice(prefix.length + 1, this.mobile.length);
      }

      let result = [...this.mobile];

      for (let i = 0; i < prefix.length; i++) {
        const c = prefix.charAt(i);
        const t = result[i];
        if (c !== t) {
          result.splice(i, 0, c);
        }
      }

      this.mobile = result.join('');
    },
    submit() {
      let email = this.email;
      let mobile = this.mobile;
      let password = this.password;

      let payload = {
        email: email ? email : undefined,
        mobile: !email ? mobile : undefined,
        password
      };

      this.$store
          .dispatch("login", payload)
          .then(() => this.redirect())
          .catch((err) => this.handleError(err));

      this.registerMixpanel(this.$store.getters.user);
    },
    googleLogin() {
      this.registerMixpanel(this.$store.getters.user);
      window.location = process.env.VUE_APP_API_URL + "/auth/google";
    },
    handleError() {
      this.errorMessage = "Your login details are invalid";
    },
    redirect() {
      const redirect = this.$route.query.redirect;

      if (redirect) {

        if (redirect === 'subscriptions') {
          let location = "/subscription-checkout";

          if (this.$route.query.coupon) {
            location += '?coupon=' + this.$route.query.coupon;
          }

          window.location = location;
          return;
        }
      }

      const user = this.$store.getters.user;

      if (user.force_reset) {
        window.location = "/settings";
      } else if (!user.postcode) {
        window.location = "/register-details";
      } else {
        window.location = "/";
      }
    },
  },
};
</script>

<style scoped>
select {
  -webkit-appearance: listbox !important;
}
</style>