<template>
  <div>
    <v-alert outlined type="error">
      Whoops there was an issue - To complete your pet’s registration please
      email us -
      <a href="support@mypethq.io">support@mypethq.io</a>
    </v-alert>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
