import Model from "./Model";

export default class AdditionalContact extends Model {
  static async create(form) {
    const response = await window.Http.post(
      "/api/v2/additional-contacts",
      form
    );
    return this.make(response.data);
  }

  static async update(uuid, form) {
    const response = await window.Http.patch(
      "/api/v2/additional-contacts/" + uuid,
      form
    );
    return this.make(response.data);
  }

  static async list(form) {
    const response = await window.Http.get("/api/v2/additional-contacts", form);
    return this.make(response.data);
  }

  static async retrieve(id) {
    const response = await window.Http.get("/api/v2/additional-contacts/" + id);
    return this.make(response.data);
  }
}