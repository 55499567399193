<template>
  <v-progress-circular
      v-if="loading"
      :size="70"
      :width="7"
      color="#0074b5"
      indeterminate
  ></v-progress-circular>
  <v-card
      class="blue-bkg white-text card-container"
      outlined
      v-else-if="success"
  >
    <img src="@/assets/logo-white.svg" class="reg-logo"/>

    <v-card-subtitle class="white-text subtitle">
      Congratulations! Your registration is complete and you’re subscribed to
      MyPet Premium!
      <br/><br/>
      You can now set up all your premium features on our dashboard!
    </v-card-subtitle>
    <v-card-actions class="card-actions">
      <v-btn
          color="#00b53f"
          class="cap white--text"
          primary
          @click="continueToAccount"
      >Continue to dashboard
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Stripe from "../models/Stripe";

export default {
  data() {
    return {
      loading: true,
      pets: {},
      user: null,
      success: false,
    };
  },
  methods: {
    async completeSubscription() {
      this.loading = true;
      const data = {session_id: this.$route.query.session_id};

      const result = await Stripe.completeSubscription(data);

      this.success = result.subscribed;

      if (this.success) {
        this.$mixpanel.track(
            "Subscription checkout completed successfully.",
            {
              version: 'b',
              date: '20240805',
            }
        );

        const subscribedUser = await this.retrieveUser();
        await this.saveSubscription(subscribedUser.subscription);
      }

      await this.$store.commit("update_cart", this.user);
      this.loading = false;
    },
    async continueToAccount() {
      await this.$router.push({name: "Dashboard"});
    },
  },
  async mounted() {
    await this.completeSubscription();
    this.user = await this.retrieveUser();
  },
};
</script>

<style scoped>
.card-container {
  padding-bottom: 20px;
  margin-top: 30px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 600px) {
  .card-container {
    max-width: 400px;
  }
}

.reg-logo {
  margin-left: 16px;
  margin-bottom: 16px;
  max-width: 90%;
}

.subtitle {
  font-size: 16px;
  padding-top: 10px;
}

.card-actions {
  justify-content: center;
}
</style>