<template>
  <v-progress-circular
    v-if="loading"
    :size="70"
    :width="7"
    color="#0074b5"
    indeterminate
  ></v-progress-circular>
  <settings :user="user" v-else></settings>
</template>

<script>
import Settings from "../components/Settings";

export default {
  components: { Settings },
  data() {
    return {
      loading: false,
      user: "",
    };
  },
  methods: {
    async getUser() {
      this.loading = true;
      try {
        this.user = await this.retrieveUser();
      } finally {
        this.loading = false;
      }
    },
  },
  beforeMount() {
    this.getUser();
  },
};
</script>

<style scoped></style>