<template>
  <div
    class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-12 tw-text-mp-blue"
  >
    <div
      class="tw-border-2 tw-rounded-lg tw-border-gray-500 tw-bg-gray-200 tw-flex tw-h-36 tw-items-center"
    >
      <div class="ml-2 tw-flex tw-justify-center tw-w-3/12">
        <img class="icon offers-icon" src="@/assets/img/pound-sterling.svg" />
      </div>
      <div class="tw-ml-2 tw-w-9/12">£2.99 to register your pet for life</div>
    </div>
    <div
      class="tw-border-2 tw-rounded-lg tw-border-gray-500 tw-bg-gray-200 tw-flex tw-h-36 tw-items-center"
    >
      <div class="ml-2 tw-flex tw-justify-center tw-w-3/12">
        <img class="icon offers-icon" src="@/assets/img/email.svg" />
      </div>
      <div class="tw-ml-2 tw-w-2/3">
        We'll email you anytime your pet's microchip is searched
      </div>
    </div>
    <div
      class="tw-border-2 tw-rounded-lg tw-border-gray-500 tw-bg-gray-200 tw-flex tw-h-36 tw-items-center"
    >
      <div class="ml-2 tw-flex tw-justify-center tw-w-3/12">
        <img class="icon offers-icon" src="@/assets/img/offers.svg" />
      </div>
      <div class="tw-ml-2 tw-w-2/3">
        Access to an exclusive range of offers and discounts
      </div>
    </div>
    <div
      class="tw-border-2 tw-rounded-lg tw-border-gray-500 tw-bg-gray-200 tw-flex tw-h-36 tw-items-center"
    >
      <div class="ml-2 tw-flex tw-justify-center tw-w-3/12">
        <img class="icon offers-icon" src="@/assets/img/percentage.svg" />
      </div>
      <div class="tw-ml-2 tw-w-2/3">10% off in the MyPet shop</div>
    </div>
    <div
      class="tw-border-2 tw-rounded-lg tw-border-gray-500 tw-bg-gray-200 tw-flex tw-h-36 tw-items-center"
    >
      <div class="ml-2 tw-flex tw-justify-center tw-w-3/12">
        <img class="icon offers-icon" src="@/assets/img/email-closed.svg" />
      </div>
      <div class="tw-ml-2 tw-w-2/3">
        We'll email you every 6 months to check your details are up to date
      </div>
    </div>
    <div
      class="tw-border-2 tw-rounded-lg tw-border-gray-500 tw-bg-gray-200 tw-flex tw-h-36 tw-items-center"
    >
      <div class="ml-2 tw-flex tw-justify-center tw-w-3/12">
        <img class="icon offers-icon" src="@/assets/img/more-coming.svg" />
      </div>
      <div class="tw-ml-2 tw-w-2/3">More coming soon...</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
};
</script>

<style scoped>
.offers-icon {
  width: 80px;
}
</style>
