module.exports = {
  prefix: "tw-",
  content: ["./public/**/*.html", "./src/**/*.{js,jsx,ts,tsx,vue}"],
  theme: {
    extend: {
      colors: {
        "mp-blue": "#0074b5",
        "mp-blue-opacity": "#b2d5e8",
        "mp-blue-grey": "#4b6675",
        "mp-dark-green": "#487b3c",
        "mp-dark-blue": "#0050b9",
        "mp-green": "#00b53f",
        "mp-green-opacity": "#CCEDD2",
        "mp-grey": "#757575",
        "mp-charcoal": "#333333",
        "mp-text-grey": "#444242",
        "mp-light-blue": "#0050B9",
        "mp-light-green": "#00a81f",
        "mp-off-white": "#F5F5F5",
        "mp-orange": "#b54100",
        "mp-purple": "#B90050",
        "mp-sky-blue": "#a4d1eb",
        "mp-teal": "#459ab9",
        "mp-red": "#B54100",
      },
      fontFamily: {
        LexendDeca: ["Lexend Deca", "serif"],
      },
    },
  },
  plugins: [
    require("@tailwindcss/forms")({
      strategy: "class", // only generate classes
    }),
  ],
};