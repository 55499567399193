<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="9" sm="8">
        <v-card class="mx-auto mt-5" width="400">
          <v-toolbar color="blue-bkg no-shadow " dark flat>
            <v-toolbar-title>Register</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid">
              <v-alert
                v-if="form.errors.has('email') || form.errors.has('password')"
                dense
                outlined
                type="error"
              >
                {{ form.errors.get("email") || form.errors.get("password") }}
              </v-alert>
              <v-text-field
                v-model="email"
                :rules="[rules.email]"
                label="Email"
                prepend-icon="mdi-account-circle"
                required
              />

              <v-text-field
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show ? 'text' : 'password'"
                counter
                hint="At least 8 characters"
                label="Enter Password"
                name="password"
                prepend-icon="mdi-lock"
                required
                @click:append="show = !show"
              ></v-text-field>

              <v-text-field
                v-model="passwordConfirmation"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min, passwordConfirmationRule]"
                :type="show1 ? 'text' : 'password'"
                counter
                hint="At least 8 characters"
                label="Re-enter Password"
                name="input-10-1"
                prepend-icon="mdi-lock"
                required
                @click:append="show1 = !show1"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center" style="flex-direction: column">
            <span style="display: flex; flex-direction: column">
              <v-btn
                :disabled="!valid"
                class="cap white--text blue-bkg mb-2"
                type="submit"
                @click="form.submit()"
                >Sign up</v-btn
              >
              <GoogleLoginRed
                class="cap mb-2"
                text="Sign up with google"
                @click="googleLogin"
              ></GoogleLoginRed>
              <v-btn
                class="transp-bkg no-shadow cap mob-text grey-text"
                @click="$router.push('/login')"
                >Back to login</v-btn
              >
              <v-row class="justify-center">
                <v-btn
                  class="transp-bkg no-shadow cap blue-text mob-text"
                  @click="
                    $router.push({
                      name: 'MicrochipAuthorisedUserRegistration',
                    })
                  "
                  >Authorised user? Register here
                </v-btn>
                <template>
                  <div class="tooltip-box">
                    ?
                    <div class="tooltip">
                      <span class="text tooltiptext">
                        An Authorised user is a Vet, Police Officer, Dog Warden
                        or Animal rescue who needs the ability to search a
                        microchip number and get pet owner details.
                      </span>
                    </div>
                  </div>
                </template>
              </v-row>
              <!-- <v-row> -->

              <!-- </v-row> -->
            </span>
          </v-card-actions>
          <!--          <v-row class="justify-start"> <v-btn @click="googleLogin" color="white&#45;&#45;text blue-bkg mb-2 ml-5">Sign up with google</v-btn></v-row>-->
        </v-card>
      </v-col>
    </v-row>
    <div class="mx-auto tw-text-mp-blue tw-font-medium tw-text-2xl mb-8 mt-12">
      Why register with MyPet?
    </div>
    <offers></offers>
  </v-container>
</template>

<script>
import GoogleLoginRed from "../components/shared/GoogleLoginRed";
import Offers from "../components/shared/Offers";

export default {
  components: { GoogleLoginRed, Offers },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    const initialData = {
      role: "",
      email: "",
      password: "",
      password_confirmation: "",
    };

    return {
      email: "",
      errorMessage: "",
      form: new window.Form(initialData, async (form) => {
        this.form.role = "customer";
        this.form.email = this.email;
        this.form.password = this.password;
        this.form.password_confirmation = this.password;

        await this.$store.dispatch("register", form);

        this.registerMixpanel(this.$store.getters.user);

        window.location = "register-details";
      }),
      password: "",
      passwordConfirmation: "",
      show: false,
      show1: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      },
      valid: true,
    };
  },
  methods: {
    googleLogin() {
      this.registerMixpanel(this.$store.getters.user);
      window.location = process.env.VUE_APP_API_URL + "/auth/google";
    },
  },
  computed: {
    passwordConfirmationRule() {
      return (
        this.password === this.passwordConfirmation || "Password must match"
      );
    },
  },
};
</script>

<style scoped>
.tooltip-box {
  position: relative;
  display: inline-block;
  background: #f3f4f6;
  width: 20px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-top: 6px;
}

.tooltip-box:hover {
  background: rgba(209, 213, 219, var(--tw-bg-opacity));
  cursor: pointer;
}

.tooltip-box:hover .tooltip {
  opacity: 1;
}

.tooltip:hover {
  visibility: visible;
}

.tooltip {
  top: -4px !important;
  width: 100%;
  border-radius: 2px;
  font-size: 9px;
  color: #fff;
  text-align: center;
  background-color: #0074b5;
  left: 28px;
  opacity: 0;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  position: absolute;
  z-index: 1;
  line-height: 1.5;
  min-width: 270px;
  padding: 5px 5px;
}

.text::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  border-color: transparent #3575b5 transparent transparent;
  left: -10px;
  top: 10px;
}

@media screen and (max-width: 991px) {
  .tooltip {
    top: 34px !important;
    left: auto;
    right: 0;
  }

  .text::after {
    border-width: 0 6px 8px 6px;
    border-color: transparent transparent #3575b5 transparent;
    right: 3px;
    left: auto;
    top: -7px;
  }
}

@media screen and (max-width: 365px) {
  .tooltip {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .text::after {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
}
</style>