<template>
  <v-progress-circular
    v-if="loading"
    :size="70"
    :width="7"
    color="#0074b5"
    indeterminate
  ></v-progress-circular>
  <user-edit-details-form v-else :user="user"></user-edit-details-form>
</template>

<script>
import axios from "axios";
import UserEditDetailsForm from "../components/UserEditDetailsForm";
import User from "../models/User";

export default {
  components: { UserEditDetailsForm },
  data() {
    return {
      loading: true,
      user: "",
    };
  },
  async beforeMount() {
    try {
      this.user = await this.retrieveUser();
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>