<template>
  <message title="Unsubscribe successful!" message="You have been unsubscribed from our mailinglist"
           link="home"></message>
</template>

<script>
import Message from "../components/shared/Message";

export default {
  props: {},
  components: {
    Message,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>
