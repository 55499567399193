<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="9">
        <v-card width="600" class="mx-auto mt-5">
          <v-toolbar color="blue-bkg" dark flat>
            <v-toolbar-title>Your details</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form method="post" v-model="valid">
              <v-text-field
                label="First name"
                v-model="form.first_name"
                :rules="textFieldRules"
                required
              ></v-text-field>
              <v-text-field
                label="Last name"
                v-model="form.last_name"
                :rules="textFieldRules"
                required
              ></v-text-field>
              <div class="tw-flex tw-justify-start">
                <div>Mobile</div>
              </div>
              <div class="tw-flex tw-items-center">
                <flag-drop-down-deprecated
                  v-model="isoCountryCode"
                  @input="setIsoCountryCode"
                  class="tw-w-16 tw-mr-4 tw-mt-1 tw-rounded-md tw-h-10 tw-bg-white tw-border tw-border-solid tw-border-slate-500"
                ></flag-drop-down-deprecated>
                <base-input-v2
                  class="tw-w-full tw-text-black"
                  @input="handleMobileNumberInput"
                  required="true"
                  v-model="form.mobile"
                  type="tel"
                ></base-input-v2>
                <v-progress-circular
                  v-if="pinpointApiLoading"
                  :size="20"
                  :width="4"
                  color="#0074b5"
                  indeterminate
                ></v-progress-circular>
                <CheckCircleIcon
                  v-if="mobilePhoneNumberIsValid"
                  class="ml-1 w-8 h-8 tw-text-mp-green"
                >
                </CheckCircleIcon>
                <XCircleIcon
                  v-if="mobilePhoneNumberIsInvalid"
                  class="ml-1 w-8 h-8 tw-text-mp-red"
                >
                </XCircleIcon>
              </div>
              <v-text-field
                label="Telephone"
                v-model="form.phone"
                class="mb-4"
              ></v-text-field>
              <v-row>
                <v-label><h3 class="ml-3">Address details</h3></v-label>
              </v-row>
              <v-row class="mb-4">
                <v-text-field
                  class="ml-3 mt-3"
                  label="Enter postcode"
                  v-model="form.postcode"
                  :rules="textFieldRules"
                  required
                ></v-text-field>
                <v-btn
                  @click="listAddresses()"
                  class="cap ml-5 mt-5 mr-2 white--text"
                  color="#00b53f"
                  prepend-icon="mdi-map-marker"
                >
                  Find address
                </v-btn>
              </v-row>
              <v-select
                v-if="addresses.length > 0"
                :items="addresses"
                item-text="line_1"
                label="Address"
                v-model="address"
                return-object
              ></v-select>
              <v-text-field
                label="Address line 1"
                required
                v-model="form.address_line_1"
                :rules="textFieldRules"
              ></v-text-field>
              <v-text-field
                label="Address line 2"
                required
                v-model="form.address_line_2"
              ></v-text-field>
              <v-text-field
                label="Address line 3"
                required
                v-if="form.address_line_3"
                v-model="form.address_line_3"
              ></v-text-field>
              <v-text-field
                label="Address line 4"
                v-if="form.address_line_4"
                required
                v-model="form.address_line_4"
              ></v-text-field>
              <v-text-field
                label="Town/City"
                v-model="form.city"
                :rules="textFieldRules"
                required
              ></v-text-field>
              <v-checkbox
                label="Are you a pet breeder?"
                v-model="form.breeder"
              ></v-checkbox>
              <v-text-field
                v-show="form.breeder"
                v-model="form.breeder_licence"
              >
                <template v-slot:label>
                  <div>Breeder licence number <small>(optional)</small></div>
                </template>
              </v-text-field>
              <v-text-field
                v-show="form.breeder"
                v-model="form.licensing_authority"
              >
                <template v-slot:label>
                  <div>Licensing Authority <small>(optional)</small></div>
                </template>
              </v-text-field>
              <v-checkbox
                label="Would you like to subscribe to emails; we will send you helpful hints, tips and offers to help you and your pet(s) and anything else we think you might find interesting?"
                v-model="form.subscribe_all"
              ></v-checkbox>
              <v-checkbox
                label="Do you agree to our privacy policy and terms of use?"
                v-model="privacy_policy"
                :rules="[(v) => !!v || 'You must agree to use our service!']"
                required
              ></v-checkbox>
              <a
                class="mr-4 tw-text-mp-blue"
                href="https://www.mypethq.io/privacy/"
                target="_blank"
                >Privacy policy</a
              >
              <a
                class="ml-4 tw-text-mp-blue"
                href="https://www.mypethq.io/terms-of-service/"
                target="_blank"
                >Terms of Service</a
              >
              <v-alert
                v-if="
                  mobilePhoneNumberIsInvalid ||
                  !mobilePhoneNumberUniqueExceptSelf
                "
                class="mt-4"
                type="error"
                outlined
              >
                Your profile has errors!
                <v-divider class="tw-mt-2"></v-divider>
                <div class="black--text text-subtitle-1 mt-4">
                  Please fix these and "update" your profile:
                </div>
                <div
                  v-if="mobilePhoneNumberIsInvalid"
                  class="black--text text-body-2 mt-2"
                >
                  Mobile number is invalid!
                </div>
                <div
                  v-if="!mobilePhoneNumberUniqueExceptSelf"
                  class="black--text text-body-2 mt-2"
                >
                  This mobile number is already associated with an account. If
                  you need help contact Support at
                  <a href="mailto:support@mypethq.io">support@mypethq.io</a>
                </div>
              </v-alert>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn @click="$router.go(-1)" color="grey-bkg">Back</v-btn>
            <v-btn
              :disabled="!computedValid"
              @click="form.submit()"
              type="submit"
              color="blue-bkg"
              >Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import FormService from "@/services/FormService";
import { debounce } from "lodash";
import PhoneNumberService from "@/services/PhoneNumberService";
import FlagDropdown from "../components/shared/FlagDropDown";
import BaseInputV2 from "@/components/shared/BaseInputV2";
import { CheckCircleIcon, XCircleIcon } from "@vue-hero-icons/solid";
import FlagDropDownDeprecated from "@/components/shared/FlagDropDownDeprecated.vue";

export default {
  components: {
    FlagDropDownDeprecated,
    FlagDropdown,
    BaseInputV2,
    CheckCircleIcon,
    XCircleIcon,
  },
  props: {
    user: Object,
  },
  data() {
    const data = {
      country: "",
      first_name: "",
      housenumber: "",
      last_name: "",
      telephone: "",
      postcode: "",
      phone: "",
      city: "",
      breeder: "",
      breeder_licence: "",
      licensing_authority: "",
      subscribe_all: false,
    };

    return {
      address: "",
      addresses: [],
      name: "",
      mobilePhoneNumberUniqueExceptSelf: true,
      mobilePhoneNumberIsValid: false,
      mobilePhoneNumberIsInvalid: false,
      pinpointApiLoading: false,
      isoCountryCode: "GB",
      beforeChangeIsoCountryCode: "GB",
      isoCountryCodeMap: [
        {
          isoCountryCode: "GB",
          prefix: "+44",
        },
        {
          isoCountryCode: "IE",
          prefix: "+353",
        },
      ],
      form: new window.Form(this.user.getAttributes(), async (form) => {
        this.form.update_shop_account = true;

        delete form.email;

        if (["+44", "+353"].includes(form.mobile)) {
          delete form.mobile;
        }

        await this.user.updateV2(form);

        const token = this.$store.state.token;
        const user = this.user;
        await this.$store.commit("auth_success", { token, user });

        await this.$router.push({ name: "PetCreate" });
      }),
      privacy_policy: false,
      textFieldRules: [(v) => !!v || "Required"],
      valid: false,
    };
  },
  async beforeMount() {
    await this.setUserMobile();
  },
  methods: {
    async setUserMobile() {
      if (this.user.mobile) {
        if (this.user.mobile.startsWith("+353")) {
          this.setIsoCountryCode("IE");
        }
      } else {
        await this.setCountryMobilePhoneCode();
      }
    },
    setIsoCountryCode(code) {
      const oldPrefix = this.isoCountryCodeMap.find(
        (i) => i.isoCountryCode === this.beforeChangeIsoCountryCode
      ).prefix;
      const suffix = this.form.mobile.slice(oldPrefix.length);
      this.isoCountryCode = code;
      this.beforeChangeIsoCountryCode = code;
      const newPrefix = this.isoCountryCodeMap.find(
        (i) => i.isoCountryCode === this.isoCountryCode
      ).prefix;
      this.form.mobile = newPrefix + suffix;
      this.setCountryMobilePhoneCode();
    },
    async setCountryMobilePhoneCode() {
      const prefix = this.isoCountryCodeMap.find(
        (i) => i.isoCountryCode === this.isoCountryCode
      ).prefix;
      if (
        !this.form.mobile ||
        ["+44", "+353"].includes(this.form.mobile) ||
        this.form.mobile.length < prefix.length
      ) {
        this.form.mobile = prefix;
        this.mobilePhoneNumberIsInvalid = false;
      }

      if (this.form.mobile.charAt(prefix.length) === "0") {
        this.form.mobile =
          this.form.mobile.slice(0, prefix.length) +
          this.form.mobile.slice(prefix.length + 1, this.form.mobile.length);
      }

      let result = [...this.form.mobile];
      for (let i = 0; i < prefix.length; i++) {
        const c = prefix.charAt(i);
        const t = result[i];
        if (c !== t) {
          result.splice(i, 0, c);
        }
      }
      this.form.mobile = result.join("");
    },
    async resetMobileSettings() {
      this.pinpointApiLoading = false;
      this.mobilePhoneNumberIsValid = false;
      this.mobilePhoneNumberIsInvalid = false;
    },
    async focusMobileNumberInput(event) {
      FormService.setCursorPosition(event.target, this.form.mobile.length);
    },
    async handleMobileNumberInput() {
      await this.resetMobileSettings();
      await this.setCountryMobilePhoneCode();
      if (this.form.mobile && this.form.mobile.length < 8) {
        return;
      }
      this.pinpointApiLoading = true;
      this.mobileNumberInput();
    },
    mobileNumberInput: debounce(function () {
      this.debouncedMobileNumberValidation();
    }, 1600),
    async debouncedMobileNumberValidation() {
      const phoneNumberValidatedEntity =
        await PhoneNumberService.validatePhoneNumber(
          this.form.mobile,
          "mobile"
        );
      if (phoneNumberValidatedEntity.is_valid) {
        this.mobilePhoneNumberIsValid = true;
      } else {
        this.mobilePhoneNumberIsInvalid = true;
      }

      if (phoneNumberValidatedEntity.unique_except_self) {
        this.mobilePhoneNumberUniqueExceptSelf = true;
      } else {
        this.mobilePhoneNumberUniqueExceptSelf = false;
      }
      this.pinpointApiLoading = false;
    },
    submit() {
      this.$refs.form.validate();
    },
    async listAddresses() {
      if (!this.form.postcode) return;
      const response = await axios.get(
        "https://api.getaddress.io/find/" +
          this.form.postcode +
          "?api-key=vb3__sSB4kaFuyPu5vWS7g18118&sort=true&expand=true"
      );

      this.addresses = response.data.addresses;
    },
  },
  computed: {
    computedValid() {
      if (
        this.valid &&
        this.privacy_policy &&
        !this.mobilePhoneNumberIsInvalid
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    address: function () {
      if (this.address) {
        this.form.address_line_1 = this.address.line_1;
        this.form.address_line_2 = this.address.line_2;
        this.form.address_line_3 = this.address.line_3;
        this.form.address_line_4 = this.address.line_4;
        this.form.city = this.address.town_or_city;
      }
    },
  },
};
</script>

<style scoped>
select {
  -webkit-appearance: listbox !important;
}
</style>