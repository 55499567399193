<template>
  <v-progress-circular
      v-if="loading"
      :size="70"
      :width="7"
      color="#0074b5"
      indeterminate
  ></v-progress-circular>
  <pet-transfer-registration-form
      v-else
      :totalData="data"
  ></pet-transfer-registration-form>
</template>

<script>
import Pet from "../models/Pet";
import PetTransferRegistrationForm from "../components/PetTransferRegistrationForm";
import User from "../models/User";

export default {
  components: {PetTransferRegistrationForm},
  props: {
    id: "",
  },
  data() {
    return {
      loading: true,
      pet: "",
      user: "",
      data: "",
    };
  },
  async mounted() {
    // await this.getPet();
    await this.getUser();
  },
  methods: {
    async getPet() {
      try {
        this.pet = await Pet.retrieve(this.id);
      } finally {
      }
    },
    async getUser() {
      try {
        this.data = await User.getPetTransferData(this.id);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
