<template>
  <v-progress-circular
      :size="70"
      :width="7"
      class="mt-8"
      color="#0074b5"
      indeterminate
  ></v-progress-circular>
</template>

<script>
export default {
  props: {
    destination: String,
  },
  async mounted() {

    const destination = this.$route.query.destination;

    switch (destination) {
      case "current-keeper-transfer-accepted":
        await this.$router.push({
          name: "GenericMessageWrapper",
          params: {
            title: "Pet transfer complete!",
            message: "The pet has been transferred to the new owner!",
            link: "Back to home",
          },
        });
        break;
      case "new-keeper-transfer-accepted":
        await this.$router.push({
          name: "ConfirmDetails",
        });
        break;
      case "current-keeper-transfer-rejected":
        await this.$router.push({
          name: "GenericMessageWrapper",
          params: {
            title: "Pet transfer rejected.",
            message: 'Thanks for letting us know we should reject this transfer. Our team may follow up with you on the transfer request. If you would like to open a support ticket to discuss the request please click ',
            link: "Back to home",
            clickHere: "open-a-support-ticket",
          },
        });
        break;
      case "error-review":

        break;
    }
  },
}
</script>

<style scoped>

</style>