<template>
  <div class="dashboard-page">
    <div class="tw-flex tw-justify-center tw-mt-8 tw-mb-8">
      <div
          class="nav-box tw-border tw-shadow-2xl tw-border-mp-blue tw-bg-gray-200 tw-w-80 tw-rounded-xl tw-mt-4 tw-cursor-pointer"
      >
        <div
            class="tw-text-mp-blue tw-mb-4 tw-mt-4 hover:tw-text-xl"
            @click="$router.push({ name: 'Profile' })"
        >
          Update your details
        </div>
        <div
            class="tw-text-mp-blue tw-mb-4 tw-cursor-pointer hover:tw-text-xl"
            @click="$router.push({ name: 'PetCreate' })"
        >
          Add pet
        </div>
        <div
            class="tw-text-mp-blue tw-mb-4 tw-cursor-pointer hover:tw-text-xl"
            @click="$router.push({ name: 'MicrochipSearch' })"
        >
          Search a microchip
        </div>
        <div
            class="tw-text-mp-blue tw-mb-4 tw-cursor-pointer hover:tw-text-xl"
            @click="additionalContactsNavigation"
        >
          Additional contacts
        </div>
        <div
            class="tw-text-mp-blue tw-mb-4 tw-cursor-pointer hover:tw-text-xl"
            @click="vetsNavigation"
        >
          My Vets
        </div>
        <div
            class="tw-text-mp-blue tw-mb-4 tw-cursor-pointer hover:tw-text-xl"
            @click="supportNavigation"
        >
          Premium support
        </div>
      </div>
    </div>
    <pets-list></pets-list>
    <premium-upgrade-modal
        v-if="showPremiumUpgradeModal"
        @upgrade="createSubscription"
        @close="closePremiumUpgradeModal"
    ></premium-upgrade-modal>
  </div>
</template>

<script>
import PetsList from "../components/PetsList.vue";
import PremiumUpgradeModal from "@/components/modals/PremiumUpgradeModal.vue";

export default {
  components: {
    PremiumUpgradeModal,
    PetsList,
  },
  data() {
    return {
      subscriptionType: "basic",
      showPremiumUpgradeModal: false,
    };
  },
  async mounted() {
    this.subscriptionType = await this.getSubscriptionType();
  },
  async beforeMount() {
    const user = await this.retrieveUser();

    const roles = ["microchip_authorised_user", "rescue"];

    if (roles.includes(user.role)) {
      this.$router.push({name: "PetsTable"});
    }

    if (user.role === "breeder") {
      this.$router.push({name: "UserPets"});
    }
  },
  methods: {
    additionalContactsNavigation() {
      if (this.subscriptionType === "premium") {
        this.$router.push({name: "AdditionalContactsHome"});
      } else {
        this.showPremiumUpgradeModal = true;
      }
    },
    vetsNavigation() {
      if (this.subscriptionType === "premium") {
        this.$router.push({name: "VetsHome"});
      } else {
        this.showPremiumUpgradeModal = true;
      }
    },
    supportNavigation() {
      if (this.subscriptionType === "premium") {
        this.$router.push({name: "Support"});
      } else {
        this.showPremiumUpgradeModal = true;
      }
    },
    closePremiumUpgradeModal() {
      this.showPremiumUpgradeModal = false;
    },
    async createSubscription() {
      if (this.subscriptionType === "basic") {
        await this.$router.push({
          name: "SubscriptionPlansPage",
        });
      }
      this.showPremiumUpgradeModal = false;
    },
  },
};
</script>

<style scoped></style>