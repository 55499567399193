<template>
  <form
    @submit.prevent="save"
    class="tw-flex tw-flex-col tw-overflow-hidden sm:tw-rounded-md tw-rounded-b-md"
  >
    <div class="md:tw-px-24 tw-px-4">
      <base-input-v2
        ref="firstNameAnchorRef"
        :invalid-class="
          details.first_name === ''
            ? '  tw-border-solid tw-border-red-500 tw-bg-red-100'
            : ''
        "
        :highlight-class="highlightFirstName ? 'animate-highlight-red' : ''"
        required="true"
        v-model="details.first_name"
        label="First name"
        type="text"
      ></base-input-v2>
      <base-input-v2
        ref="lastNameAnchorRef"
        :invalid-class="
          details.last_name === ''
            ? '  tw-border-solid tw-border-red-500 tw-bg-red-100'
            : ''
        "
        :highlight-class="highlightLastName ? 'animate-highlight-red' : ''"
        required="true"
        v-model="details.last_name"
        label="Last name"
        type="text"
      ></base-input-v2>
      <base-input-v2
        v-if="!user.email"
        :invalid-class="
          details.email === ''
            ? '  tw-border-solid tw-border-red-500 tw-bg-red-100'
            : ''
        "
        v-model="details.email"
        label="Email"
        placeholder="jane@example.com"
        type="email"
      ></base-input-v2>

      <div ref="mobileAnchorRef" class="tw-mt-1 tw-flex tw-justify-start">
        <div>Mobile</div>
      </div>
      <div class="tw-flex tw-items-center">
        <flag-drop-down-deprecated
          v-model="isoCountryCode"
          @input="setIsoCountryCode"
          class="tw-mt-1"
        ></flag-drop-down-deprecated>
        <base-input-v2
          class="tw-w-full"
          :invalid-class="
            mobilePhoneNumberIsInvalid
              ? 'tw-border tw-ring-red-500 tw-border-red-500 tw-bg-red-100'
              : ''
          "
          :highlight-class="highlightMobile ? 'animate-highlight-red' : ''"
          @input="handleMobileNumberInput"
          required="true"
          v-model="details.mobile"
          type="tel"
        >
          <v-progress-circular
            v-if="pinpointApiLoading"
            :size="20"
            :width="4"
            color="#0074b5"
            class="ml-1"
            indeterminate
          ></v-progress-circular>
          <CheckCircleIcon
            v-if="mobilePhoneNumberIsValid"
            class="ml-1 w-8 h-8 tw-text-mp-green"
          >
          </CheckCircleIcon>
          <XCircleIcon
            v-if="mobilePhoneNumberIsInvalid"
            class="ml-1 w-8 h-8 tw-text-mp-red"
          >
          </XCircleIcon>
        </base-input-v2>
      </div>

      <base-input-v2
        ref="phoneAnchorRef"
        v-model="details.phone"
        :highlight-class="highlightPhone ? 'animate-highlight-red' : ''"
        class="py-2"
        label="Telephone"
        type="tel"
      ></base-input-v2>
      <address-lookup
        ref="addressComponentRef"
        v-if="!loading"
        :address="details"
        @setAddressLines="setAddressLines"
      ></address-lookup>
      <label
        v-if="!alreadySubscribed"
        class="tw-inline-flex tw-items-top tw-mt-3"
      >
        <input
          v-model="details.subscribe_all"
          type="checkbox"
          class="form-checkbox tw-h-8 tw-w-8 tw-text-gray-600"
          checked
        /><span class="ml-2 mr-2 text-gray-700"
          >Would you like to subscribe to emails; we will send you helpful
          hints, tips and offers to help you and your pet(s) and anything else
          we think you might find interesting?</span
        >
      </label>
    </div>
    <div class="md:tw-px-24 tw-px-4 tw-mb-4">
      <div
        v-if="getValidationData.length !== 0"
        class="tw-flex tw-flex-col tw-items-center"
      >
        <div class="tw-px-1 tw-mt-4 md:tw-w-2/3">
          <form-validation
            :data="getValidationData"
            @jump-to-anchor="jumpToAnchor"
          ></form-validation>
        </div>
      </div>
      <div class="tooltip-container">
        <button
          :disabled="getValidationData.length !== 0"
          type="submit"
          class="tooltip-target tw-w-full tw-h-full tw-inline-flex tw-justify-center tw-mt-4 tw-py-2 tw-border tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-opacity-70 hover:tw-opacity-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
          :class="
            getValidationData.length !== 0
              ? 'tw-bg-gray-300 tw-text-gray-500  tw-border-slate-700'
              : 'tw-bg-mp-light-blue tw-border-transparent'
          "
          @click.prevent="save"
        >
          Save
        </button>
        <div v-if="getValidationData.length !== 0" class="tooltip">
          Please correct any errors before clicking the "save" button
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import AddressLookup from "@/components/shared/AddressLookup";
import BaseInputV2 from "@/components/shared/BaseInputV2";
import User from "../models/User";
import FormService from "@/services/FormService.js";
import FlagDropdown from "../components/shared/FlagDropDown";
import { debounce } from "lodash";
import PhoneNumberService from "../services/PhoneNumberService";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  XCircleIcon,
} from "@vue-hero-icons/solid";
import FormValidation from "@/components/shared/FormValidation";
import FlagDropDownDeprecated from "@/components/shared/FlagDropDownDeprecated.vue";

export default {
  components: {
    FlagDropDownDeprecated,
    FormValidation,
    AddressLookup,
    BaseInputV2,
    User,
    FlagDropdown,
    CheckCircleIcon,
    XCircleIcon,
    ChevronDownIcon,
  },
  data() {
    return {
      loading: true,
      user: new User(),
      mobileUniqueExceptSelf: true,
      mobilePhoneNumberIsValid: false,
      mobilePhoneNumberIsInvalid: false,
      alreadySubscribed: false,
      pinpointApiLoading: false,
      beforeChangeIsoCountryCode: "GB",
      isoCountryCode: "GB",
      isoCountryCodeMap: [
        {
          isoCountryCode: "GB",
          prefix: "+44",
        },
        {
          isoCountryCode: "IE",
          prefix: "+353",
        },
      ],
      details: {
        first_name: "",
        last_name: "",
        phone: "",
        address_line_1: "",
        address_line_2: "",
        address_line_3: "",
        address_line_4: "",
        postcode: "",
        city: "",
        subscribe_all: "",
      },
      highlightFirstName: false,
      highlightLastName: false,
      highlightMobile: false,
      highlightPhone: false,
      errors: [],
    };
  },
  computed: {
    getValidationData() {
      let data = [];

      if (!this.details.first_name || this.details.first_name.length === 0) {
        data.push({
          type: "first_name",
          message: "You need to supply a first name",
          anchor: "firstNameAnchorRef",
        });
      }

      if (!this.details.last_name || this.details.last_name.length === 0) {
        data.push({
          type: "last_name",
          message: "You need to supply a last name",
          anchor: "lastNameAnchorRef",
        });
      }

      if (this.mobilePhoneNumberIsInvalid) {
        data.push({
          type: "mobile",
          message: "The mobile phone number is invalid",
          anchor: "mobileAnchorRef",
        });
      }

      if (!this.mobileUniqueExceptSelf) {
        data.push({
          type: "mobile",
          message:
            "This mobile number is already associated with an account. If you need help contact Support at support@mypethq.io",
          anchor: "mobileAnchorRef",
        });
      }

      if (
        !this.details.address_line_1 ||
        this.details.address_line_1.length === 0
      ) {
        data.push({
          type: "address_line_1",
          message: "You need to supply the first line of your address",
          anchor: "addressLine1AnchorRef",
        });
      }

      if (!this.details.postcode || this.details.postcode.length === 0) {
        data.push({
          type: "postcode",
          message: "You need to supply a postcode",
          anchor: "postcodeAnchorRef",
        });
      }

      if (!this.details.city || this.details.city.length === 0) {
        data.push({
          type: "city",
          message: "You need to supply a town/city",
          anchor: "cityAnchorRef",
        });
      }

      if (
        !this.details.phone &&
        this.mobileUniqueExceptSelf &&
        !(this.details.mobile && this.mobilePhoneNumberIsValid)
      ) {
        data.push({
          type: "phone",
          message: "Telephone or mobile is required",
          anchor: "phoneAnchorRef",
        });
      }

      return data;
    },
  },
  methods: {
    determineMailingListStatus() {
      this.alreadySubscribed = this.user?.mailinglists?.some(
        (list) => list.name === "marketing"
      );
    },
    setIsoCountryCode(code) {
      const oldPrefix = this.isoCountryCodeMap.find(
        (i) => i.isoCountryCode === this.beforeChangeIsoCountryCode
      ).prefix;
      const suffix = this.details.mobile.slice(oldPrefix.length);
      this.isoCountryCode = code;
      this.beforeChangeIsoCountryCode = code;
      const newPrefix = this.isoCountryCodeMap.find(
        (i) => i.isoCountryCode === this.isoCountryCode
      ).prefix;
      this.details.mobile = newPrefix + suffix;
      this.setCountryMobilePhoneCode();
    },
    async setCountryMobilePhoneCode() {
      const prefix = this.isoCountryCodeMap.find(
        (i) => i.isoCountryCode === this.isoCountryCode
      ).prefix;
      if (
        !this.details.mobile ||
        this.details.mobile.length < prefix.length ||
        [("+44", "+353")].includes(this.details.mobile)
      ) {
        this.details.mobile = prefix;
        this.mobilePhoneNumberIsInvalid = false;
        return;
      }

      if (this.details.mobile.charAt(prefix.length) === "0") {
        this.details.mobile =
          this.details.mobile.slice(0, prefix.length) +
          this.details.mobile.slice(
            prefix.length + 1,
            this.details.mobile.length
          );
      }

      let result = [...this.details.mobile];
      for (let i = 0; i < prefix.length; i++) {
        const c = prefix.charAt(i);
        const t = result[i];
        if (c !== t) {
          result.splice(i, 0, c);
        }
      }
      this.details.mobile = result.join("");
    },
    async resetMobileSettings() {
      this.pinpointApiLoading = false;
      this.mobilePhoneNumberIsValid = false;
      this.mobilePhoneNumberIsInvalid = false;
      this.mobileUniqueExceptSelf = true;
    },
    async focusMobileNumberInput(event) {
      FormService.setCursorPosition(event.target, this.details.mobile.length);
    },
    async handleMobileNumberInput() {
      await this.resetMobileSettings();
      await this.setCountryMobilePhoneCode();
      if (
        this.details.mobile &&
        ["+44", "+353"].includes(this.details.mobile)
      ) {
        this.mobilePhoneNumberIsInvalid = false;
        return;
      }
      if (this.details.mobile && this.details.mobile.length < 8) {
        this.mobilePhoneNumberIsInvalid = true;
        return;
      }
      this.pinpointApiLoading = true;
      this.mobileNumberInput();
    },
    mobileNumberInput: debounce(function () {
      this.debouncedMobileNumberValidation();
    }, 1600),
    async debouncedMobileNumberValidation() {
      const phoneNumberValidatedEntity =
        await PhoneNumberService.validatePhoneNumber(
          this.details.mobile,
          "mobile"
        );

      if (!phoneNumberValidatedEntity.unique_except_self) {
        this.mobileUniqueExceptSelf = false;
      } else if (phoneNumberValidatedEntity.is_valid) {
        this.mobilePhoneNumberIsValid = true;
      } else {
        this.mobilePhoneNumberIsInvalid = true;
      }
      this.pinpointApiLoading = false;
    },
    async save() {
      await this.$refs.addressComponentRef.emitSelectedAddress();

      this.$emit("saveUserDetails");
      await this.user.updateV2(this.details);
    },
    async jumpToAnchor(item) {
      const el = this.$refs[item.anchor];

      if (el) {
        window.scrollTo(0, el.offsetTop);
      }

      switch (item.type) {
        case "first_name":
          this.highlightFirstName = true;
          break;
        case "last_name":
          this.highlightLastName = true;
          break;
        case "mobile":
          this.highlightMobile = true;
          break;
        case "phone":
          this.highlightPhone = true;
          this.highlightMobile = true;
          break;
        case "address_line_1":
        case "postcode":
        case "city":
          await this.$refs.addressComponentRef.jumpToAnchor(item);
          break;
      }
    },
    async setUserDetails() {
      this.details.first_name = this.user.first_name;
      this.details.last_name = this.user.last_name;
      this.details.phone = this.user.phone;
      this.details.mobile = this.user.mobile;
      if (!this.user.email) {
        this.details.email = this.user.email;
      }
      this.details.address_line_1 = this.user.address_line_1;
      this.details.address_line_2 = this.user.address_line_2 || null;
      this.details.address_line_3 = this.user.address_line_3 || null;
      this.details.address_line_4 = this.user.address_line_4 || null;
      this.details.postcode = this.user.postcode;
      this.details.city = this.user.city;
    },
    async setAddressLines(address) {
      this.details.address_line_1 = address.address_line_1;
      this.details.address_line_2 = address.address_line_2 || null;
      this.details.address_line_3 = address.address_line_3 || null;
      this.details.address_line_4 = address.address_line_4 || null;
      this.details.postcode = address.postcode;
      this.details.city = address.city;
    },
    async setUserMobile() {
      if (this.user.mobile) {
        if (this.user.mobile.startsWith("+353")) {
          this.setIsoCountryCode("IE");
        }
      } else {
        await this.setCountryMobilePhoneCode();
      }
    },
  },
  async beforeMount() {
    this.user = await this.retrieveUser();
    await this.determineMailingListStatus();
    await this.setUserDetails();
    await this.setUserMobile();
    this.handleMobileNumberInput();

    this.loading = false;
  },
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
}

.tooltip-target:hover + .tooltip {
  visibility: visible;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  background-color: #0074b5;
  color: white;
  border-radius: 5px;
  padding: 10px;
}

.tooltip::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #0074b5 transparent;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  margin-left: -3px;
  border-width: 3px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
</style>