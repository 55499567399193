import { render, staticRenderFns } from "./ConfirmDetails.vue?vue&type=template&id=84166a3c&scoped=true"
import script from "./ConfirmDetails.vue?vue&type=script&lang=js"
export * from "./ConfirmDetails.vue?vue&type=script&lang=js"
import style0 from "./ConfirmDetails.vue?vue&type=style&index=0&id=84166a3c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84166a3c",
  null
  
)

export default component.exports