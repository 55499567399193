import Model from "./Model";

export default class Microchip extends Model {
  static async isMicrochipRegisteredInternally(value, uuid = null) {
    let url = "/api/v2/microchips/is-registered?microchip=" + value;
    if (uuid !== null) {
      url += "&uuid=" + uuid;
    }
    return await window.Http.get(url);
  }

  static async isMicrochipRegisteredExternally(value) {
    return await window.Http.get(
      "/api/v2/microchips/is-registered-external?microchip=" + value
    );
  }

  static async duplicateRequest(value) {
    const response = await window.Http.post(
      "/api/v2/microchips/duplicate-registration",
      { microchip: value }
    );
    return this.make(response.data);
  }
}