<template>
  <div>
    <div class="tw-mt-4 tw-h-screen  tw-flex tw-flex-col tw-justify-center tw-items-center">
      <img class="tw-rounded-lg" src="../assets/img/rollie-error.png" alt="Error page">
      <ExclamationIcon class="tw-h-24 tw-w-24 tw-text-mp-red tw-mt-8" aria-hidden="true"/>
      <p class="tw-mt-4">Something has gone wrong. If you need help contact <a
          href="mailto:support@mypethq.io">support@mypethq.io</a></p>
    </div>
  </div>
</template>

<script>
import {ExclamationIcon} from "@vue-hero-icons/solid";

export default {
  name: "GenericErrorPage",
  components: {
    ExclamationIcon,
  },
}
</script>

<style scoped>

</style>