<template>
  <v-progress-circular
    v-if="loading"
    :size="70"
    :width="7"
    color="#0074b5"
    indeterminate
  ></v-progress-circular>
  <pet-lost-form v-else :pet="pet"></pet-lost-form>
</template>

<script>
import Pet from "../models/Pet";
import PetLostForm from "../components/PetLostForm";

export default {
  components: { PetLostForm },
  props: {
    id: "",
  },
  data() {
    return {
      loading: true,
      pet: "",
    };
  },
  async mounted() {
    this.getPet();
  },
  methods: {
    async getPet() {
      try {
        this.pet = await Pet.retrieve(this.id);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
