<template>
  <div>
    <back-button></back-button>
    <div class="additional-contacts-page tw-w-full tw-mt-8">
      <h1 class="tw-font-semibold">Additional Contacts</h1>
      <div class="tw-text-sm tw-mt-2 tw-text-center tw-py-4 tw-px-4">
        We display your additional contact information when your microchip is
        searched, to help reunite you with your pet should they ever go missing.
        You can add up to 5 additional contacts.
      </div>
      <button
        v-if="additionalContacts.length < 5"
        class="tw-bg-mp-blue tw-rounded tw-text-white tw-mt-8 tw-p-2 tw-shadow"
        @click="
          $router.push({
            name: 'AdditionalContactAdd',
          })
        "
      >
        Add contact
      </button>
      <div
        class="additional-contacts-contents tw-flex tw-justify-center tw-items-center tw-w-full tw-mt-8"
      >
        <div class="tw-w-2/3">
          <div v-for="contact in additionalContacts" :key="contact.id">
            <additional-contact-card
              class="tw-mb-8"
              :contact="contact"
            ></additional-contact-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdditionalContact from "@/models/AdditionalContact";
import AdditionalContactCard from "@/components/shared/AdditionalContactCard.vue";
import AdditionalContactAdd from "@/views/AdditionalContactAdd.vue";
import BackButton from "@/components/shared/BackButton.vue";

export default {
  components: { BackButton, AdditionalContactAdd, AdditionalContactCard },
  data() {
    return {
      additionalContacts: [],
    };
  },
  async mounted() {
    this.additionalContacts = await AdditionalContact.list();
  },
};
</script>

<style scoped></style>