<template>
  <v-app>
    <v-main class="tw-bg-gray-100">
      <v-navigation-drawer
        v-if="isLoggedIn && !mobile"
        v-model="drawer"
        :mini-variant.sync="mini"
        app
        bottom
        clipped
        permanent
      >
        <v-list-item class="px-2">
          <v-avatar class="tw-cursor-pointer" color="#00b53f" size="36">
            <span class="headline white--text">{{ this.user_char }}</span>
          </v-avatar>

          <v-list-item-title>{{ this.full_name }}</v-list-item-title>

          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="{ name: item.page }"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-bottom-navigation v-if="isLoggedIn && mobile" color="#00b53f" fixed>
        <v-btn
          v-if="user.role === 'customer'"
          @click="$router.push({ name: 'Dashboard' })"
        >
          <span>Dashboard</span>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-btn>

        <v-btn
          v-if="user.role === 'microchip_authorised_user'"
          @click="$router.push({ name: 'MicrochipAuthorisedSearch' })"
        >
          <span>Search</span>
          <v-icon>mdi-table-search</v-icon>
        </v-btn>

        <v-btn @click="$router.push({ name: 'Profile' })">
          <span>Profile</span>
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>

        <v-btn
          v-if="user.role === 'customer' || user.role === 'breeder'"
          @click="$router.push({ name: 'UserPets' })"
        >
          <span>Pets</span>
          <v-icon>mdi-paw</v-icon>
        </v-btn>

        <v-btn
          v-if="
            user.role === 'microchip_authorised_user' || user.role === 'rescue'
          "
          @click="$router.push({ name: 'PetsTable' })"
        >
          <span>Pets</span>
          <v-icon>mdi-paw</v-icon>
        </v-btn>

        <v-btn @click="$router.push({ name: 'Account' })">
          <span>Settings</span>
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <v-app-bar
        app
        clipped-left
        flat
        style="
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px 0 rgba(0, 0, 0, 0);
          background-color: white;
          border-width: 1px;
          border-bottom-color: #0000001f;
          z-index: 50;
        "
      >
        <div @click="onClickHome">
          <v-img
            alt="MyPet Logo"
            class="shrink mt-1"
            contain
            min-width="80"
            src="@/assets/logo.svg"
            width="80"
          />
        </div>

        <v-spacer></v-spacer>

        <!--        shopping cart-->
        <v-btn
          v-show="isLoggedIn"
          class="transp-bkg no-shadow blue-text mob-text"
          @click="goToBasket"
        >
          <v-badge color="#00b53f" right>
            <template v-slot:badge>
              <span>{{ cartItems }}</span>
            </template>
            <v-icon>mdi-cart</v-icon>
          </v-badge>
        </v-btn>

        <router-link
          v-if="!isLoggedIn"
          :to="{ name: 'UserLogin' }"
          class="no-shadow transp-bkg blue-text button log-but"
          tag="button"
          >LOGIN
        </router-link>
        <v-divider v-show="!isLoggedIn" vertical></v-divider>
        <router-link
          v-if="!isLoggedIn"
          :to="{ name: 'UserRegister' }"
          class="no-shadow transp-bkg blue-text button log-but"
          tag="button"
          >REGISTER
        </router-link>

        <div
          id="account-dropdown"
          v-if="isLoggedIn && false"
          class="tw-relative tw-inline-block tw-text-left"
        >
          <button
            type="button"
            class="tw-border tw-rounded tw-px-4 tw-py-2 tw-text-mp-blue focus:tw-outline-none focus:tw-border-blue-700 focus:tw-shadow-outline tw-transition tw-ease-in-out tw-duration-150"
            @click="toggleAccountMenu"
          >
            <UserCircleIcon class="tw-w-8 tw-h-8 blue-text"></UserCircleIcon>
          </button>

          <div
            v-if="accountDropdownOpen"
            class="tw-origin-top-right tw-absolute tw-right-0 mt-2 tw-w-56 tw-rounded-md tw-shadow-lg"
          >
            <div
              class="tw-rounded-md tw-bg-white tw-shadow-xs tw-cursor-pointer"
            >
              <div
                @click="$router.push({ name: 'Account' })"
                class="tw-block tw-text-mp-blue tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-gray-100"
              >
                My Account
              </div>
              <div
                @click="logout"
                class="tw-block tw-text-mp-blue tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-gray-100 tw-cursor-pointer"
              >
                Logout
              </div>
            </div>
          </div>
        </div>

        <v-btn
          v-if="isLoggedIn"
          class="no-shadow transp-bkg blue-text"
          @click="logout"
          >LOGOUT
        </v-btn>
      </v-app-bar>
      <span class="w-links">
        <a
          :href="`https://mypethq.io/`"
          class="mr-5 black--text no-shadow transp-bkg cap web-link"
          target="_blank"
          >Go to website</a
        >

        <a
          :href="`https://www.mypethq.io/help-centre/`"
          class="mr-5 black--text no-shadow transp-bkg cap sup-link"
          target="_blank"
          >Help Centre</a
        >
      </span>
      <v-container>
        <router-view class="mb-12"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
//fullstory

window["_fs_debug"] = false;
window["_fs_host"] = "fullstory.com";
window["_fs_script"] = "edge.fullstory.com/s/fs.js";
window["_fs_org"] = "JXKVH";
window["_fs_namespace"] = "FS";
(function (m, n, e, t, l, o, g, y) {
  if (e in m) {
    if (m.console && m.console.log) {
      m.console.log(
        'FullStory namespace conflict. Please set window["_fs_namespace"].'
      );
    }
    return;
  }
  g = m[e] = function (a, b, s) {
    g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
  };
  g.q = [];
  o = n.createElement(t);
  o.async = 1;
  o.crossOrigin = "anonymous";
  o.src = "https://" + _fs_script;
  y = n.getElementsByTagName(t)[0];
  y.parentNode.insertBefore(o, y);
  g.identify = function (i, v, s) {
    g(l, { uid: i }, s);
    if (v) g(l, v, s);
  };
  g.setUserVars = function (v, s) {
    g(l, v, s);
  };
  g.event = function (i, v, s) {
    g("event", { n: i, p: v }, s);
  };
  g.anonymize = function () {
    g.identify(!!0);
  };
  g.shutdown = function () {
    g("rec", !1);
  };
  g.restart = function () {
    g("rec", !0);
  };
  g.log = function (a, b) {
    g("log", [a, b]);
  };
  g.consent = function (a) {
    g("consent", !arguments.length || a);
  };
  g.identifyAccount = function (i, v) {
    o = "account";
    v = v || {};
    v.acctId = i;
    g(o, v);
  };
  g.clearUserCookie = function () {};
  g._w = {};
  y = "XMLHttpRequest";
  g._w[y] = m[y];
  y = "fetch";
  g._w[y] = m[y];
  if (m[y])
    m[y] = function () {
      return g._w[y].apply(this, arguments);
    };
  g._v = "1.2.0";
})(window, document, window["_fs_namespace"], "script", "user");
//end of fullstory
import { UserCircleIcon } from "@vue-hero-icons/solid";

export default {
  components: { UserCircleIcon },
  data: () => ({
    drawer: true,
    items: [
      { title: "Profile", icon: "mdi-account-circle", page: "Profile" },
      { title: "Pets", icon: "mdi-paw", page: "UserPets" },
      { title: "Account", icon: "mdi-cog-outline", page: "Account" },
    ],
    overwriteBreakpoint: true,
    user: {},
    user_char: "",
    mobile: false,
    showSubscriptions: true,
    accountDropdownOpen: false,
  }),
  computed: {
    cartItems: function () {
      return this.$store.getters.cartCount || 0;
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    full_name() {
      if (
        this.$store.state.user &&
        this.$store.state.user.first_name &&
        this.$store.state.user.last_name
      ) {
        this.user_char = this.$store.state.user.first_name.charAt(0);
        return (
          this.$store.state.user.first_name +
          " " +
          this.$store.state.user.last_name
        );
      }
      return "";
    },
    mini: {
      get() {
        return this.$vuetify.breakpoint.mdAndDown || this.overwriteBreakpoint;
      },
      set(value) {
        this.overwriteBreakpoint = value;
      },
    },
  },
  methods: {
    handleOutsideClick(event) {
      const dropdownElem = document.getElementById("account-dropdown");
      if (!dropdownElem || !dropdownElem.contains(event.target)) {
        this.accountDropdownOpen = false;
      }
    },
    onClickHome() {
      if (this.isLoggedIn) {
        if (
          this.user.role === "rescue" ||
          this.user.role === "microchip_authorised_user"
        ) {
          this.$router.push({ name: "PetsTable" });
        } else {
          this.$router.push({ name: "Dashboard" });
        }
      } else {
        this.$router.push({ name: "MicrochipSearch" });
      }
    },
    toggleAccountMenu() {
      this.accountDropdownOpen = !this.accountDropdownOpen;
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    goToBasket() {
      if (this.user.role === "customer") {
        this.$router.push({ name: "SubscriptionCheckoutPage" });
      } else {
        this.$router.push({ name: "Checkout" });
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  async mounted() {
    document.addEventListener("click", this.handleOutsideClick);

    if (this.$vuetify.breakpoint.xs) {
      this.mobile = true;
    }

    this.showSubscriptions = false;

    if (this.isLoggedIn) {
      this.user = await this.retrieveUser();

      const token = this.$store.state.token;
      const user = this.user;
      await this.$store.commit("auth_success", { token, user });
      await this.saveSubscription(this.user?.subscription);

      if (this.user.role === "customer") {
        this.items.unshift({
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          page: "Dashboard",
        });
      }

      if (
        this.user.role === "microchip_authorised_user" ||
        this.user.role === "rescue"
      ) {
        this.items = this.items.filter((i) => i.title !== "Pets");

        this.items.unshift({
          title: "Pets",
          icon: "mdi-paw",
          page: "PetsTable",
        });

        this.items.unshift({
          title: "Authorised Database Search",
          icon: "mdi-table-search",
          page: "MicrochipAuthorisedSearch",
        });
      }
    }
  },

  created: function () {
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logout");
        }
        throw err;
      });
    });
  },
};
</script>

<style>
#app {
  /* font-family: "Montserrat", Helvetica, Arial, sans-serif !important; */
  font-family: Lexend Deca, "Montserrat", Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #0020b2;*/
  background-color: #f9f9f9;
}

.swal2-popup {
  font-family: "Lexend Deca", serif !important;
}

#nav {
  padding: 30px;
}

.w-links {
  display: block;
  margin-top: 10px;
  margin-bottom: -10px;
  text-align: right;
  padding-right: 10px;
}

.v-btn {
  border-radius: 4px !important;
}

.log-but {
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
}

.log-but:hover {
  background-color: #ebeef9;
  border-radius: 4px;
}

.button {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1.25px;
}

a {
  font-size: 0.875rem;
  color: #000000;
  text-decoration: none;
}

a:hover {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

#nav a {
  font-weight: bold;
  color: #0074b5;
  cursor: pointer;
}

#nav a:hover {
  text-decoration: underline;
}

#nav a.router-link-exact-active {
  color: #16c5b9;
}

.blue-text {
  color: #0074b5 !important;
}

.blue-bkg {
  background-color: #0074b5 !important;
  border-color: #0074b5 !important;
  color: white !important;
}

.grey-bkg {
  background-color: #809abe !important;
  border-color: #809abe !important;
  color: white !important;
}

.no-shadow {
  box-shadow: none !important;
}

.transp-bkg {
  background-color: transparent !important;
}

.selected-tab {
  background-color: #809abe !important;
  border-color: #809abe !important;
  color: white !important;
}

.v-list-item__title,
.v-select__selections,
.cap {
  text-transform: capitalize !important;
}

.no-cap {
  text-transform: lowercase !important;
}

.v-btn__content {
  white-space: normal !important;
  max-width: 100% !important;
}

.v-card--flat {
  background-color: transparent !important;
}

.white-text {
  color: white !important;
}

.green-bkg {
  background-color: #44fe5a !important;
  border-color: #44fe5a !important;
  color: #0020b2 !important;
}

.lost-but {
  justify-content: flex-end;
  width: 50%;
  display: flex;
}

.edit-pet {
  justify-content: flex-start;
  width: 50%;
  display: flex;
}

.petcard {
  padding: 0px !important;
  text-transform: capitalize;
  min-height: 30px !important;
  display: block !important;
}

.pet-label {
  color: white;
  display: inline-block !important;
  font-weight: 700;
  width: 40%;
}

.pet-val {
  color: white;
  display: inline-block !important;
  width: 60%;
}

.v-input--selection-controls {
  margin-top: 0px !important;
}

/*pop-up*/
.swal2-styled.swal2-confirm {
  background-color: #0020b2 !important;
  border-color: #0020b2 !important;
  color: white !important;
}

.swal2-icon.swal2-warning {
  border-color: #b54100 !important;
  color: #b54100 !important;
}

.swal2-title {
  color: #0074b5 !important;
  font-size: 22px !important;
}

.reg-logo {
  margin-bottom: -30px;
  margin-top: 20px;
  width: 350px;
}

.theme--light.v-data-table {
  background-color: transparent !important;
}

.green-text {
  color: #44fe5a !important;
}

.pet-full-card .theme--light.v-card {
  background-color: #0020b2 !important;
}

.indiv-card {
  padding: 0px !important;
}

.p-icon {
  height: 190px;
}

.search-page .v-data-table-header-mobile,
.search-page .v-data-table-header {
  display: none !important;
}

.search-page .v-data-table td,
.v-data-table td a {
  width: 100% !important;
  display: flex !important;
  height: auto !important;
  justify-content: center !important;
}

.search-page .v-data-table__wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
}

.search-page .v-data-table td,
.v-data-table td a {
  font-size: 16px !important;
  font-weight: 600;
}

@media (max-width: 768px) {
  .pets-card {
    max-width: 300px !important;
  }

  .v-list-item .v-list-item__title,
  .v-list-item .v-list-item__subtitle,
  .v-list-item__content > * {
    line-height: 1 !important;
  }

  .v-list-item__title {
    font-size: 14px !important;
  }

  .indiv-card {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }

  .p-icon {
    width: 100% !important;
    max-width: 100% !important;
    height: auto;
    padding-bottom: 0px !important;
  }

  .p-info {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100%;
  }

  .reg-logo {
    width: 100%;
  }

  .web-link,
  .sup-link {
    font-size: 13px;
    line-height: 1em;
  }

  .w-links {
    margin-top: 5px;
    margin-bottom: -20px;
  }

  .pet-full-card {
    width: 100% !important;
    max-width: 100% !important;
    display: block !important;
    flex: none !important;
  }

  .mob-text {
    font-size: 12px !important;
    white-space: normal !important;
    max-width: 100% !important;
  }

  .edit-pet,
  .lost-but {
    display: block;
    width: 100% !important;
    clear: both;
    margin-bottom: 10px;
  }

  .mob-block {
    display: block !important;
  }

  .v-label {
    font-size: 14px !important;
  }
}

@media (max-width: 500px) {
  div#hubspot-messages-iframe-container {
    bottom: 30px !important;
    height: 500px !important;
  }
}
</style>