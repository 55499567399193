<template>
  <div class="tw-flex tw-justify-center">
    <div class="tw-w-full tw-flex tw-flex-col tw-justify-center tw-p-6">
      <h2 class="tw-mt-8 tw-mb-4 tw-text-xl tw-self-start tw-font-bold">
        Confirm your details
      </h2>
      <div class="tw-w-full tw-flex tw-justify-center">
        <details
            @click.stop="onToggleUserDetails"
            :open="userDetailsOpen"
            class="tw-w-full tw-border tw-border-gray-400 open:tw-ring-1 open:tw-ring-black/5 open:tw-shadow-lg tw-rounded-lg cursor-auto pointer-events-auto tw-bg-white"
        >
          <summary
              style="background-color: rgba(0, 80, 185, 0.5)"
              class="tw-text-sm tw-leading-6 tw-text-slate-900 tw-font-semibold tw-select-none tw-cursor-pointer tw-w-full tw-rounded-t-md"
          >
            <div
                class="tw-p-6 tw-text-mp-charcoal tw-text-xl tw-font-medium tw-flex tw-justify-center"
            >
              <div class="tw-w-1/3">
                <ChevronDownIcon
                    class="tw-w-12 tw-h-12 tw-text-mp-light-blue"
                    :class="[
                    chevronRight
                      ? 'chevron-down-to-right'
                      : 'chevron-right-to-down',
                  ]"
                ></ChevronDownIcon>
              </div>
              <div class="tw-w-1/3 tw-mt-2">
                <div class="tw-text-2xl tw-text-white">Your details</div>
              </div>
              <div class="tw-w-1/3">
                <div class="tw-relative tooltip-container">
                  <div
                      class="tw-w-4 tw-h-4 tw-bg-white tw-rounded-full tw-absolute tw-top-1 tw-right-1 tw-z-10"
                  ></div>
                  <v-icon
                      v-if="userDetailsSaved"
                      class="tw-float-right tw-opacity-100 icon tw-z-20"
                      color="#00b53f"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon
                      v-if="!userDetailsSaved"
                      class="tw-float-right tw-opacity-100 icon tooltip-target tw-z-20"
                      color="#B54100"
                  >mdi-alert-circle
                  </v-icon>
                  <div v-if="!userDetailsSaved" class="tooltip">
                    Please correct any errors before clicking the "save" button
                  </div>
                </div>
              </div>
            </div>
          </summary>
          <div
              @click.stop
              class="tw-mt-3 tw-w-full tw-text-sm tw-leading-6 tw-text-mp-charcoal tw-bg-white tw-rounded-md"
          >
            <user-details-form
                ref="userComponentRef"
                @saveUserDetails="saveUserDetails"
            ></user-details-form>
          </div>
        </details>
      </div>

      <h2 class="tw-mt-8 tw-mb-4 tw-text-xl tw-self-start tw-font-bold">
        {{ "Confirm your pet" + (pets.length > 1 ? "s'" : "'s") + " details" }}
      </h2>

      <div
          v-for="(pet, i) in pets"
          class="tw-w-full tw-flex tw-justify-center tw-mt-4"
      >
        <details
            @click.prevent="onTogglePetDetails(i)"
            :class="[i % 2 === 0 ? 'pet-green' : 'pet-blue']"
            :open="pet.open"
            class="tw-w-full tw-border tw-border-gray-400 open:tw-ring-1 open:tw-ring-black/5 open:tw-shadow-lg tw-rounded-lg cursor-auto pointer-events-auto"
        >
          <summary
              class="tw-text-sm tw-leading-6 tw-text-slate-900 tw-font-semibold tw-select-none tw-rounded-t-md tw-cursor-pointer"
          >
            <div
                class="tw-p-6 tw-text-mp-charcoal tw-text-xl tw-font-medium tw-flex tw-justify-center"
            >
              <div class="tw-w-1/3">
                <ChevronDownIcon
                    class="tw-w-12 tw-h-12"
                    :class="[
                    pet.open
                      ? 'chevron-right-to-down'
                      : 'chevron-down-to-right',
                    i % 2 === 0
                      ? 'tw-text-mp-light-green'
                      : 'tw-text-mp-light-blue',
                  ]"
                ></ChevronDownIcon>
              </div>
              <div class="tw-w-1/3">
                <v-icon class="tw-float-left tw-m-1" color="#000000"
                >{{ getPetIcon(pet) }}
                </v-icon>
                <div class="tw-text-black tw-m-1">
                  {{ pet.name ? pet.name + "'s" : "pet" + " details" }}
                </div>
              </div>
              <div class="tw-w-1/3">
                <div class="tw-relative tooltip-container">
                  <div
                      class="tw-w-4 tw-h-4 tw-bg-white tw-rounded-full tw-absolute tw-top-1 tw-right-1 tw-z-10"
                  ></div>
                  <v-icon
                      v-if="pet.saved"
                      class="tw-float-right tw-opacity-100 icon tw-z-20"
                      color="#00b53f"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <div
                      class="tw-w-4 tw-h-4 tw-bg-white tw-rounded-full tw-absolute tw-top-1 tw-right-1"
                  ></div>
                  <v-icon
                      v-if="!pet.saved"
                      class="tw-float-right tw-opacity-100 icon tooltip-target tw-z-20"
                      color="#B54100"
                  >mdi-alert-circle
                  </v-icon>
                  <div class="tooltip">
                    Please correct any errors before clicking the "save" button
                  </div>
                </div>
              </div>
            </div>
          </summary>
          <div
              @click.stop
              class="tw-mt-3 tw-w-full tw-text-sm tw-leading-6 tw-text-mp-charcoal tw-bg-white tw-rounded-b-md"
          >
            <pet-details-form
                ref="{{'petComponentRef' + i}}"
                :pet="pet"
                :user="user"
                :position="i"
                :flow="flow"
                @savePetDetails="savePetDetails"
                @userDuplicatePets="userDuplicatePets"
                @changePetName="changePetName"
            ></pet-details-form>
          </div>
        </details>
      </div>
      <!--      <div class="tw-mt-4" v-if="formIsFilledOut">-->
      <!--        You’re all done, we will email you the registration certificate-->
      <!--        {{ pets.length > 1 ? "s" : "" }} for your pet-->
      <!--        {{ pets.length > 1 ? "s" : "" }}-->
      <!--      </div>-->
      <div
          v-if="formIsFilledOut"
          class="tw-cursor-pointer tw-inline-flex tw-justify-center tw-mt-6 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-mp-dark-green hover:tw-bg-green-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
          @click="onContinue"
      >
        <div class="tw-flex">
          <span class="tw-ml-2 tw-text-lg">Continue</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PetDetailsForm from "@/components/PetDetailsFormComponent";
import UserDetailsForm from "@/components/UserDetailsFormComponent";
import {ChevronDownIcon} from "@vue-hero-icons/solid";
import Pet from "@/models/Pet";
import User from "@/models/User";
import Vue from "vue";

export default {
  components: {
    UserDetailsForm,
    PetDetailsForm,
    ChevronDownIcon,
  },
  data() {
    return {
      value: "",
      user: new User(),
      pets: [],
      chevronRight: false,
      flow: "confirm_details",
      userDetailsOpen: true,
      userDetailsSaved: false,
      formIsFilledOut: false,
    };
  },
  methods: {
    onToggleUserDetails() {
      this.chevronRight = !this.chevronRight;
    },
    onTogglePetDetails(position) {
      const pet = this.pets[position];

      pet.open = !pet.open;

      const newValue = {...pet};

      Vue.set(this.pets, position, newValue);
    },
    changePetName(data) {
      const pet = this.pets[data.position];

      pet.name = data.name;
      const newValue = {...pet, name: data.name};

      Vue.set(this.pets, data.position, newValue);
    },
    checkFormFilledOut() {
      this.formIsFilledOut = this.isFormFilledOut();
    },
    onContinue() {
      const roles = ["microchip_authorised_user", "rescue", "breeder"];

      if (roles.includes(this.user.role)) {
        this.$router.push({
          name: "CheckoutSuccessFreeVersion",
          params: {pets: this.getFreeChipQueue()},
        });
      } else if (this.subscriptionType === 'premium') {
        this.$router.push({
          name: "CheckoutSuccessFreeVersion",
          params: {pets: this.getFreeChipQueue()},
        });
      } else {
        this.$router.push({
          name: "SubscriptionCheckoutPage",
          params: {pets: this.getFreeChipQueue()},
        })
      }
    },
    isFormFilledOut() {
      if (!this.userDetailsSaved) {
        return false;
      }

      for (let i = 0; i < this.pets.length; i++) {
        if (!this.pets[i].saved) {
          return false;
        }
      }
      return true;
    },
    async saveAll() {
      await this.$refs.userComponentRef.save();

      for (let i = 0; i < this.pets.length; i++) {
        const pet = this.pets[i];

        if (pet.id > 0) {
          await Pet.updateV2(pet);
        } else {
          await Pet.create(pet);
        }
      }
    },
    async savePetDetails(data) {
      this.pets[data.position].open = false;

      if (this.pets.length > data.position + 1) {
        if (!this.pets[data.position + 1].saved) {
          this.pets[data.position + 1].open = true;
        }
      }

      this.pets[data.position].saved = true;

      const index = data.position;
      const newValue = {...this.pets[index], open: false};

      Vue.set(this.pets, index, newValue);

      await this.checkFormFilledOut();
    },
    async saveUserDetails() {
      this.userDetailsOpen = false;
      this.userDetailsSaved = true;

      this.chevronRight = true;

      if (!this.pets[0].saved) {
        this.pets[0].open = true;
      }

      await this.checkFormFilledOut();
    },
    async userDuplicatePets(data) {
      let pet = {};
      pet.name = "New Pet";
      pet.microchip = "";
      pet.dob = data?.details?.dob;
      pet.microchip = data?.details?.colour;
      pet.microchip = data?.details?.distinguishing_features;
      pet.saved = false;
      pet.open = true;

      for (let i = 0; i < data.count; i++) {
        this.pets.push(pet);
      }
    },
    async setFirstPet() {
      this.pets.push({
        open: false,
        name: "New pet",
        microchip: "",
        dob: "",
        colour: "",
        distinguishing_features: "",
      });
    },
    getPetIcon(pet) {
      switch (pet.type) {
        case "Dog":
          return "mdi-dog";
        default:
          return "mdi-paw";
      }
    },
  },
  async mounted() {
    this.subscriptionType = await this.getSubscriptionType();
    if (this.$route.query.flow) {
      this.flow = this.$route.query.flow;
    }
  },
  async beforeMount() {
    const pets = await Pet.index("registration_status=pending");

    this.pets = pets.map((p) => {
      p.open = false;
      return p;
    });

    this.user = await User.retrieve();
  },
};
</script>

<style scoped>
details:not([open]) summary {
  border-radius: 0.375rem;
}

.chevron-down-to-right {
  transform: rotate(-90deg);
  animation: rotate-down-to-right 0.5s linear forwards;
}

@keyframes rotate-down-to-right {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-90deg);
  }
}

.chevron-right-to-down {
  transform: rotate(0deg);
  animation: rotate-right-to-down 0.5s linear forwards;
}

@keyframes rotate-right-to-down {
  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.pet-green {
  background-color: rgb(0, 168, 31, 0.2);
}

.pet-blue {
  background-color: rgba(0, 80, 185, 0.2);
}

details summary {
  display: block;
  width: 100%;
}

.details[open] summary {
  cursor: pointer;
}

.cursor-auto {
  cursor: auto;
}

.pointer-events-auto {
  pointer-events: auto;
}

.icon-container {
  display: inline-block;
  padding: 0.25rem;

  background-color: #ffffff; /* Set the background color to white */
}

.icon {
  fill: #000000;
}

.tooltip-container {
  position: relative;
}

.tooltip-target:hover + .tooltip {
  visibility: visible;
}

.tooltip {
  position: absolute;
  bottom: -38px;
  left: auto;
  right: calc(50% - 530px);
  visibility: hidden;
  background-color: #0074b5;
  color: white;
  border-radius: 5px;
  padding: 10px;
  transform: translateX(-100%);
  font-size: 15px;
}

.tooltip::before {
  content: "";
  position: absolute;
  bottom: 20px;
  left: 100%; /* set to left */
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #0074b5; /* adjust border-color */
  transform: scaleX(-1); /* horizontally reverse the arrow */
}
</style>