import Model from "./Model";
import Pagination from "@/services/Pagination";

export default class Pet extends Model {
  get icon() {
    switch (this.type.toLowerCase()) {
      case "dog":
        return "dog";
      case "cat":
        return "cat";
      case "rabbit":
        return "rabbit";
      case "hamster":
        return "hamster";
      case "horse":
        return "horse";
      default:
        return "paw";
    }
  }

  static async retrieve(id) {
    const response = await window.Http.get("/api/v2/pets/" + id);
    return this.make(response.data);
  }

  static async retrieveByMicrochip(microchip) {
    const response = await window.Http.get(
      "/api/v2/pets/list?microchip=" + microchip
    );
    return this.make(response.data[0]);
  }

  static async isMicrochipFree(id) {
    const response = await window.Http.get(
      "/api/v2/pets/" + id + "/microchips/check"
    );

    return response.free;
  }

  static async list() {
    const response = await window.Http.get("/api/v1/pets");
    return this.make(response.data);
  }

  static async index(filters = "") {
    let url = "/api/v2/pets/list";

    if (filters.length > 0) {
      url += "?";
      url += filters;
    }

    const response = await window.Http.get(url);
    return response.data;
  }

  static async v2list(
    includes = null,
    pagination = new Pagination(),
    search = "",
    sortBy = "",
    order = "desc"
  ) {
    return await window.Http.get(
      this.getUrl("/api/v2/pets", includes, pagination, search, sortBy, order)
    );
  }

  static async create(form) {
    const response = await window.Http.post("/api/v2/pets", form);
    return this.make(response.data);
  }

  static async sendCertificate(id) {
    await window.Http.post("/api/v1/mc_certificate", { id });
  }

  /**
   * register from app peer to peer transfer
   * @param {*} form
   * @returns
   */
  static async registerDetails(id, form) {
    const response = await window.Http.post(
      "/api/v2/pets/" + id + "/complete-transfer",
      form
    );

    return this.make(response.data);
  }

  static async transfer(id, form) {
    const response = await window.Http.post(
      "/api/v1/pets/" + id + "/transfer",
      form
    );
    return this.make(response);
  }

  async delete() {
    const response = await window.Http.delete("/api/v2/pets/" + this.id);
    return this.fill(response);
  }

  async update(form) {
    delete form.ids;
    const response = await window.Http.patch("/api/v2/pets/" + this.id, form);
    return this.fill(response.data[0]);
  }

  static async updateV2(data) {
    const response = await window.Http.patch("/api/v2/pets/" + data.id, data);
    return this.make(response.data);
  }

  async registerMicrochip(form) {
    const response = await window.Http.put(
      "/api/v1/pets/" + this.id + "/microchip/register",
      form
    );
    // return this.fill(response.data[0]);
  }

  async toggleLost() {
    const data = { is_lost: !this.is_lost };
    const response = await window.Http.patch("/api/v2/pets/" + this.id, data);
    return this.fill(response.data);
  }

  registerOnMicrochipDatabase(data) {
    const response = window.Http.post("/api/v1/mc_certificate", data);
  }
}
