<template>
  <div>
    <back-button></back-button>
    <h2 class="tw-text-4xl">Your account</h2>
    <v-alert v-if="subscription.end_at" class="mt-4" outlined type="info">
      Your subscription will end on
      {{ new Date(subscription.end_at).toLocaleDateString("en-UK") }}
    </v-alert>
    <hr class="tw-mt-12 tw-w-full tw-border-t tw-border-mp-green" />
    <div class="md:tw-flex">
      <div class="md:tw-w-1/2">
        <div class="tw-flex tw-flex-col tw-items-start">
          <div class="tw-flex tw-mt-4 tw-text-xl">
            <div class="tw-font-black tw-mr-2">Your Plan:</div>
            <div>
              {{
                subscriptionType === "basic" ? "MyPet Basic" : "MyPet premium"
              }}
            </div>
          </div>
          <div class="tw-mt-2 tw-text-left">
            You can change your account plan here.
          </div>
        </div>
      </div>
      <div class="md:tw-w-1/2">
        <div class="tw-flex tw-flex-col md:tw-items-end">
          <div
            @click="createSubscription"
            class="tw-mt-12 tw-border tw-rounded tw-text-white tw-p-2"
            :class="[
              subscriptionType === 'basic'
                ? 'tw-cursor-pointer tw-bg-mp-purple'
                : 'tw-disabled tw-bg-gray-500',
            ]"
          >
            {{ subscriptionType === "basic" ? "Upgrade" : "Upgraded" }}
          </div>
        </div>
      </div>
    </div>
    <hr class="tw-mt-12 tw-w-full tw-border-t tw-border-mp-green" />
    <div class="md:tw-flex">
      <div class="md:tw-w-1/2">
        <div class="tw-flex tw-flex-col tw-items-start">
          <div class="tw-flex tw-mt-4 tw-text-xl">
            <div class="tw-font-black tw-mr-2">Billing:</div>
          </div>
          <div class="tw-mt-2 tw-text-left">
            You can update your billing information here.
          </div>
        </div>
      </div>
      <div class="md:tw-w-1/2">
        <div class="md:tw-flex tw-flex-col md:tw-items-end">
          <div
            class="tw-disabled tw-bg-mp-purple tw-mt-12 tw-border tw-rounded tw-text-white tw-p-2 tw-cursor-pointer"
            :class="[
              subscription.end_at
                ? 'tw-disabled tw-bg-gray-500'
                : 'tw-cursor-pointer tw-bg-mp-purple',
            ]"
            @click="
              subscriptionType === 'basic'
                ? createSubscription()
                : updatePaymentMethod()
            "
          >
            {{ subscriptionType === "basic" ? "Upgrade" : "Update" }}
          </div>
        </div>
      </div>
    </div>
    <hr class="tw-mt-12 tw-w-full tw-border-t tw-border-mp-green" />
    <div class="md:tw-flex">
      <div class="md:tw-w-1/2">
        <div class="tw-flex tw-flex-col tw-items-start">
          <div class="tw-flex tw-mt-4 tw-text-xl">
            <div class="tw-font-black tw-mr-2">Settings</div>
          </div>
          <div class="tw-mt-2 tw-text-left">
            Update your notifications, passwords & breeder status here.
          </div>
        </div>
      </div>
      <div class="md:tw-w-1/2">
        <div class="tw-flex tw-flex-col md:tw-items-end">
          <div
            class="tw-disabled tw-bg-mp-purple tw-mt-12 tw-border tw-rounded tw-text-white tw-p-2 tw-cursor-pointer"
            @click="$router.push({ name: 'Settings' })"
          >
            Settings
          </div>
        </div>
      </div>
    </div>
    <hr class="tw-mt-12 tw-w-full tw-border-t tw-border-mp-green" />
    <div class="md:tw-flex">
      <div class="md:tw-w-1/2">
        <div class="tw-flex tw-flex-col tw-items-start">
          <div class="tw-flex tw-mt-4 tw-text-xl">
            <div class="tw-font-black tw-mr-2">Cancel Subscription</div>
          </div>
          <div class="tw-mt-2 tw-text-left">
            Cancel your subscription plan here.
          </div>
        </div>
      </div>
      <div class="md:tw-w-1/2">
        <div class="tw-flex tw-flex-col md:tw-items-end">
          <div
            class="tw-disabled tw-mt-12 tw-border tw-rounded tw-text-white tw-p-2"
            :class="[
              subscriptionType === 'basic' || subscription.end_at
                ? 'tw-disabled tw-bg-gray-500'
                : 'tw-cursor-pointer tw-bg-mp-purple',
            ]"
            @click="cancelSubscription"
          >
            Cancel
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stripe from "../models/Stripe";
import BackButton from "@/components/shared/BackButton.vue";

export default {
  components: { BackButton },
  data() {
    return {
      user: null,
      subscription: { plan: null, deactivated_at: null, activated_at: null },
      subscriptionType: "basic",
    };
  },
  methods: {
    async createSubscription() {
      if (this.subscriptionType === "basic") {
        await this.$router.push({
          name: "SubscriptionPlansPage",
        });
      }
    },
    //if user is signed up then update payment
    //if user is not signed up then create subscription
    async updatePaymentMethod() {
      if (this.subscription?.end_at) {
        return;
      }

      if (this.subscriptionType === "basic") {
        await this.createSubscription();
      } else {
        const result = await Stripe.updatePaymentMethod();
        window.location = result.url;
      }
    },
    async cancelSubscription() {
      if (this.subscriptionType === "premium") {
        const result = await Stripe.cancelSubscription();
        window.location = result.url;
      }
    },
  },
  async mounted() {
    this.user = this.$store.getters.user;
    this.subscriptionType = await this.getSubscriptionType();
    this.subscription = await this.getSubscription();
  },
  async beforeMount() {
    const user = await this.retrieveUser();

    const roles = ["microchip_authorised_user", "rescue", "breeder"];

    if (roles.includes(user.role)) {
      this.$router.push({ name: "Settings" });
    }
  },
};
</script>

<style scoped></style>