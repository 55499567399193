<template>
  <transition name="modal-fade">
    <div class="tw-fixed tw-z-50 tw-inset-0 tw-overflow-y-auto tw-m-auto">
      <div
        class="tw-flex tw-items-center tw-justify-center tw-min-h-screen tw-pt-4 tw-px-8 tw-pb-20 tw-text-center tw-sm:block tw-sm:p-0"
      >
        <div
          aria-hidden="true"
          class="tw-fixed tw-inset-0 tw-transition-opacity"
        >
          <div
            class="tw-absolute tw-inset-0 tw-bg-gray-500 tw-opacity-75"
          ></div>
        </div>

        <div
          class="tw-fixed tw-inset-0 tw-z-60 tw-flex tw-items-center tw-justify-center"
        >
          <div class="duplicate-registration" style="margin: auto; width: 80%">
            <div class="duplicate-registration-main">
              <div
                class="duplicate-registration-main-header blue-bkg"
                style="position: relative"
              >
                <div>
                  Sorry, this microchip is registered on another database
                </div>
                <svg
                  fill="currentColor"
                  style="
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    right: 0;
                  "
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="close"
                >
                  <path
                    clip-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    fill-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="duplicate-registration-main-body mt-4 ml-4 mr-4">
                <div class="duplicate-registration-main-body-text">
                  We can't accept a registration for a microchip that exists on
                  another Defra compliant database. You can find more
                  information on this database below. <br /><br />
                  We don't accept these because it makes your pet less safe and
                  you harder to locate if your pet ever goes missing.
                </div>
                <br />
                <div
                  class="tw-flex tw-flex-col tw-justify-center tw-items-center"
                >
                  <div>Your pet's microchip is registered with:</div>
                  <br />
                  <a
                    :href="databaseUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="tw-border-2 tw-rounded-lg tw-border-green-300 tw-p-2 tw-cursor-pointer"
                  >
                    {{ databaseName }}
                  </a>
                  <br />
                  <a
                    href="https://mypethq.io/help-centre/how-can-i-change-microchip-database/"
                    class="mr-5 tw-text-blue-700 no-shadow transp-bkg cap web-link tw-cursor-pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Find out how to change database</a
                  >
                  <br />
                  <a
                    href="https://mypethq.io/open-a-support-ticket/"
                    class="mr-5 tw-text-blue-700 no-shadow transp-bkg cap web-link tw-cursor-pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Contact support</a
                  >
                  <br />
                  <div
                    class="tw-border tw-rounded tw-bg-mp-purple tw-text-white tw-p-2 tw-cursor-pointer"
                    @click="close"
                  >
                    Close
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    databaseName: {
      type: String,
      required: true,
    },
    databaseUrl: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.duplicate-registration {
  overflow-y: auto;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
}

.duplicate-registration-main {
  background-color: white;
}

.duplicate-registration-main-header {
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.duplicate-registration-main-body {
  background-color: white;
  text-align: left;
}

.duplicate-registration-main-body-text {
  color: #444242;
}
</style>
