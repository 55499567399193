<template>
  <div class="tw-text-left tw-bg-red-50 tw-border tw-border-mp-red tw-rounded-md tw-border tw-border-dashed tw-py-2">
    <div class="tw-text-lg tw-my-2 tw-mx-4 tw-mb-2">
      Please fix these issues before continuing:
    </div>
    <div v-for="item in data" class="tw-mb-2 tw-mx-4">
    <span class="tw-text-red-500">
      {{ item.message }} <span class="tw-text-black"> - </span>
      <span @click="jumpToAnchor(item)" class="tw-text-blue-600 tw-underline tw-underline-offset-1 tw-cursor-pointer">review</span>
    </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array
  },
  methods: {
    jumpToAnchor(item) {
      this.$emit('jump-to-anchor', item);
    }
  }
}
</script>

<style scoped>

</style>