<template>
  <div
    @click="$router.go(-1)"
    class="tw-flex tw-cursor-pointer tw-text-mp-blue tw-h-6 tw-w-14"
  >
    <ArrowLeftIcon></ArrowLeftIcon>
    <div class="tw-ml-1">Back</div>
  </div>
</template>
<script>
import { ArrowLeftIcon } from "@vue-hero-icons/solid";

export default {
  components: { ArrowLeftIcon },
};
</script>