<template>
  <transition name="modal-fade">
    <div class="tw-fixed tw-z-50 tw-inset-0 tw-overflow-y-auto tw-m-auto">
      <div
        class="tw-flex tw-items-center tw-justify-center tw-min-h-screen tw-pt-4 tw-px-8 tw-pb-20 tw-text-center tw-sm:block tw-sm:p-0"
      >
        <div
          aria-hidden="true"
          class="tw-fixed tw-inset-0 tw-transition-opacity"
        >
          <div
            class="tw-absolute tw-inset-0 tw-bg-gray-500 tw-opacity-75"
          ></div>
        </div>

        <div
          class="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center"
        >
          <div
            class="resent-certificate tw-bg-white tw-border-2 tw-border-mp-blue tw-rounded"
            style="margin: auto; width: 80%"
          >
            <div class="resent-certificate-main">
              <div
                class="resent-certificate-main-header blue-bkg tw-h-12 tw-flex tw-flex-col tw-justify-center tw-items-center"
                style="position: relative"
              >
                <div class="tw-text-2xl">We've resent your pet certificate</div>
                <svg
                  fill="currentColor"
                  style="
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    right: 0;
                  "
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="close"
                >
                  <path
                    clip-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    fill-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                class="resent-certificate-main-body mt-4 ml-4 mr-4 tw-flex tw-flex-col tw-justify-center tw-items-center"
              >
                <div class="resent-certificate-main-body-text">
                  We have resent your pet's registration certificate. It will
                  arrive by email shortly.<br /><br />
                  If this doesn't arrive within 15 minutes please let us know by
                  opening a
                  <a
                    href="https://mypethq.io/open-a-support-ticket/"
                    class="mr-5 black--text tw-underline tw-cursor-pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                    >support ticket HERE.</a
                  >
                </div>
                <br />
                <div
                  class="tw-border tw-rounded tw-bg-mp-purple tw-text-white tw-p-2 tw-cursor-pointer"
                  @click="close"
                >
                  Close
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {},
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
