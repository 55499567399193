<template>
  <v-progress-circular
    v-if="loading"
    :size="70"
    :width="7"
    color="#0074b5"
    indeterminate
  ></v-progress-circular>
  <user-register-details-form :user="user" v-else></user-register-details-form>
</template>

<script>
import axios from "axios";
import UserRegisterDetailsForm from "../components/UserRegisterDetailsForm";
import User from "../models/User";
import Swal from "sweetalert2";

export default {
  components: { UserRegisterDetailsForm },
  data() {
    return {
      loading: false,
      user: "",
    };
  },
  methods: {
    async getUser() {
      this.loading = true;
      try {
        this.user = await this.retrieveUser();
      } finally {
        this.loading = false;
      }
    },
  },
  beforeMount() {
    this.getUser();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "UserLogin") {
      next();
    } else if (to.name === "PetCreate") {
      next();
    } else {
      Swal.fire(
        "We'd need to know a bit about you first! Please fill out some details"
      );
      next(false);
    }
  },
};
</script>

<style scoped></style>
