<template>
  <v-progress-circular
    v-if="loading"
    :size="70"
    :width="7"
    color="#0074b5"
    indeterminate
  ></v-progress-circular>
  <pet-profile-form-microchip-registration
    v-else
    :user="user"
    :pet="pet"
    :user-fields-missing="checkUserDetails"
    :action="action"
  ></pet-profile-form-microchip-registration>
</template>

<script>
import Pet from "../models/Pet";
import PetProfileFormMicrochipRegistration from "../components/PetProfileFormMicrochipRegistration";
import User from "../models/User";

export default {
  components: { PetProfileFormMicrochipRegistration },
  props: {
    id: "",
    action: "",
    microchip: "",
  },
  data() {
    return {
      loading: true,
      pet: "",
      user: "",
      data: "",
    };
  },
  async mounted() {
    await this.getPet();
    await this.getUser();
  },
  computed: {
    checkUserDetails() {
      let userFieldsMissing = [];

      if (!this.user.first_name) {
        userFieldsMissing.push("First name");
      }
      if (!this.user.last_name) {
        userFieldsMissing.push("Last name");
      }
      if (!this.user.email) {
        userFieldsMissing.push("Email");
      }
      if (!this.user.phone) {
        userFieldsMissing.push("Phone number");
      }
      if (!this.user.address_line_1) {
        userFieldsMissing.push("Address line 1");
      }
      if (!this.user.city) {
        userFieldsMissing.push("City");
      }
      if (!this.user.postcode) {
        userFieldsMissing.push("Postcode");
      }

      return userFieldsMissing;
    },
  },
  methods: {
    async getPet() {
      try {
        this.pet = await Pet.retrieve(this.id);

        if (!this.pet.microchip_number && this.microchip) {
          this.pet.microchip_number = this.microchip;
        }
      } finally {
      }
    },
    async getUser() {
      try {
        this.user = await this.retrieveUser();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
