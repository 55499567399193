<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="9" sm="8">
        <v-form ref="form" v-model="valid" lazy-validation method="post">
          <v-card class="mx-auto mt-5" width="400">
            <v-toolbar class="blue-bkg no-shadow">
              <v-toolbar-title>Forgot your password?</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-text-field
                v-if="!resetViaMobile"
                v-model="email"
                :rules="emailRules"
                label="Email"
                prepend-icon="mdi-at"
                required
              ></v-text-field>
              <div class="tw-flex tw-items-center" v-if="resetViaMobile">
                <flag-drop-down-deprecated
                  @input="setIsoCountryCode"
                  class="tw-w-12 tw-h-8 tw-mr-4 tw-border tw-border-black"
                ></flag-drop-down-deprecated>
                <v-text-field class="tw-w-8" v-model="mobile" label="Mobile" />
              </div>
            </v-card-text>

            <div v-if="showResult" class="mb-2">
              If you have an account with us, we’ll send you a link to reset
              your password.
            </div>
            <div v-else-if="showErrors">Password reset failed</div>
            <v-divider></v-divider>
            <v-card-actions
              class="justify-center"
              style="flex-direction: column"
            >
              <v-btn
                v-model="valid"
                :disabled="!valid"
                class="white--text blue-bkg mb-2"
                @click="submit"
                >Reset password
              </v-btn>
              <div
                class="tw-text-mp-blue tw-cursor-pointer tw-font-thin tw-mt-2 tw-mb-2"
                @click="resetViaMobile = !resetViaMobile"
              >
                reset via {{ resetViaMobile ? "email" : "mobile" }}
              </div>
              <v-btn
                class="mb-2 transp-bkg no-shadow cap grey-text tw-text-lg"
                @click="$router.push({ name: 'UserLogin' })"
                >Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import User from "../models/User";
import FlagDropdown from "../components/shared/FlagDropDown";
import FlagDropDownDeprecated from "@/components/shared/FlagDropDownDeprecated.vue";

export default {
  components: {
    FlagDropDownDeprecated,
    FlagDropdown,
  },
  data() {
    return {
      email: "",
      mobile: "",
      emailRules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
      showErrors: false,
      showResult: false,
      resetViaMobile: false,
      valid: true,
      isoCountryCode: "GB",
      beforeChangeIsoCountryCode: "GB",
      isoCountryCodeMap: [
        {
          isoCountryCode: "GB",
          prefix: "+44",
        },
        {
          isoCountryCode: "IE",
          prefix: "+353",
        },
      ],
    };
  },
  beforeMount() {
    this.setCountryMobilePhoneCode();
  },
  methods: {
    submit() {
      // const data = {email: this.email};
      let email = this.email;
      let mobile = this.mobile;

      let payload = {
        email: email ? email : undefined,
        mobile: !email ? mobile : undefined,
      };

      const result = User.forgotPassword(payload);

      if (result) {
        this.showResult = true;
      }
    },
    setIsoCountryCode(code) {
      const oldPrefix = this.isoCountryCodeMap.find(
        (i) => i.isoCountryCode === this.beforeChangeIsoCountryCode
      ).prefix;
      const suffix = this.mobile.slice(oldPrefix.length);

      this.isoCountryCode = code;
      this.beforeChangeIsoCountryCode = code;

      const newPrefix = this.isoCountryCodeMap.find(
        (i) => i.isoCountryCode === this.isoCountryCode
      ).prefix;
      this.mobile = newPrefix + suffix;

      this.setCountryMobilePhoneCode();
    },
    async setCountryMobilePhoneCode() {
      const prefix = this.isoCountryCodeMap.find(
        (i) => i.isoCountryCode === this.isoCountryCode
      ).prefix;

      if (
        !this.mobile ||
        ["+44", "+353"].includes(this.mobile) ||
        this.mobile.length < prefix.length
      ) {
        this.mobile = prefix;
        this.mobilePhoneNumberIsInvalid = false;
      }

      if (this.mobile.charAt(prefix.length) === "0") {
        this.mobile =
          this.mobile.slice(0, prefix.length) +
          this.mobile.slice(prefix.length + 1, this.mobile.length);
      }

      let result = [...this.mobile];

      for (let i = 0; i < prefix.length; i++) {
        const c = prefix.charAt(i);
        const t = result[i];
        if (c !== t) {
          result.splice(i, 0, c);
        }
      }

      this.mobile = result.join("");
    },
  },
};
</script>

<style scoped>
select {
  -webkit-appearance: listbox !important;
}
</style>