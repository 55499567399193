<template>
  <v-container class="fill-height" fluid>
    <v-progress-circular
      v-if="loading"
      :size="70"
      :width="7"
      class="mt-5 tw-mx-auto"
      color="#0074b5"
      indeterminate
    ></v-progress-circular>
    <div class="tw-mx-auto tw-w-full" v-else>
      <div
        class="pets-contents tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-mt-8"
      >
        <div
          v-for="p in pets"
          :key="p.id"
          class="tw-place-self-center tw-mt-8 p-card tw-w-full sm:tw-w-3/4 md:tw-w-2/3 lg:tw-w-1/2 xl:tw-w-1/3"
        >
          <div
            @click="handleClick(p)"
            class="tw-cursor-pointer tw-flex-1 tw-justify-center tw-shadow-2xl tw-rounded-lg tw-border tw-border-mp-blue"
          >
            <div
              class="tw-bg-mp-blue tw-text-white tw-text-center tw-flex tw-items-center tw-rounded-t-md tw-justify-center tw-h-16 tw-text-2xl"
            >
              <h3>{{ p.name }}</h3>
            </div>
            <div class="md:tw-flex tw-items-center tw-px-5 tw-py-6">
              <div
                class="tw-flex tw-justify-center tw-rounded-full md:tw-w-1/3"
              >
                <div class="tw-mb-4">
                  <image-upload
                    :clickable="false"
                    type="pet"
                    :entity-uuid="p.uuid"
                    :has-file-uploads="p.has_file_uploads"
                  ></image-upload>
                </div>
              </div>
              <div class="md:tw-w-2/3">
                <div class="description tw-leading-9 tw-ml-2 tw-p-2">
                  <div class="tw-grid tw-grid-cols-3 tw-gap-20">
                    <div class="tw-text-left tw-font-semibold tw-text-mp-blue">
                      Microchip:
                    </div>
                    <div
                      class="tw-col-span-2 tw-text-black tw-text-left tw-truncate"
                    >
                      {{ p.microchip || "-" }}
                    </div>
                  </div>
                  <div class="tw-grid tw-grid-cols-3 tw-gap-20">
                    <div class="tw-text-left tw-font-semibold tw-text-mp-blue">
                      Colour:
                    </div>
                    <div
                      class="tw-col-span-2 tw-text-black tw-text-left tw-truncate"
                    >
                      {{ p.colour || "-" }}
                    </div>
                  </div>
                  <div class="tw-grid tw-grid-cols-3 tw-gap-20">
                    <div class="tw-text-left tw-font-semibold tw-text-mp-blue">
                      Age:
                    </div>
                    <div class="tw-col-span-2 tw-text-black tw-text-left">
                      {{ getAge(p.dob) || "-" }}
                    </div>
                  </div>
                  <div class="tw-grid tw-grid-cols-3 tw-gap-20">
                    <div class="tw-text-left tw-font-semibold tw-text-mp-blue">
                      Features:
                    </div>
                    <div
                      class="tw-col-span-2 tw-text-black tw-text-left tw-truncate"
                    >
                      {{ p.distinguishing_features }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import Pet from "../models/Pet";
import moment from "moment";
import ImageUpload from "@/components/shared/ImageUpload.vue";
import BackButton from "@/components/shared/BackButton.vue";

export default {
  components: { BackButton, ImageUpload },
  data() {
    return {
      loading: true,
      pets: [],
    };
  },
  methods: {
    async getPets() {
      try {
        this.pets = await Pet.index();
      } finally {
        this.loading = false;
      }
    },
    handleClick(pet) {
      if (event.target.tagName !== "svg" && event.target.tagName !== "INPUT") {
        this.submitpet(pet);
      }
    },
    submitpet(pet) {
      this.$router.push({ name: "PetProfile", params: { id: pet.id } });
    },
    getAge(dob) {
      const a = moment();
      const b = moment(dob);

      const years = a.diff(b, "year");
      b.add(years, "years");

      const months = a.diff(b, "months");

      return years + "y " + months + "m";
    },
  },
  beforeMount() {
    this.getPets();
  },
};
</script>

<style scoped>
.p-card {
  max-width: 32rem;
  width: 100%;
}
</style>