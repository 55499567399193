import Model from "./Model";

export default class Breed extends Model {
  static async list(petType) {
    const response = await window.Http.get(
      "/api/v2/breeds?pet_type=" + petType
    );
    return response;
  }
}
