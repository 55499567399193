<template>
  <div class="mobile-input tw-mt-2">
    Mobile
    <div class="tw-flex tw-items-center">
      <flag-dropdown
        v-model="isoCountryCode"
        @input="setIsoCountryCode"
        class="tw-mt-1"
        :initial-prefix="prefix"
      ></flag-dropdown>
      <base-input-v2
        ref="mobileAnchorRef"
        class="tw-w-full"
        :invalid-class="
          mobilePhoneNumberIsInvalid
            ? 'tw-border tw-ring-red-500 tw-border-red-500 tw-bg-red-100'
            : ''
        "
        :highlight-class="highlightMobile ? 'animate-highlight-red' : ''"
        @input="handleMobileNumberInput"
        required="true"
        v-model="mobileValue"
        type="tel"
      >
        <v-progress-circular
          v-if="pinpointApiLoading"
          :size="20"
          :width="4"
          color="#0074b5"
          class="ml-1"
          indeterminate
        ></v-progress-circular>
        <CheckCircleIcon
          v-if="mobilePhoneNumberIsValid"
          class="ml-1 w-8 h-8 tw-text-mp-green"
        >
        </CheckCircleIcon>
        <XCircleIcon
          v-if="mobilePhoneNumberIsInvalid"
          class="ml-1 w-8 h-8 tw-text-mp-red"
        >
        </XCircleIcon>
      </base-input-v2>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon, XCircleIcon } from "@vue-hero-icons/solid";
import FlagDropdown from "@/components/shared/FlagDropDown.vue";
import BaseInputV2 from "@/components/shared/BaseInputV2.vue";
import { debounce } from "lodash";
import PhoneNumberService from "@/services/PhoneNumberService";
import { prefix } from "../../../tailwind.config";

export default {
  components: { CheckCircleIcon, BaseInputV2, FlagDropdown, XCircleIcon },
  props: {
    mobile: null,
  },
  data() {
    return {
      mobilePhoneNumberIsValid: false,
      mobilePhoneNumberIsInvalid: false,
      pinpointApiLoading: false,
      highlightMobile: false,
      isoCountryCode: "GB",
      mobileValue: "",
      prefix: "+44",
      countryMap: {
        GB: {},
        IE: {},
      },
      isoCountryCodeMap: [
        {
          isoCountryCode: "GB",
          prefix: "+44",
        },
        {
          isoCountryCode: "IE",
          prefix: "+353",
        },
      ],
    };
  },
  methods: {
    findPrefix(countryCode) {
      const country = this.isoCountryCodeMap.find(
        (entry) => entry.isoCountryCode === countryCode
      );
      return country ? country.prefix : null;
    },
    async jumpToAnchor(item) {
      const el = this.$refs[item.anchor];

      if (el) {
        window.scrollTo(0, el.offsetTop);
      }

      switch (item.type) {
        case "mobile":
          this.highlightMobile = true;
          break;
      }
    },
    mobileNumberInput: debounce(function () {
      this.debouncedMobileNumberValidation();
    }, 1600),
    async debouncedMobileNumberValidation() {
      const phoneNumberValidatedEntity =
        await PhoneNumberService.validatePhoneNumber(
          this.prefix + this.mobileValue,
          "mobile"
        );

      if (!phoneNumberValidatedEntity.unique_except_self) {
        this.mobileUniqueExceptSelf = false;
      } else if (phoneNumberValidatedEntity.is_valid) {
        this.mobilePhoneNumberIsValid = true;
        this.$emit("setMobileValid", true);
      } else {
        this.mobilePhoneNumberIsInvalid = true;
        this.$emit("setMobileValid", false);
      }
      this.pinpointApiLoading = false;
    },
    async handleMobileNumberInput() {
      await this.resetMobileSettings();
      this.updateMobileValue(this.mobileValue);
      if (this.mobileValue.length < 8) {
        this.mobilePhoneNumberIsInvalid = true;
        return;
      }
      this.pinpointApiLoading = true;
      this.mobileNumberInput();
    },
    setIsoCountryCode(code) {
      this.isoCountryCode = code;
      this.prefix = this.findPrefix(this.isoCountryCode);
      this.handleMobileNumberInput();
    },
    async resetMobileSettings() {
      this.pinpointApiLoading = false;
      this.mobilePhoneNumberIsValid = false;
      this.mobilePhoneNumberIsInvalid = false;
    },
    updateMobileValue(value) {
      this.$emit("setMobile", this.formatMobileNumber(value));
    },
    formatMobileNumber(mobile) {
      let formattedMobile = this.mobileValue.trim();
      if (formattedMobile.startsWith("0")) {
        // Remove leading "0" and add "+44" prefix
        formattedMobile = this.prefix + formattedMobile.slice(1);
      } else if (formattedMobile.startsWith(this.prefix)) {
        // If already starts with "+44", leave as it is
      } else {
        // otherwise set default
        formattedMobile = this.prefix + formattedMobile;
      }
      return formattedMobile;
    },
    removePrefix(mobile) {
      if (!mobile) {
        return;
      }
      // Check if the mobile number starts with either "+353" or "+44"
      if (mobile.startsWith("+353")) {
        this.prefix = "+353";
        return mobile.slice(4);
      } else if (mobile.startsWith("+44")) {
        this.prefix = "+44";
        return mobile.slice(3);
      } else {
        return mobile;
      }
    },
  },
  created() {
    this.mobileValue = this.removePrefix(this.mobile);
  },
};
</script>

<style scoped></style>