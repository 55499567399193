<template>
  <message :title="title" :message="message" :link="link"></message>
</template>

<script>
import Message from "../components/shared/Message";

export default {
  props: {},
  components: {
    Message,
  },
  data() {
    return {
      title: "pet registration",
      message: "thank you",
      link: "home",
      loading: false,
    };
  },
  beforeMount() {
    if (this.$route.query.accepted === "true") {
      this.title = "Pet registration completed successfully!";
      this.message = "Thank you for transferring this pet.";
      this.link = "Home";
    } else {
      this.message = "Pet registration did not complete successfully";
    }
  },
};
</script>

<style scoped>
</style>
