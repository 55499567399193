var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-flex tw-justify-center"},[_c('div',{staticClass:"tw-w-full tw-flex tw-flex-col tw-justify-center tw-p-6"},[_c('h2',{staticClass:"tw-mt-8 tw-mb-4 tw-text-xl tw-self-start tw-font-bold"},[_vm._v(" Confirm your details ")]),_c('div',{staticClass:"tw-w-full tw-flex tw-justify-center"},[_c('details',{staticClass:"tw-w-full tw-border tw-border-gray-400 open:tw-ring-1 open:tw-ring-black/5 open:tw-shadow-lg tw-rounded-lg cursor-auto pointer-events-auto tw-bg-white",attrs:{"open":_vm.userDetailsOpen},on:{"click":function($event){$event.stopPropagation();return _vm.onToggleUserDetails.apply(null, arguments)}}},[_c('summary',{staticClass:"tw-text-sm tw-leading-6 tw-text-slate-900 tw-font-semibold tw-select-none tw-cursor-pointer tw-w-full tw-rounded-t-md",staticStyle:{"background-color":"rgba(0, 80, 185, 0.5)"}},[_c('div',{staticClass:"tw-p-6 tw-text-mp-charcoal tw-text-xl tw-font-medium tw-flex tw-justify-center"},[_c('div',{staticClass:"tw-w-1/3"},[_c('ChevronDownIcon',{staticClass:"tw-w-12 tw-h-12 tw-text-mp-light-blue",class:[
                  _vm.chevronRight
                    ? 'chevron-down-to-right'
                    : 'chevron-right-to-down',
                ]})],1),_vm._m(0),_c('div',{staticClass:"tw-w-1/3"},[_c('div',{staticClass:"tw-relative tooltip-container"},[_c('div',{staticClass:"tw-w-4 tw-h-4 tw-bg-white tw-rounded-full tw-absolute tw-top-1 tw-right-1 tw-z-10"}),(_vm.userDetailsSaved)?_c('v-icon',{staticClass:"tw-float-right tw-opacity-100 icon tw-z-20",attrs:{"color":"#00b53f"}},[_vm._v(" mdi-checkbox-marked-circle ")]):_vm._e(),(!_vm.userDetailsSaved)?_c('v-icon',{staticClass:"tw-float-right tw-opacity-100 icon tooltip-target tw-z-20",attrs:{"color":"#B54100"}},[_vm._v("mdi-alert-circle ")]):_vm._e(),(!_vm.userDetailsSaved)?_c('div',{staticClass:"tooltip"},[_vm._v(" Please correct any errors before clicking the \"save\" button ")]):_vm._e()],1)])])]),_c('div',{staticClass:"tw-mt-3 tw-w-full tw-text-sm tw-leading-6 tw-text-mp-charcoal tw-bg-white tw-rounded-md",on:{"click":function($event){$event.stopPropagation();}}},[_c('user-details-form',{ref:"userComponentRef",on:{"saveUserDetails":_vm.saveUserDetails}})],1)])]),_c('h2',{staticClass:"tw-mt-8 tw-mb-4 tw-text-xl tw-self-start tw-font-bold"},[_vm._v(" "+_vm._s("Confirm your pet" + (_vm.pets.length > 1 ? "s'" : "'s") + " details")+" ")]),_vm._l((_vm.pets),function(pet,i){return _c('div',{staticClass:"tw-w-full tw-flex tw-justify-center tw-mt-4"},[_c('details',{staticClass:"tw-w-full tw-border tw-border-gray-400 open:tw-ring-1 open:tw-ring-black/5 open:tw-shadow-lg tw-rounded-lg cursor-auto pointer-events-auto",class:[i % 2 === 0 ? 'pet-green' : 'pet-blue'],attrs:{"open":pet.open},on:{"click":function($event){$event.preventDefault();return _vm.onTogglePetDetails(i)}}},[_c('summary',{staticClass:"tw-text-sm tw-leading-6 tw-text-slate-900 tw-font-semibold tw-select-none tw-rounded-t-md tw-cursor-pointer"},[_c('div',{staticClass:"tw-p-6 tw-text-mp-charcoal tw-text-xl tw-font-medium tw-flex tw-justify-center"},[_c('div',{staticClass:"tw-w-1/3"},[_c('ChevronDownIcon',{staticClass:"tw-w-12 tw-h-12",class:[
                  pet.open
                    ? 'chevron-right-to-down'
                    : 'chevron-down-to-right',
                  i % 2 === 0
                    ? 'tw-text-mp-light-green'
                    : 'tw-text-mp-light-blue',
                ]})],1),_c('div',{staticClass:"tw-w-1/3"},[_c('v-icon',{staticClass:"tw-float-left tw-m-1",attrs:{"color":"#000000"}},[_vm._v(_vm._s(_vm.getPetIcon(pet))+" ")]),_c('div',{staticClass:"tw-text-black tw-m-1"},[_vm._v(" "+_vm._s(pet.name ? pet.name + "'s" : "pet" + " details")+" ")])],1),_c('div',{staticClass:"tw-w-1/3"},[_c('div',{staticClass:"tw-relative tooltip-container"},[_c('div',{staticClass:"tw-w-4 tw-h-4 tw-bg-white tw-rounded-full tw-absolute tw-top-1 tw-right-1 tw-z-10"}),(pet.saved)?_c('v-icon',{staticClass:"tw-float-right tw-opacity-100 icon tw-z-20",attrs:{"color":"#00b53f"}},[_vm._v(" mdi-checkbox-marked-circle ")]):_vm._e(),_c('div',{staticClass:"tw-w-4 tw-h-4 tw-bg-white tw-rounded-full tw-absolute tw-top-1 tw-right-1"}),(!pet.saved)?_c('v-icon',{staticClass:"tw-float-right tw-opacity-100 icon tooltip-target tw-z-20",attrs:{"color":"#B54100"}},[_vm._v("mdi-alert-circle ")]):_vm._e(),_c('div',{staticClass:"tooltip"},[_vm._v(" Please correct any errors before clicking the \"save\" button ")])],1)])])]),_c('div',{staticClass:"tw-mt-3 tw-w-full tw-text-sm tw-leading-6 tw-text-mp-charcoal tw-bg-white tw-rounded-b-md",on:{"click":function($event){$event.stopPropagation();}}},[_c('pet-details-form',{ref:"{{'petComponentRef' + i}}",refInFor:true,attrs:{"pet":pet,"user":_vm.user,"position":i,"flow":_vm.flow},on:{"savePetDetails":_vm.savePetDetails,"userDuplicatePets":_vm.userDuplicatePets,"changePetName":_vm.changePetName}})],1)])])}),(_vm.formIsFilledOut)?_c('div',{staticClass:"tw-cursor-pointer tw-inline-flex tw-justify-center tw-mt-6 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-mp-dark-green hover:tw-bg-green-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500",on:{"click":_vm.onContinue}},[_vm._m(1)]):_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-w-1/3 tw-mt-2"},[_c('div',{staticClass:"tw-text-2xl tw-text-white"},[_vm._v("Your details")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-flex"},[_c('span',{staticClass:"tw-ml-2 tw-text-lg"},[_vm._v("Continue")])])
}]

export { render, staticRenderFns }