<template>
  <table class="table-auto">
    <thead>
    <tr >
      <th v-for="header in headers" :key="header.value">{{header.text}}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="value in values" :key="value.id">
      <td>{{value.name}}</td>
      <td>{{value.pet_name}}</td>
      <td>{{value.price}}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
    export default {
      props: {
        headers: {},
        values: {}
      },
    }
</script>

<style>
html,
body {
  height: 100%;
}

@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

</style>