<template>
  <v-progress-circular
      :size="70"
      :width="7"
      class="mt-8"
      color="#0074b5"
      indeterminate
  ></v-progress-circular>
</template>

<script>
import User from "../models/User";

export default {
  props: {
    token: String,
  },
  data() {
    return {
      user: "",
    };
  },
  methods: {
    async authenticate() {
      await window.Http.setAuthorizationToken(this.token);
      const user = await User.retrieve();
      const t = this.token;

      await this.$store.commit("auth_success", {t, user});

      return user;
    },
  },
  async mounted() {
    const destination = this.$route.query.destination;

    if (destination === 'transfer-complete') {
      await this.$router.push({
        name: "GenericMessageWrapper",
        params: {
          title: "Pet transfer complete!",
          message: "The pet has been transferred to your account!",
          link: "Back to home",
        },
      });
    }

    await this.authenticate();

    switch (destination) {
      case "transfer-complete":
        window.location = "/redirect-destination?destination=transfer-complete";
        break;
      case "current-keeper-transfer-accepted":
        window.location = "/redirect-destination?destination=current-keeper-transfer-accepted";
        break;
      case "new-keeper-transfer-accepted":
        window.location = "/redirect-destination?destination=new-keeper-transfer-accepted&flow=new_keeper";
        break;
      case "current-keeper-transfer-rejected":
        window.location = "/redirect-destination?destination=current-keeper-transfer-rejected";
        break;
      case "error-review":

        break;
    }
  },
};
</script>

<style scoped>
</style>
