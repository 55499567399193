<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="9" sm="8">
        <v-card class="mx-auto mt-5" width="600">
          <v-toolbar color="blue-bkg" dark flat style="justify-content: center">
            <v-toolbar-title style="justify-content: center"
              >Authorised User Registration
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-alert
              class="mb-5"
              style="text-align: left; display: block"
              type="error"
              >Please note this form is for authorised users only
            </v-alert>

            <a
              class="tw-text-sm"
              href="https://mypet.tawk.help/article/what-is-an-authorised-user"
              target="_blank"
              >What is an authorised user?</a
            >
            <v-form v-model="valid">
              <v-text-field
                v-model="email"
                :error-messages="messages.email"
                :rules="[rules.required, rules.email]"
                label="Email"
                @input="clearError()"
              ></v-text-field>
              <v-text-field
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show ? 'text' : 'password'"
                counter
                hint="At least 8 characters"
                label="Enter Password"
                name="password"
                @click:append="show = !show"
              ></v-text-field>
              <v-text-field
                v-if="this.password"
                v-model="passwordConfirmation"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min, passwordConfirmationRule]"
                :type="show1 ? 'text' : 'password'"
                counter
                hint="At least 8 characters"
                label="Re-enter Password"
                name="input-10-1"
                @click:append="show1 = !show1"
              ></v-text-field>
              <v-text-field
                v-model="first_name"
                :rules="textFieldRules"
                label="First name"
                required
              ></v-text-field>
              <v-text-field
                v-model="last_name"
                :rules="textFieldRules"
                label="Last name"
                required
              ></v-text-field>
              <v-text-field
                v-model="organisationName"
                :rules="textFieldRules"
                label="Organisation"
                required
              ></v-text-field>
              <v-select
                v-model="organisationType"
                :items="organisationTypes"
                :rules="textFieldRules"
                label="Organisation Type"
              ></v-select>
              <v-text-field
                v-if="organisationType.toLowerCase() === 'other'"
                v-model="organisationTypeSpecified"
                label="Please specify organisation type"
              ></v-text-field>

              <v-row>
                <v-label><h3 class="mt-5 ml-3">Address details</h3></v-label>
              </v-row>

              <v-row>
                <v-text-field
                  v-model="postcode"
                  :rules="textFieldRules"
                  class="ml-3 mt-3"
                  label="Postcode"
                  required
                ></v-text-field>
                <v-btn
                  class="cap ml-5 mt-3 mr-2 white--text"
                  color="#00b53f"
                  prepend-icon="mdi-map-marker"
                  @click="listAddresses()"
                >
                  Find address
                </v-btn>
              </v-row>
              <v-select
                v-if="addresses.length > 0"
                v-model="address"
                :items="addresses"
                item-text="line_1"
                label="Address"
                return-object
                @change="populateAddressFields()"
              ></v-select>
              <v-text-field
                v-model="address_line_1"
                :disabled="disabled"
                :rules="textFieldRules"
                label="Address line 1"
                required
              ></v-text-field>
              <v-text-field
                v-model="address_line_2"
                :disabled="disabled"
                label="Address line 2"
              ></v-text-field>
              <v-text-field
                v-if="address_line_3"
                v-model="address_line_3"
                :disabled="disabled"
                label="Address line 3"
                required
              ></v-text-field>
              <v-text-field
                v-if="address_line_4"
                v-model="address_line_4"
                :disabled="disabled"
                label="Address line 4"
                required
              ></v-text-field>
              <v-text-field
                v-model="city"
                :disabled="disabled"
                :rules="textFieldRules"
                label="Town/City"
                required
              ></v-text-field>
              <v-text-field
                v-model="phone"
                :rules="telephoneRules"
                label="Telephone"
                required
              ></v-text-field>
              <v-checkbox
                v-model="subscribe_all"
                label="Would you like to subscribe to email notifications (Marketing, reminders and updates)?"
              ></v-checkbox>
              <v-checkbox
                v-model="privacy_policy"
                :rules="[(v) => !!v || 'You must agree to use our service!']"
                label="Do you agree to our privacy policy and terms of use?"
                required
              ></v-checkbox>
              <a
                class="mr-4 tw-text-mp-blue"
                href="https://www.mypethq.io/privacy/"
                target="_blank"
                >Privacy policy</a
              >
              <a
                class="ml-4 tw-text-mp-blue"
                href="https://www.mypethq.io/terms-of-service/"
                target="_blank"
                >Terms of Service</a
              >
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center" style="flex-direction: column">
            <v-btn
              :disabled="!valid"
              class="white--text blue-bkg mb-2"
              type="submit"
              @click="submit()"
              >Sign up
            </v-btn>
            <v-btn
              class="mb-2 transp-bkg no-shadow cap mob-text grey-text"
              @click="$router.go(-1)"
              >Go Back
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      address: "",
      addresses: [],
      city: "",
      country: "",
      email: "",
      errors: "",
      subscribe_all: false,
      housenumber: "",
      first_name: "",
      last_name: "",
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      address_line_4: "",
      messages: {
        email: [],
        password: [],
      },
      disabled: false,
      organisationTypes: [
        "Veterinary Practice",
        "Local Council",
        "Animal Rescue",
        "Police",
        "Other",
      ],
      organisationName: "",
      organisationType: "",
      organisationTypeSpecified: "",
      password: "",
      passwordConfirmation: "",
      phone: "",
      postcode: "",
      privacy_policy: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      },
      show: false,
      show1: false,
      telephoneRules: [
        (v) => /[+]?\d{6,12}/.test(v) || "Must be a valid telephone number",
      ],
      textFieldRules: [
        (v) => !!v || "Required",
        (v) => v.length >= 3 || "Min 3 characters",
      ],
      valid: false,
    };
  },
  computed: {
    passwordConfirmationRule() {
      return (
        this.password === this.passwordConfirmation || "Password must match"
      );
    },
  },
  methods: {
    submit() {
      const resp = this.$store
        .dispatch("register", {
          role: "customer",
          email: this.email,
          password: this.password,
          password_confirmation: this.password,
          first_name: this.first_name,
          last_name: this.last_name,
          organisation_name: this.organisationName,
          organisation_type:
            this.organisationType === "Other"
              ? this.organisationTypeSpecified
              : this.organisationType,
          postcode: this.postcode,
          phone: this.phone,
          city: this.city,
          subscribe_all: this.subscribe_all,
          address_line_1: this.address_line_1,
          address_line_2: this.address_line_2,
          address_line_3: this.address_line_3,
          address_line_4: this.address_line_4,
          userType: "microchip_authorised_user",
        })
        .then(() => {
          this.$router.push({
            name: "GenericMessageWrapper",
            params: {
              title: "Thanks! We have received your registration request.",
              message:
                "We take the privacy of our users very seriously, so we manually review every authorised account request. We will be in touch within 48 hours. If you need access urgently please reach out to us on 0800 368 4150 or at support@mypethq.io",
              link: "Back to home",
            },
          });
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
    clearError() {
      this.messages = {
        email: [],
        password: [],
      };
    },
    handleError(err) {
      let errors = err.response.data.errors;

      if (errors) {
        for (let field in errors) {
          this.messages[field] = errors[field];
        }
      }
    },

    async listAddresses() {
      if (!this.postcode) return;
      const response = await axios.get(
        "https://api.getaddress.io/find/" +
          this.postcode +
          "?api-key=vb3__sSB4kaFuyPu5vWS7g18118&sort=true&expand=true"
      );

      this.addresses = response.data.addresses;
    },
    isDisabled() {
      if (this.breeder) {
        if (!this.breeder_licence || !this.licensing_authority) {
          return true;
        }
      } else {
        return false;
      }
    },
    populateAddressFields() {
      if (this.address) {
        this.address_line_1 = this.address.line_1;
        this.address_line_2 = this.address.line_2;
        this.address_line_3 = this.address.line_3;
        this.address_line_4 = this.address.line_4;
        this.city = this.address.town_or_city;
      }
    },
  },
};
</script>