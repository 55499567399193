<template>
  <div class="tw-flex tw-flex-col">
    <h2 class="tw-float-left tw-mt-2">Your Vet Details</h2>
    <div class="tw-relative">
      <base-input-v2
        v-if="!detailsManualEntry"
        v-model="searchField"
        label="Find your vet"
        :placeholder="hasPickedAddress ? '' : 'Enter veterinary practice name'"
        type="text"
        @down="incrementVetDisplayObjectSelectedPosition"
        @enter="selectVetDisplayObjectByPosition"
        @input="lookupVets"
        @up="decrementVetDisplayObjectSelectedPosition"
      ></base-input-v2>
      <div
        class="tw-w-1/2 tw-inline-flex tw-justify-center tw-mt-4 tw-border tw-border-transparent tw-shadow-lg tw-py-2 tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-text-opacity-100 tw-bg-mp-purple tw-opacity-70 hover:tw-opacity-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
        @click="detailsManualEntry = !detailsManualEntry"
      >
        <div class="tw-ml-2 tw-mr-2">
          {{
            detailsManualEntry
              ? "search UK veterinary practices"
              : "or enter manually"
          }}
        </div>
      </div>
      <div
        v-if="vetDisplayObjects.length > 0"
        class="tw-absolute tw-left-0 tw-right-0 tw-max-h-60 tw-overflow-y-auto tw-border tw-border-slate-500 tw-rounded-md tw-z-50"
      >
        <div
          v-for="vetDisplayObject in vetDisplayObjects"
          :key="vetDisplayObject.position"
          :class="[
            vetDisplayObject.position === vetDisplayObjectSelectedPosition
              ? 'tw-bg-violet-500 tw-text-white'
              : 'tw-bg-gray-50 tw-text-gray-700',
          ]"
          class="tw-text-sm tw-font-medium tw-flex tw-justify-items-start"
          @click="selectVet(vetDisplayObject)"
          @mouseover="
            setVetDisplayObjectSelectedPosition(vetDisplayObject.position)
          "
        >
          <span class="tw-ml-2">{{ vetDisplayObject.display }}</span>
        </div>
      </div>
      <base-input-v2
        v-if="name || address || detailsManualEntry"
        v-model="name"
        required="true"
        :invalid-class="
          name === '' ? '  tw-border-solid tw-border-red-500 tw-bg-red-100' : ''
        "
        class="py-2"
        label="Name"
        type="text"
      ></base-input-v2>
      <base-input-v2
        v-if="name || address || detailsManualEntry"
        v-model="address"
        class="py-2"
        label="Address"
        type="text"
      ></base-input-v2>
    </div>
  </div>
</template>

<script>
import BaseInputV2 from "@/components/shared/BaseInputV2";
import Vet from "@/models/Vet";

export default {
  components: { BaseInputV2 },
  props: {
    vet: {},
  },
  data() {
    return {
      searchField: "",
      name: "",
      address: "",
      place_id: "",
      vetDisplayObjects: [],
      vetDisplayObjectSelectedPosition: 1,
      detailsManualEntry: false,
      vetSelected: false,
    };
  },
  mounted() {
    this.name = this.vet.name;
    this.address = this.vet.address;
    this.place_id = this.vet.place_id;
  },
  computed: {
    hasPickedAddress() {
      return this.name && this.address && this.place_id;
    },
    watchValidationVariables() {
      return [this.name, this.address, this.place_id];
    },
  },
  watch: {
    watchValidationVariables: function (newVals, oldVals) {
      this.emitSelectedVet();
    },
    address: {
      handler(newVal) {
        this.postcode = newVal.postcode || "";
        this.city = newVal.city || "";
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async lookupVets(value) {
      const response = await Vet.search(value);
      if (response) {
        this.vetDisplayObjects = response.map((e, i) => ({
          ...e,
          display: e.name + " - " + e.address,
          position: i + 1,
        }));
      }
    },
    async setVet(vetDetails) {
      this.vet.name = vetDetails.name;
      this.vet.address = vetDetails.address;
      this.vet.place_id = vetDetails.place_id;
    },
    async selectVet(vet) {
      this.name = vet.name;
      this.address = vet.address;
      this.place_id = vet.place_id;

      this.vetDisplayObjects = [];
      this.searchField = "";
      this.vetSelected = true;
    },
    async emitSelectedVet() {
      this.$emit("setVet", {
        name: this.name,
        address: this.address,
        place_id: this.place_id,
      });
    },
    async setVetDisplayObjectSelectedPosition(position) {
      this.vetDisplayObjectSelectedPosition = position;
    },
    selectVetDisplayObjectByPosition() {
      this.selectVet(
        this.vetDisplayObjects[this.vetDisplayObjectSelectedPosition - 1]
      );
    },
    incrementVetDisplayObjectSelectedPosition() {
      if (
        !(
          this.vetDisplayObjectSelectedPosition >= this.vetDisplayObjects.length
        )
      ) {
        this.setVetDisplayObjectSelectedPosition(
          this.vetDisplayObjectSelectedPosition + 1
        );
      }
    },
    decrementVetDisplayObjectSelectedPosition() {
      if (!(this.vetDisplayObjectSelectedPosition <= 1)) {
        this.setVetDisplayObjectSelectedPosition(
          this.vetDisplayObjectSelectedPosition - 1
        );
      }
    },
  },
};
</script>

<style scoped></style>