<template>
  <v-progress-circular
      v-if="loading"
      :size="70"
      :width="7"
      color="#0074b5"
      indeterminate
  ></v-progress-circular>
  <v-container v-else-if="!loading" class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="9" sm="8">
        <v-alert
            v-if="!register_microchip && subscriptionType === 'basic'"
            class="mx-auto"
            color="#0074b5"
            outlined
            type="info"
        >
          If you want to add this pet to our Defra compliant database please
          <v-btn
              :disabled="disabled"
              class="cap white--text"
              color="#00b53f"
              @click="microchipRegisterPet"
          >Pay here
          </v-btn>
          <br/>
          <p class="tw-text-black">
            At £2.99 lifetime registration, we are the cheapest database in the
            UK
          </p>
        </v-alert>
        <v-card class="mx-auto mt-5">
          <v-toolbar color="blue-bkg" dark flat>
            <v-toolbar-title>Your pet's details</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div class="tw-mb-4">
              <image-upload
                  type="pet"
                  :clickable="isImageClickable"
                  :entity-uuid="pet.uuid"
                  :has-file-uploads="pet.has_file_uploads"
              ></image-upload>
            </div>
            <v-form>
              <v-alert v-if="form.errors.get('microchip')" outlined type="error"
              >Microchip number must be unique. If your microchip number has
                been registered already please contact us at support@mypethq.io
              </v-alert>
              <v-text-field
                  v-model="form.microchip"
                  :disabled="disabled"
                  :error="form.errors.get('microchip')"
                  :rules="microchipRules"
                  label="Microchip number"
                  required
                  @blur="onMicrochipBlur"
              ></v-text-field>
              <v-text-field
                  v-model="form.name"
                  :disabled="disabled"
                  :error="form.errors.get('name')"
                  label="Pet name"
                  required
                  @blur="checkFreeInsuranceEligible"
              ></v-text-field>
              <v-select
                  v-model="form.type"
                  :disabled="disabled"
                  :error="form.errors.get('type')"
                  :items="petTypes"
                  label="Type of pet"
                  @change="populateBreeds()"
              />
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="computedDateFormatted"
                      :disabled="disabled"
                      label="Birthday date"
                      prepend-icon="mdi-calendar-range"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    ref="picker"
                    v-model="date"
                    :max="new Date().toISOString().substr(0, 10)"
                    color="#0074b5"
                    min="1950-01-01"
                    @change="save"
                ></v-date-picker>
              </v-menu>
              <p>Sex</p>
              <v-btn-toggle
                  v-model="form.sex"
                  :disabled="disabled"
                  color="primary"
                  dense
                  @change="checkFreeInsuranceEligible"
              >
                <v-btn :disabled="disabled" class="cap" value="male">
                  Male
                </v-btn>

                <v-btn :disabled="disabled" class="cap" value="female">
                  Female
                </v-btn>

                <v-btn :disabled="disabled" class="cap" value="unknown">
                  Unknown
                </v-btn>
              </v-btn-toggle>
              <v-combobox
                  v-if="
                  form.type === 'Dog' ||
                  form.type === 'Cat' ||
                  form.type === 'Lizard' ||
                  form.type === 'Tortoise' ||
                  form.type === 'Bird'
                "
                  v-model="form.breed"
                  :items="this.petBreeds"
                  clearable
                  label="Breed"
                  @update:search-input="onBreedChanged"
              ></v-combobox>
              <v-text-field
                  v-model="form.colour"
                  :disabled="disabled"
                  label="Colour"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="form.distinguishing_features"
                  :disabled="disabled"
                  label="Distinguishing features"
                  required
              ></v-text-field>
              <p>Spayed / Neutered</p>
              <v-btn-toggle
                  v-model="form.neutered"
                  color="primary"
                  dense
                  @change="checkFreeInsuranceEligible"
              >
                <v-btn :disabled="disabled" class="cap" value="1"> Yes</v-btn>

                <v-btn :disabled="disabled" class="cap" value="0"> No</v-btn>

                <v-btn :disabled="disabled" class="cap" value="unknown">
                  Unknown
                </v-btn>
              </v-btn-toggle>
              <v-spacer></v-spacer>
              <insurance-option
                  v-if="showFreeInsuranceOffer"
                  :checked="freeInsurance"
                  :crossbreedText="crossbreedText"
                  :name="form.name"
                  :pet="insurancePet"
                  flow="pet_profile"
                  class="tw-mt-4"
                  @input="freeInsurance = !freeInsurance"
                  @toggleHelp="toggleInsuranceHelp"
              ></insurance-option>
              <p class="mt-4">
                You must fully complete all your personal details to comply with
                microchip regulations.
              </p>
              <v-alert
                  v-if="userFieldsMissing.length === 0"
                  dense
                  outlined
                  type="success"
              >
                All your personal details have been completed. Please keep them
                up to date!
              </v-alert>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mob-block">
            <span class="edit-pet">
              <v-btn class="cap" color="grey-bkg" @click="$router.go(-1)"
              >Back</v-btn
              >
              <v-btn
                  :disabled="user.id !== pet.user_id"
                  class="cap"
                  color="blue-bkg"
                  style="margin-left: 5px"
                  type="submit"
                  @click="form.submit()"
              >Save</v-btn
              >
            </span>
          </v-card-actions>
        </v-card>
        <div>
          <div
              class="tw-container tw-mx-auto tw-mt-8 tw-flex tw-justify-center tw-items-center"
          >
            <div class="tw-flex tw-flex-col md:tw-flex-row">
              <v-btn
                  v-if="user.role === 'customer'"
                  :disabled="user.id !== pet.user_id"
                  class="cap white--text mr-8 mb-2 d-btn"
                  color="#00b53f"
                  @click="showResendCertificateModal"
              >Resend certificate
              </v-btn>
              <v-btn
                  :disabled="disabled || user.id !== pet.user_id"
                  class="cap white--text mr-8 mb-2 d-btn"
                  color="#00b53f"
                  @click="transferPet"
              >Re-home pet
              </v-btn>
              <v-btn
                  :disabled="user.id !== pet.user_id"
                  class="cap white--text mr-8 mb-2 d-btn"
                  color="#B54100"
                  @click="$router.push({ name: 'PetLost' })"
              >Lost My Pet
              </v-btn>
            </div>
          </div>
          <div class="tw-flex tw-justify-center">
            <div
                :disabled="disabled || user.id !== pet.user_id"
                class="tw-text-mp-blue tw-mt-4 tw-cursor-pointer tw-bg-gray-300 tw-w-48 tw-rounded tw-pa-1"
                color="#757575"
                @click="deletePet"
            >
              Mark {{ (pet.name || "Pet") + " as " }}passed away
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="green darken-1"
        top
    >
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar_text = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-row align="center" justify="center">
      <insurance-help-modal
          :modalHeadline="
          'Congratulations! ' + form.name + ' is eligible for FREE insurance.'
        "
          :visible="showInsuranceHelp"
          @close="toggleInsuranceHelp"
      >
      </insurance-help-modal>
    </v-row>
    <resent-certificate-modal
        v-if="showResentCertModal"
        @close="closeResentCertificateModal"
    ></resent-certificate-modal>
    <premium-upgrade-modal
        v-if="showPremiumUpgradeModal"
        @upgrade="createSubscription"
        @close="closePremiumUpgradeModal"
    ></premium-upgrade-modal>
    <microchip-already-assigned-contact-support-modal
        v-if="showMicrochipAlreadyAssignedModal"
        :microchip="form.microchip"
        :breed="existingBreed"
        :sex="existingSex"
        :type="existingType"
        :existsInUserAccount="existsInUserAccount"
        @confirm="microchipAlreadyAssignedModalConfirm"
        @decline="microchipAlreadyAssignedModalDecline"
    ></microchip-already-assigned-contact-support-modal>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import Agria from "@/models/Agria";
import InsuranceOption from "@/components/InsuranceOption";
import InsuranceHelpModal from "@/components/modals/InsuranceHelpModal";
import ResentCertificateModal from "@/components/modals/ResentCertificateModal";
import PremiumUpgradeModal from "@/components/modals/PremiumUpgradeModal";
import Pet from "@/models/Pet";
import ImageUpload from "@/components/shared/ImageUpload.vue";
import Microchip from "@/models/Microchip";
import MicrochipAlreadyAssignedContactSupportModal
  from "@/components/modals/MicrochipAlreadyAssignedContactSupportModal.vue";

export default {
  components: {
    MicrochipAlreadyAssignedContactSupportModal,
    InsuranceOption,
    InsuranceHelpModal,
    ResentCertificateModal,
    PremiumUpgradeModal,
    ImageUpload,
  },
  props: {
    user: Object,
    userFieldsMissing: Array,
    action: String,
    pet: Object,
  },
  async mounted() {
    this.subscriptionType = await this.getSubscriptionType();
    this.populateBreeds();
    await this.fixNeutered();
    await this.fixMcRegistered();
    await this.fixDOB();
    await this.hasLiveAgriaPolicy();
    await this.checkFreeInsuranceEligible();
    this.loading = false;
  },
  data() {
    const data = {
      name: "",
      dob: "",
      breed: "",
      colour: "",
      distinguishing_features: "",
      microchip: "",
      sex: "unknown",
      neutered: "unknown",
      type: null,
    };

    return {
      microchipRules: [
        (v) => !!v || "Microchip is required",
        (v) =>
            (v && (v.length === 10 || v.length === 15) && /^\d+$/.test(v)) ||
            "Microchip must be 10 or 15 numbers",
      ],
      subscriptionType: "basic",
      menu: false,
      showInsuranceHelp: false,
      breed: "",
      breeds: [],
      loading: true,
      existingBreed: "",
      existingSex: "",
      existingType: "",
      existsInUserAccount: false,
      showMicrochipAlreadyAssignedModal: false,
      showDuplicateRegistrationModal: false,
      showPremiumUpgradeModal: false,
      hasCertSetting: false,
      register_microchip: false,
      freeInsurance: false,
      crossbreedText: "",
      petTypes: [
        "Dog",
        "Cat",
        "Rabbit",
        "Hamster",
        "Horse",
        "Lizard",
        "Bird",
        "Tortoise",
        "Ferret",
        "Guinea pig",
        "Other",
        "Unknown",
      ],
      insurancePet: Object,
      petBreeds: [],
      hasLivePolicy: false,
      showFreeInsuranceOffer: false,
      showResentCertModal: false,
      date: new Date().toISOString().substr(0, 10),
      disabled: false,
      modal: false,
      neutered: "false",
      valid: true,
      form: new window.Form(this.pet.getAttributes(), async (form) => {
        this.form.dob = moment(this.date).format("ddd DD MMM YYYY");
        this.form.breed = this.breed;

        this.form.mc_registered = this.register_microchip === true;

        if (this.form.neutered === "unknown") {
          this.form.neutered = null;
        }

        if (this.pet.mc_registered === 0 || this.pet.mc_registered == null) {
          await this.pet.update(form);
          const user = await this.retrieveUser();

          await this.$store.commit("update_cart", user);
          await this.$router.push({name: "Checkout"});
        } else {
          await this.pet.update(form);
          if (
              this.user.role === "rescue" ||
              this.user.role === "microchip_authorised_user"
          ) {
            this.$router.push({name: "PetsTable"});
          } else {
            this.$router.push({name: "UserPets"});
          }
        }
      }),
      snackbar: false,
      snackbar_text: "",
      timeout: 2000,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.date ? moment(this.date).format("dddd, MMMM Do YYYY") : "";
    },
    isImageClickable() {
      const user = this.$store.state.user;
      return !user || !user.role || user.role === "customer";
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  beforeMount() {
    this.breed = this.pet.breed;
    this.date = this.pet.dob;
  },
  methods: {
    async microchipAlreadyAssignedModalConfirm() {
      const result = await Microchip.duplicateRequest(this.microchipValue);

      this.showPetTransferModal = true;
    },
    microchipAlreadyAssignedModalDecline() {
      this.showMicrochipAlreadyAssignedModal = false;
      this.form.microchip = "";
    },
    toggleInsuranceHelp() {
      this.showInsuranceHelp = !this.showInsuranceHelp;
    },
    closePremiumUpgradeModal() {
      this.showPremiumUpgradeModal = false;
    },
    async createSubscription() {
      if (this.subscriptionType === "basic") {
        await this.$router.push({
          name: "SubscriptionPlansPage",
        });
      }
      this.showPremiumUpgradeModal = false;
    },
    closeResentCertificateModal() {
      this.showResentCertModal = false;
    },
    async hasLiveAgriaPolicy() {
      if (this.user.role === "rescue" || this.user.role === "breeder") {
        return;
      }

      if (
          this.form.microchip &&
          (this.form.microchip.length === 10 || this.form.microchip.length === 15)
      ) {
        this.hasLivePolicy = await Agria.livePolicyCheck(this.form.microchip);
      }

      await this.checkFreeInsuranceEligible();
    },
    async onMicrochipBlur() {
      await this.checkFreeInsuranceEligible();
      await this.checkMicrochipRegistered();
    },
    async checkMicrochipRegistered() {
      if (
          this.form.microchip.length === 10 ||
          this.form.microchip.length === 15
      ) {
        const internalResponse =
            await Microchip.isMicrochipRegisteredInternally(
                this.form.microchip,
                this.pet.uuid
            );

        this.existingBreed = internalResponse.breed;
        this.existingSex = internalResponse.sex;
        this.existingType = internalResponse.type;
        this.existsInUserAccount = internalResponse.belongs_to_requester;

        if (
            internalResponse.registered &&
            internalResponse.is_current_pet === false
        ) {
          this.showMicrochipAlreadyAssignedModal = true;
        }
      }
    },
    async checkFreeInsuranceEligible() {
      if (this.user.role === "rescue" || this.user.role === "breeder") {
        return;
      }

      if (this.hasLivePolicy) {
        return;
      }

      this.showFreeInsuranceOffer = await this.freeInsuranceEligible();
    },
    async freeInsuranceEligible() {
      if (this.user.role === "breeder") {
        return false;
      }

      if (this.pet.has_agria_policy) {
        return false;
      }

      const pet = {
        ...{
          id: this.pet.id,
          dob: this.date,
          microchip: this.form.microchip,
          route: "profile",
          name: this.form.name,
          breed: this.breed,
          sex: this.form.sex,
          type: this.form.type,
        },
      };

      this.insurancePet = pet;

      const petHasPrerequisites = Agria.petHasPrerequisites(pet);

      if (!petHasPrerequisites) {
        return false;
      }

      if (this.showFreeInsuranceOffer) {
        return true;
      }

      if (petHasPrerequisites && !this.hasLivePolicy) {
        const response = await Agria.freeInsuranceCheck(pet);

        this.crossbreedText = response.CrossbreedText || "";

        return response.CanOfferInsurance;
      }

      return false;
    },
    save(date) {
      this.$refs.menu.save(date);
      this.checkFreeInsuranceEligible();
    },
    onBreedChanged(currentValue) {
      this.breed = currentValue;
    },
    async deletePet() {
      const petName = this.pet.name;
      const result = await Swal.fire({
        title:
            "Are you sure? If you mark your pet as passed away, we will remove " +
            this.pet.name +
            " from your profile completely.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0074b5",
        cancelButtonColor: "#757575",
        confirmButtonText: `Confirm ${petName} has passed away`,
        showClass: {
          popup: `
      animate__animated
      animate__fadeInUp
      animate__faster
    `,
        },
        hideClass: {
          popup: `
      animate__animated
      animate__fadeOutDown
      animate__faster
    `,
        },
      });

      if (!result.value) {
        return;
      }

      this.snackbar_text = "We have removed this pet from your account.";
      await this.pet.delete();
      this.snackbar = true;

      this.redirect();
    },
    async deregisterPet() {
      const result = await Swal.fire({
        title:
            "Are you sure? If you deregister your pet, we will remove " +
            this.pet.name +
            " from our microchip database.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Remove my pet from the mypet microchip database",
      });

      if (!result.value) {
        return;
      }

      this.snackbar_text =
          "We have removed this pet from our microchip database.";
      this.form.mc_registered = false;
      if (this.form.neutered === "unknown") {
        this.form.neutered = null;
      }
      await this.pet.update(this.form);
      this.snackbar = true;

      this.redirect();
    },
    async microchipRegisterPet() {
      this.snackbar_text =
          "We have added " + this.pet.name + "'s microchip to your cart!";

      if (this.form.neutered === "unknown") {
        this.form.neutered = null;
      }
      await this.pet.update(this.form);
      this.snackbar = true;

      const user = await this.retrieveUser();

      await this.$store.commit("update_cart", user);

      this.redirectToStaging();
    },
    async fixDOB() {
      if (this.pet.dob) {
        const date = new Date(this.pet.dob);
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        const finaldate = new Date(date.getTime() - userTimezoneOffset);
        this.date = finaldate.toISOString().substr(0, 10);
      }
    },
    async fixMcRegistered() {
      this.register_microchip = !!this.pet.mc_registered;
    },
    async fixNeutered() {
      if (this.pet.neutered === null) {
        this.form.neutered = "unknown";
      }

      if (this.pet.neutered) {
        this.form.neutered = "1";
      }

      if (!this.pet.neutered) {
        this.form.neutered = "0";
      }
    },
    redirect() {
      setTimeout(
          function () {
            if (
                this.user.role === "microchip_authorised_user" ||
                this.user.role === "rescue"
            ) {
              this.$router.push({name: "PetsTable"});
            } else {
              this.$router.push({name: "UserPets"});
            }
          }.bind(this),
          2500
      );
    },
    redirectToStaging() {
      setTimeout(
          function () {
            this.$router.push({name: "PetRegistrationStaging"});
          }.bind(this),
          2500
      );
    },
    toggleDisabled() {
      this.disabled = !this.disabled;
    },
    async transferPet() {
      this.$router.push({name: "RehomePet", params: {id: this.pet.id}});
    },
    async showResendCertificateModal() {
      if (this.subscriptionType === "premium") {
        this.showResentCertModal = true;
        this.resendCertificate();
      } else {
        this.showPremiumUpgradeModal = true;
      }
    },
    async resendCertificate() {
      await Pet.sendCertificate(this.pet.uuid);
    },
    populateBreeds() {
      if (this.form.type === "Dog") {
        this.petBreeds = [
          "Unknown",
          "Crossbreed",
          "Affenpinscher",
          "Afghan Hound",
          "Airedale Terrier",
          "Akita Inu",
          "Alaskan Malamute",
          "Am Staff ",
          "American Coonhound",
          "American Eskimo Dog",
          "American Foxhound",
          "American Pit Bull Terrier",
          "American Water Spaniel",
          "Anatolian Shepherd Dog",
          "Australian Cattle",
          "Australian Shepherd",
          "Australian Terrier",
          "Azawakh",
          "Basenji",
          "Basset Hound ",
          "Beagle",
          "Bearded Collie",
          "Beauceron",
          "Bedlington Terrier",
          "Belgian Laekenois",
          "Belgian Malinois",
          "Belgian Sheepdog",
          "Belgian Tervuren",
          "Bergamasco",
          "Bernese Mountain Dog",
          "Bichon Frisé",
          "Biewer Terrier",
          "Black & Tan Coonhound",
          "Black Russian Terrier",
          "Bloodhound",
          "Bluetick Coonhound",
          "Boerboel",
          "Border Collie",
          "Border Terrier",
          "Borzoi",
          "Boston Terrier",
          "Bouvier des Flandres",
          "Boxer",
          "Briard",
          "Brittany",
          "Brussels Griffon",
          "Bull Terrier",
          "Bulldog - American",
          "Bullmastiff",
          "Cairn Terrier ",
          "Canaan Dog",
          "Cane Corso",
          "Cardigan Welsh Corgi",
          "Carolina Dog",
          "Cavalier King Charles",
          "Cesky Terrier ",
          "Chesapeake Bay Retriever",
          "Chihuahua",
          "Chinese Crested",
          "Chinese Shar-Pei",
          "Chinook",
          "Chow Chow",
          "Cirneco dell'Etna ",
          "Clumber Spaniel",
          "Cocker Spaniel - American",
          "Cocker Spaniel - English",
          "Collie",
          "Curly-Coated Retriever",
          "Dachshund",
          "Dachshund - Long-Haired",
          "Dachshund - Miniature Long Haired",
          "Dachshund - Smooth Haired",
          "Dachshund - Miniature Smooth haired",
          "Dachshund - Wire Haired",
          "Dachshund - Miniature Wire Haired",
          "Dalmatian",
          "Dandie Dinmont Terrier",
          "Doberman Pinscher",
          "Dogo Argentino",
          "English Bulldog",
          "English Foxhound",
          "English Pointer",
          "English Setter",
          "English Toy Spaniel",
          "Entlebucher Mountain Dog",
          "Field Spaniel",
          "Fila Brasileiro",
          "Finnish Lapphund",
          "Finnish Spitz",
          "Flat Coated Retriever",
          "French Bulldog",
          "German Pinscher",
          "German Shepherd",
          "German Shorthaired Pointer",
          "German Wirehaired Pointer",
          "Giant Schnauzer",
          "Glen of Imaal Terrier",
          "Golden Retriever",
          "Goldendoodle",
          "Gordon Setter",
          "Great Dane",
          "Great Pyrenees",
          "Greater Swiss Mountain Dog",
          "Greyhound",
          "Harrier",
          "Havana Silk Dog",
          "Havanese",
          "Ibizan Hound",
          "Icelandic Sheepdog",
          "Irish Setter",
          "Irish Terrier",
          "Irish Water Spaniel",
          "Irish Wolfhound",
          "Italian Greyhound",
          "Jack Chi",
          "Jack Russell Terrier",
          "Japanese Chin",
          "Keeshond",
          "Kerry Blue Terrier",
          "Komondor",
          "Kuvasz",
          "Labradoodle",
          "Labrador Retriever",
          "Lakeland Terrier",
          "Lhasa Apso",
          "Lowchen",
          "Maltese",
          "Manchester Terrier",
          "Mastiff",
          "Miniature Bull Terrier",
          "Miniature Pinscher",
          "Miniature Schnauzer",
          "NAID Breed",
          "Neapolitan Mastiff",
          "Newfoundland",
          "Norfolk Terrier",
          "Norwegian Buhund",
          "Norwegian Elkhound",
          "Norwegian Lundehund",
          "Norwich Terrier",
          "Nova Scotia Duck Tolling",
          "Old English Sheepdog",
          "Other",
          "Otterhound",
          "Papillon",
          "Parson Russell Terrier",
          "Pekingese",
          "Pembroke Welsh Corgi",
          "Peruvian Inca Orchid",
          "Petit Basset Griffon Vendéen",
          "Pharaoh Hound",
          "Plott Hound",
          "Polish Lowland",
          "Pomeranian",
          "Poodle - Standard",
          "Poodle - Toy",
          "Portuguese Podengo Pequeno ",
          "Portuguese Water Dog",
          "Pug",
          "Puli",
          "Pumi",
          "Pyrenean Shepherd",
          "Rat Terrier",
          "Redbone Coonhound",
          "Rhodesian Ridgeback",
          "Rottweiler",
          "Saint Bernard",
          "Saluki",
          "Samoyed",
          "Schipperke",
          "Scottish Deerhound",
          "Scottish Terrier",
          "Sealyham Terrier",
          "Shetland Sheepdog",
          "Shiba Inu",
          "Shih Tzu",
          "Siberian Husky",
          "Silky Terrier",
          "Skye Terrier",
          "Sloughi",
          "Smooth Fox Terrier",
          "Soft Coated Wheaten Terrier",
          "Spinone Italiano",
          "Springer Spaniel - English",
          "Staffordshire Bull Terrier",
          "Standard Schnauzer",
          "Sussex Spaniel",
          "Swedish Vallhund",
          "Tibetan Mastiff",
          "Tibetan Spaniel",
          "Tibetan Terrier",
          "Toy Fox Terrier",
          "Treeing Walker Coonhound",
          "Unkown",
          "Vizsla",
          "Weimaraner",
          "Welsh Springer Spaniel",
          "Welsh Terrier",
          "West Highland White Terrier",
          "Whippet",
          "Wire Fox Terrier",
          "Wirehaired Pointing Griffon",
          "Wirehaired Vizsla",
          "Xoloitzcuintli",
          "Yorkshire Terrier",
        ];
      } else if (this.form.type === "Tortoise") {
        this.petBreeds = ["Hermann",
          "Ibera",
          "Whitei",
          "Marginated",
          "Horsefield",
          "Leopard",
          "Sulcata",
          "Other MST",
          "Other Tropical",
          "Unknown"];
      } else if (this.form.type == "Cat") {
        this.petBreeds = [
          "Unknown",
          "Crossbreed",
          "Abyssinian",
          "Aegean",
          "American Bobtail",
          "American Curl",
          "American Shorthair",
          "American Wirehair",
          "Aphrodite Giant",
          "Arabian Mau",
          "Asian",
          "Asian Semi-longhair",
          "Australian Mist",
          "Balinese",
          "Bambino",
          "Bengal",
          "Birman",
          "Bombay",
          "Brazilian Shorthair",
          "British Longhair",
          "British Semi-longhair",
          "British Shorthair",
          "Burmese",
          "Burmilla",
          "California Spangled",
          "Chantilly-Tiffany",
          "Chartreux",
          "Chausie",
          "Cheetoh",
          "Colorpoint Longhair",
          "Colorpoint Persian",
          "Colorpoint Shorthair",
          "Cornish Rex",
          "Cymric, or",
          "Cyprus",
          "Devon Rex",
          "Don Sphynx",
          "Donskoy",
          "Dragon Li",
          "Dwelf",
          "Egyptian Mau",
          "European Shorthair",
          "Exotic Shorthair",
          "Foldex",
          "Foreign Longhair",
          "German Rex",
          "Havana Brown",
          "Highlander",
          "Himalayan, or",
          "Japanese Bobtail",
          "Javanese, or",
          "Karelian Bobtail",
          "Khao Manee",
          "Korat",
          "Korean Bobtail",
          "Korn Ja",
          "Kuril Islands Bobtail",
          "Kurilian Bobtail",
          "LaPerm",
          "Longhaired Manx",
          "Lykoi",
          "Maine Coon",
          "Mandarin",
          "Manx",
          "Manx Longhair",
          "Mekong Bobtail",
          "Minskin",
          "Munchkin",
          "Napoleon",
          "Nebelung",
          "Norwegian Forest cat",
          "Ocicat",
          "Ojos Azules",
          "Oregon Rex",
          "Oriental Bicolor",
          "Oriental Longhair",
          "Oriental Shorthair",
          "Other",
          "Persian (modern)",
          "Persian (traditional)",
          "Peterbald",
          "Pixie-bob",
          "Raas",
          "Ragamuffin, or",
          "Ragdoll",
          "Russian Black",
          "Russian Blue",
          "Russian Tabby",
          "Russian White",
          "Sam Sawet",
          "Savannah",
          "Scottish Fold",
          "Selkirk Rex",
          "Serengeti",
          "Serrade petit",
          "Siamese",
          "Siamese (modern)",
          "Siberian",
          "Siberian Forest Cat",
          "Singapura",
          "Snowshoe",
          "Sokoke",
          "Somali",
          "Sphynx",
          "Suphalak",
          "Thai Lilac",
          "Thai, or",
          "Tonkinese",
          "Toyger",
          "Turkish Angora",
          "Turkish Van",
          "Ukrainian Levkoy",
          "Unknown",
          "Wichien Maat",
          "Wila Krungthep",
          "York Chocolate",
        ];
      } else if (this.form.type == "Lizard") {
        this.petBreeds = [
          "Unknown",
          "Chinese water dragon",
          "Leopard gecko",
          "Tokay gecko",
          "Bearded Dragon",
          "Crested gecko",
          "Monitor lizards",
          "Blue tongued skink",
          "Frilled dragon",
          "Uromastyx",
          "Veiled chameleon",
        ];
      } else if (this.form.type == "Bird") {
        this.petBreeds = [
          "Unknown",
          "Budgerigars",
          "Caiques",
          "Canaries",
          "Cockatiels",
          "Cockatoos",
          "Finches",
          "Grey Parrots",
          "Macaws",
        ];
      }
    },
  },
};
</script>

<style scoped>
.d-btn {
  width: 10rem;
}
</style>