<template>
  <select
    @input="$emit('input', $event.target.value)"
    class="tw-bg-mp-off-white tw-w-16 tw-mr-4 tw-rounded-md tw-h-10 tw-bg-white"
    :class="[errorClass]"
  >
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      v-text="option.display"
      :selected="option.value === value"
    ></option>
  </select>
</template>

<script>
export default {
  props: {
    value: null,
  },
  data() {
    return {
      errorClass: {
        type: String,
        default: "",
      },
      options: [
        {
          value: "GB",
          display: "🇬🇧",
        },
        {
          value: "IE",
          display: "🇮🇪",
        },
      ],
    };
  },
};
</script>

<style scoped>
select {
  -webkit-appearance: listbox !important;
}
</style>