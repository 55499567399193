<template>
  <div class="tw-mt-8">
    <h1 class="tw-font-semibold">Premium support</h1>
    <div class="tw-text-sm tw-mt-2 tw-text-center tw-py-4 tw-px-4"></div>
    <div
      class="hubspot-container tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md"
    >
      <div id="hubspotForm" class=""></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // Load HubSpot form script
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    document.head.appendChild(script);

    // Create the form
    script.onload = () => {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "4919543",
        formId: "ce05ab9a-1685-4cc0-b80a-39dbc55c962d",
        target: "#hubspotForm",
      });
    };
  },
};
</script>

<style scoped></style>