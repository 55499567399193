<template>
  <div class="tw-relative" @click="showDatePicker">
    <label class="tw-block tw-cursor-pointer">
      <span class="tw-float-left tw-text-black">{{ label }}</span>
      <input :placeholder="placeholder"
             type="date"
             :required="required"
             :value="value"
             class="tw-form-input peer

                      tw-rounded-full
                      tw-bg-mp-off-white
                      tw-mt-1
                      tw-border
                      tw-text-mp-charcoal
                      tw-block
                      tw-w-full
                      tw-rounded-md
                      tw-shadow-sm
                      focus:tw-ring
                      focus:tw-ring-mp-blue
                      focus:tw-ring-opacity-50"
             :class="[hasError ? 'tw-border-red-500' : '', invalidClass, highlightClass]"
             @input="$emit('input', $event.target.value)"
             @keyup.up="$emit('up', $event.target.value)"
             @keyup.down="$emit('down', $event.target.value)"
             @keyup.enter="$emit('enter', $event.target.value)"
      />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    placeholder: String,
    type: {
      type: String,
      default: 'text',
    },
    invalidClass: {
      type: String,
      default: '',
    },
    highlightClass: {
      type: String,
      default: '',
    },
    required: false,
    value: null,
  },
  computed: {
    hasError() {
      return this.required && (this.value != null) && this.value.length === 0;
    }
  },
  methods: {
    showDatePicker() {
      const datepicker = this.$refs.datepicker;
      if (datepicker) {
        datepicker.showCalendar();
      }
    },
  },
}
</script>

<style scoped>
@keyframes highlight-red {
  0% {
    transform: scale(1);
  }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translate(-1px, 1px);
  }
  10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translate(1px, -1px);
  }
  50% {
    background-color: #ef4444;
    color: white;
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}

.animate-highlight-red {
  animation-name: highlight-red;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

</style>