<template>
  <v-progress-circular
    v-if="loading"
    :size="70"
    :width="7"
    color="#0074b5"
    indeterminate
  ></v-progress-circular>
  <v-card
    style="padding-bottom: 20px; margin-top: 30px"
    class="mx-auto blue-bkg white-text"
    max-width="400"
    outlined
    v-else
  >
    <img src="@/assets/logo-white.svg" class="reg-logo ml-4 mb-4" />
    <v-card-title
      style="display: block; text-align: center; word-break: break-word"
    >
      You will receive an email with your registration certificate!
    </v-card-title>

    <v-card-subtitle
      class="white-text"
      style="font-size: 16px; padding-top: 10px"
    >
      {{ message }}
    </v-card-subtitle>
    <v-card-actions style="justify-content: center">
      <v-btn
        color="#00b53f"
        class="cap white--text"
        primary
        @click="continueToAccount"
        >Continue to account
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    pets: Array,
  },
  computed: {
    message() {
      if (this.subscriptionType === "premium") {
        return "Congrats! Your pet(s) are now registered on our Defra compliant database.";
      }

      if (this.subscriptionType === "basic") {
        if (this.pets.length > 1) {
          return "Congrats! Your pets are now registered on our Defra compliant database.";
        }

        return (
          "Congrats! " +
          this.pets[0].name +
          " is now registered on our Defra compliant database."
        );
      }
    },
  },
  data() {
    return {
      loading: false,
      success: false,
      subscriptionType: null,
    };
  },
  methods: {
    async completePurchase() {
      this.loading = false;

      const user = await this.retrieveUser();

      await this.$store.commit("update_cart", user);
    },
    async continueToAccount() {
      await this.$router.push({ name: "Dashboard" });
    },
  },
  async beforeMount() {
    this.subscriptionType = await this.getSubscriptionType();
  },
  async mounted() {
    await this.completePurchase();
  },
};
</script>

<style scoped></style>