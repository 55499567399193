import Model from "./Model";

export default class AdditionalContact extends Model {
  static async create(form) {
    const response = await window.Http.post("/api/v2/vets", form);
    return this.make(response.data);
  }

  static async update(uuid, form) {
    const response = await window.Http.patch("/api/v2/vets/" + uuid, form);
    return this.make(response.data);
  }

  static async list(form) {
    const response = await window.Http.get("/api/v2/vets", form);
    return this.make(response.data);
  }

  static async search(query) {
    if (!query || query.length < 3) return;
    const response = await window.Http.get(
      "/api/v2/vets-search?query=" + query
    );
    return this.make(response.data);
  }

  static async retrieve(id) {
    const response = await window.Http.get("/api/v2/vets/" + id);
    return this.make(response.data);
  }
}