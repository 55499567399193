<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="9">
        <v-card width="600" class="mx-auto mt-5">
          <v-toolbar color="blue-bkg" dark flat>
            <v-toolbar-title>Change password</v-toolbar-title>
          </v-toolbar>
          <v-form method="post" v-model="valid" class="pa-4">
            <p>Please set a new password</p>
            <v-text-field
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              v-model="form.password"
              counter
              :rules="[rules.required, rules.min]"
              @click:append="showPassword = !showPassword"
            />
            <v-text-field
              :type="showPassword ? 'text' : 'password'"
              label="Confirm password"
              prepend-icon="mdi-lock"
              counter
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              v-model="form.password_confirmation"
              :rules="[rules.required, rules.min, passwordConfirmationRule]"
              @click:append="showPassword = !showPassword"
            />
            <v-btn
              @click="form.submit()"
              class="cap white--text blue-bkg mb-2"
              :disabled="!valid"
              >Submit
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      color="green darken-1"
      v-model="snackbar"
      :timeout="timeout"
      top
    >
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import User from "../models/User";

export default {
  props: {
    email: "",
    mobile: "",
    token: "",
  },
  data() {
    const data = {
      password: "",
      password_confirmation: "",
    };

    return {
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      showPassword: false,
      snackbar: false,
      snackbar_text: "",
      timeout: 3000,
      form: new window.Form(data, async (form) => {
        this.form.token = this.token;

        if (this.$route.query.email) {
          this.form.email = this.$route.query.email;
        }
        if (this.$route.query.mobile) {
          this.form.mobile = this.$route.query.mobile.trim();
        }

        const token = await User.updatePassword(form);

        this.snackbar_text = "Password reset successfully!";
        this.snackbar = true;

        await new Promise((resolve) => setTimeout(resolve, 3000));

        this.$router.push({
          name: "AuthPostRedirect",
          query: { action: "login-from-forgot-password", token },
        });
      }),
      valid: false,
    };
  },
  computed: {
    passwordConfirmationRule() {
      return (
        this.form.password === this.form.password_confirmation ||
        "Password must match"
      );
    },
  },
};
</script>
