<template>
    <div class="not-found">
        <h1>(404) Page NotFound</h1>
    </div>
</template>

<script>
    export default {
        name: "NotFoundComponent"
    }
</script>

<style scoped>

</style>
