<template>
  <div>
    <back-button></back-button>
    <div class="vet-page tw-w-full tw-mt-8">
      <h1 v-if="!vetsEmpty" class="tw-font-semibold">Your vet</h1>
      <div
        v-if="!vetsEmpty"
        class="tw-text-sm tw-mt-2 tw-text-center tw-py-4 tw-px-4"
      >
        We display your vet's information when your microchip is searched, to
        help reunite you with your pet should they ever go missing.
      </div>
      <div v-if="vetsEmpty" class="tw-flex tw-flex-col tw-justify-center">
        <div class="tw-font-semibold">
          You don't seem to have added a vet yet.
        </div>
        <div class="tw-text-sm tw-mt-2 tw-text-center tw-py-4 tw-px-4">
          We display your vet information when your microchip is searched, to
          help reunite you with your pet should they ever go missing.
        </div>
      </div>
      <button
        class="tw-bg-mp-blue tw-rounded tw-text-white tw-mt-8 tw-p-2 tw-shadow"
        @click="
          $router.push({
            name: 'VetAdd',
          })
        "
      >
        Add vet
      </button>
      <div
        class="vets-contents tw-flex tw-justify-center tw-items-center tw-w-full tw-mt-8"
      >
        <div class="tw-w-2/3">
          <div v-for="vet in vets" :key="vet.uuid">
            <vet-card class="tw-mb-2 tw-mt-8" :vet="vet"></vet-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdditionalContactCard from "@/components/shared/AdditionalContactCard.vue";
import AdditionalContactAdd from "@/views/AdditionalContactAdd.vue";
import AddressLookup from "@/components/shared/AddressLookup.vue";
import VetLookup from "@/components/shared/VetLookup.vue";
import BackButton from "@/components/shared/BackButton.vue";
import Vet from "@/models/Vet";
import VetCard from "@/components/shared/VetCard.vue";

export default {
  components: {
    VetCard,
    BackButton,
    VetLookup,
    AddressLookup,
    AdditionalContactAdd,
    AdditionalContactCard,
  },
  data() {
    return {
      loading: true,
      vetsEmpty: true,
      vets: [],
      vetDetails: {
        name: "",
        mobile: "",
        address_line_3: "",
        address_line_4: "",
        postcode: "",
        city: "",
      },
    };
  },
  async mounted() {
    this.vets = await Vet.list();
    this.vetsEmpty = this.vets.length === 0;
  },
};
</script>

<style scoped></style>