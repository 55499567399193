import Vue from "vue";
import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
  debug: false,
});

Vue.prototype.$mixpanel = mixpanel;

export default {
  install: function (Vue) {
    Vue.prototype.$mixpanel = mixpanel;
  },
};
