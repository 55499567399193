<template>
  <transition name="modal-fade">
    <div class="tw-fixed tw-z-50 tw-inset-0 tw-overflow-y-auto tw-m-auto">
      <div
        class="tw-flex tw-items-center tw-justify-center tw-min-h-screen tw-pt-4 tw-px-8 tw-pb-20 tw-text-center tw-sm:block tw-sm:p-0"
      >
        <div
          aria-hidden="true"
          class="tw-fixed tw-inset-0 tw-transition-opacity"
        >
          <div
            class="tw-absolute tw-inset-0 tw-bg-gray-500 tw-opacity-75"
          ></div>
        </div>

        <div
          class="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center"
        >
          <div
            class="upgrade-modal tw-bg-white tw-border-2 tw-border-mp-blue tw-rounded tw-relative tw-mx-auto"
            style="width: 80%; max-width: 600px; transform: translateY(-20px)"
          >
            <div class="upgrade-modal-main">
              <div
                class="upgrade-modal-main-header blue-bkg tw-h-12 tw-flex tw-items-center tw-justify-center tw-relative"
              >
                <div class="md:tw-text-2xl tw-text-center tw-px-4">
                  This is only available on MyPet Premium
                </div>
                <svg
                  v-if="!smallScreen"
                  fill="currentColor"
                  class="tw-w-8 tw-h-8 tw-cursor-pointer tw-absolute tw-top-2 tw-right-2"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="close"
                >
                  <path
                    clip-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    fill-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                class="upgrade-modal-main-body tw-mt-4 tw-ml-4 tw-mr-4 tw-flex tw-flex-col tw-justify-center tw-items-center"
              >
                <div class="upgrade-modal-main-body-text tw-text-center">
                  This feature is only available as part of MyPet Premium! You
                  can upgrade by clicking the button below! 😊
                </div>
                <br />
                <div
                  class="tw-border tw-rounded tw-bg-mp-green tw-text-white tw-p-2 tw-cursor-pointer tw-w-1/3"
                  @click="upgrade"
                >
                  Upgrade
                </div>
                <div
                  class="tw-border tw-rounded tw-bg-mp-grey tw-text-white tw-p-2 tw-cursor-pointer tw-w-1/3 tw-mt-2"
                  @click="close"
                >
                  Close
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      smallScreen: true,
    };
  },
  methods: {
    upgrade() {
      this.$emit("upgrade");
    },
    close() {
      this.$emit("close");
    },
    checkScreenSize() {
      this.smallScreen = window.innerWidth < 640;
    },
  },
  mounted() {
    this.checkScreenSize();
  },
};
</script>

<style scoped>
.upgrade-modal-main-header {
  position: relative;
}
</style>