import Organisation from "./Organisation";

export default class OrganisationRescue extends Organisation {
  static async transferPetToRescue(user_id, data) {
    const response = await window.Http.post(
      "/api/v1/rescue/request-transfer",
      data
    );
    // return this.make(response.data[0]);
  }

  static async getAll() {
    const response = await window.Http.get("/api/v1/rescues");

    return this.make(response);
  }
}
