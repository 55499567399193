<template>
  <div>Hi!</div>
</template>

<script>
export default {
  props: {
    token: String,
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    async authenticate() {
      await window.Http.setAuthorizationToken(this.token);
      const user = await this.retrieveUser();
      const t = this.token;

      await this.$store.commit("auth_success", { t, user });

      return user;
    },
  },
  async mounted() {
    if (this.token) {
      const user = await this.authenticate();

      if (user) {
        window.location = "/";
      } else {
        window.location = "/register-details";
      }
    }
  },
};
</script>
