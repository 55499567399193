<template>
  <v-progress-circular
    v-if="loading"
    :size="70"
    :width="7"
    color="#0074b5"
    indeterminate
  ></v-progress-circular>
  <rehome-pet-form v-else :pet="pet" :rescues="rescues"></rehome-pet-form>
</template>

<script>
import Pet from "../models/Pet";
import RehomePetForm from "../components/RehomePetForm";
import OrganisationRescue from "../models/OrganisationRescue";

export default {
  components: { RehomePetForm },
  props: {
    id: "",
  },
  data() {
    return {
      loading: true,
      pet: "",
      rescues: [],
    };
  },
  async mounted() {
    this.getPet();
    this.getRescues();
  },
  methods: {
    async getPet() {
      try {
        this.pet = await Pet.retrieve(this.id);
      } finally {
        this.loading = false;
      }
    },
    async getRescues() {
      try {
        this.rescues = await OrganisationRescue.getAll(this.id);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
