<template>
  <v-card
      style="padding-bottom: 20px; margin-top: 30px"
      class="mx-auto blue-bkg white-text pa-4 tw-items-center tw-justify-center"
      max-width="400"
      outlined
  >
    <img src="@/assets/logo-white.svg" class="reg-logo ml-4 mb-4"/>

    <v-card-title
        style="display: block; text-align: center; word-break: break-word"
        class="mt-2 mb-2"
        v-if="title"
    >
      {{ title }}
    </v-card-title>

    <v-card-subtitle
        class="white-text"
        style="font-size: 16px; padding-top: 10px"
    >
      {{ message }}
      <a v-if="clickHere && clickHere.length > 0" class="green-text tw-text-lg" :href="clickHereLink">HERE.</a>
    </v-card-subtitle>
    <v-card-actions v-if="link" class="mt-2" style="justify-content: center">
      <v-btn class="grey-bkg" primary to="/">{{ link }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    title: String,
    message: String,
    link: String,
    clickHere: String,
  },
  data() {
    return {
      clickHereLink: '',
    };
  },
  beforeMount() {
    this.clickHereLink = 'https://mypethq.io/' + this.clickHere;
  }
};
</script>
