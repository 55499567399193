<template>
  <transition name="modal-fade">
    <div
      class="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto tw-w-2/3"
      style="width: 800px; margin: 0 auto"
    >
      <div
        class="
          tw-flex
          tw-items-center
          tw-justify-center
          tw-min-h-screen
          tw-pt-4
          tw-px-4
          tw-pb-20
          tw-text-center
          tw-sm:block
          tw-sm:p-0
        "
      >
        <div
          class="tw-fixed tw-inset-0 tw-transition-opacity"
          aria-hidden="true"
        >
          <div
            class="tw-absolute tw-inset-0 tw-bg-gray-500 tw-opacity-75"
          ></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="tw-hidden tw-sm:inline-block tw-sm:align-middle tw-sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="
            tw-inline-block
            tw-align-bottom
            tw-bg-white
            tw-rounded-lg
            tw-text-left
            tw-overflow-hidden
            tw-shadow-xl
            tw-transform
            tw-transition-all
            tw-sm:my-8
            tw-sm:align-middle
            tw-sm:max-w-lg
            tw-sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 tw-sm:p-6 tw-sm:pb-4">
            <div class="tw-sm:flex tw-sm:items-start">
              <div
                class="
                  tw-mx-auto
                  tw-flex-shrink-0
                  tw-flex
                  tw-items-center
                  tw-justify-center
                  tw-h-12
                  tw-w-12
                  tw-rounded-full
                  tw-bg-blue-100
                  tw-sm:mx-0
                  tw-sm:h-12
                  tw-sm:w-12
                "
              >
                <!-- Heroicon name: outline/exclamation -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z"
                  />
                </svg>
              </div>
              <div
                class="
                  tw-mt-3 tw-text-center tw-sm:mt-0 tw-sm:ml-4 tw-sm:text-left
                "
              >
                <h3
                  class="
                    tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900
                  "
                  id="modal-headline"
                >
                  {{ modalHeadline }}
                </h3>
                <div class="tw-mt-2">
                  <p class="tw-text-sm tw-text-gray-500">
                    {{ message }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              tw-text-center
              tw-bg-white
              tw-py-3
              tw-sm:px-6
              tw-sm:flex
              tw-sm:justify-self-auto
            "
          >
            <button
              type="button"
              @click="confirm"
              class="
                tw-w-2/6
                tw-inline-flex
                tw-justify-center
                tw-rounded-md
                tw-border
                tw-border-transparent
                tw-shadow-sm
                tw-px-4
                tw-py-2
                tw-bg-mp-green
                tw-text-base
                tw-font-medium
                tw-text-white
                tw-hover:bg-red-700
                tw-focus:outline-none
                tw-focus:ring-2
                tw-focus:ring-offset-2
                tw-focus:ring-red-500
                tw-sm:ml-3
                tw-sm:w-auto
                tw-sm:text-sm
                tw-mr-2
              "
            >
              Yes
            </button>
            <button
              type="button"
              @click="close"
              class="
                tw-mt-3
                tw-w-2/6
                tw-inline-flex
                tw-justify-center
                tw-rounded-md
                tw-border
                tw-border-gray-300
                tw-shadow-sm
                tw-px-4
                tw-py-2
                tw-bg-mp-red
                tw-text-base
                tw-font-medium
                tw-text-white
                tw-hover:bg-gray-50
                tw-focus:outline-none
                tw-focus:ring-2
                tw-focus:ring-offset-2
                tw-focus:ring-indigo-500
                tw-sm:mt-0
                tw-sm:ml-3
                tw-sm:w-auto
                tw-sm:text-sm
                tw-ml-2
              "
            >
              No
            </button>
          </div>
          <div
            v-if="checkboxText"
            class="
              tw-text-center
              tw-bg-white
              tw-sm:flex
              tw-sm:justify-self-auto
              tw-pa-4
            "
          >
            <label class="inline-flex items-center mt-3">
              <input
                v-model="checked"
                type="checkbox"
                class="form-checkbox h-5 w-5 text-gray-600"
                checked
              /><span class="ml-2 mr-2 text-gray-700">{{ checkboxText }}</span>
            </label>
          </div>
          <div
            class="tw-mt-3 tw-text-center tw-sm:mt-0 tw-sm:ml-4 tw-sm:text-left"
          >
            <div class="tw-mt-2 ml-2">
              <p class="tw-text-sm tw-text-gray-500 ml-4 mr-4">
                {{ subScript }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "modal",
  props: {
    modalHeadline: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    subScript: {
      type: String,
      required: true,
    },
    checkboxText: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("transferPetEvent", this.checked);
    },
  },
};
</script>