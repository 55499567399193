import Account from "@/views/Account.vue";
import Dashboard from "@/views/Dashboard.vue";
import AdditionalContactsHome from "@/views/AdditionalContactsHome.vue";
import VetsHome from "@/views/VetsHome.vue";
import AdditionalContact from "@/views/AdditionalContact.vue";
import Vet from "@/views/Vet.vue";
import AdditionalContactAdd from "@/views/AdditionalContactAdd.vue";
import VetAdd from "@/views/VetAdd.vue";
import AuthPostRedirect from "../views/AuthPostRedirect.vue";
import Redirect from "../views/Redirect.vue";
import RedirectDestination from "../views/RedirectDestination.vue";
import GenericErrorPage from "../views/GenericErrorPage.vue";
import Support from "../views/Support.vue";
import ConfirmDetails from "../views/ConfirmDetails.vue";
import Checkout from "../views/CheckoutPage.vue";
import CreatePassword from "../views/CreatePassword.vue";
import CheckoutSuccess from "../views/CheckoutSuccessPage.vue";
import SubscriptionCheckoutSuccess from "../views/SubscriptionCheckoutSuccessPage.vue";
import CheckoutSuccessFreeVersion from "../views/CheckoutSuccessPageFreeVersion.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import MailinglistUnsubscribe from "../views/MailinglistUnsubscribe.vue";
import MicrochipAuthorisedUserRegistration from "../views/MicrochipAuthorisedUserRegistration.vue";
import MicrochipRegistrationFailed from "../views/MicrochipRegistrationFailed.vue";
import MicrochipRegistrationContinue from "../views/MicrochipRegistrationContinue.vue";
import MicrochipAuthorisedSearch from "../views/MicrochipAuthorisedSearch.vue";
import MicrochipSearch from "../views/MicrochipSearch.vue";
import NotFoundComponent from "../components/NotFoundComponent.vue";
import PetCreate from "../views/PetCreate.vue";
import PetLost from "../views/PetLost.vue";
import RehomePet from "../views/RehomePet.vue";
import PetProfile from "../views/PetProfile.vue";
import PetTransferRegistration from "../views/PetTransferRegistration.vue";
import PetProfileMicrochipRegistration from "../views/PetProfileMicrochipRegistration.vue";
import PetRegistrationStaging from "../views/PetRegistrationStaging.vue";
import GenericMessageWrapper from "../views/GenericMessageWrapper.vue";
import PetTransferResult from "../views/PetTransferResult.vue";
import Settings from "../views/Settings.vue";
import UserPets from "../views/UserPets.vue";
import PasswordReset from "../views/PasswordReset.vue";
import PetsTable from "../views/PetsTable.vue";
import Profile from "../views/Profile.vue";
import UserLogin from "../views/Login.vue";
import UserRegister from "../views/UserRegister.vue";
import UserRegisterDetails from "../views/UserRegisterDetails.vue";
import SubscriptionPlansPage from "@/views/SubscriptionPlansPage.vue";
import SubscriptionCheckoutPage from "@/views/SubscriptionCheckoutPage.vue";
// import TwoFactorAuth from "@/views/TwoFactorAuth.vue";

export default [
    {
        path: "*",
        component: NotFoundComponent,
    },
    // { path: "/verify-2fa", component: TwoFactorAuth },
    {
        path: "/",
        redirect: "/dashboard",
    },
    {
        path: "/checkout",
        name: "Checkout",
        component: Checkout,
    },
    {
        path: "/confirm-details",
        name: "ConfirmDetails",
        component: ConfirmDetails,
        meta: {
            requiresAuth: true,
        },
        props: (route) => ({flow: route.query.flow}),
    },
    {
        path: "/create-password",
        name: "CreatePassword",
        component: CreatePassword,
        props: (route) => ({token: route.query.token, pet: route.query.pet}),
    },
    {
        path: "/subscription-checkout/success",
        name: "SubscriptionCheckoutSuccess",
        component: SubscriptionCheckoutSuccess,
    },
    {
        path: "/checkout/success",
        name: "CheckoutSuccess",
        component: CheckoutSuccess,
    },
    {
        path: "/checkout/complete",
        name: "CheckoutSuccessFreeVersion",
        component: CheckoutSuccessFreeVersion,
        props: true,
    },
    {
        path: "/create-pet",
        name: "PetCreate",
        component: PetCreate,
        props: (route) => ({microchip: route.query.microchip}),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
    },
    {
        path: "/login",
        name: "UserLogin",
        component: UserLogin
    },
    {
        path: "/mailinglist-unsubscribe",
        name: "MailinglistUnsubscribe",
        component: MailinglistUnsubscribe,
    },
    {
        path: "/account",
        name: "Account",
        component: Account,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            requiresAuth: true,
        },
        // props: (route) => ({
        //   uuid: route.params.uuid,
        // }),
    },
    {
        path: "/additional-contacts",
        name: "AdditionalContactsHome",
        component: AdditionalContactsHome,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/vets",
        name: "VetsHome",
        component: VetsHome,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/additional-contact/:uuid",
        name: "AdditionalContact",
        component: AdditionalContact,
        meta: {
            requiresAuth: true,
        },
        props: (route) => ({
            uuid: route.params.uuid,
        }),
    },
    {
        path: "/vet/:uuid",
        name: "Vet",
        component: Vet,
        meta: {
            requiresAuth: true,
        },
        props: (route) => ({
            uuid: route.params.uuid,
        }),
    },
    {
        path: "/add-additional-contact",
        name: "AdditionalContactAdd",
        component: AdditionalContactAdd,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/add-vet",
        name: "VetAdd",
        component: VetAdd,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/microchip-authorised-registration",
        name: "MicrochipAuthorisedUserRegistration",
        component: MicrochipAuthorisedUserRegistration,
    },
    {
        path: "/microchip-authorised-search",
        name: "MicrochipAuthorisedSearch",
        component: MicrochipAuthorisedSearch,
        props: (route) => ({microchip: route.query.microchip}),
    },
    {
        path: "/microchip-search",
        name: "MicrochipSearch",
        component: MicrochipSearch,
        props: (route) => ({microchip: route.query.microchip}),
    },
    {
        path: "/microchip-registration-continue",
        name: "MicrochipRegistrationContinue",
        component: MicrochipRegistrationContinue,
        props: (route) => ({token: route.query.token}),
    },
    {
        path: "/microchip-registration-failed",
        name: "MicrochipRegistrationFailed",
        component: MicrochipRegistrationFailed,
    },
    {
        path: "/reset-password",
        name: "PasswordReset",
        component: PasswordReset,
        props: (route) => ({
            token: route.query.token,
            email: route.query.email,
            mobile: route.query.mobile,
        }),
    },
    {
        path: "/pet-lost/:id",
        name: "PetLost",
        component: PetLost,
        meta: {
            requiresAuth: true,
        },
        props: (route) => ({id: route.params.id}),
    },
    {
        path: "/pet-profile/:id",
        name: "PetProfile",
        component: PetProfile,
        meta: {
            requiresAuth: true,
        },
        props: (route) => ({
            id: route.params.id,
            action: route.query.action,
            microchip: route.query.microchip,
        }),
    },
    {
        path: "/pet-transfer-registration/:id",
        name: "PetTransferRegistration",
        component: PetTransferRegistration,
        meta: {
            requiresAuth: true,
        },
        props: (route) => ({
            id: route.params.id,
            action: route.query.action,
            pet: route.query.pet,
        }),
    },
    {
        path: "/pet-profile-microchip-registration/:id",
        name: "PetProfileMicrochipRegistration",
        component: PetProfileMicrochipRegistration,
        meta: {
            requiresAuth: true,
        },
        props: (route) => ({
            id: route.params.id,
            action: route.query.action,
            microchip: route.query.microchip,
        }),
    },
    {
        path: "/pet-transfer-result",
        name: "PetTransferResult",
        component: PetTransferResult,
    },
    {
        path: "/pet-registration-stage",
        name: "PetRegistrationStaging",
        component: PetRegistrationStaging,
        meta: {
            requiresAuth: true,
        },
        props: (route) => ({
            current_pet: route.query.current_pet,
            policyNumber: route.query.policyNumber,
        }),
    },
    {
        path: "/pets",
        name: "UserPets",
        component: UserPets,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/pets-table",
        name: "PetsTable",
        component: PetsTable,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/rehome/:id",
        name: "RehomePet",
        component: RehomePet,
        meta: {
            requiresAuth: true,
        },
        props: (route) => ({id: route.params.id}),
    },
    {
        path: "/register",
        name: "UserRegister",
        component: UserRegister,
    },
    {
        path: "/register-complete",
        name: "GenericMessageWrapper",
        component: GenericMessageWrapper,
        props: (route) => ({
            name: route.query.name,
            title: route.params.title,
            message: route.params.message,
            link: route.params.link,
            clickHere: route.params.clickHere,
            policyNumber: route.query.policyNumber,
        }),
    },
    {
        path: "/register-details",
        name: "UserRegisterDetails",
        component: UserRegisterDetails,
        props: (route) => ({user: route.query.user}),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/subscription-plans",
        name: "SubscriptionPlansPage",
        component: SubscriptionPlansPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/support",
        name: "Support",
        component: Support,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/subscription-checkout",
        name: "SubscriptionCheckoutPage",
        component: SubscriptionCheckoutPage,
        meta: {
            requiresAuth: true,
        },
        props: true,
    },
    {
        path: "/settings",
        name: "Settings",
        component: Settings,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/social-callback",
        name: "AuthPostRedirect",
        component: AuthPostRedirect,
        props: (route) => ({token: route.query.token}),
    },
    {
        path: "/redirect",
        name: "Redirect",
        component: Redirect,
        props: (route) => ({token: route.query.token}),
    },
    {
        path: "/redirect-destination",
        name: "RedirectDestination",
        component: RedirectDestination,
        props: (route) => ({destination: route.query.destination}),
    },
    {
        path: "/error",
        name: "GenericErrorPage",
        component: GenericErrorPage,
    },
];