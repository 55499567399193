<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="9" sm="8">
        <v-alert class="mx-auto" color="#0074b5" outlined type="info">
          Did you know it’s a legal obligation to keep your details up to date?
          Don’t worry we’ll send you an email every 6 months just to check in.
          <br/>
        </v-alert>
        <v-alert v-if="!form.errors.isEmpty()" outlined type="error"
        >Please check the form for errors
        </v-alert>
        <v-card v-if="!loading" class="mx-auto mt-5">
          <v-toolbar color="blue-bkg" dark flat>
            <v-toolbar-title>New account</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" method="post">
              <p class="tw-text-xl">Your details</p>
              <v-text-field
                  v-model="totalData.first_name"
                  :error="form.errors.get('first_name')"
                  label="First name"
                  required
                  @blur="checkFreeInsuranceEligible"
              ></v-text-field>
              <v-text-field
                  v-model="totalData.last_name"
                  :error="form.errors.get('last_name')"
                  label="Last name"
                  required
                  @blur="checkFreeInsuranceEligible"
              ></v-text-field>
              <div class="tw-flex tw-justify-start">
                <div>Mobile</div>
              </div>
              <div class="tw-flex tw-items-center">
                <flag-drop-down-deprecated
                    v-model="isoCountryCode"
                    @input="setIsoCountryCode"
                    class="tw-w-16 tw-mr-4 tw-mt-1 tw-rounded-md tw-h-10 tw-bg-white tw-border tw-border-solid tw-border-slate-500"
                ></flag-drop-down-deprecated>
                <base-input-v2
                    class="tw-w-full tw-text-black"
                    @input="handleMobileNumberInput"
                    required="true"
                    v-model="form.mobile"
                    type="tel"
                ></base-input-v2>
                <v-progress-circular
                    v-if="pinpointApiLoading"
                    :size="20"
                    :width="4"
                    color="#0074b5"
                    indeterminate
                ></v-progress-circular>
                <CheckCircleIcon
                    v-if="mobilePhoneNumberIsValid"
                    class="ml-1 w-8 h-8 tw-text-mp-green"
                >
                </CheckCircleIcon>
                <XCircleIcon
                    v-if="mobilePhoneNumberIsInvalid"
                    class="ml-1 w-8 h-8 tw-text-mp-red"
                >
                </XCircleIcon>
              </div>
              <v-text-field
                  v-model="totalData.phone"
                  :error="form.errors.get('phone')"
                  label="Telephone"
                  @blur="checkFreeInsuranceEligible"
              ></v-text-field>
              <v-row>
                <v-text-field
                    v-model="totalData.postcode"
                    class="ml-3 mt-3"
                    label="Enter postcode"
                    required
                ></v-text-field>
                <v-btn
                    class="cap ml-5 mt-5 mr-2 white--text"
                    color="#00b53f"
                    prepend-icon="mdi-map-marker"
                    @click="listAddresses()"
                >
                  Find address
                </v-btn>
              </v-row>
              <v-select
                  v-if="addresses.length > 0"
                  v-model="address"
                  :items="addresses"
                  item-text="line_1"
                  label="Address"
                  return-object
              ></v-select>
              <v-text-field
                  v-model="totalData.address_line_1"
                  :error="form.errors.get('address_line_1')"
                  label="Address line 1"
                  required
                  @blur="checkFreeInsuranceEligible"
              ></v-text-field>
              <v-text-field
                  v-model="totalData.address_line_2"
                  :error="form.errors.get('address_line_2')"
                  label="Address line 2"
                  required
                  @blur="checkFreeInsuranceEligible"
              ></v-text-field>
              <v-text-field
                  v-if="totalData.address_line_3"
                  v-model="totalData.address_line_3"
                  :error="form.errors.get('address_line_3')"
                  label="Address line 3"
                  required
              ></v-text-field>
              <v-text-field
                  v-if="totalData.address_line_4"
                  v-model="totalData.address_line_4"
                  :error="form.errors.get('address_line_4')"
                  label="Address line 4"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="totalData.city"
                  :error="form.errors.get('city')"
                  label="Town/City"
                  required
              ></v-text-field>
              <p class="mt-4 tw-text-lg">Your pet's details</p>
              <v-alert v-if="form.errors.get('microchip')" outlined type="error"
              >Microchip number must be unique. If your microchip number has
                been registered already please contact us at support@mypethq.io
              </v-alert>
              <v-text-field
                  v-model="totalData.microchip"
                  :error="form.errors.get('microchip')"
                  :rules="microchipRules"
                  label="Microchip number"
                  required
                  @blur="hasLiveAgriaPolicy"
              ></v-text-field>
              <v-text-field
                  v-model="totalData.name"
                  :error="form.errors.get('name')"
                  :rules="textFieldRules"
                  label="Pet name"
                  required
                  @blur="checkFreeInsuranceEligible"
              ></v-text-field>
              <v-select
                  v-model="pet_type"
                  :error="form.errors.get('type')"
                  :items="petTypes"
                  label="Type of pet"
                  @change="populateBreeds()"
              />
              <v-combobox
                  v-if="
                  pet_type === 'Dog' ||
                  pet_type === 'Cat' ||
                  pet_type === 'Lizard' ||
                  pet_type === 'Tortoise' ||
                  pet_type === 'Bird'
                "
                  v-model="form.breed"
                  :items="this.petBreeds"
                  clearable
                  label="Breed"
                  @update:search-input="onBreedChanged"
              ></v-combobox>
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="computedDateFormatted"
                      label="Birthday date"
                      prepend-icon="mdi-calendar-range"
                      readonly
                      :error="form.errors.get('dob')"
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    ref="picker"
                    v-model="date"
                    :max="new Date().toISOString().substr(0, 10)"
                    color="#0074b5"
                    min="1950-01-01"
                    @change="save"
                ></v-date-picker>
              </v-menu>
              <p>Sex</p>
              <v-btn-toggle
                  v-model="totalData.sex"
                  color="primary"
                  dense
                  @change="checkFreeInsuranceEligible"
              >
                <v-btn class="cap" value="male"> Male</v-btn>

                <v-btn class="cap" value="female"> Female</v-btn>

                <v-btn class="cap" value="unknown"> Unknown</v-btn>
              </v-btn-toggle>
              <v-text-field
                  v-model="totalData.colour"
                  label="Colour"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="totalData.distinguishing_features"
                  label="Distinguishing features"
                  required
              ></v-text-field>
              <p>Spayed / Neutered</p>
              <v-btn-toggle
                  v-model="form.neutered"
                  class="mb-4"
                  color="primary"
                  dense
              >
                <v-btn class="cap" value="1"> Yes</v-btn>

                <v-btn class="cap" value="0"> No</v-btn>

                <v-btn class="cap" value="unknown"> Unknown</v-btn>
              </v-btn-toggle>
              <v-spacer></v-spacer>
              <insurance-option
                  v-if="showFreeInsuranceOffer"
                  :checked="freeInsurance"
                  :crossbreedText="crossbreedText"
                  :name="totalData.name"
                  :pet="insurancePet"
                  class="tw-mt-4"
                  flow="rehome"
                  @input="freeInsurance = !freeInsurance"
                  @toggleHelp="toggleInsuranceHelp"
              ></insurance-option>
              <v-checkbox
                  v-model="subscribe"
                  class="mt-4"
                  label="Would you like to subscribe to emails; we will send you helpful hints, tips and offers to help you and your pet(s) and anything else we think you might find interesting?"
              ></v-checkbox>
              <v-checkbox
                  v-model="privacy_policy"
                  :error="computedPrivacyError"
                  :rules="[(v) => !!v || 'You must agree to use our service!']"
                  label="Do you agree to our privacy policy and terms of use?"
                  required
              ></v-checkbox>
              <a
                  class="mr-4 tw-text-mp-blue"
                  href="https://www.mypethq.io/privacy/"
                  target="_blank"
              >Privacy policy</a
              >
              <a
                  class="ml-4 tw-text-mp-blue"
                  href="https://www.mypethq.io/terms-of-service/"
                  target="_blank"
              >Terms of Service</a
              >
              <v-alert class="mt-4" color="#0074b5" type="info">
                You must fully complete all your personal details to comply with
                microchip regulations.
              </v-alert>
              <v-alert
                  v-if="
                  mobilePhoneNumberIsInvalid ||
                  form.errors.get('mobile') === 'mobile_not_unique'
                "
                  type="error"
                  outlined
              >
                Your profile has errors!
                <v-divider class="tw-mt-2"></v-divider>
                <div class="black--text text-subtitle-1 mt-4">
                  Please fix these and "update" your profile:
                </div>
                <div
                    v-if="mobilePhoneNumberIsInvalid"
                    class="black--text text-body-2 mt-2"
                >
                  Mobile number is invalid!
                </div>
                <div
                    v-if="form.errors.get('mobile') === 'mobile_not_unique'"
                    class="black--text text-body-2 mt-2"
                >
                  This mobile number is already associated with an account. If
                  you need help contact Support at
                  <a href="mailto:support@mypethq.io">support@mypethq.io</a>
                </div>
              </v-alert>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mob-block">
            <span class="edit-pet">
              <v-btn class="cap" color="grey-bkg" @click="$router.go(-1)"
              >Back</v-btn
              >
              <v-btn
                  :disabled="!valid"
                  class="cap"
                  color="blue-bkg"
                  style="margin-left: 5px"
                  type="submit"
                  @click="submit"
              >Register</v-btn
              >
            </span>
          </v-card-actions>
        </v-card>
        <v-progress-circular
            v-if="loading"
            :size="100"
            :width="8"
            class="mt-12"
            color="#0074b5"
            indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="green darken-1"
        top
    >
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar_text = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-row align="center" justify="center">
      <insurance-help-modal
          :modalHeadline="
          'Congratulations! ' + form.name + ' is eligible for FREE insurance.'
        "
          :visible="showInsuranceHelp"
          @close="toggleInsuranceHelp"
      >
      </insurance-help-modal>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import Pet from "../models/Pet";
import Swal from "sweetalert2";
import moment from "moment";
import Message from "../components/shared/Message";
import Agria from "@/models/Agria";
import InsuranceOption from "@/components/InsuranceOption";
import InsuranceHelpModal from "@/components/modals/InsuranceHelpModal";
import FlagDropdown from "../components/shared/FlagDropDown";
import BaseInputV2 from "@/components/shared/BaseInputV2";
import FormService from "@/services/FormService";
import {debounce} from "lodash";
import PhoneNumberService from "@/services/PhoneNumberService";
import {CheckCircleIcon, XCircleIcon} from "@vue-hero-icons/solid";
import FlagDropDownDeprecated from "@/components/shared/FlagDropDownDeprecated.vue";

export default {
  components: {
    FlagDropDownDeprecated,
    Message,
    InsuranceOption,
    InsuranceHelpModal,
    FlagDropdown,
    BaseInputV2,
    CheckCircleIcon,
    XCircleIcon,
  },
  props: {
    totalData: Object,
  },
  async mounted() {
    this.pet_type = this.totalData.type;
    this.form.breed = this.totalData.breed;

    await this.fixNeutered();
    await this.fixMcRegistered();
    await this.fixDOB();
  },
  data() {
    return {
      microchipRules: [
        (v) => !!v || "Microchip is required",
        (v) =>
            (v && (v.length == 10 || v.length == 15) && /^\d+$/.test(v)) ||
            "Microchip must be 10 or 15 numbers",
      ],
      data: "",
      user: Object,
      menu: false,
      loading: false,
      isoCountryCode: "GB",
      beforeChangeIsoCountryCode: "GB",
      isoCountryCodeMap: [
        {
          isoCountryCode: "GB",
          prefix: "+44",
        },
        {
          isoCountryCode: "IE",
          prefix: "+353",
        },
      ],
      breed: "",
      breeds: [],
      register_microchip: false,
      freeInsurance: false,
      crossbreedText: "",
      petTypes: [
        "Dog",
        "Cat",
        "Rabbit",
        "Hamster",
        "Horse",
        "Lizard",
        "Bird",
        "Tortoise",
        "Ferret",
        "Guinea pig",
        "Other",
        "Unknown",
      ],
      insurancePet: Object,
      address: "",
      addresses: [],
      petBreeds: [],
      pet_type: "",
      selectedBreed: "",
      hasLivePolicy: false,
      showInsuranceHelp: false,
      pinpointApiLoading: false,
      showFreeInsuranceOffer: false,
      mobilePhoneNumberIsValid: false,
      mobilePhoneNumberIsInvalid: false,
      subscribe: false,
      privacy_policy: false,
      textFieldRules: [(v) => !!v || "Required"],
      selectRules: [(v) => !!v || "Item is required"],
      date: "",
      modal: false,
      neutered: "false",

      valid: true,
      form: new window.Form(this.totalData, async (form) => {
        this.form.dob = moment(this.date).format("ddd DD MMM YYYY");

        this.form.mc_registered = this.register_microchip === true;

        if (this.form.neutered === "unknown") {
          this.form.neutered = null;
        }

        this.form.type = this.pet_type;
        this.form.name = this.totalData.name;
        this.form.first_name = this.totalData.first_name;
        this.form.last_name = this.totalData.last_name;
        this.form.address_line_1 = this.totalData.address_line_1;
        this.form.address_line_2 = this.totalData.address_line_2;
        this.form.address_line_3 = this.totalData.address_line_3;
        this.form.address_line_4 = this.totalData.address_line_4;
        this.form.city = this.totalData.city;
        this.form.sex = this.totalData.sex;
        this.form.phone = this.totalData.phone;
        this.form.colour = this.totalData.colour;
        this.form.distinguishing_features =
            this.totalData.distinguishing_features;
        this.form.breed = this.breed ? this.breed : "unknown";
        this.form.postcode = this.totalData.postcode;
        this.form.subscribe = this.subscribe;
        this.form.subscribe_all = this.subscribe;

        const createdPet = await Pet.registerDetails(this.form.id, this.form);

        this.scrollToTop();
        this.loading = true;
        await this.$router.push({
          name: "PetRegistrationStaging",
          query: {
            pet: createdPet.id,
            flow: "rehome",
            ...insuranceParams,
            ...errorParams,
          },
        });
      }),
      snackbar: false,
      snackbar_text: "",
      timeout: 2000,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.date ? moment(this.date).format("dddd, MMMM Do YYYY") : "";
    },
    computedPrivacyError() {
      return !this.privacy_policy;
    },
    toScroll: function () {
      return this.form.errors.get("dob");
    },
  },
  async beforeMount() {
    this.pet_type = this.totalData.type;
    await this.getUser();
    await this.setUserMobile();
    await this.populateBreeds();
    await this.hasLiveAgriaPolicy();
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    address: function () {
      if (this.address) {
        this.totalData.address_line_1 = this.address.line_1;
        this.totalData.address_line_2 = this.address.line_2;
        this.totalData.address_line_3 = this.address.line_3;
        this.totalData.address_line_4 = this.address.line_4;
        this.totalData.city = this.address.town_or_city;
        this.checkFreeInsuranceEligible();
      }
    },
    toScroll: function (newVal, oldVal) {
      if (newVal) {
        this.scrollToTop();
      }
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async getUser() {
      this.loading = true;
      try {
        this.user = await this.retrieveUser();
      } finally {
        this.loading = false;
      }
    },
    setIsoCountryCode(code) {
      const oldPrefix = this.isoCountryCodeMap.find(
          (i) => i.isoCountryCode === this.beforeChangeIsoCountryCode
      ).prefix;
      const suffix = this.form.mobile.slice(oldPrefix.length);
      this.isoCountryCode = code;
      this.beforeChangeIsoCountryCode = code;
      const newPrefix = this.isoCountryCodeMap.find(
          (i) => i.isoCountryCode === this.isoCountryCode
      ).prefix;
      this.form.mobile = newPrefix + suffix;
      this.setCountryMobilePhoneCode();
    },
    async setUserMobile() {
      if (this.user.mobile) {
        if (this.user.mobile.startsWith("+353")) {
          this.setIsoCountryCode("IE");
        }
      } else {
        await this.setCountryMobilePhoneCode();
      }
    },
    async resetMobileSettings() {
      this.pinpointApiLoading = false;
      this.mobilePhoneNumberIsValid = false;
      this.mobilePhoneNumberIsInvalid = false;
    },
    async focusMobileNumberInput(event) {
      FormService.setCursorPosition(event.target, this.form.mobile.length);
    },
    async handleMobileNumberInput() {
      await this.resetMobileSettings();
      await this.setCountryMobilePhoneCode();
      if (this.form.mobile && this.form.mobile.length < 8) {
        return;
      }
      this.pinpointApiLoading = true;
      this.mobileNumberInput();
    },
    mobileNumberInput: debounce(function () {
      this.debouncedMobileNumberValidation();
    }, 1600),
    async debouncedMobileNumberValidation() {
      const phoneNumberValidatedEntity =
          await PhoneNumberService.validatePhoneNumber(
              this.form.mobile,
              "mobile"
          );
      if (phoneNumberValidatedEntity.is_valid) {
        this.mobilePhoneNumberIsValid = true;
      } else {
        this.mobilePhoneNumberIsInvalid = true;
      }
      this.pinpointApiLoading = false;
    },
    async setCountryMobilePhoneCode() {
      const prefix = this.isoCountryCodeMap.find(
          (i) => i.isoCountryCode === this.isoCountryCode
      ).prefix;
      if (
          !this.form.mobile ||
          ["+44", "+353"].includes(this.form.mobile) ||
          this.details.mobile.length < prefix.length
      ) {
        this.form.mobile = prefix;
        this.mobilePhoneNumberIsInvalid = false;
      }

      if (this.form.mobile.charAt(prefix.length) === "0") {
        this.form.mobile =
            this.form.mobile.slice(0, prefix.length) +
            this.form.mobile.slice(prefix.length + 1, this.form.mobile.length);
      }

      let result = [...this.form.mobile];
      for (let i = 0; i < prefix.length; i++) {
        const c = prefix.charAt(i);
        const t = result[i];
        if (c !== t) {
          result.splice(i, 0, c);
        }
      }
      this.form.mobile = result.join("");
    },
    async listAddresses() {
      if (!this.totalData.postcode) return;
      const response = await axios.get(
          "https://api.getaddress.io/find/" +
          this.totalData.postcode +
          "?api-key=vb3__sSB4kaFuyPu5vWS7g18118&sort=true&expand=true"
      );

      this.addresses = response.data.addresses;
    },
    save(date) {
      this.form.errors.clear();
      this.$refs.menu.save(date);
      this.checkFreeInsuranceEligible();
    },
    submit() {
      this.form.submit();
    },
    onBreedChanged(currentValue) {
      this.breed = currentValue;
      // this.checkFreeInsuranceEligible();
    },
    async checkFreeInsuranceEligible() {
      this.showFreeInsuranceOffer = await this.freeInsuranceEligible();
    },
    async freeInsuranceEligible() {
      const pet = {
        ...this.form,
        ...{
          dob: this.date,
          name: this.totalData.name,
          type: this.pet_type,
          sex: this.totalData.sex,
          microchip: this.totalData.microchip,
          route: "vet",
          first_name: this.totalData.first_name,
          last_name: this.totalData.last_name,
          address_line_1: this.totalData.address_line_1,
          address_line_2: this.totalData.address_line_2,
          address_line_3: this.totalData.address_line_3,
          address_line_4: this.totalData.address_line_4,
          postcode: this.totalData.postcode,
          phone: this.totalData.phone,
        },
      };

      this.insurancePet = pet;

      const petHasPrerequisites = Agria.petHasPrerequisites(pet);

      if (!petHasPrerequisites) {
        return false;
      }

      if (!pet.first_name || !pet.last_name || !pet.postcode || !pet.phone) {
        return false;
      }

      if (this.showFreeInsuranceOffer) {
        return true;
      }

      if (petHasPrerequisites && !this.hasLivePolicy) {
        const response = await Agria.freeInsuranceCheck(pet);

        this.crossbreedText = response.CrossbreedText || "";

        return response.CanOfferInsurance;
      }

      return false;
    },
    toggleInsuranceHelp() {
      this.showInsuranceHelp = !this.showInsuranceHelp;
    },
    async hasLiveAgriaPolicy() {
      if (
          this.totalData.microchip &&
          (this.totalData.microchip.length == 10 ||
              this.totalData.microchip.length == 15)
      ) {
        this.hasLivePolicy = await Agria.livePolicyCheck(
            this.totalData.microchip
        );
      }
      await this.checkFreeInsuranceEligible();
    },
    async deletePet() {
      const result = await Swal.fire({
        title:
            "Are you sure? If you mark your pet as passed away, we will remove " +
            this.totalData.name +
            " from your profile completely.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm my pet has passed away",
      });

      if (!result.value) {
        return;
      }

      this.snackbar_text = "We have removed this pet from your account.";
      await this.totalData.delete();
      this.snackbar = true;

      this.redirect();
    },
    async deregisterPet() {
      const result = await Swal.fire({
        title:
            "Are you sure? If you deregister your pet, we will remove " +
            this.totalData.name +
            " from our microchip database.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Remove my pet from the mypet microchip database",
      });

      if (!result.value) {
        return;
      }

      this.snackbar_text =
          "We have removed this pet from our microchip database.";
      this.form.mc_registered = false;
      if (this.form.neutered === "unknown") {
        this.form.neutered = null;
      }
      await this.totalData.update(this.form);
      this.snackbar = true;

      this.redirect();
    },
    async microchipRegisterPet() {
      this.snackbar_text =
          "We have added " + this.totalData.name + "'s microchip to your cart!";
      if (this.form.neutered === "unknown") {
        this.form.neutered = null;
      }
      await this.totalData.update(this.form);
      this.snackbar = true;

      const user = await this.retrieveUser();

      await this.$store.commit("update_cart", user);

      this.redirectToStaging();
    },
    async fixDOB() {
      if (this.totalData.dob) {
        const date = new Date(this.totalData.dob);
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        const finaldate = new Date(date.getTime() - userTimezoneOffset);
        this.date = finaldate.toISOString().substr(0, 10);
      }
    },
    async fixMcRegistered() {
      this.register_microchip = this.totalData.mc_registered ? true : false;
    },
    async fixNeutered() {
      if (this.totalData.neutered === null) {
        this.form.neutered = "unknown";
      }

      if (this.totalData.neutered) {
        this.form.neutered = "1";
      }

      if (!this.totalData.neutered) {
        this.form.neutered = "0";
      }
    },
    async populateBreeds() {
      if (this.pet_type === "Dog") {
        this.petBreeds = [
          "Unknown",
          "Crossbreed",
          "Affenpinscher",
          "Afghan Hound",
          "Airedale Terrier",
          "Akita Inu",
          "Alaskan Malamute",
          "Am Staff ",
          "American Coonhound",
          "American Eskimo Dog",
          "American Foxhound",
          "American Pit Bull Terrier",
          "American Water Spaniel",
          "Anatolian Shepherd Dog",
          "Australian Cattle",
          "Australian Shepherd",
          "Australian Terrier",
          "Azawakh",
          "Basenji",
          "Basset Hound ",
          "Beagle",
          "Bearded Collie",
          "Beauceron",
          "Bedlington Terrier",
          "Belgian Laekenois",
          "Belgian Malinois",
          "Belgian Sheepdog",
          "Belgian Tervuren",
          "Bergamasco",
          "Bernese Mountain Dog",
          "Bichon Frisé",
          "Biewer Terrier",
          "Black & Tan Coonhound",
          "Black Russian Terrier",
          "Bloodhound",
          "Bluetick Coonhound",
          "Boerboel",
          "Border Collie",
          "Border Terrier",
          "Borzoi",
          "Boston Terrier",
          "Bouvier des Flandres",
          "Boxer",
          "Briard",
          "Brittany",
          "Brussels Griffon",
          "Bull Terrier",
          "Bulldog - American",
          "Bullmastiff",
          "Cairn Terrier ",
          "Canaan Dog",
          "Cane Corso",
          "Cardigan Welsh Corgi",
          "Carolina Dog",
          "Cavalier King Charles",
          "Cesky Terrier ",
          "Chesapeake Bay Retriever",
          "Chihuahua",
          "Chinese Crested",
          "Chinese Shar-Pei",
          "Chinook",
          "Chow Chow",
          "Cirneco dell'Etna ",
          "Clumber Spaniel",
          "Cocker Spaniel - American",
          "Cocker Spaniel - English",
          "Collie",
          "Curly-Coated Retriever",
          "Dachshund",
          "Dachshund - Long-Haired",
          "Dachshund - Miniature Long Haired",
          "Dachshund - Smooth Haired",
          "Dachshund - Miniature Smooth haired",
          "Dachshund - Wire Haired",
          "Dachshund - Miniature Wire Haired",
          "Dalmatian",
          "Dandie Dinmont Terrier",
          "Doberman Pinscher",
          "Dogo Argentino",
          "English Bulldog",
          "English Foxhound",
          "English Pointer",
          "English Setter",
          "English Toy Spaniel",
          "Entlebucher Mountain Dog",
          "Field Spaniel",
          "Fila Brasileiro",
          "Finnish Lapphund",
          "Finnish Spitz",
          "Flat Coated Retriever",
          "French Bulldog",
          "German Pinscher",
          "German Shepherd",
          "German Shorthaired Pointer",
          "German Wirehaired Pointer",
          "Giant Schnauzer",
          "Glen of Imaal Terrier",
          "Golden Retriever",
          "Goldendoodle",
          "Gordon Setter",
          "Great Dane",
          "Great Pyrenees",
          "Greater Swiss Mountain Dog",
          "Greyhound",
          "Harrier",
          "Havana Silk Dog",
          "Havanese",
          "Ibizan Hound",
          "Icelandic Sheepdog",
          "Irish Setter",
          "Irish Terrier",
          "Irish Water Spaniel",
          "Irish Wolfhound",
          "Italian Greyhound",
          "Jack Chi",
          "Jack Russell Terrier",
          "Japanese Chin",
          "Keeshond",
          "Kerry Blue Terrier",
          "Komondor",
          "Kuvasz",
          "Labradoodle",
          "Labrador Retriever",
          "Lakeland Terrier",
          "Lhasa Apso",
          "Lowchen",
          "Maltese",
          "Manchester Terrier",
          "Mastiff",
          "Miniature Bull Terrier",
          "Miniature Pinscher",
          "Miniature Schnauzer",
          "NAID Breed",
          "Neapolitan Mastiff",
          "Newfoundland",
          "Norfolk Terrier",
          "Norwegian Buhund",
          "Norwegian Elkhound",
          "Norwegian Lundehund",
          "Norwich Terrier",
          "Nova Scotia Duck Tolling",
          "Old English Sheepdog",
          "Other",
          "Otterhound",
          "Papillon",
          "Parson Russell Terrier",
          "Pekingese",
          "Pembroke Welsh Corgi",
          "Peruvian Inca Orchid",
          "Petit Basset Griffon Vendéen",
          "Pharaoh Hound",
          "Plott Hound",
          "Polish Lowland",
          "Pomeranian",
          "Poodle - Standard",
          "Poodle - Toy",
          "Portuguese Podengo Pequeno ",
          "Portuguese Water Dog",
          "Pug",
          "Puli",
          "Pumi",
          "Pyrenean Shepherd",
          "Rat Terrier",
          "Redbone Coonhound",
          "Rhodesian Ridgeback",
          "Rottweiler",
          "Saint Bernard",
          "Saluki",
          "Samoyed",
          "Schipperke",
          "Scottish Deerhound",
          "Scottish Terrier",
          "Sealyham Terrier",
          "Shetland Sheepdog",
          "Shiba Inu",
          "Shih Tzu",
          "Siberian Husky",
          "Silky Terrier",
          "Skye Terrier",
          "Sloughi",
          "Smooth Fox Terrier",
          "Soft Coated Wheaten Terrier",
          "Spinone Italiano",
          "Springer Spaniel - English",
          "Staffordshire Bull Terrier",
          "Standard Schnauzer",
          "Sussex Spaniel",
          "Swedish Vallhund",
          "Tibetan Mastiff",
          "Tibetan Spaniel",
          "Tibetan Terrier",
          "Toy Fox Terrier",
          "Treeing Walker Coonhound",
          "Unkown",
          "Vizsla",
          "Weimaraner",
          "Welsh Springer Spaniel",
          "Welsh Terrier",
          "West Highland White Terrier",
          "Whippet",
          "Wire Fox Terrier",
          "Wirehaired Pointing Griffon",
          "Wirehaired Vizsla",
          "Xoloitzcuintli",
          "Yorkshire Terrier",
        ];
      } else if (this.form.type === "Tortoise") {
        this.petBreeds = ["Hermann",
          "Ibera",
          "Whitei",
          "Marginated",
          "Horsefield",
          "Leopard",
          "Sulcata",
          "Other MST",
          "Other Tropical",
          "Unknown"];
      } else if (this.pet_type == "Cat") {
        this.petBreeds = [
          "Unknown",
          "Crossbreed",
          "Abyssinian",
          "Aegean",
          "American Bobtail",
          "American Curl",
          "American Shorthair",
          "American Wirehair",
          "Aphrodite Giant",
          "Arabian Mau",
          "Asian",
          "Asian Semi-longhair",
          "Australian Mist",
          "Balinese",
          "Bambino",
          "Bengal",
          "Birman",
          "Bombay",
          "Brazilian Shorthair",
          "British Longhair",
          "British Semi-longhair",
          "British Shorthair",
          "Burmese",
          "Burmilla",
          "California Spangled",
          "Chantilly-Tiffany",
          "Chartreux",
          "Chausie",
          "Cheetoh",
          "Colorpoint Longhair",
          "Colorpoint Persian",
          "Colorpoint Shorthair",
          "Cornish Rex",
          "Cymric, or",
          "Cyprus",
          "Devon Rex",
          "Don Sphynx",
          "Donskoy",
          "Dragon Li",
          "Dwelf",
          "Egyptian Mau",
          "European Shorthair",
          "Exotic Shorthair",
          "Foldex",
          "Foreign Longhair",
          "German Rex",
          "Havana Brown",
          "Highlander",
          "Himalayan, or",
          "Japanese Bobtail",
          "Javanese, or",
          "Karelian Bobtail",
          "Khao Manee",
          "Korat",
          "Korean Bobtail",
          "Korn Ja",
          "Kuril Islands Bobtail",
          "Kurilian Bobtail",
          "LaPerm",
          "Longhaired Manx",
          "Lykoi",
          "Maine Coon",
          "Mandarin;",
          "Manx",
          "Manx Longhair",
          "Mekong Bobtail",
          "Minskin",
          "Munchkin",
          "Napoleon",
          "Nebelung",
          "Norwegian Forest cat",
          "Ocicat",
          "Ojos Azules",
          "Oregon Rex",
          "Oriental Bicolor",
          "Oriental Longhair",
          "Oriental Shorthair",
          "Other",
          "Persian (modern)",
          "Persian (traditional)",
          "Peterbald",
          "Pixie-bob",
          "Raas",
          "Ragamuffin, or",
          "Ragdoll",
          "Russian Black",
          "Russian Blue",
          "Russian Tabby",
          "Russian White",
          "Sam Sawet",
          "Savannah",
          "Scottish Fold",
          "Selkirk Rex",
          "Serengeti",
          "Serrade petit",
          "Siamese",
          "Siamese (modern)",
          "Siberian",
          "Siberian Forest Cat",
          "Singapura",
          "Snowshoe",
          "Sokoke",
          "Somali",
          "Sphynx",
          "Suphalak",
          "Thai Lilac",
          "Thai, or",
          "Tonkinese",
          "Toyger",
          "Turkish Angora",
          "Turkish Van",
          "Ukrainian Levkoy",
          "Unknown",
          "Wichien Maat",
          "Wila Krungthep",
          "York Chocolate",
        ];
      } else if (this.pet_type == "Lizard") {
        this.petBreeds = [
          "Unknown",
          "Chinese water dragon",
          "Leopard gecko",
          "Tokay gecko",
          "Bearded Dragon",
          "Crested gecko",
          "Monitor lizards",
          "Blue tongued skink",
          "Frilled dragon",
          "Uromastyx",
          "Veiled chameleon",
        ];
      } else if (this.pet_type == "Bird") {
        this.petBreeds = [
          "Unknown",
          "Budgerigars",
          "Caiques",
          "Canaries",
          "Cockatiels",
          "Cockatoos",
          "Finches",
          "Grey Parrots",
          "Macaws",
        ];
      }
    },
  },
};
</script>

<style scoped>
.d-btn {
  width: 10rem;
}

select {
  -webkit-appearance: listbox !important;
}
</style>