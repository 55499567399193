<template>
  <div class="tw-flex tw-items-center tw-justify-center">
    <select
      @input="updateValue"
      class="tw-bg-mp-off-white tw-w-68 tw-rounded-md tw-h-10 tw-mr-4"
      :class="[errorClass]"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        v-html="smallScreen ? option.flag : option.display"
        :selected="option.prefix === initialPrefix"
      ></option>
    </select>
    <div
      class="tw-bg-mp-off-white tw-flex tw-items-center tw-w-12 tw-h-10 tw-rounded-md tw-mr-4 tw-px-2"
    >
      {{ initialPrefix }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: null,
    initialPrefix: {
      type: String,
      default: "+44",
    },
  },
  data() {
    return {
      errorClass: {
        type: String,
        default: "",
      },
      options: [
        {
          value: "GB",
          display: "🇬🇧 United Kingdom",
          prefix: "+44",
          flag: "🇬🇧",
        },
        {
          value: "IE",
          display: "🇮🇪 Ireland",
          prefix: "+353",
          flag: "🇮🇪",
        },
      ],
      smallScreen: false,
    };
  },
  methods: {
    updateValue(event) {
      this.$emit("input", event.target.value);
    },
    checkScreenSize() {
      this.smallScreen = window.innerWidth < 640;
    },
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>

<style scoped>
select {
  -webkit-appearance: listbox !important;
}
</style>