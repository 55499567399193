<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="9">
        <v-card width="600" class="mx-auto mt-5">
          <v-toolbar color="blue-bkg" dark flat>
            <v-toolbar-title>My Account</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-col cols="12">
              <v-label>
                {{ user.email }}
              </v-label>
            </v-col>
            <v-col cols="12">
              <v-img
                :src="require('../assets/logo.svg')"
                class="my-3"
                contain
                height="200"
              />
            </v-col>
            <v-col>
              <v-row
                v-if="!user.first_name || !user.postcode"
                style="justify-content: center"
              >
                <v-btn
                  :to="{ name: 'UserRegisterDetails' }"
                  class="transp-bkg no-shadow cap blue-text mob-text"
                >
                  Continue with registration
                </v-btn>
              </v-row>
              <v-row style="justify-content: center">
                <v-btn
                  :to="{ name: 'PetCreate' }"
                  class="transp-bkg no-shadow cap blue-text mob-text"
                >
                  Register your pet on our microchip database!
                </v-btn>
              </v-row>
            </v-col>
            <v-col>
              <v-row class="justify-center">
                <v-card-title class="blue-text"
                  >Email notifications
                </v-card-title>
              </v-row>
              <v-row class="justify-center">
                <v-switch
                  @change="updateMarketingEmails()"
                  v-model="emailMarketing"
                  label="Marketing emails"
                  color="primary"
                  hide-details
                ></v-switch>
              </v-row>
            </v-col>
            <v-col>
              <v-row class="justify-center">
                <v-switch
                  @change="updateUpdatesEmails()"
                  v-model="emailUpdates"
                  label="Update emails"
                  color="primary"
                  hide-details
                ></v-switch>
              </v-row>
            </v-col>
            <v-col>
              <v-row class="justify-center">
                <v-switch
                  @change="updateSixMonthlyReminderEmails()"
                  v-model="sixMonthlyReminders"
                  label="Reminder emails"
                  color="primary"
                  hide-details
                ></v-switch>
              </v-row>
            </v-col>
            <v-col>
              <v-row class="justify-center">
                <v-form v-model="valid">
                  <v-card flat>
                    <v-card-title class="justify-center blue-text">
                      Change password
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showCurrent = !showCurrent"
                        label="Current Password"
                        prepend-icon="mdi-lock"
                        :rules="[rules.required, rules.min]"
                        :type="showCurrent ? 'text' : 'password'"
                        v-model="passwordCurrent"
                      ></v-text-field>
                      <v-text-field
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show = !show"
                        counter
                        label="New Password"
                        prepend-icon="mdi-lock"
                        :rules="[rules.required, rules.min]"
                        :type="show ? 'text' : 'password'"
                        v-model="password"
                      ></v-text-field>
                      <v-text-field
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show1 = !show1"
                        counter
                        label="Confirm Password"
                        prepend-icon="mdi-lock"
                        :rules="[
                          rules.required,
                          rules.min,
                          passwordConfirmationRule,
                        ]"
                        :type="show1 ? 'text' : 'password'"
                        v-if="password"
                        v-model="passwordConfirmation"
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions style="justify-content: center">
                      <v-btn
                        @click="updatePassword"
                        :disabled="!valid"
                        color="blue-bkg"
                        class="cap"
                      >
                        Update password
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-row>
              <div class="mt-4 tw-flex tw-justify-center">
                <div class="tw-w-60 tw-ml-2">
                  <v-card-title class="justify-center blue-text">
                    Breeder settings
                  </v-card-title>
                  <div>Show breeder settings</div>
                  <v-col>
                    <v-row class="justify-center">
                      <v-switch
                        @change="toggleBreederSettings"
                        color="primary"
                        hide-details
                        inset
                      ></v-switch>
                    </v-row>
                  </v-col>
                  <base-input-v2
                    :required="showBreederSettings"
                    v-if="showBreederSettings"
                    v-model="breederLicenceNumber"
                    class="tw-py-2"
                    label="Breeder licence number"
                    type="text"
                  ></base-input-v2>
                  <base-input-v2
                    :required="showBreederSettings"
                    v-if="showBreederSettings"
                    v-model="breederLicenceAuthority"
                    label="Licensing Authority"
                    type="text"
                    class="tw-py-2"
                  ></base-input-v2>
                  <div
                    v-if="showBreederSettings"
                    class="tw-cursor-pointer tw-inline-flex tw-justify-center tw-mt-4 tw-py-2 tw-px-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-mp-blue hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
                    @click="saveBreederSettings"
                  >
                    Save breeder settings
                  </div>
                </div>
              </div>
            </v-col>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      :color="snackbarColour"
      v-model="snackbar"
      :timeout="timeout"
      left
    >
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="dismissSnackbar">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import BaseInputV2 from "@/components/shared/BaseInputV2";

export default {
  props: {
    user: Object,
  },
  components: {
    BaseInputV2,
  },
  data() {
    const data = {
      password: "",
    };

    return {
      emailMarketing: false,
      emailUpdates: false,
      breederLicenceNumber: "",
      breederLicenceAuthority: "",
      showBreederSettings: false,
      sixMonthlyReminders: false,
      form: new window.Form(this.user.getAttributes(), async (form) => {}),
      password: "",
      passwordConfirmation: "",
      passwordCurrent: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      show: false,
      show1: false,
      showCurrent: false,
      snackbar: false,
      snackbar_text: "Your password has been changed successfully!",
      snackbarColour: "green darken-1",
      timeout: 50000,
      valid: false,
    };
  },
  async beforeMount() {
    await this.setEmailBools();

    if (this.user.role === "breeder") {
      this.breederLicenceNumber = this.user.breeder.breeder_licence;
      this.breederLicenceAuthority = this.user.breeder.licensing_authority;
      this.toggleBreederSettings();
    }
  },
  methods: {
    dismissSnackbar() {
      this.snackbar_text = false;
      this.snackbar = false;
    },
    async saveBreederSettings() {
      if (!this.breederLicenceNumber || !this.breederLicenceAuthority) {
        this.snackbar_text = "Both fields are required!";
        this.snackbarColour = "red darken-1";
        this.snackbar = true;
        return;
      }

      const data = {
        breeder: true,
        breeder_licence: this.breederLicenceNumber,
        licensing_authority: this.breederLicenceAuthority,
      };

      await this.user.updateV2(data);

      this.snackbar_text = "Breeder settings saved successfully!";
      this.snackbarColour = "green darken-1";
      this.snackbar = true;
    },
    async toggleBreederSettings() {
      this.showBreederSettings = !this.showBreederSettings;
    },
    async setEmailBools() {
      this.emailMarketing = !!this.user.mailinglists.find(
        (l) => l.name === "marketing"
      );
      this.emailUpdates = !!this.user.mailinglists.find(
        (l) => l.name === "updates"
      );
      this.sixMonthlyReminders = !!this.user.mailinglists.find(
        (l) => l.name === "six_month_reminder"
      );
    },
    async updatePassword() {
      this.form.old_password = this.passwordCurrent;
      this.form.new_password = this.password;
      this.form.new_password_confirmation = this.passwordConfirmation;
      const result = await this.user.passwordChange(this.form);

      if (result.success) {
        this.snackbar_text = "Your password has been changed successfully!";
        this.snackbarColour = "green darken-1";
      } else {
        this.snackbar_text = result.message;
        this.snackbarColour = "red darken-1";
      }

      this.snackbar = true;

      await new Promise((resolve) => setTimeout(resolve, 5000));

      this.snackbar = false;

      if (result.success) {
        await this.$router.push("/");
      }
    },
    updateMarketingEmails() {
      if (this.emailMarketing) {
        const data = { list_type: "marketing" };
        this.user.subscribe(data);
      } else {
        const data = { list_type: "marketing" };
        this.user.unsubscribe(data);
      }
    },
    updateUpdatesEmails() {
      if (this.emailUpdates) {
        const data = { list_type: "updates" };
        this.user.subscribe(data);
      } else {
        const data = { list_type: "updates" };
        this.user.unsubscribe(data);
      }
    },
    updateSixMonthlyReminderEmails() {
      if (this.sixMonthlyReminders) {
        const data = { list_type: "six_month_reminder" };
        this.user.subscribe(data);
      } else {
        const data = { list_type: "six_month_reminder" };
        this.user.unsubscribe(data);
      }
    },
  },
  computed: {
    passwordConfirmationRule() {
      return (
        this.password === this.passwordConfirmation || "Password must match"
      );
    },
  },
};
</script>

<style scoped></style>