var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('back-button'),_c('h2',{staticClass:"tw-text-4xl"},[_vm._v("Your account")]),(_vm.subscription.end_at)?_c('v-alert',{staticClass:"mt-4",attrs:{"outlined":"","type":"info"}},[_vm._v(" Your subscription will end on "+_vm._s(new Date(_vm.subscription.end_at).toLocaleDateString("en-UK"))+" ")]):_vm._e(),_c('hr',{staticClass:"tw-mt-12 tw-w-full tw-border-t tw-border-mp-green"}),_c('div',{staticClass:"md:tw-flex"},[_c('div',{staticClass:"md:tw-w-1/2"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-start"},[_c('div',{staticClass:"tw-flex tw-mt-4 tw-text-xl"},[_c('div',{staticClass:"tw-font-black tw-mr-2"},[_vm._v("Your Plan:")]),_c('div',[_vm._v(" "+_vm._s(_vm.subscriptionType === "basic" ? "MyPet Basic" : "MyPet premium")+" ")])]),_c('div',{staticClass:"tw-mt-2 tw-text-left"},[_vm._v(" You can change your account plan here. ")])])]),_c('div',{staticClass:"md:tw-w-1/2"},[_c('div',{staticClass:"tw-flex tw-flex-col md:tw-items-end"},[_c('div',{staticClass:"tw-mt-12 tw-border tw-rounded tw-text-white tw-p-2",class:[
            _vm.subscriptionType === 'basic'
              ? 'tw-cursor-pointer tw-bg-mp-purple'
              : 'tw-disabled tw-bg-gray-500',
          ],on:{"click":_vm.createSubscription}},[_vm._v(" "+_vm._s(_vm.subscriptionType === "basic" ? "Upgrade" : "Upgraded")+" ")])])])]),_c('hr',{staticClass:"tw-mt-12 tw-w-full tw-border-t tw-border-mp-green"}),_c('div',{staticClass:"md:tw-flex"},[_vm._m(0),_c('div',{staticClass:"md:tw-w-1/2"},[_c('div',{staticClass:"md:tw-flex tw-flex-col md:tw-items-end"},[_c('div',{staticClass:"tw-disabled tw-bg-mp-purple tw-mt-12 tw-border tw-rounded tw-text-white tw-p-2 tw-cursor-pointer",class:[
            _vm.subscription.end_at
              ? 'tw-disabled tw-bg-gray-500'
              : 'tw-cursor-pointer tw-bg-mp-purple',
          ],on:{"click":function($event){_vm.subscriptionType === 'basic'
              ? _vm.createSubscription()
              : _vm.updatePaymentMethod()}}},[_vm._v(" "+_vm._s(_vm.subscriptionType === "basic" ? "Upgrade" : "Update")+" ")])])])]),_c('hr',{staticClass:"tw-mt-12 tw-w-full tw-border-t tw-border-mp-green"}),_c('div',{staticClass:"md:tw-flex"},[_vm._m(1),_c('div',{staticClass:"md:tw-w-1/2"},[_c('div',{staticClass:"tw-flex tw-flex-col md:tw-items-end"},[_c('div',{staticClass:"tw-disabled tw-bg-mp-purple tw-mt-12 tw-border tw-rounded tw-text-white tw-p-2 tw-cursor-pointer",on:{"click":function($event){return _vm.$router.push({ name: 'Settings' })}}},[_vm._v(" Settings ")])])])]),_c('hr',{staticClass:"tw-mt-12 tw-w-full tw-border-t tw-border-mp-green"}),_c('div',{staticClass:"md:tw-flex"},[_vm._m(2),_c('div',{staticClass:"md:tw-w-1/2"},[_c('div',{staticClass:"tw-flex tw-flex-col md:tw-items-end"},[_c('div',{staticClass:"tw-disabled tw-mt-12 tw-border tw-rounded tw-text-white tw-p-2",class:[
            _vm.subscriptionType === 'basic' || _vm.subscription.end_at
              ? 'tw-disabled tw-bg-gray-500'
              : 'tw-cursor-pointer tw-bg-mp-purple',
          ],on:{"click":_vm.cancelSubscription}},[_vm._v(" Cancel ")])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:tw-w-1/2"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-start"},[_c('div',{staticClass:"tw-flex tw-mt-4 tw-text-xl"},[_c('div',{staticClass:"tw-font-black tw-mr-2"},[_vm._v("Billing:")])]),_c('div',{staticClass:"tw-mt-2 tw-text-left"},[_vm._v(" You can update your billing information here. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:tw-w-1/2"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-start"},[_c('div',{staticClass:"tw-flex tw-mt-4 tw-text-xl"},[_c('div',{staticClass:"tw-font-black tw-mr-2"},[_vm._v("Settings")])]),_c('div',{staticClass:"tw-mt-2 tw-text-left"},[_vm._v(" Update your notifications, passwords & breeder status here. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:tw-w-1/2"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-start"},[_c('div',{staticClass:"tw-flex tw-mt-4 tw-text-xl"},[_c('div',{staticClass:"tw-font-black tw-mr-2"},[_vm._v("Cancel Subscription")])]),_c('div',{staticClass:"tw-mt-2 tw-text-left"},[_vm._v(" Cancel your subscription plan here. ")])])])
}]

export { render, staticRenderFns }