<template>
  <div>
    <back-button></back-button>
    <div class="additional-contact-add tw-w-full tw-mt-8 tw-bg-white tw-z-20">
      <div
        class="additional-contact-add-form tw-border tw-border-mp-blue tw-rounded"
      >
        <form class="tw-flex tw-flex-col">
          <div
            class="additional-contact-add-form-title tw-h-16 tw-bg-mp-blue-opacity tw-flex tw-justify-center tw-items-center tw-rounded-t"
          >
            <h2 class="tw-text-black">Add Additional Contact</h2>
          </div>
          <div
            class="additional-contact-add-form-body md:tw-px-24 tw-px-4 tw-py-2"
          >
            <base-input-v2
              ref="emailAnchorRef"
              :invalid-class="
                emailInvalid
                  ? '  tw-border-solid tw-border-red-500 tw-bg-red-100'
                  : ''
              "
              :highlight-class="highlightEmail ? 'animate-highlight-red' : ''"
              v-model="email"
              label="Email"
              placeholder="jane@example.com"
              type="email"
              class="mt-4"
            ></base-input-v2>
            <base-input-v2
              ref="firstNameAnchorRef"
              :invalid-class="
                first_name === ''
                  ? '  tw-border-solid tw-border-red-500 tw-bg-red-100'
                  : ''
              "
              :highlight-class="
                highlightFirstName ? 'animate-highlight-red' : ''
              "
              v-model="first_name"
              label="First name"
              placeholder="First name"
              type="text"
              class="mt-2"
            ></base-input-v2>
            <base-input-v2
              ref="lastNameAnchorRef"
              :invalid-class="
                last_name === ''
                  ? '  tw-border-solid tw-border-red-500 tw-bg-red-100'
                  : ''
              "
              :highlight-class="
                highlightLastName ? 'animate-highlight-red' : ''
              "
              v-model="last_name"
              label="Last name"
              placeholder="Last name"
              type="text"
              class="mt-2"
            ></base-input-v2>
            <mobile-input-v2
              ref="mobileComponentRef"
              :mobile="initialMobile"
              @setMobile="setMobile"
            ></mobile-input-v2>
            <base-input-v2
              v-model="phone"
              label="Phone number"
              placeholder="Phone number"
              type="text"
              class="mt-2"
            ></base-input-v2>
            <address-lookup
              class="tw-z-50"
              ref="addressComponentRef"
              v-if="!loading"
              :address="address"
              @setAddressLines="setAddressLines"
            ></address-lookup>
            <div class="tw-flex tw-justify-items-start">
              <label
                class="tw-inline-flex tw-items-top tw-mt-3 tw-justify-center tw-items-center"
              >
                <input
                  v-model="applyToAllPets"
                  type="checkbox"
                  class="form-checkbox tw-h-4 tw-w-4 tw-text-gray-600"
                  checked
                /><span class="ml-2 mr-2 text-gray-700"
                  >Make this my additional contact for all my pets</span
                >
              </label>
            </div>
            <div
              v-if="!applyToAllPets"
              class="pets-container tw-overflow-x-auto tw-mt-2 tw-h-32 tw-w-full"
            >
              <div class="tw-flex tw-justify-items-start">
                <div>Only apply to these pets:</div>
              </div>
              <div class="pets tw-flex tw-justify-center tw-mt-2">
                <div v-for="pet in pets" :key="pet.id">
                  <button
                    :class="[
                      'tw-py-1 tw-px-2 tw-ml-2 tw-rounded-2xl tw-text-white',
                      selectedPets.includes(pet.id)
                        ? 'tw-bg-gray-400 '
                        : 'tw-bg-mp-blue',
                    ]"
                    @click.prevent="toggleSelectPet(pet.id)"
                  >
                    {{ pet.name }}
                  </button>
                </div>
              </div>
            </div>
            <div class="sm:tw-w-full tw-mb-4">
              <div
                v-if="getValidationData.length !== 0"
                class="tw-flex tw-flex-col tw-items-center"
              >
                <div class="tw-px-1 tw-mt-4 md:tw-w-2/3 sm:tw-w-full">
                  <form-validation
                    :data="getValidationData"
                    @jump-to-anchor="jumpToAnchor"
                  ></form-validation>
                </div>
              </div>
              <div class="tooltip-container">
                <button
                  :disabled="getValidationData.length !== 0"
                  type="submit"
                  class="tooltip-target tw-w-full tw-h-full tw-inline-flex tw-justify-center tw-mt-4 tw-py-2 tw-border tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-opacity-70 hover:tw-opacity-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
                  :class="
                    getValidationData.length !== 0
                      ? 'tw-bg-gray-300 tw-text-gray-500  tw-border-slate-700'
                      : 'tw-bg-mp-light-blue tw-border-transparent'
                  "
                  @click.prevent="save"
                >
                  Save
                </button>
                <div v-if="getValidationData.length !== 0" class="tooltip">
                  Please correct any errors before clicking the "save" button
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInputV2 from "@/components/shared/BaseInputV2.vue";
import AddressLookup from "@/components/shared/AddressLookup.vue";
import { CheckCircleIcon, XCircleIcon } from "@vue-hero-icons/solid";
import FlagDropdown from "@/components/shared/FlagDropDown.vue";
import AdditionalContact from "@/models/AdditionalContact";
import BackButton from "@/components/shared/BackButton.vue";
import FormValidation from "@/components/shared/FormValidation.vue";
import Pet from "@/models/Pet";
import MobileInputV2 from "@/components/shared/MobileInputV2.vue";

export default {
  components: {
    MobileInputV2,
    FormValidation,
    BackButton,
    CheckCircleIcon,
    FlagDropdown,
    XCircleIcon,
    AddressLookup,
    BaseInputV2,
  },
  data() {
    return {
      loading: false,
      email: "",
      mobile: "",
      first_name: "",
      last_name: "",
      phone: "",
      errors: [],
      initialMobile: "",
      applyToAllPets: true,
      highlightFirstName: false,
      highlightLastName: false,
      highlightMobile: false,
      highlightEmail: false,
      emailInvalid: false,
      pets: [],
      selectedPets: [],
      address: {
        address_line_1: "",
        address_line_2: "",
        address_line_3: "",
        address_line_4: "",
        postcode: "",
        city: "",
      },
    };
  },
  methods: {
    async setMobile(mobile) {
      this.mobile = mobile;
    },
    async toggleSelectPet(petId) {
      if (this.selectedPets.includes(petId)) {
        this.selectedPets = this.selectedPets.filter((item) => item !== petId);
      } else {
        this.selectedPets.push(petId);
      }
    },
    async setAddressLines(address) {
      this.address_line_1 = address.address_line_1;
      this.address_line_2 = address.address_line_2 || null;
      this.address_line_3 = address.address_line_3 || null;
      this.address_line_4 = address.address_line_4 || null;
      this.postcode = address.postcode;
      this.city = address.city;
    },
    async save() {
      if (this.errors.length === 0) {
        const data = {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          mobile: this.mobile,
          phone: this.phone,
          address_line_1: this.address_line_1,
          address_line_2: this.address_line_2,
          address_line_3: this.address_line_3,
          address_line_4: this.address_line_4,
          postcode: this.postcode,
          city: this.city,
          pet_uuids: this.applyToAllPets
            ? (this.selectedPets = this.pets.map((p) => p.id))
            : this.selectedPets,
        };

        await AdditionalContact.create(data);
        await this.$router.push({
          name: "AdditionalContactsHome",
        });
      } else {
        const element = this.$refs["errors"];
        element.scrollIntoView({ behavior: "smooth" });
      }
    },

    async checkForm() {
      this.errors = [];

      if (!this.first_name) {
        this.errors.push({
          field: "first_name",
          message: "First name is required",
        });
      }

      if (!this.mobile && !this.phone) {
        this.errors.push({
          field: "mobile",
          message: "Either mobile or landline is required",
        });
      }
    },
    async jumpToAnchor(item) {
      switch (item.type) {
        case "first_name":
          this.highlightFirstName = true;
          break;
        case "last_name":
          this.highlightLastName = true;
          break;
        case "mobile":
          this.highlightMobile = true;
          await this.$refs.mobileComponentRef.jumpToAnchor(item);
          break;
        case "email":
          this.highlightEmail = true;
          break;
      }
    },
  },

  computed: {
    getValidationData() {
      let data = [];

      if (!this.first_name || this.first_name.length === 0) {
        data.push({
          type: "first_name",
          message: "First name is required",
          anchor: "firstNameAnchorRef",
        });
      }

      if (!this.last_name || this.last_name.length === 0) {
        data.push({
          type: "last_name",
          message: "Last name is required",
          anchor: "lastNameAnchorRef",
        });
      }

      this.emailInvalid = false;
      if (this.email) {
        if (!this.isValidEmail(this.email)) {
          this.emailInvalid = true;
          data.push({
            type: "email",
            message: "Email is invalid",
            anchor: "emailAnchorRef",
          });
        }
      }

      if (
        (!this.mobile || this.mobile.length === 0 || this.mobile.length < 12) &&
        (!this.phone || this.phone.length === 0)
      ) {
        data.push({
          type: "mobile",
          message: "Either mobile or landline is required",
          anchor: "mobileAnchorRef",
        });
      }

      return data;
    },
  },
  async beforeMount() {
    this.pets = await Pet.list();
    this.selectedPets = this.pets.map((p) => p.id);
  },
};
</script>

<style scoped></style>