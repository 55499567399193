<template>
  <v-container class="fill-height" fluid>
    <back-button></back-button>
    <div class="tw-mx-auto tw-w-full">
      <v-btn
        color="#00b53f"
        class="cap mb-8 mt-2 white--text"
        @click="$router.push({ name: 'PetCreate' })"
      >
        <v-icon>mdi-plus</v-icon>
        Add pet
      </v-btn>
    </div>
    <pets-list></pets-list>
  </v-container>
</template>

<script>
import PetsList from "../components/PetsList.vue";
import BackButton from "@/components/shared/BackButton.vue";

export default {
  components: {
    BackButton,
    PetsList,
  },
};
</script>

<style scoped></style>