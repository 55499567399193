var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.petNames && _vm.petNames.length > 0)?_c('div',{},[_c('div',{staticClass:"tw-text-mp-blue tw-text-lg"},[_vm._v("Pets")]),_c('div',{staticClass:"tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-4"},[_c('div',{staticClass:"tw-bg-mp-blue-opacity tw-w-2/3 tw-rounded tw-border-2 tw-border-mp-blue"},_vm._l((_vm.petNames),function(petName){return _c('div',{key:petName},[_c('div',{staticClass:"tw-mt-1 tw-mb-1"},[_vm._v(_vm._s(petName))])])}),0)])]):_vm._e(),_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center md:tw-ml-40 md:tw-mr-40 tw-mt-8"},[_c('div',[_c('div',{staticClass:"tw-text-mp-dark-blue tw-font-semibold tw-text-lg tw-mb-2"},[_vm._v(" MyPet Basic ")]),_c('div',{staticClass:"tw-rounded tw-border-2 tw-py-4 tw-px-4 tw-border-mp-blue tw-cursor-pointer tw-w-44 tw-h-24 tw-mt-4 tw-shadow-2xl",class:[
          _vm.premiumSelected ? 'tw-bg-white' : 'tw-bg-mp-blue tw-text-white',
        ],on:{"click":_vm.selectBasic}},[_vm._v(" "+_vm._s(this.orderTotal > 0 ? "£" + this.orderTotal : "Included")+" ")])]),_c('div',[_c('div',{staticClass:"tw-text-mp-dark-blue tw-font-semibold tw-text-lg tw-mb-2"},[_vm._v(" MyPet Premium ")]),_c('div',{staticClass:"tw-relative tw-rounded tw-border-2 tw-py-4 tw-px-4 tw-border-mp-blue tw-cursor-pointer tw-w-44 tw-h-24 tw-mt-4 tw-shadow-2xl",class:[
          _vm.premiumSelected ? 'tw-bg-mp-blue tw-text-white' : 'tw-bg-white',
        ],on:{"click":_vm.selectPremium}},[_c('div',{staticClass:"tw-font-semibold"},[_vm._v("Annual")]),_c('div',{staticClass:"tw-font-semibold"},[(_vm.couponApplied)?_c('span',[_c('s',[_vm._v("£12")]),_vm._v(" £"+_vm._s(this.discountedPrice)+" ")]):_c('span',[_vm._v(" £"+_vm._s(this.premiumPrice)+" ")])]),_vm._m(0)])])]),_c('div',{staticClass:"tw-flex tw-flex-col tw-w-full tw-mt-8 tw-justify-center tw-items-center"},[_c('div',{staticClass:"md:tw-w-2/3 sm:tw-w-full"},[(!_vm.premiumSelected)?_c('my-pet-basic-subscription-panel',{attrs:{"offer-page":"","show-relative":false},on:{"acceptBasic":_vm.acceptBasic}}):_vm._e()],1),_c('div',{staticClass:"md:tw-w-2/3 sm:tw-w-full"},[(_vm.premiumSelected)?_c('my-pet-premium-subscription-panel',{attrs:{"offer-page":"","show-relative":false},on:{"acceptPremium":_vm.acceptPremium}}):_vm._e()],1)]),_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-mt-8 tw-mb-8"},[_c('div',{class:{
        'tw-text-center tw-border tw-rounded tw-bg-mp-purple tw-text-white tw-p-2 tw-cursor-pointer tw-w-1/4 tw-border-black':
          !_vm.continueClicked,
        'tw-text-center tw-border-2 tw-rounded tw-bg-white tw-text-mp-purple tw-p-2 tw-cursor-pointer tw-w-1/4 tw-border-mp-purple':
          _vm.continueClicked,
      },on:{"click":_vm.continueRegistration}},[_vm._v(" Continue ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-absolute tw-top-20 tw-left-12"},[_c('span',{staticClass:"tw-inline-block tw-text-white tw-px-2 tw-py-1 tw-rounded-full tw-bg-mp-green tw-shadow-2xl"},[_vm._v("Popular")])])
}]

export { render, staticRenderFns }