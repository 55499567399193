<template>
  <v-card style="border: solid 1px; border-color: #757575; border-radius: 15px; margin-top: 50px;">
    <v-toolbar color="#F5F5F5" dark flat style="display: flex; align-items: center; justify-content: center;">
      <v-toolbar-title v-if="flowValue === 'organic'" style="color: #0074b5;">Nearly there! &#128512 &nbsp
      </v-toolbar-title>
      <v-toolbar-title v-else style="color: #0074b5;">Registration complete! &#128512 &nbsp</v-toolbar-title>
    </v-toolbar>

    <v-card-text v-if="flowValue === 'organic' && !microchipFree">
      <h3 class="">One more step to complete your microchip registration (Unless you need to add more pets).</h3>
      <br/>
      <p v-if="subscriptionType ==='basic'">Head through to the checkout and once you’re all finished we’ll send you
        your shiny new registration
        certificate.
      </p>
    </v-card-text>

    <v-card-text v-if="flowValue === 'organic' && microchipFree">
      <h3 class="">Nearly finished! (Unless you need to add more pets).</h3>
      <br/>
      <p>Click complete registration and we’ll send you your shiny new registration certificate.
      </p>
    </v-card-text>

    <v-card-text v-if=" (flowValue === 'veterinary')">
      <h3 class="">Your pet has been registered successfully!</h3>
      <br/>
      <br/>
      <p>We will send you an email with your registration Certificate. &#128512
      </p>

    </v-card-text>

    <v-card-text v-if="(flowValue === 'rehome')">
      <h3 class="">We’ve now transferred {{ pet.name }} to your account! &#128512 </h3>
      <br/>
      <p>We’ll email you a shiny new certificate!
      </p>
    </v-card-text>

    <v-container style="margin-bottom: 40px; ">
      <v-col class="mx-auto" cols="9">
        <v-alert
            v-if="microchipFree"
            class="mb-6 mx-auto"
            dense
            outlined
            type="success"
        >
          Your implanter has paid for your registration
        </v-alert>
      </v-col>

      <v-row v-if="flowValue === 'veterinary' || flowValue === 'rehome'" align="center" class="mx-4 "
             justify="space-around" md6 xs12>
        <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            class="cap mb-2"
            color="blue-bkg"
            style="border-radius: 30px;"
            type="submit"
            x-large
            @click="$router.push({ name: 'Dashboard' })"
        >Home
        </v-btn
        >
      </v-row>

      <v-row v-if="flowValue === 'organic'" align="center" class="mx-4 " justify="space-around" md6 xs12>
        <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            class="cap mb-2"
            color="blue-bkg"
            style="border-radius: 30px;"
            type="submit"
            x-large
            @click="$router.push({ name: 'PetCreate' })"
        >Add another pet
        </v-btn
        >
        <v-btn
            v-if="!microchipFree && subscriptionType === 'basic'"
            v-show="true"
            :block="$vuetify.breakpoint.xsOnly"
            class="cap white--text mb-2"
            color="#00b53f"
            type="submit"
            x-large
            @click="checkout()"
        >Check out
        </v-btn
        >
        <v-btn
            v-if="microchipFree || subscriptionType === 'premium'"
            :block="$vuetify.breakpoint.xsOnly"
            class="cap white--text mb-2"
            color="#487b3c"
            type="submit"
            x-large
            @click="checkoutFree()"
        >{{ subscriptionType === 'premium' ? 'Complete' : 'Next' }}
        </v-btn
        >
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Pet from "../models/Pet";
import User from "@/models/User";

export default {
  props: {
    flow: "",
  },
  data() {
    return {
      pet: {},
      user: null,
      petNames: [],
      microchipFree: false,
      flowValue: "",
      subscriptionType: "basic",
    };
  },
  methods: {
    checkout() {
      if (this.user.role === 'customer') {
        this.$router.push({name: "SubscriptionCheckoutPage"});
      } else {
        this.$router.push({name: "Checkout"});
      }
    },
    async computeMicrochipStatus(pet_id) {
      this.microchipFree = await Pet.isMicrochipFree(pet_id);

      if (this.microchipFree) {
        var freeChipQueue =
            JSON.parse(localStorage.getItem("freeChipQueue")) || [];

        freeChipQueue.push({name: this.pet.name});

        localStorage.setItem("freeChipQueue", JSON.stringify(freeChipQueue));
      }
    },
    async checkoutFree() {
      var freeChipQueue = JSON.parse(localStorage.getItem("freeChipQueue"));

      if (this.subscriptionType === 'premium') {
        await this.$router.push({
          name: "CheckoutSuccessFreeVersion",
          params: {pets: freeChipQueue},
        });
      } else if (this.user.role === "customer") {
        await this.$router.push({
          name: "SubscriptionCheckoutPage",
          params: {pets: freeChipQueue},
        });
      } else {
        await this.$router.push({
          name: "CheckoutSuccessFreeVersion",
          params: {pets: freeChipQueue},
        });
      }
      localStorage.removeItem("freeChipQueue");
    }
  },
  async mounted() {
    this.subscriptionType = await this.getSubscriptionType();
  },
  async beforeMount() {
    if (this.$route.query.pet) {
      this.pet = await Pet.retrieve(this.$route.query.pet);
      await this.computeMicrochipStatus(this.$route.query.pet);
    }
    if (this.$route.query.flow) {
      this.flowValue = this.$route.query.flow;
    } else {
      this.flowValue = 'organic';
    }

    this.user = await User.retrieve("microchip_queue");
    await this.$store.commit("update_cart", this.user);
  },
};
</script>

<style scoped>
</style>