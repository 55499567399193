import Model from "./Model";
import axios from "axios";

export default class Agria extends Model {
  static async livePolicyCheck(microchip) {
    if (process.env.VUE_APP_SHOW_INSURANCE == "false") {
      return true;
    }

    const fetchWithTimeout = (url, timeout = 3000) => {
      return Promise.race([
        window.Http.get(url),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error("Request timed out")), timeout)
        ),
      ]);
    };

    try {
      const response = await fetchWithTimeout(
        "/api/v2/agria/live-policy-check?microchip=" + microchip,
        3000
      );
      return response.HasLivePolicy;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  static async freeInsuranceCheck(data) {
    if (process.env.VUE_APP_SHOW_INSURANCE == "false") {
      return;
    }
    const response = await window.Http.post(
      "/api/v2/agria/free-insurance-check",
      data
    );
    return response;
  }

  static async createPolicy(data) {
    if (process.env.VUE_APP_SHOW_INSURANCE == "false") {
      return;
    }
    const response = await axios
      .post(process.env.VUE_APP_API_URL + "/api/v2/agria/policy-create", data)
      .catch(function (error) {});

    return this.make(response);
  }

  static petHasPrerequisites(pet) {
    if (process.env.VUE_APP_SHOW_INSURANCE == "false") {
      return false;
    }

    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(pet.dob);
    const secondDate = new Date();

    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

    //no free insurance for pets which are too young or old
    if (diffDays < 56 || diffDays > 1825) {
      return false;
    }

    if (
      pet.name != "" &&
      pet.type != "" &&
      pet.dob != "" &&
      pet.breed != null &&
      pet.breed != "" &&
      pet.sex != "unknown"
    ) {
      return true;
    }

    //check pet age
    return false;
  }
}