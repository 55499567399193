var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('back-button'),_c('div',{staticClass:"vet-add tw-w-full tw-mt-8 tw-bg-white tw-z-20"},[_c('div',{staticClass:"vet-add-form tw-border tw-border-mp-blue tw-rounded"},[_c('form',{staticClass:"tw-flex tw-flex-col"},[_vm._m(0),_c('div',{staticClass:"vet-add-form-body md:tw-px-24 tw-px-4 tw-py-2"},[(!_vm.loading)?_c('vet-lookup',{staticClass:"tw-z-50",attrs:{"vet":_vm.vet},on:{"setVet":_vm.setVet}}):_vm._e()],1),_c('div',{staticClass:"tw-flex tw-justify-items-start tw-px-4"},[_c('label',{staticClass:"tw-inline-flex tw-items-top tw-mt-3 tw-justify-center tw-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.applyToAllPets),expression:"applyToAllPets"}],staticClass:"form-checkbox tw-h-4 tw-w-4 tw-text-gray-600",attrs:{"type":"checkbox","checked":""},domProps:{"checked":Array.isArray(_vm.applyToAllPets)?_vm._i(_vm.applyToAllPets,null)>-1:(_vm.applyToAllPets)},on:{"change":function($event){var $$a=_vm.applyToAllPets,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.applyToAllPets=$$a.concat([$$v]))}else{$$i>-1&&(_vm.applyToAllPets=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.applyToAllPets=$$c}}}}),_c('span',{staticClass:"ml-2 mr-2 text-gray-700"},[_vm._v("Make this my vet for all my pets")])])]),(!_vm.applyToAllPets)?_c('div',{staticClass:"pets-container tw-overflow-x-auto tw-mt-2 tw-h-32 tw-w-full tw-px-4"},[_vm._m(1),_c('div',{staticClass:"pets tw-flex tw-justify-center tw-mt-2"},_vm._l((_vm.pets),function(pet){return _c('div',{key:pet.id},[_c('button',{class:[
                  'tw-py-1 tw-px-2 tw-ml-2 tw-rounded-2xl tw-text-white',
                  _vm.selectedPets.includes(pet.id)
                    ? 'tw-bg-mp-blue'
                    : 'tw-bg-gray-400',
                ],on:{"click":function($event){$event.preventDefault();return _vm.toggleSelectPet(pet.id)}}},[_vm._v(" "+_vm._s(pet.name)+" ")])])}),0)]):_vm._e(),_c('div',{staticClass:"tw-flex tw-justify-center tw-w-full"},[_c('button',{staticClass:"tooltip-target tw-border-transparent tw-w-2/3 tw-h-full tw-inline-flex tw-justify-center tw-mt-4 tw-mb-2 tw-py-2 tw-border tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-opacity-70 hover:tw-opacity-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500",class:[_vm.vet.name ? 'tw-bg-mp-light-blue' : 'tw-bg-gray-300'],attrs:{"disabled":!_vm.vet.name,"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v(" Save ")])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vet-add-form-title tw-h-16 tw-bg-mp-blue-opacity tw-flex tw-justify-center tw-items-center tw-rounded-t"},[_c('h2',{staticClass:"tw-text-black"},[_vm._v("Vet")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-flex tw-justify-items-start"},[_c('div',[_vm._v("Only apply to these pets:")])])
}]

export { render, staticRenderFns }