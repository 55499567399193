<template>
  <v-progress-circular
    v-if="loading"
    :size="70"
    :width="7"
    color="#0074b5"
    indeterminate
  ></v-progress-circular>
  <checkout v-else></checkout>
</template>

<script>
import Checkout from "../components/Checkout";

export default {
  components: { Checkout },
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style scoped>
</style>
