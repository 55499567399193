<template>
  <form @submit.prevent="save" class="tw-flex tw-text-slate-600 tw-flex-col tw-overflow-hidden sm:tw-rounded-md">
    <div class="md:tw-px-24 tw-px-4">

      <base-input-v2
          ref="microchipAnchorRef"
          :invalid-class="(details.microchip === '' || !(details.microchip.length === 10 || details.microchip.length === 15)) ? '  tw-border-solid tw-border-red-500 tw-bg-red-100' : ''"
          :highlight-class="(highlightMicrochip) ? 'animate-highlight-red' : ''"
          required="true" v-model="details.microchip" class="py-2" label="Microchip"
          type="text" @input="debounceHasLiveAgriaPolicy"></base-input-v2>
      <base-input-v2
          :ref="'nameAnchorRef' + pet.id"
          :invalid-class="(name === '') ? '  tw-border-solid tw-border-red-500 tw-bg-red-100' : ''"
          :highlight-class="(highlightName) ? 'animate-highlight-red' : ''"
          required="true" v-model="name" class="py-2" label="Name" type="text"
          @input="changePetName(name, position)"
      ></base-input-v2>
      <label ref="{{'petTypeAnchorRef' + pet.id}}" class="tw-block">
        <span class="tw-float-left tw-text-black">Pet type</span>
        <select
            v-model="pt" @change="selectPetType($event.target.value)"
            :class="[(highlightPetType) ? '  tw-border-solid tw-border-red-500 tw-bg-red-100' : '']"
            class="tw-form-select
                      tw-rounded-full
                      tw-mt-2
                      tw-block
                      tw-w-full
                      tw-rounded-md
                      tw-shadow-sm
                      tw-bg-mp-off-white
                           tw-text-mp-charcoal
                      focus:tw-border-violet-300
                      focus:tw-ring
                      focus:tw-ring-violet-200
                      animate-highlight-red
                      focus:tw-ring-opacity-50">
          <option v-for="type in petTypes"
                  :key="type.value"
                  :disabled="type.disabled"
                  :value="type.value"
                  :label="type.text"
          >
            {{ type.text }}
          </option>
        </select>
      </label>
      <label v-if="petTypesWithAssociatedBreeds.includes(this.pt) " class="tw-block py-2">
        <span v-if="showBreedSelect" class="tw-float-left tw-text-black">Breed</span>
        <select v-if="showBreedSelect" v-model="breed"
                @change="selectBreed($event.target.value)" class="tw-form-select
                      tw-rounded-full
                      tw-mt-2
                      tw-block
                      tw-w-full
                      tw-bg-mp-off-white
                      tw-text-mp-charcoal
                      tw-rounded-md
                      tw-shadow-sm
                      tw-text-slate-600
                      "
                :class="[errors.some(b => b.field === 'breed') ? 'tw-border-red-500' : 'focus:tw-border-violet-300 focus:tw-ring focus:tw-ring-violet-200 focus:tw-ring-opacity-50']">
          <option v-for="type in breeds"
                  :key="type.id"
                  :disabled="type.disabled"
                  :value="type.name"
                  :label="type.name"
          >
            {{ type.name }}
          </option>
        </select>
        <base-input-v2 v-if="!showBreedSelect" placeholder="Enter breed" v-model="breed" label="Breed"
                       type="text"></base-input-v2>
      </label>
      <div class="tw-text-mp-blue tw-cursor-pointer text-left mt-2 "
           @click="showBreedSelect = !showBreedSelect">
        {{ (showBreedSelect) ? 'click here to add your own breed' : 'click here to select breed from list' }}
      </div>

      <base-input-v2 required="true"
                     v-model="details.dob"
                     class="tw-mt-2" label="Date of birth (or best guess)"
                     type="date" @input="debounceCheckFreeInsuranceEligible"></base-input-v2>
      <label class="tw-text-left">Spayed/Neutered</label>
      <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full">
        <div class="tw-flex tw-w-full tw-max-w-xl">
          <div
              @click="toggleNeutered('neutered')"
              :class="[(neuteredStatus === 'neutered') ? 'tw-text-white tw-bg-mp-blue' : 'tw-text-slate-600 tw-bg-white']"
              class="tw-inline-flex tw-justify-center tw-mt-4 tw-py-2 tw-px-2 tw-w-1/3 tw-border-gray-300 tw-border-r tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-l-md">
            Yes
          </div>
          <div
              @click="toggleNeutered('not_neutered')"
              :class="[(neuteredStatus === 'not_neutered') ? 'tw-text-white tw-bg-mp-blue' : 'tw-text-slate-600 tw-bg-white']"
              class="tw-inline-flex tw-justify-center tw-mt-4 tw-py-2 tw-px-2 tw-w-1/3 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium">
            No
          </div>
          <div
              @click="toggleNeutered('unknown')"
              :class="[(neuteredStatus === 'unknown') ? 'tw-text-white tw-bg-mp-blue' : 'tw-text-slate-600 tw-bg-white']"
              class="tw-inline-flex tw-justify-center tw-mt-4 tw-py-2 tw-w-1/3 tw-border-l tw-border-gray-300 tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-r-md">
            Unknown
          </div>
        </div>
      </div>
      <label class="tw-text-left">Sex</label>
      <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full">
        <div class="tw-flex tw-w-full tw-max-w-xl">
          <div
              @click="toggleSex('male')"
              :class="[(sexStatus === 'male') ? 'tw-text-white tw-bg-mp-blue' : 'tw-text-slate-600 tw-bg-white']"
              class="tw-inline-flex tw-justify-center tw-mt-4 tw-py-2 tw-px-2 tw-w-1/3 tw-border-gray-300 tw-border-r tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-l-md">
            Male
          </div>
          <div
              @click="toggleSex('female')"
              :class="[(sexStatus === 'female') ? 'tw-text-white tw-bg-mp-blue' : 'tw-text-slate-600 tw-bg-white']"
              class="tw-inline-flex tw-justify-center tw-mt-4 tw-py-2 tw-px-2 tw-w-1/3 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium">
            Female
          </div>
          <div
              @click="toggleSex('unknown')"
              :class="[(sexStatus === 'unknown') ? 'tw-text-white tw-bg-mp-blue' : 'tw-text-slate-600 tw-bg-white']"
              class="tw-inline-flex tw-justify-center tw-mt-4 tw-py-2 tw-w-1/3 tw-border-l tw-border-gray-300 tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-r-md">
            Unknown
          </div>
        </div>
      </div>
      <base-input-v2 v-model="details.colour" class="py-2" label="Colour" type="text"></base-input-v2>
      <base-input-v2 v-model="details.distinguishing_features" class="py-2" label="Distinguishing features"
                     type="text"></base-input-v2>
      <!--    <label class="tw-underline tw-cursor-pointer" @click="userDuplicatedPetShow = !userDuplicatedPetShow">I want to-->
      <!--      register more pets with these details</label>-->
      <insurance-option v-if="showFreeInsuranceOffer" :checked="freeInsurance" :crossbreedText="crossbreedText"
                        :name="name"
                        :pet="insurancePet"
                        :flow="flow"
                        class="tw-mt-4"
                        @input="freeInsurance = !freeInsurance"
                        @toggleHelp="toggleInsuranceHelp"></insurance-option>
      <div v-if="userDuplicatedPetShow"
           class="user-duplicate-pets tw-mx-auto tw-border tw-border-mp-blue tw-px-2 tw-py-2 tw-mt-2 tw-rounded-md">
        <div class="tw-flex tw-mt-2 tw-align-middle">
          <span class="tw-mr-2 tw-mt-3 tw-text-base">I would like to add another </span>
          <input v-model="userDuplicatedPetCount" class="tw-form-input
                      tw-mt-1
                      tw-border
                      tw-border-slate-500
                      tw-block
                      tw-w-14
                      tw-rounded-md
                      tw-shadow-sm
                      tw-border-solid
                      focus:tw-border-violet-300
                      focus:tw-ring
                      focus:tw-ring-violet-200
                      focus:tw-ring-opacity-50" label="Number" type="number"></input>
          <span class="tw-ml-2 tw-mt-3 tw-text-base">pet(s)</span>
        </div>
        <div v-if="userDuplicatedPetShow">
          <div
              class="tw-inline-flex tw-justify-center tw-mt-4 tw-py-2 tw-px-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-mp-blue hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
              @click="$emit('userDuplicatePets', {count: userDuplicatedPetCount, details: details})">
            <svg class="tw-w-4 tw-h-4 tw-mr-1 tw-accent-amber-400" fill="#ffcc00" viewBox="0 0 20 20"
                 xmlns="http://www.w3.org/2000/svg">
              <path clip-rule="evenodd"
                    d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                    fill-rule="evenodd"/>
            </svg>
            <span>Generate</span>
          </div>
        </div>
      </div>
      <div class="sm:tw-w-full tw-mb-4">
        <div v-if="getValidationData.length !== 0" class="tw-flex tw-flex-col tw-items-center">
          <div class="tw-px-1 tw-mt-4 md:tw-w-2/3 sm:tw-w-full">
            <form-validation :data="getValidationData" @jump-to-anchor="jumpToAnchor"></form-validation>
          </div>
        </div>
        <div
            class="tooltip-container ">
          <button :disabled="getValidationData.length !== 0" type="submit"
                  class="tooltip-target tw-w-full tw-h-full tw-inline-flex tw-justify-center tw-mt-4 tw-py-2 tw-border tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-opacity-70 hover:tw-opacity-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
                  :class="(getValidationData.length !== 0) ? 'tw-bg-gray-300 tw-text-gray-500  tw-border-slate-700' : 'tw-bg-mp-light-blue tw-border-transparent'"
                  @click.prevent="save(details,position)"
          >
            Save
          </button>
          <div v-if="getValidationData.length !== 0" class="tooltip">
            Please correct any errors before clicking the "save" button
          </div>
        </div>
      </div>
    </div>
    <insurance-help-modal
        :modalHeadline="'Congratulations! ' + name + ' is eligible for FREE insurance.'"
        :visible="showInsuranceHelp"
        @close="toggleInsuranceHelp"
    >
    </insurance-help-modal>
  </form>
</template>

<script>
import BaseInputV2 from "@/components/shared/BaseInputV2";
import BaseDateInputV2 from "@/components/shared/BaseDateInputV2";
import Breed from "@/models/Breed";
import InsuranceOption from "@/components/InsuranceOption";
import InsuranceHelpModal from "@/components/modals/InsuranceHelpModal";
import FormValidation from "@/components/shared/FormValidation";
import Agria from "@/models/Agria";
import Pet from "@/models/Pet";
import {debounce} from "lodash";
import User from "@/models/User";

export default {
  components: {BaseInputV2, BaseDateInputV2, InsuranceOption, InsuranceHelpModal, FormValidation},
  props: {
    user: User,
    pet: Object,
    position: 0,
    flow: String,
  },
  data() {
    return {
      breed: "",
      breeds: [],
      showBreedSelect: false,
      details: {
        name: "",
        microchip: "",
        dob: "",
        colour: "",
        distinguishing_features: "",
      },
      name: '',
      hasLivePolicy: false,
      showInsuranceHelp: false,
      freeInsurance: false,
      pt: "",
      crossbreedText: "",
      neuteredStatus: "unknown",
      petBreed: {},
      petType: {},
      insurancePet: Object,
      petTypes: [
        {
          value: "default",
          text: "-- select an option --",
          disabled: true
        },
        {
          value: "dog",
          text: "Dog"
        },
        {
          value: "cat",
          text: "Cat"
        },
        {
          value: "rabbit",
          text: "Rabbit",
        },
        {
          value: "hamster",
          text: "Hamster",
        },
        {
          value: "horse",
          text: "Horse",
        },
        {
          value: "lizard",
          text: "Lizard",
        },
        {
          value: "bird",
          text: "Bird",
        },
        {
          value: "tortoise",
          text: "Tortoise",
        },
        {
          value: "ferret",
          text: "Ferret",
        },
        {
          value: "guinea_pig",
          text: "Guinea pig",
        },
        {
          value: "other",
          text: "Other",
        }
      ],
      petTypesWithAssociatedBreeds: ['dog', 'cat', 'lizard', 'bird', 'tortoise'],
      sexStatus: "unknown",
      showFreeInsuranceOffer: false,
      errors: [],
      userDuplicatedPetShow: false,
      userDuplicatedPetCount: 1,
      highlightMicrochip: false,
      highlightBreed: false,
      highlightPetType: false,
      highlightDob: false,
      highlightName: false,
    }
  },
  methods: {
    async jumpToAnchor(item) {
      const el = this.$refs[item.anchor];

      // console.log(item);
      // console.log(el);
      // console.log(el.offsetTop);
      // if (el) {
      //   el.scrollIntoView({behavior: 'smooth'})
      // }
      // console.log(el)


      switch (item.type) {
        case "microchip":
          this.highlightMicrochip = true;
          break;
        case "name":
          this.highlightName = true;
          break;
        case "pet_type":
          this.highlightPetType = true;
          break;
        case "dob":
          this.highlightDob = true;
          break;
        case "breed":
          this.highlightBreed = true;
          break;
      }
    },
    changePetName(name, position) {
      this.$emit('changePetName', {name, position})
      this.checkFreeInsuranceEligible();
    },
    toggleInsuranceHelp() {
      this.showInsuranceHelp = !this.showInsuranceHelp;
    },
    debounceHasLiveAgriaPolicy: debounce(function () {
      this.hasLiveAgriaPolicy();
    }, 1600),
    async hasLiveAgriaPolicy() {
      if (this.user.role === "rescue" || this.user.role === "breeder") {
        return;
      }

      if (!this.details) {
        return;
      }

      if (
          this.details.microchip &&
          (this.details.microchip.length === 10 || this.details.microchip.length === 15)
      ) {
        this.hasLivePolicy = await Agria.livePolicyCheck(this.details.microchip);
      }
      await this.checkFreeInsuranceEligible();
    },
    debounceCheckFreeInsuranceEligible: debounce(function () {
      this.checkFreeInsuranceEligible();
    }, 1600),
    async checkFreeInsuranceEligible() {
      this.showFreeInsuranceOffer = await this.freeInsuranceEligible();
    },
    async freeInsuranceEligible() {
      if (this.user.role === "rescue" || this.user.role === "breeder") {
        return;
      }

      if (this.hasLivePolicy) {
        return false;
      }

      const neutered = await this.findNeutered();

      const pet = {
        ...this.details,
        breed: this.breed,
        neutered,
        sex: this.sexStatus,
        type: this.pt,
        name: this.name,
        route: 'confirm_details'
      }

      this.insurancePet = pet;

      const petHasPrerequisites = Agria.petHasPrerequisites(pet);

      if (!petHasPrerequisites) {
        return false;
      }

      if (this.showFreeInsuranceOffer) {
        return true;
      }

      if (petHasPrerequisites && !this.hasLivePolicy) {
        const response = await Agria.freeInsuranceCheck(pet);

        this.crossbreedText = response.CrossbreedText || '';

        return response.CanOfferInsurance;
      }

      return false;
    },
    async save(details, position) {
      await this.checkForm();

      if (this.errors.length === 0) {

        const neutered = await this.findNeutered();

        const petDetails = {
          ...this.details,
          breed: this.breed,
          neutered,
          sex: this.sexStatus,
          type: this.pt[0].toUpperCase() + this.pt.slice(1), //capitalize first letter
          name: this.name,
          flow: this.flow,
        }

        let pet = null;

        if (this.pet.id > 0) {
          pet = await Pet.updateV2(petDetails);
        } else {
          pet = await Pet.create(petDetails);
        }

        if (['rehome'].includes(this.flow)) {
          await this.saveToFreeChipQueue(pet);
        }

        this.$emit('savePetDetails', {details, position})
      } else {
        const element = this.$refs['errors'];
        element.scrollIntoView({behavior: 'smooth'});
      }
    },
    async findNeutered() {
      if (this.neuteredStatus === 'neutered') {
        return true;
      }

      if (this.neuteredStatus === 'not_neutered') {
        return false;
      }

      if (this.neuteredStatus === 'unknown') {
        return null;
      }
    },
    async toggleNeutered(value) {
      this.neuteredStatus = value;
      this.checkFreeInsuranceEligible();
    },
    async toggleSex(value) {
      this.sexStatus = value;
      this.checkFreeInsuranceEligible();
    },
    async selectPetType(value) {
      if (!value) return;

      value = value.toLowerCase();

      this.petTypes.forEach(option => {
        if (option.value === value) {
          option.selected = true;
        } else {
          option.selected = false;
        }
      });

      this.pt = value;

      if (this.petTypesWithAssociatedBreeds.includes(this.pt)) {
        await this.fetchBreeds(this.pt);
        this.breed = "-- select an option --";
      }
    },
    async selectBreed(value) {
      if (!value) return;

      this.breeds.forEach(option => {
        if (option.name === value) {
          option.selected = true;
        } else {
          option.selected = false;
        }
      });

      this.breed = value;
      this.checkFreeInsuranceEligible();
    },
    async fetchBreeds(br) {
      this.breeds = await Breed.list(this.pt);

      this.breeds = [{
        id: "default",
        name: "-- select an option --",
        disabled: true
      }, ...this.breeds];
    },
    async checkForm() {
      this.errors = [];

      if (!this.details.microchip) {
        this.errors.push({field: "microchip", "message": "Microchip is required"});
      }

      if (!(this.details.microchip.length === 10 || this.details.microchip.length === 15)) {
        this.errors.push({field: "microchip", "message": "Microchip must be 10 or 15 characters in length"});
      }

      if (!this.name) {
        this.errors.push({field: "name", "message": "Pet name is required"});
      }

      if (this.pt === 'default') {
        this.errors.push({field: "name", "message": "Pet type is required"});
      }

      if (!this.details.dob) {
        this.errors.push({field: "dob", message: "Date of Birth is required"});
      }

      if (!this.breed && this.petTypesWithAssociatedBreeds.includes(this.pt)) {
        this.errors.push({field: "breed", message: "Breed is required"});
      }
    }
  },
  computed: {
    getValidationData() {
      let data = [];

      if (!this.name || this.name.length === 0) {
        data.push({
          type: "name",
          message: "You need to supply a name for the pet",
          anchor: "nameAnchorRef" + this.pet.id
        })
      }

      if (!this.details.microchip || this.details.microchip.length === 0) {
        data.push({
          type: "microchip",
          message: "You need to supply a microchip number",
          anchor: "microchipAnchorRef"
        })
      }

      if (this.details.microchip && !(this.details.microchip.length === 10 || this.details.microchip.length === 15)) {
        data.push({
          type: "microchip",
          message: "The microchip number is not a valid length",
          anchor: "microchipAnchorRef" + this.pet.id
        })
      }

      if (!this.pt || this.pt === 'default') {
        data.push({
          type: "pet_type",
          message: "You need to supply a pet type eg. dog, cat etc",
          anchor: "petTypeAnchorRef" + this.pet.id
        })
      } else {
        this.highlightPetType = false;
      }

      if (!this.details.dob) {
        data.push({
          type: "dob",
          message: "You need to supply a date of birth (or best guess)",
          anchor: "dobAnchorRef" + this.pet.id
        })
      }

      if ((this.pt && (this.pt === 'dog' || this.pt === 'cat')) && (!this.breed || this.breed === '-- select an option --')) {
        data.push({
          type: "breed",
          message: "You need to supply a breed (there are 'crossbreed' and 'unknown' options)",
          anchor: "breedAnchorRef" + this.pet.id
        })
      } else {
        this.highlightBreed = false;
      }

      return data;
    },
    computedBreed: {
      get() {
      },
      set(newValue) {
        this.petBreed = newValue;
      }
    },
    computedPetType: {
      get() {
      },
      set(newValue) {
        this.petType = newValue;
      }
    },
  },
  async mounted() {
    await this.checkFreeInsuranceEligible();
  },
  async beforeMount() {
    this.details = this.pet;

    this.showBreedSelect = !this.details.breed;

    await this.selectPetType((this.details.type) ? this.details.type : "default")

    this.name = this.details.name;

    if (this.details.neutered === true) {
      this.neuteredStatus = 'neutered';
    }

    if (this.details.neutered === false) {
      this.neuteredStatus = 'not_neutered';
    }

    if (this.details.neutered === null) {
      this.neuteredStatus = 'unknown';
    }

    this.sexStatus = this.details.sex;
    this.breed = this.details.breed;

    await this.hasLiveAgriaPolicy();
  }
}
</script>

<style scoped>
.tooltip-container {
  position: relative;
}

.tooltip-target:hover + .tooltip {
  visibility: visible;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  background-color: #0074b5;
  color: white;
  border-radius: 5px;
  padding: 10px;
}

.tooltip::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #0074b5 transparent;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  margin-left: -3px;
  border-width: 3px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

</style>