<template>
  <message :title="title" :message="message" :link="link" :clickHere="clickHere"></message>
</template>

<script>
import Message from "../components/shared/Message";

export default {
  props: {
    title: String,
    message: String,
    link: String,
    clickHere: String
  },
  components: {
    Message
  },
  data() {
    return {
      loading: false
    }
  },
}
</script>

<style scoped>

</style>
