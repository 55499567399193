var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('div',{staticClass:"tw-flex tw-flex-col tw-items-center"},[_c('label',{staticClass:"tw-block tw-w-32 tw-h-32 tw-py-4 tw-rounded-full tw-overflow-hidden tw-border tw-border-gray-300 tw-cursor-pointer tw-bg-white",on:{"click":_vm.onFileLabelClicked}},[_c('input',{staticClass:"tw-hidden",attrs:{"type":_vm.clickable && (_vm.subscriptionType === 'premium' || _vm.isAuthorisedUser)
          ? 'file'
          : 'number'},on:{"change":_vm.onFileChange}}),_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-h-full"},[(!_vm.imageUrl && !_vm.uploading)?_c('camera-upload-icon',{staticClass:"mb-4 checkout-button tw-cursor-pointer",attrs:{"alt":"camera upload icon","type":"image"}}):_vm._e(),(_vm.uploading)?_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center"},[_c('div',{staticClass:"spinner"})]):_vm._e(),(
          (_vm.subscriptionType === 'premium' || this.isAuthorisedUser) &&
          !!_vm.imageUrl &&
          !_vm.uploading
        )?_c('div',[_c('img',{attrs:{"src":_vm.imageUrl,"alt":"Pet Profile Image"}})]):_vm._e()],1)]),(_vm.showPremiumUpgradeModal)?_c('premium-upgrade-modal',{on:{"upgrade":_vm.createSubscription,"close":_vm.closePremiumUpgradeModal}}):_vm._e(),(_vm.errorMessage)?_c('div',{staticClass:"tw-text-red-500 tw-mt-2"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }