var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-flex tw-flex-col"},[_c('h2',{staticClass:"tw-float-left tw-mt-2"},[_vm._v("Address")]),_c('div',{staticClass:"tw-relative"},[(!_vm.addressManualEntry)?_c('base-input-v2',{attrs:{"label":"Find address","placeholder":_vm.hasPickedAddress ? '' : 'eg. 46 George St / DL22 7IW',"type":"text"},on:{"focus":_vm.focusAddressInput,"down":_vm.incrementAddressDisplayObjectSelectedPosition,"enter":_vm.selectAddressDisplayObjectByPosition,"input":_vm.lookupAddress,"up":_vm.decrementAddressDisplayObjectSelectedPosition},model:{value:(_vm.addressSearchField),callback:function ($$v) {_vm.addressSearchField=$$v},expression:"addressSearchField"}}):_vm._e(),_c('div',{staticClass:"tw-w-1/2 tw-inline-flex tw-justify-center tw-mt-4 tw-py-2 tw-border tw-border-transparent tw-shadow-lg tw-py-2 tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-text-opacity-100 tw-bg-mp-purple tw-opacity-70 hover:tw-opacity-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500",on:{"click":function($event){_vm.addressManualEntry = !_vm.addressManualEntry}}},[_c('div',{staticClass:"tw-ml-2 tw-mr-2"},[_vm._v(" "+_vm._s(_vm.addressManualEntry ? "search address" : "or enter manually")+" ")])]),(_vm.addressDisplayObjects.length > 0 && _vm.displayAddressSection)?_c('div',{staticClass:"tw-absolute tw-left-0 tw-right-0 tw-max-h-60 tw-overflow-y-auto tw-border tw-border-slate-500 tw-rounded-md tw-z-50"},_vm._l((_vm.addressDisplayObjects),function(addressDisplayObject){return _c('div',{key:addressDisplayObject.position,staticClass:"tw-text-sm tw-font-medium tw-flex tw-justify-items-start",class:[
          addressDisplayObject.position ===
          _vm.addressDisplayObjectSelectedPosition
            ? 'tw-bg-violet-500 tw-text-white'
            : 'tw-bg-gray-50 tw-text-gray-700',
        ],on:{"click":function($event){return _vm.selectAddress(addressDisplayObject)},"mouseover":function($event){return _vm.setAddressDisplayObjectSelectedPosition(
            addressDisplayObject.position
          )}}},[_c('span',{staticClass:"tw-ml-2",attrs:{"id":"address-item"}},[_vm._v(_vm._s(addressDisplayObject.address))])])}),0):_vm._e(),(_vm.address_line_1 || _vm.addressManualEntry || _vm.addressSelected)?_c('base-input-v2',{ref:"addressLine1AnchorRef",staticClass:"py-2",attrs:{"required":"true","highlight-class":_vm.highlightAddressLine1 ? 'animate-highlight-red' : '',"label":"Address line 1","invalid-class":_vm.address_line_1 === ''
          ? '  tw-border-solid tw-border-red-500 tw-bg-red-100'
          : '',"type":"text"},model:{value:(_vm.address_line_1),callback:function ($$v) {_vm.address_line_1=$$v},expression:"address_line_1"}}):_vm._e(),(_vm.address_line_2 || _vm.address_line_1 || _vm.addressManualEntry)?_c('base-input-v2',{staticClass:"py-2",attrs:{"label":"Address line 2","type":"text"},model:{value:(_vm.address_line_2),callback:function ($$v) {_vm.address_line_2=$$v},expression:"address_line_2"}}):_vm._e(),(_vm.address_line_2 && _vm.address_line_2.length > 0)?_c('base-input-v2',{staticClass:"py-2",attrs:{"label":"Address line 3","type":"text"},model:{value:(_vm.address_line_3),callback:function ($$v) {_vm.address_line_3=$$v},expression:"address_line_3"}}):_vm._e(),(_vm.address_line_3 && _vm.address_line_3.length > 0)?_c('base-input-v2',{staticClass:"py-2",attrs:{"label":"Address line 4","type":"text"},model:{value:(_vm.address_line_4),callback:function ($$v) {_vm.address_line_4=$$v},expression:"address_line_4"}}):_vm._e(),(_vm.postcode || _vm.addressManualEntry || _vm.addressSelected)?_c('base-input-v2',{ref:"postcodeAnchorRef",staticClass:"py-2",attrs:{"required":"true","highlight-class":_vm.highlightPostcode ? 'animate-highlight-red' : '',"uppercase":true,"invalid-class":_vm.postcode === ''
          ? '  tw-border-solid tw-border-red-500 tw-bg-red-100'
          : '',"label":"Postcode","type":"text"},model:{value:(_vm.postcode),callback:function ($$v) {_vm.postcode=$$v},expression:"postcode"}}):_vm._e(),(_vm.city || _vm.addressManualEntry || _vm.addressSelected)?_c('base-input-v2',{ref:"cityAnchorRef",staticClass:"py-2",attrs:{"required":"true","highlight-class":_vm.highlightCity ? 'animate-highlight-red' : '',"label":"Town/city","invalid-class":_vm.city === '' ? '  tw-border-solid tw-border-red-500 tw-bg-red-100' : '',"type":"text"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }