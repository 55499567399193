import Model from "./Model";

export default class User extends Model {
  static getUser() {
    return localStorage.getItem("user");
  }

  static async retrieve(includes = "microchip_queue") {
    const response = await window.Http.get(
      this.getUrl("/api/v2/users", includes)
    );
    return this.make(response.data);
  }

  static async getPetTransferData(pet_uuid) {
    const response = await window.Http.get(
      "/api/v1/users/pet-transfer-data/" + pet_uuid
    );

    return this.make(response);
  }

  static async forgotPassword(data) {
    return await window.Http.post("/api/v2/forgot-password", data);
  }

  static async updatePassword(form) {
    const response = await window.Http.post("/api/v2/reset-password", form);
    return response.token;
  }

  async updateV2(form) {
    const response = await window.Http.put("/api/v2/users/" + this.id, form);
    return this.fill(response.data);
  }

  async passwordChange(form) {
    const response = await window.Http.patch(
      "/api/v2/users/password-change",
      form
    );
    return this.fill(response);
  }

  async subscribe(data) {
    const response = await window.Http.post("/api/v1/mailinglist", data);
    return this.fill(response);
  }

  async unsubscribe(data) {
    const response = await window.Http.delete(
      "/api/v1/mailinglist/" + this.id,
      data
    );
    return this.fill(response);
  }

  async update(form) {
    const response = await window.Http.put("/api/v1/users/" + this.id, form);
    return this.fill(response.data[0]);
  }
}