import UrlBuilder from "../services/UrlBuilder";

export default class Model {
  constructor(attributes = {}) {
    this.fill(attributes);
  }

  static make(attributes = {}) {
    return Array.isArray(attributes)
      ? attributes.map((nested) => new this(nested))
      : new this(attributes);
  }

  fill(attributes = {}) {
    this.setAttributes(attributes);
    this.wrapRelationships();
    return this;
  }

  setAttributes(attributes) {
    Object.assign(this, attributes);
  }

  getAttributes() {
    return { ...this };
  }

  clone() {
    return this.constructor.make({ ...this.getAttributes() });
  }

  wrapRelationships() {
    let attributes = this.getAttributes() || {};
    let relationships = this.getRelationships() || {};

    Object.keys(relationships).forEach((key) => {
      if (attributes.hasOwnProperty(key) && attributes[key]) {
        attributes[key] = relationships[key].make(attributes[key]);
      }
    });

    this.setAttributes(attributes);
  }

  getRelationships() {
    return {};
  }

  static getUrl(
    base,
    includes,
    pagination = null,
    search = "",
    sortBy = "",
    order = "desc"
  ) {
    const urlBuilder = new UrlBuilder(base);

    if (includes) {
      urlBuilder.addQueryParam("include", includes);
    }

    if (search) {
      urlBuilder.addQueryParam("search", search);
    }

    if (sortBy) {
      urlBuilder.addQueryParam("sortBy", sortBy);
    }

    if (order) {
      urlBuilder.addQueryParam("order", order);
    }

    if (pagination) {
      if (pagination.perPage) {
        urlBuilder.addQueryParam("perPage", pagination.perPage);
      }

      if (pagination.page) {
        urlBuilder.addQueryParam("page", pagination.page);
      }
    }

    return urlBuilder.build();
  }
}
